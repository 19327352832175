import { DeviceState } from '@/store/lib/DeviceState';
import { Box,Text,Flex } from '@chakra-ui/react';
import React from 'react';
import { useStore } from '@/store/index';

const DeviceStatus=({status})=>{
  const {lang} = useStore()
  //  status - 0: created - 1: proposed  -2: confrimed
  let stateColor = DeviceState.getStateColor(status);
  //@ts-ignore
  let stateTitle = lang.t(DeviceState.getTitle(status));
  return(
    <Flex alignItems='center'>
      <Box
        background={stateColor}
        w='6px'
        h='6px'
        borderRadius='3px'
      />
      <Text ml={2}>{stateTitle}</Text>
    </Flex>
  )
}

export default DeviceStatus
