import { makeAutoObservable } from 'mobx';
import { BooleanState } from './standard/base';
import { RootStore } from './root';
import { DeviceState, NewDeviceState } from './lib/DeviceState';
import { PebbleState } from './lib/PebbleState';
import { BankState } from './lib/BankState';
import { ShiperSatate } from './lib/ShiperSatate';
import { order_by } from '../generated/gql/schema';
import { helper } from '@/lib/helper';


export class DeviceStore {
  rootStore: RootStore;
  devices: DeviceState[] = [];
  pebble = new PebbleState({});
  bank = new BankState({});
  shiper = new ShiperSatate({});
  totalNumber: number = 0;
  isConnect = new BooleanState({ value: false });
  isDeposit: boolean = false;
  currentDevice?: DeviceState = undefined;
  device?: NewDeviceState = {
    name: '',
    imei: '',
    avatar: '',
    SN: ''
  };

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.pebble.rootStore = this.rootStore;
    this.bank.rootStore = this.rootStore;
    this.shiper.rootStore = this.rootStore;
    makeAutoObservable(this);
  }

  get god() {
    return this.rootStore.god;
  }

  get currentNetwork() {
    return this.god.currentNetwork;
  }

  get confirmedDevices() {
    return this.devices.filter(device => device.status === 2);
  }

  // load device list
  async loadDevices({ limit = 0, skip = 0 }, filter) {
    if (!this.god.currentNetwork.account) {
      return [];
    }
    const response = await this.subgraphAPI.query({
      pebble_device: [
        {
          where: {
            _or: [
              {
                proposer: {
                  _ilike: this.god.currentNetwork.account
                }
              },
              {
                owner: {
                  _ilike: this.god.currentNetwork.account
                }
              }
            ],
            ...filter
          }
        },
        {
          id: true, // IMEI
          owner: true,
          address: true,
          proposer: true,
          firmware: true,
          real_firmware: true,
          name: true,
          avatar: true,
          type: true,
          status: true,
          state: true,
          updated_at: true,
          upload_period: true,
          total_gas: true,
          beep: true,
          bulk_upload: true,
          data_channel: true,
          deviceRecord: [
            {
              limit: 1,
              order_by: [
                { timestamp: order_by.desc }
              ]
            },
            {
              vbat: true,
              gas_resistance: true,
              temperature: true,
              temperature2: true,
              pressure: true,
              humidity: true,
              light: true,
              gyroscope: true,
              latitude: true,
              longitude: true,
              timestamp: true
            }
          ]
        }
      ],
      pebble_device_aggregate: [
        {
          where: {
            _or: [
              {
                proposer: {
                  _ilike: this.god.currentNetwork.account
                }
              },
              {
                owner: {
                  _ilike: this.god.currentNetwork.account
                }
              }
            ],
            ...filter
          }
        },
        {
          aggregate: {
            count: true
          }
        }
      ]
    });

    // @ts-ignore
    this.devices = response.data.pebble_device.map(device => {
      //@ts-ignore
      device.deviceRecord = device.deviceRecord[0] || {};
      //@ts-ignore
      device.channels = helper.getConfigKey(device.data_channel);
      //@ts-ignore
      return new DeviceState(device);
    });
    // @ts-ignore
    this.totalNumber = response.data.pebble_device_aggregate.aggregate.count;
    return this.devices;
  }

  get subgraphAPI() {
    return this.rootStore.pebbleApi.subgraphAPI;
  }

  subscribeInstallFirmwareDevice(imei) {
    return this.subgraphAPI.subscription({
      pebble_device: [
        {
          where: {
            id: {
              _eq: imei
            }
          }
        },
        {
          id: true,
          firmware:true,
          real_firmware:true
        }
      ]
    });
  }

  // bank balance
  loadBankBalanceOf() {
    return this.currentNetwork.multicall([
      this.bank.preMulticall({
        address: this.rootStore.god.currentChain.pebbleContracts.BANK_CONTRACT,
        method: 'balanceOf',
        params: [this.currentNetwork.account],
        handler: this.bank.balance
      })
    ]);
  }
}
