import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '@/store/index';
import { Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from '@chakra-ui/react';
import { ModalOverlay } from '@chakra-ui/modal';
import { DeviceState } from '@/store/lib/DeviceState';
import { PrimaryButton, PrimaryLineButton } from '@/components/Button';
import { toast } from 'react-hot-toast';

const RemoveDeviceModal = observer(({ isOpen, onClose, device }: { isOpen: boolean; onClose: () => void; device: any }) => {
  const { lang, deviceStore } = useStore();
  const store = useLocalObservable(() => ({
    isLoading: false,
    isSuccess: false,
    device: {
      id: ''
    },
    onUntie: async () => {
      store.isLoading = true;
      try {
        const res = await deviceStore.pebble.removeDevice({ params: [store.device.id] });
        const receipt = await res.wait();
        console.log('removeDevice', receipt);
        if (receipt.status) {
          toast.success(receipt.blockHash);
          await deviceStore.loadDevices({}, {});
        }
        store.isSuccess = true;
        store.isLoading = false;
      } catch (error) {
        console.log(error);
        store.isLoading = false;
        if (error.code !== 4001) toast.error(error.data.message);
      }
    }
  }));
  useEffect(() => {
    store.device = device;
  }, []);
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="mainColor">
          <ModalHeader>{store.isSuccess ? lang.t('remove.device.success.title') : lang.t('remove.device.title')} </ModalHeader>
          <ModalCloseButton _focus={{}} onClick={onClose} _hover={{ color: 'brandColor' }} />
          <ModalBody>
            {store.isSuccess ? lang.t('remove.device.success.desc') : lang.t('remove.device.desc', { deviceName: device.name })}
            <Divider mt={8} />
          </ModalBody>
          <Flex justifyContent="flex-end" my={4}>
            {store.isSuccess ? (
              <>
                <PrimaryButton onClick={onClose} isLoading={store.isLoading} text={lang.t('ok')} />
              </>
            ) : (
              <>
                <PrimaryLineButton text={lang.t('cancel')} mr={2} onClick={onClose} />
                <PrimaryButton isLoading={store.isLoading} text={lang.t('confirm')} mr={2} onClick={store.onUntie} />
              </>
            )}
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
});

RemoveDeviceModal.displayName = 'RemoveDeviceModal';

export default RemoveDeviceModal;
