import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { useStore } from '@/store/index';
import { helper } from '@/lib/helper';
import { Flex, Image, Spinner, Td, Text, Tr } from '@chakra-ui/react';
import { Header } from '@/components/Table/type';
import ITable from '@/components/Table/Table';

const MyDataTable = observer(({ imei }: { imei: string }) => {
  const { lang, deviceRecordStore } = useStore();
  const store = useLocalObservable(() => ({
    isLoading: false,
    fetchDeviceRecords: async (pagination) => {
      store.isLoading = true;
      await deviceRecordStore.loadDeviceLogs(pagination, { imei: imei });
      store.isLoading = false;
      return {
        items: deviceRecordStore.devicelogs,
        totalItems: deviceRecordStore.totalDeviceLogs
      };
    },
    lastTime: null,
    fetchLastTime: async () => {
      store.lastTime = await deviceRecordStore.loadLastLogsTime(imei);
    }
  }));
  // @ts-ignore
  const columns: Header[] = [
    {
      text: lang.t('time'),
      value: 'timestamp',
      formatter: (value) => helper.formatTime(value)
    },
    {
      text: lang.t('temperature'),
      value: 'temperature',
      children: (item) => {
        return (
          <Flex justifyContent='center' alignItems='center'>
            <Image src='images/mydata/Temperature.png' />
            <Text color='#56CCF2'>{item.temperature}</Text>
          </Flex>
        );
      }
    },
    {
      text: lang.t('pressure'),
      value: 'pressure',
      children: (item) => {
        return (
          <Flex justifyContent='center' alignItems='center'>
            <Image src='images/mydata/pressure.png' />
            <Text color='#9290FE'>{item.pressure}</Text>
          </Flex>
        );
      }
    },
    {
      text: lang.t('humidity'),
      value: 'humidity',
      children: (item) => {
        return (
          <Flex justifyContent='center' alignItems='center'>
            <Image src='images/mydata/Humidity.png' />
            <Text color='#FFB572'>{item.humidity}</Text>
          </Flex>
        );
      }
    },
    {
      text: lang.t('gas'),
      value: 'gas_resistance',
      children: (item) => {
        return (
          <Flex justifyContent='center' alignItems='center'>
            <Image src='images/mydata/gas.png' />
            <Text color='#B1C7DF'>{item.gas_resistance}</Text>
          </Flex>
        );
      }
    },
    {
      text: lang.t('LIGHT_SENSOR'),
      value: 'light',
    },
    {
      text: lang.t('ACCELEROMETER'),
      value: 'accelerometer',
    },
    {
      text: lang.t('GYROSCOPE'),
      value: 'gyroscope',
    },
    {
      text: lang.t('location'),
      value: 'latitude',
      children: (item) => <Text>{item.latitude===200&&item.longitude===200?lang.t('nogps'):`[${item.latitude},${item.longitude}]`}</Text>
    }
  ];
  const intervalRef = useRef(null);
  const timeInterval = 60000;
  useEffect(() => {
    store.fetchLastTime();
    intervalRef.current = setInterval(() => {
      store.fetchLastTime();
    }, timeInterval);

    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <>
      <ITable headers={columns} fetch={store.fetchDeviceRecords} timeInterval={timeInterval}>
        <Tr style={{ backgroundColor: 'rgba(18, 18, 34, 1)' }}>
          <Td textAlign='left' colSpan={columns.length}>
            {store.isLoading && <Spinner color='brandColor' />}
            {store.lastTime && <Text color='textLight45' ml={4} display='inline-block'>Scanned up
              to {helper.formatTime(store.lastTime, 'DD/MM/YY, HH:mm')}</Text>}
          </Td>
        </Tr>
      </ITable>
    </>
  );
});
MyDataTable.displayName = 'MyDataTable';

export default MyDataTable;
