import { makeAutoObservable } from 'mobx';
import { RootStore } from './root';
import { NetworkState } from './lib/NetworkState';
import coreAbi from '@/constants/abi/Core.json'
import { FirmwareState} from '@/store/lib/FirmwareState';

export class Firmware {
  id: string = '';
  uri: string = '';
  avatar: string = '';
  version: string = '';

  network: NetworkState;
  constructor(args: Partial<Firmware>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }
}

export class FirmwareStore {
  rootStore: RootStore;
  firmwareList: Firmware[] = [];
  firmware = new FirmwareState({})
  isOperators: boolean = false

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get god() {
    return this.rootStore.god;
  }

  get currentNetwork() {
    return this.god.currentNetwork;
  }

  get subgraphAPI(){
    return this.rootStore.pebbleApi.subgraphAPI
  }

  async loadFirmware() {
    const response = await this.subgraphAPI.query({
      pebble_app: [
        {},
        {
          id: true,
          uri: true,
          avatar: true,
          version: true
        }
      ]
    });
    //@ts-ignore
    this.firmwareList = response.data.pebble_app.map(i => new Firmware(i));
    return true
  }


  async queryFirmware({id,version}){
    const response = await this.subgraphAPI.query({
      pebble_app: [
        {
          where:{
            id:{
              _eq:id
            },
            version:{
              _eq:version
            }
          }
        },
        {
          id: true,
          uri: true,
          avatar: true,
          version: true
        }
      ]
    });
    // @ts-ignore
    return response.data.pebble_app[0]
  }

}
