import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  Box,
  Button,
  Flex,
  Text,
  Image, Modal, ModalOverlay, ModalHeader, ModalContent, ModalCloseButton, ModalBody, Divider
} from '@chakra-ui/react';
import { Link } from '@chakra-ui/layout';
import { useStore } from '@/store/index';
import { AddDeviceLoading } from './AddDeviceLoading';
import { useHistory } from 'react-router-dom';
import { PrimaryButton, PrimaryLineButton } from '@/components/Button';
import { DepositDialog } from '@/components/depositDialog';

interface IComponentProps {
  nextStep: () => void;
  prevStep: () => void;
  setComplete: () => void;
}

const ConfirmPebble = observer(({ nextStep, prevStep, setComplete }: IComponentProps) => {
  const { lang, god, deviceStore } = useStore();
  const history = useHistory();
  const store = useLocalObservable(() => ({
    isLoading: false,
    errorMsg: '',
    isSuccess: false,
    isOpenDeposit: false,
    closeDeposit() {
      this.isOpenDeposit = false;
    },
    openDeposit() {
      this.isOpenDeposit = true;
    },
    deviceAddress: '',
    get isOpen() {
      return !!this.errorMsg;
    },
    onClose() {
      this.errorMsg = '';
    },
    set loading(value) {
      this.isLoading = value;
    },
    async fetchAddress(){
      const [result] = await deviceStore.loadDevices({}, {
        id: {
          _eq: deviceStore.device.imei
        }
      });
      if (result){
        this.deviceAddress = result.address;
      }
    },
    async addDevice() {
      this.isSuccess = true
      setComplete();

    },
    finish() {
      deviceStore.device = {
        imei: '',
        avatar: '',
        name: '',
        SN: ''
      };
      history.push('/device');
    }
  }));

  useEffect(()=>{
    store.fetchAddress()
  },[])

  return (
    <>
      {
        !store.isLoading && !store.isSuccess && (
          <>
            <Box mt={20}>
              <Flex alignItems={{ base: 'center', xl: 'flex-start' }} flexDirection={{ base: 'column', xl: 'row' }}
                    textAlign='left' wordBreak={'break-word'}>
                <Image src='/images/device/step3_img.png' />
                <Box ml={{ base: 0, md: 20 }}>
                  <Text fontSize='1rem' fontWeight='normal' mb='3'>
                    {lang.t('add.device.step3.tips')}
                  </Text>
                  <Flex mt={5}>
                    <Box>
                      <Flex flexDirection={{ base: 'column', md: 'row' }}>
                        <Text fontSize='1rem' fontWeight='normal' color='textLight45' w='9rem'>
                          {lang.t('wallet.address')}:
                        </Text>
                        <Text fontSize='1rem' fontWeight='normal'>
                          {god.displayAddressType === '0x' ? god.currentNetwork.account : god.accountIoAddress}
                        </Text>
                      </Flex>
                      <Flex mb='5' flexDirection={{ base: 'column', md: 'row' }}>
                        <Text fontSize='1rem' fontWeight='normal' w='9rem' color='textLight45'>
                          {lang.t('device.imei')}:
                        </Text>
                        <Text mb='3' fontSize='1rem'>{deviceStore.device.imei}</Text>
                      </Flex>
                    </Box>

                  </Flex>
                  <Text fontSize='sm' color='textWhite50' mb='3'>
                    {lang.t('add.device.step3.note')}
                  </Text>
                </Box>
              </Flex>
              <Flex justifyContent='flex-end' flexDirection={{ base: 'column-reverse', md: 'row' }}
                    mt={{ base: 10, md: 40 }}>
                <Link _hover={{}} display={'inherit'}>
                  <PrimaryLineButton
                    h='50px'
                    w={{ base: '100%', md: '270px' }}
                    size='md'
                    fontWeight='400'
                    mt={{ base: 5, md: 0 }}
                    mr={{base:0,md:10}}
                    onClick={prevStep}
                  >
                    Back
                  </PrimaryLineButton>
                </Link>
                <Button
                  _hover={{}}
                  _focus={{}} h='50px'
                  w={{ base: '100%', md: '270px' }}
                  bg='btnPrimaryColor' color='black' fontWeight={400} border='unset'
                  onClick={store.addDevice}
                >{lang.t('next')}</Button>
              </Flex>
            </Box>

          </>
        )
      }
      {
        store.isLoading && <AddDeviceLoading />
      }
      {
        store.isSuccess && (
          <>
            <Flex mt={20} flexDirection={{base:'column',md:'row'}} textAlign='left'>
              <Image src='/images/device/img_added.png' w={{base:'100%',md:'30%'}} />
              <Box px={{base:0,md:10}}>
                <Text fontSize='1rem' dangerouslySetInnerHTML={{ __html: lang.t('add.device.done') }}
                      whiteSpace='pre-line' />
                <Flex mt={10} flexDirection={'column'} fontSize='1rem'>
                  <Flex flexDirection={{base:'column',md:'row'}}>
                    <Text color='textLight45' minW='140px'>{lang.t('device.name')}:</Text>
                    <Text>{deviceStore.device.name}</Text>
                  </Flex>
                  <Flex flexDirection={{base:'column',md:'row'}} mt={{base:5,md:0}}>
                    <Text color='textLight45' minW='140px'>{lang.t('device.imei')}:</Text>
                    <Text>{deviceStore.device.imei}</Text>
                  </Flex>
                  {
                    store.deviceAddress&&(
                      <Flex flexDirection={{base:'column',md:'row'}} mt={{base:5,md:0}}>
                        <Text color='textLight45' minW='140px'>{lang.t('device.address')}:</Text>
                        <Text>{store.deviceAddress}</Text>
                      </Flex>
                    )
                  }
                </Flex>
              </Box>
            </Flex>
            <Flex justifyContent='flex-end' flexDirection={['column', 'row']} w='100%' mt={{base:5,md:0}}>

              <PrimaryLineButton onClick={store.openDeposit} text={lang.t('add.deposit')} w={{base:'100%',md:'270px'}} mr={4} />
              <PrimaryButton mt={{base:5,md:0}} w={{base:'100%',md:'270px'}} onClick={store.finish} text={lang.t('finish')} />
            </Flex>
          </>
        )
      }
      {
        store.errorMsg && (
          <Modal isOpen={store.isOpen} onClose={store.onClose} size='xl' isCentered>
            <ModalOverlay />
            <ModalContent bg='#1C1B30'>
              <ModalHeader>
                {lang.t('add.device.fail')}
              </ModalHeader>
              <ModalCloseButton _focus={{}} _hover={{ color: 'brandColor' }} onClick={store.onClose} />
              <ModalBody>
                <Flex alignItems='center'>
                  <Image src='/images/device/timeout.png' />
                  <Text>{store.errorMsg}</Text>
                </Flex>
                <Divider />
                <Flex mt={5} justifyContent='flex-end' mb={2}>
                  <Button
                    _hover={{}}
                    _focus={{}}
                    bg='btnPrimaryColor'
                    color='black'
                    fontWeight='normal'
                    onClick={() => {
                      store.onClose();
                      // store.addDevice();
                    }}
                  >{lang.t('ok')}</Button>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        )
      }
      <DepositDialog onClose={store.closeDeposit} isOpen={store.isOpenDeposit} />

    </>
  );
});
ConfirmPebble.displayName = 'ConfirmPebble';
export default ConfirmPebble;
