import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '@/store/index';
import { Divider, Flex, Image, Text } from '@chakra-ui/react';
import { PrimaryButton, PrimaryLineButton } from '@/components/Button';

const Step3Confirm = observer(({ nextStep, prevStep,currentDevice,currentApp }:any)=>{
  const{lang} = useStore()

  return(
    <>
        <Flex alignItems='flex-start' mt={10}>
          <Image w='7.5rem' h='7.5rem' src='/images/apps/enter_ota.gif' />
          <Text ml={10} whiteSpace='pre-line' dangerouslySetInnerHTML={{__html:lang.t('confirm.update.desc',{deviceName:currentDevice.name,currentFirmware:currentDevice.real_firmware||'undefined',firmware:`${currentApp.firmware}`})}}/>
        </Flex>
        <Divider mt={10} />
        <Flex justifyContent='flex-end' mt={5}>
          <PrimaryLineButton w='140px' mr={4} onClick={prevStep}>
            {lang.t('back')}
          </PrimaryLineButton>
          <PrimaryButton w='140px' onClick={nextStep}>
            {lang.t('next')}
          </PrimaryButton>
        </Flex>
    </>
  )
})

Step3Confirm.displayName = 'Step3Confirm';

export default Step3Confirm
