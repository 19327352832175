import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '@/store/index';
import { Box, Heading, Text } from '@chakra-ui/react';

export const TermOfService = observer(() => {
  const { lang } = useStore();

  return (
    <Box pb='1rem'>
      <Heading color='white' fontSize='2rem'>{lang.t('term.title')}</Heading>
      <Text mt={2} color='textLight45' fontWeight={500}>{lang.t('term.timestamp')}</Text>
      <Box mt={8} fontSize='1rem'>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc1')}</Text>
        <Text mt={4} opacity={0.85} dangerouslySetInnerHTML={{__html:lang.t('term.desc2')}}/>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc3')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc4')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc5')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc7')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc8')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc9')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc10')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc11')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc12')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc13')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc14')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc15')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc16')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc17')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc18')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc19')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc20')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc21')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc22')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc23')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc24')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc25')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc26')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc27')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc28')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc29')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc30')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc31')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc32')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc33')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc34')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc35')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc36')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc37')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc38')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc39')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc40')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc41')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc42')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc43')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc44')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc45')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc46')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc47')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc48')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc49')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc50')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc51')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc52')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc53')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc54')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc55')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc56')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc57')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc58')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc59')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc60')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc61')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc62')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc63')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('term.desc64')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc65')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc66')}</Text>
        <Text mt={4} opacity={0.85}>{lang.t('term.desc67')}</Text>
      </Box>
    </Box>
  );
});
