import { observer } from 'mobx-react-lite';
import React from 'react';
import { Heading, Text, Box, ListItem, UnorderedList } from '@chakra-ui/react';
import { useStore } from '@/store/index';

export const PrivacyPolicy = observer(() => {
  const { lang } = useStore();

  return (
    <Box>
      <Heading color='white' fontSize='2rem'>{lang.t('policy.title')}</Heading>
      <Text mt={2} color='textLight45' fontWeight={500}>{lang.t('policy.timestamp')}</Text>
      <Box mt={8} fontSize='1rem'>
        <Text mt={8}>{lang.t('policy.desc1')}</Text>
        <Text mt={4}>{lang.t('policy.desc2')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc3')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc4')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc5')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc6')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc7')}</Text>
        <UnorderedList lineHeight='24px' opacity='0.85'>
          <ListItem mt={4} fontWeight={500}>{lang.t('policy.desc8')}</ListItem>
          <ListItem mt={0} fontWeight={500}>{lang.t('policy.desc9')}</ListItem>
          <ListItem mt={0} fontWeight={500}>{lang.t('policy.desc10')}</ListItem>
          <ListItem mt={0} fontWeight={500}>{lang.t('policy.desc11')}</ListItem>
          <ListItem mt={0} fontWeight={500}>{lang.t('policy.desc12')}</ListItem>
        </UnorderedList>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc13')}</Text>
        <Text mt={4} fontStyle='italic' opacity='0.85'>{lang.t('policy.desc14')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc15')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc16')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc17')}</Text>
        <Text mt={4} fontStyle='italic' opacity='0.85'>{lang.t('policy.desc18')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc19')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc20')}</Text>
        <Text mt={4} fontStyle='italic' opacity='0.85'>{lang.t('policy.desc21')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc22')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc23')}</Text>
        <Text mt={4} fontStyle='italic' opacity='0.85'>{lang.t('policy.desc24')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc25')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc26')}</Text>
        <Text mt={4} opacity='0.85' dangerouslySetInnerHTML={{__html:lang.t('policy.desc27')}}/>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc28')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc29')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc30')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc31')}</Text>
        <Text mt={4} fontStyle='italic' opacity='0.85'>{lang.t('policy.desc32')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc33')}</Text>
        <Text mt={4} opacity='0.85'>{lang.t('policy.desc34')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc35')}</Text>
        <Text mt={4} fontStyle='italic' opacity='0.85'>{lang.t('policy.desc36')}</Text>
        <Text mt={4}  opacity='0.85'>{lang.t('policy.desc37')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc38')}</Text>
        <Text mt={4}  opacity='0.85'>{lang.t('policy.desc39')}</Text>
        <Text mt={4}  opacity='0.85'>{lang.t('policy.desc40')}</Text>
        <Text mt={4}  opacity='0.85'>{lang.t('policy.desc41')}</Text>
        <Text mt={4}  opacity='0.85'>{lang.t('policy.desc42')}</Text>
        <Text mt={8} fontWeight={500}>{lang.t('policy.desc43')}</Text>
        <Text mt={4}  opacity='0.85' dangerouslySetInnerHTML={{__html:lang.t('policy.desc44')}}/>

      </Box>
    </Box>
  );
});
