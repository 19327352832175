import { LangStore } from './lang';
import { GodStore } from './god';
import { TokenStore } from './token';
import { DeviceStore } from './device'
import { APPStore } from './app';
import { DeviceRecordStore } from './mydata';
import { AccountStore } from './account';
import { FirmwareStore } from '@/store/firmware';
import { PebbleAPIStore } from '@/store/pebbleAPI';

export class RootStore {
  lang = new LangStore();
  god = new GodStore(this);
  // token = new TokenStore(this);
  deviceStore = new DeviceStore(this)
  appStore = new APPStore(this)
  deviceRecordStore = new DeviceRecordStore(this)
  accountStore = new AccountStore(this)
  firmware = new FirmwareStore(this)
  pebbleApi = new PebbleAPIStore(this)
}
