import React, {useEffect} from 'react';
import { useStore } from '@/store/index';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  Button,
  Image,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Text,
  ModalOverlay,
  useDisclosure,
  useColorMode,
  Flex,
  Box,
  Select
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { APPState } from '@/store/app';

export enum EStep {
  Step1 = 0,
  Step2,
  Downloading,
  Completed
}
interface IProps {
  step: EStep;
  onSubmit?: (step: EStep) => void;
  onClosed: () => void;
  onSelectDevice: (value: string) => void;
  isOpened: boolean;
  app: APPState;
}

export const AppsDialogContent: React.FC<IProps> = observer(({ step, onSubmit, isOpened, onClosed, app, onSelectDevice }) => {
  const { isOpen } = useDisclosure({ isOpen: isOpened });
  const { lang, deviceStore } = useStore();
  const { colorMode } = useColorMode();
  const router = useHistory();
  // const contentInfos = [
  //   {
  //     subtitle: lang.t('app.initialize.step1.subtitle'),
  //     description: lang.t('app.initialize.step1.description')
  //   },
  //
  //   {
  //     subtitle: lang.t('app.initialize.step2.subtitle'),
  //     description: lang.t('app.initialize.step2.description', { name: app.id, version: app.version })
  //   },
  //   {
  //     subtitle: null,
  //     description: lang.t('app.initialize.loading.description')
  //   },
  //   {
  //     subtitle: lang.t('app.initialize.completed.subtitle'),
  //     description: lang.t('app.initialize.completed.description')
  //   }
  // ];

  const store = useLocalObservable(() => ({
    get confirmedDevices() {
      return deviceStore.devices.filter(item => item.status === 2)
    },
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClosed} isCentered closeOnOverlayClick={false} size="xl">
      <ModalOverlay />
      <ModalContent>
        <Text pl={6} py={2} fontWeight="bold" fontFamily="Roboto" fontSize="lg" color={colorMode === 'light' ? 'blackColor' : 'ligntColor'}>
          {lang.t('app.initialize.title')}
        </Text>
        <Divider />
        <ModalCloseButton size="sm" />
        <ModalBody>
          <Flex pb="2rem">
            <Box width="100%">
              <Flex py={2}>
                {step === EStep.Completed && <IoIosCheckmarkCircleOutline size="22" color="#43C9BA" style={{ flex: 'none' }} />}
                {step !== EStep.Downloading ? (
                  <Text as="span" pl={step === EStep.Completed ? 2 : 0} fontWeight="500" fontFamily="Roboto" fontSize="16px" color={colorMode === 'light' ? 'blackColor' : 'ligntColor'}>
                    {/*{contentInfos[step].subtitle}{' '}*/}
                  </Text>
                ) : (
                  <Box m="0 auto" my="3">
                    <Image boxSize="30px" src="/images/Download.png" alt="" />
                  </Box>
                )}
              </Flex>
              <Flex>
                <Text
                  pl={step === EStep.Completed ? 7 : 0}
                  py={2}
                  align={step === EStep.Downloading ? 'center' : 'left'}
                  fontFamily="Roboto"
                  fontSize="14px"
                  color={colorMode === 'light' ? 'blackColor' : 'ligntColor'}
                >
                  {/*{contentInfos[step].description}*/}
                </Text>
                {step === EStep.Step1 && <Image boxSize="90px" src="/images/img_green_light.png" alt="Step1" />}
              </Flex>
              {step === EStep.Step2 && (
                store.confirmedDevices.length !== 0 ?  <Box>
                <Select placeholder={lang.t("select.device")} onChange={(e) => {
                  onSelectDevice(e.target.value)
                }}>
                  {
                    store.confirmedDevices.filter(item => item.status === 2).map(item => {
                      return <option key={item.id} value={item.id}>{item.name}</option>
                    })
                  }
                </Select>
              </Box> : <Text color="brandColor" onClick={() => router.push('/device')}>{lang.t("confirm.device")}</Text>
              )}
            </Box>
          </Flex>
        </ModalBody>
        {step !== EStep.Downloading && (
          <Box>
            <Divider />
            <ModalFooter>
              {step !== EStep.Completed && (
                <Button size="sm" variant="outline" color="mainColor" bg="lightColor" onClick={onClosed}>
                  {lang.t('cancel')}
                </Button>
              )}
              <Button
                onClick={() => {
                  onSubmit?.(step);
                }}
                ml="3"
                size="sm"
                color="lightColor"
                bg="brandColor"
              >
                {step !== EStep.Completed ? lang.t('next') : lang.t('app.launch.title')}
              </Button>
            </ModalFooter>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
});
