import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { Avatar, Box, Button, Divider, Flex, Text, Tooltip, useColorMode } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { helper } from '@/lib/helper';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { CopyIcon } from '@chakra-ui/icons';
import { PrimaryButton, PrimaryLineButton } from '@/components/Button';
import { ExclamationCircle } from '@/components/Icons';

export const Profile = observer(({ onOpen, onOpenWithdraw }: { onOpen: () => void, onOpenWithdraw: () => void }) => {
  const { lang, accountStore, god, deviceStore } = useStore();
  const store = useLocalObservable(() => ({
    isLoading: false,
    isOpen: false
  }));

  return (
    <Box mt={10} bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
         borderRadius='20px' padding='40px'>
      <Flex mt='2rem' justifyContent='space-between' alignItems={{ base: 'flex-start', md: 'center' }}
            flexDirection={{ base: 'column', md: 'row' }}>
        <Flex alignItems='center'>
          <Avatar name='' boxSize='72px' src={accountStore.details.avatar} alt='device avatar' mr='1.25rem' />
          <Box>
            <Text fontSize='1.25rem' fontWeight='medium' mb='0.75rem'>
              {accountStore.details.name}
            </Text>
            <Flex alignItems='center'>
              <Text fontSize='0.875rem'>
                {lang.t('connected.wallet')}: {helper.string.truncate(god.currentNetwork.account, 12, '...')}
              </Text>
              <Box
                cursor='pointer'
                onClick={() => {
                  copy(god.currentNetwork.account);
                  toast.success(lang.t('address.copied'));
                }}
                ml='4px'
                mt='4px'
              >
                <CopyIcon color='brandColor' />
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex mt={{ base: '3rem', md: 0 }} justifyContent={{ base: 'space-between', md: 'flex-end' }}
              w={{ base: '100%', md: 'max-content' }}>
          <Box mr={{ base: '0', md: '10rem' }} textAlign={{ base: 'center', md: 'right' }}>
            <Text fontSize='0.875rem' mb='0.5rem'
                  color='textLight45'>{lang.t('connected.devices')}</Text>
            <Text fontSize='1.25rem' textAlign='center' fontWeight='medium'>{deviceStore.devices.length}</Text>
          </Box>
          <Box textAlign={{ base: 'center', md: 'right' }} w={{ base: '50%', md: 'max-content' }}>
            <Flex alignItems='center'>
              <Text
                color={deviceStore.bank.balanceStatus ? 'textLight45' : 'warnColor'}>{deviceStore.bank.balanceStatus ? lang.t('credit.balance') : lang.t('insufficient.balance')}</Text>

              <Tooltip hasArrow fontWeight={400} w='240px' fontSize='12px' label={lang.t('warn.text')} bg='white'>
                <ExclamationCircle ml={1} />
              </Tooltip>

            </Flex>
            <Text textAlign='center' fontSize='1.25rem' fontWeight='medium'
                  color={deviceStore.bank.balanceStatus ? 'white' : 'warnColor'}>{deviceStore.bank.balance.format} IOTX</Text>
          </Box>
        </Flex>
      </Flex>
      <Divider my='2rem' />
      <Flex justifyContent={{ base: 'center', md: 'flex-end' }} mb='2rem'>
        {
          deviceStore.bank.balanceStatus && <PrimaryLineButton
            w={{ base: '90%', md: '200px' }}
            h='46px'
            ml='1rem'
            px='1rem'
            onClick={onOpenWithdraw}
          >
            {lang.t('withdraw')}
          </PrimaryLineButton>
        }
        {
          !deviceStore.bank.balanceStatus && <Button
            ml='1rem'
            px='1rem'
            color='textLight45'
            cursor='not-allowed'
            w={{ base: '90%', md: '200px' }}
            borderRadius='10px'
            h='46px'
            border='1px solid #999999'
            bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'>
            {lang.t('withdraw')}
          </Button>
        }
        <PrimaryButton
          w={{ base: '90%', md: '200px' }}
          h='46px'
          px='1rem'
          ml='1rem'
          onClick={onOpen}
        >
          {lang.t('deposit.credit')}
        </PrimaryButton>
      </Flex>
    </Box>
  );
});
