import { observer, useLocalObservable } from 'mobx-react-lite';
import { Image, Box, Flex, Text, SimpleGrid, Center, Stack } from '@chakra-ui/react';
import { EStep } from '@/components/appDialog/dialog';
import React, { useEffect } from 'react';
import { useStore } from '@/store/index';
import { APPState } from '@/store/app';
import { DeviceState } from '@/store/lib/DeviceState';
import toast from 'react-hot-toast';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import { publicConfig } from '../../config/public';
import { AddIcon } from '@/components/Icons';
import { Link } from '@chakra-ui/layout';
import {groupBy} from 'lodash'

export enum AppStatus{
  OFFLINE='offline',
  COMING ='coming',
  PUBLISH='publish'
}


export const DeviceApps = observer(() => {
  const { god, lang, appStore } = useStore();
  const router = useHistory();
  const APP_CATEGORY_MAPPING={
    0:{
      title:'Dapps & Play-to-Earn',
      logo:'/images/apps/category_0.png'
    },
    1:{
      title:'Submit Your Data to a Service API3 ',
      subTitle:'Turn your machine into an oracle with your own terms/pricing',
      logo:'/images/apps/category_1.png'
    },
    2:{
      title:'Join a Data Consortium',
      logo:'/images/apps/category_2.png'
    }
  }

  const APPS_CAN_INSTALL = ['Scaleout','Firmware Upgrade','CityDAO']

  const store = useLocalObservable(() => ({
    open: false,
    isAdd: true,
    loading: false,
    addDialog: false,
    showPick: false,
    step: EStep.Step1,
    currentItem: null as APPState,
    pickedDevice: null as DeviceState,
    deviceImei: null as string,
    getAppList: async () => {
      store.currentItem = null as APPState;
      try {
        store.loading = true;
        await appStore.loadAPPS();
        store.loading = false;
      } catch (error) {
        console.log(error);
        store.loading = false;
      }
    },
    get appGroups(){
      return groupBy(appStore.appList.filter(app=>app.status!==AppStatus.OFFLINE&&app.category!==null),'category')
    },
  }));


  useEffect(() => {
    store.getAppList();
  }, [god.isConnect,god.currentNetwork.account]);

  return (
    <Box>
      {store.loading ? (
        <LoadingSpinner />
      ) : (
        <Box padding="4">
          <Link _hover={{color:'unset'}} isExternal href='https://github.com/iotexproject/machinefi-dapps'>
            <Flex alignItems='center' px={10} py={10}  bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)' borderRadius='20px'>
              <AddIcon w='62' h='62'/>
              <Box ml={5}>
                <Text color='brandColor' fontSize='28px' fontWeight={500}>{lang.t('Your Dapp Here')}</Text>
                <Text size='sm'>{lang.t('Your Dapp Here.desc')}</Text>
              </Box>
            </Flex>
          </Link>
          <Box minHeight="100px" position="relative" mt={14}>
            <Stack spacing={20}>
              {
                store.appGroups&&Object.keys(store.appGroups).map(key=>{
                  return(
                    <Box key={`group_${key}`} mt={key==='0'?0:20}>
                      <Flex alignItems='center'>
                        <Image w='35px' h='35px' src={APP_CATEGORY_MAPPING[key].logo}/>
                        <Box ml={4}>
                          <Text  fontSize='28px' fontWeight={500}>{lang.t(APP_CATEGORY_MAPPING[key].title)}</Text>
                          {APP_CATEGORY_MAPPING[key].subTitle&&<Text color='textLight45'>{lang.t(APP_CATEGORY_MAPPING[key].subTitle)}</Text>}
                        </Box>
                      </Flex>
                      <SimpleGrid minChildWidth={'320px'} mt={10} spacingX={6} spacingY={6}>
                        {
                          store.appGroups[key].map((app)=>{
                            return(
                              <>
                                <Flex
                                  onClick={()=>{
                                    if (app.direct_link){
                                      window.open(app.direct_link)
                                    }
                                    if(APPS_CAN_INSTALL.includes(app.id)) {
                                      router.push(`/apps/${app.id}`)
                                    }
                                  }}
                                  bgColor='#17162A'
                                  maxW="350px"
                                  maxH={"150px"}
                                  align="start"
                                  justify="space-around"
                                  direction="row"
                                  key={app.id}
                                  boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15)"
                                  borderRadius="20px"
                                  pt="2.75rem"
                                  py="1.75rem"
                                  px={5}
                                  css={{
                                    '&:hover': {
                                      background:'linear-gradient(90deg, rgba(67, 201, 186, 0.3) 4.38%, rgba(150, 67, 201, 0.3) 108.12%)'
                                    }
                                  }}
                                  cursor='pointer'
                                  position="relative"
                                >
                                  <Box h="96px" w="96px" position='relative'>
                                    <Image mb="1rem" borderRadius='20px' src={`${publicConfig.CDN}${app.logo}`} alt="Logo" minH="96px" minW="96px"/>
                                    {/*{*/}
                                    {/*  app.status===AppStatus.COMING&&(*/}
                                    {/*    <Center bgColor='#43C9BA' position='absolute' bottom='0' w='100%' h='43px' borderBottomRadius='20px'>*/}
                                    {/*      <Text fontSize='12px'>Coming soon</Text>*/}
                                    {/*    </Center>*/}
                                    {/*  )*/}
                                    {/*}*/}
                                  </Box>
                                  <Box ml={4} w='70%' maxH={'full'} overflowY={'hidden'}>
                                    <Text fontSize="1.25rem" fontWeight="semibold" >
                                      {app.id}
                                    </Text>
                                    <Text fontSize="0.625rem"  mt={2} >
                                      {app.slug}
                                    </Text>
                                  </Box>
                                </Flex>
                              </>
                            )
                          })
                        }
                        {store.appGroups[key].length < 3 && (
                          <Flex w={'320px'}/>
                        )}
                      </SimpleGrid>
                    </Box>
                  )
                })
              }
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
});
