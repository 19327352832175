import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const DeviceIcon = createIcon({
  displayName: 'DeviceIcon',
  viewBox: '0 0 20 20',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (

    <path
      d='M4 4H16V16H4V4ZM4 0H6V3H4V0ZM4 17H6V20H4V17ZM0 4H3V6H0V4ZM0 14H3V16H0V14ZM17 4H20V6H17V4ZM17 14H20V16H17V14ZM14 0H16V3H14V0ZM14 17H16V20H14V17Z'
      fill='currentColor' />
  )
});

export const DataIcon = createIcon({
  displayName: 'DeviceIcon',
  viewBox: '0 0 18 19',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (

    <path d='M0 10H4V19H0V10ZM14 6H18V19H14V6ZM7 0H11V19H7V0Z' fill='currentColor' />

  )
});

export const AppStoreIcon = createIcon({
  displayName: 'AppStoreIcon',
  viewBox: '0 0 18 18',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (

    <path d='M0 10H8V0H0V10ZM0 18H8V12H0V18ZM10 18H18V8H10V18ZM10 0V6H18V0H10Z' fill='currentColor' />

  )
});

export const FirmwareStoreIcon = createIcon({
  displayName: 'FirmwareStoreIcon',
  viewBox: '0 0 22 22',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (

    <path fillRule='evenodd' clipRule='evenodd'
          d='M6.19922 2.79801H3.79922C3.24583 2.79801 2.79722 3.24662 2.79722 3.80001V6.60001H0V8.60001H2.79722V13.8L0 13.8V15.8H2.79722V18.2C2.79722 18.7534 3.24583 19.202 3.79922 19.202H6.19922V21.8H8.19922V19.202H13.3992V21.8H15.3992V19.202H18.1992C18.7526 19.202 19.2012 18.7534 19.2012 18.2V15.8H21.7992V13.8L19.2012 13.8V8.60001H21.7992V6.60001H19.2012V3.80001C19.2012 3.24662 18.7526 2.79801 18.1992 2.79801H15.3992V0.200012H13.3992V2.79801H8.19922V0.200012H6.19922V2.79801ZM4.80122 17.198V4.80201H17.1972V17.198H4.80122ZM13.3992 8.60001H8.59922V13.4H13.3992V8.60001Z'
          fill='currentColor' />

  )
});

export const CollapseLeftIcon = createIcon({
  displayName: 'CollapseLeftIcon',
  viewBox: '0 0 16 12',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path d='M15 0.999939L10.1613 5.75466L15 10.5094' stroke='currentColor' strokeWidth='2.4' />
      <path d='M5.32227 2.58484L2.09646 5.75465L5.32227 8.92446' stroke='currentColor' strokeWidth='2.4' />
    </>

  )
});

export const CollapseRightIcon = createIcon({
  displayName: 'CollapseRightIcon',
  viewBox: '0 0 16 12',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path d='M1 1L5.83871 5.75472L1 10.5094' stroke='currentColor' strokeWidth='2.4' />
      <path d='M10.6777 2.58496L13.9035 5.75477L10.6777 8.92458' stroke='currentColor' strokeWidth='2.4' />
    </>

  )
});

export const GridIcon = createIcon({
  displayName: 'GridIcon',
  viewBox: '0 0 24 24',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <rect x='4' y='4.5' width='5' height='5' stroke='currentColor' strokeOpacity='0.85' strokeWidth='2' />
      <rect x='15' y='4.5' width='5' height='5' stroke='currentColor' strokeOpacity='0.85' strokeWidth='2' />
      <rect x='4' y='14.5' width='5' height='5' stroke='currentColor' strokeOpacity='0.85' strokeWidth='2' />
      <rect x='15' y='14.5' width='5' height='5' stroke='currentColor' strokeOpacity='0.85' strokeWidth='2' />
    </>

  )
});

export const ListIcon = createIcon({
  displayName: 'ListIcon',
  viewBox: '0 0 24 24',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <rect x='7' y='4' width='14' height='2' fill='currentColor' fillOpacity='0.85' />
      <rect x='7' y='11' width='14' height='2' fill='currentColor' fillOpacity='0.85' />
      <rect x='7' y='18' width='14' height='2' fill='currentColor' fillOpacity='0.85' />
      <circle cx='4' cy='5' r='1' fill='currentColor' fillOpacity='0.85' />
      <circle cx='4' cy='12' r='1' fill='currentColor' fillOpacity='0.85' />
      <circle cx='4' cy='19' r='1' fill='currentColor' fillOpacity='0.85' />
    </>

  )
});

export const DeleteIcon = createIcon({
  displayName: 'ListIcon',
  viewBox: '0 0 20 21',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path
      d='M5.8 2.0832H13.4V3.8832H15.2V1.8832C15.2 1.0007 14.4825 0.283203 13.6 0.283203H5.6C4.7175 0.283203 4 1.0007 4 1.8832V3.8832H5.8V2.0832ZM18.4 3.8832H0.8C0.3575 3.8832 0 4.2407 0 4.6832V5.4832C0 5.5932 0.09 5.6832 0.2 5.6832H1.71L2.3275 18.7582C2.3675 19.6107 3.0725 20.2832 3.925 20.2832H15.275C16.13 20.2832 16.8325 19.6132 16.8725 18.7582L17.49 5.6832H19C19.11 5.6832 19.2 5.5932 19.2 5.4832V4.6832C19.2 4.2407 18.8425 3.8832 18.4 3.8832ZM15.0825 18.4832H4.1175L3.5125 5.6832H15.6875L15.0825 18.4832Z'
      fill='white' fillOpacity='0.45' />
  )
});

export const DeleteIconRed = createIcon({
  displayName: 'DeleteIconRed',
  viewBox: "0 0 18 19",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path d="M5.4375 1.71H12.5625V3.42H14.25V1.52C14.25 0.681625 13.5773 0 12.75 0H5.25C4.42266 0 3.75 0.681625 3.75 1.52V3.42H5.4375V1.71ZM17.25 3.42H0.75C0.335156 3.42 0 3.75962 0 4.18V4.94C0 5.0445 0.084375 5.13 0.1875 5.13H1.60312L2.18203 17.5513C2.21953 18.3611 2.88047 19 3.67969 19H14.3203C15.1219 19 15.7805 18.3635 15.818 17.5513L16.3969 5.13H17.8125C17.9156 5.13 18 5.0445 18 4.94V4.18C18 3.75962 17.6648 3.42 17.25 3.42ZM14.1398 17.29H3.86016L3.29297 5.13H14.707L14.1398 17.29Z" fill="#FF4D4F"/>
  )
});

export const AccountIcon = createIcon({
  displayName: 'AccountIcon',
  viewBox: '0 0 24 24',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <circle cx="12" cy="8" r="5" fill="white"/>
      <path d="M20 21C20 18.8783 19.1571 16.8434 17.6569 15.3431C16.1566 13.8429 14.1217 13 12 13C9.87827 13 7.84344 13.8429 6.34315 15.3431C4.84286 16.8434 4 18.8783 4 21L20 21Z" fill="white"/>
    </>
  )
});

export const AddIcon = createIcon({
  displayName: 'AddIcon',
  viewBox: '0 0 62 62',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path fillRule="evenodd" clipRule="evenodd" d="M33 2C33 0.89543 32.1046 0 31 0C29.8954 0 29 0.895433 29 2V29H2C0.89543 29 0 29.8954 0 31C0 32.1046 0.895432 33 2 33H29V60C29 61.1046 29.8954 62 31 62C32.1046 62 33 61.1046 33 60V33H60C61.1046 33 62 32.1046 62 31C62 29.8954 61.1046 29 60 29H33V2Z" fill="url(#paint0_linear_2046_15180)"/>
      <defs>
        <linearGradient id="paint0_linear_2046_15180" x1="2.7125" y1="-2.55206" x2="67.0375" y2="-2.55206" gradientUnits="userSpaceOnUse">
          <stop stopColor="#43C9BA"/>
          <stop offset="1" stopColor="#9643C9"/>
        </linearGradient>
      </defs>
    </>
  )
});

export const ExclamationCircle = createIcon({
  displayName: 'ExclamationCircle',
  viewBox: '0 0 24 24',
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path
        d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
        fill="#FAAD14"/>
      <path
        d="M10.8746 16.125C10.8746 16.4234 10.9932 16.7095 11.2041 16.9205C11.4151 17.1315 11.7013 17.25 11.9996 17.25C12.298 17.25 12.5842 17.1315 12.7951 16.9205C13.0061 16.7095 13.1246 16.4234 13.1246 16.125C13.1246 15.8266 13.0061 15.5405 12.7951 15.3295C12.5842 15.1185 12.298 15 11.9996 15C11.7013 15 11.4151 15.1185 11.2041 15.3295C10.9932 15.5405 10.8746 15.8266 10.8746 16.125ZM11.4371 13.5H12.5621C12.6653 13.5 12.7496 13.4156 12.7496 13.3125V6.9375C12.7496 6.83438 12.6653 6.75 12.5621 6.75H11.4371C11.334 6.75 11.2496 6.83438 11.2496 6.9375V13.3125C11.2496 13.4156 11.334 13.5 11.4371 13.5Z"
        fill="#FAAD14"/>
    </>
  )
});

export const ResetIcon = createIcon({
  displayName: 'ResetIcon',
  viewBox:"0 0 18 18",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path d="M14.2646 5.57163L17.6488 6.37326C17.7567 6.39837 17.8625 6.31884 17.8625 6.21209L17.8798 2.83186C17.8798 2.69163 17.7136 2.6121 17.6013 2.7L14.1999 5.27651C14.1742 5.2958 14.1546 5.32173 14.1434 5.35132C14.1322 5.38092 14.1299 5.41297 14.1366 5.4438C14.1434 5.47463 14.159 5.50298 14.1816 5.52559C14.2043 5.54821 14.233 5.56416 14.2646 5.57163ZM17.8841 11.8737L16.6603 11.4656C16.6177 11.4514 16.571 11.454 16.5302 11.4727C16.4895 11.4915 16.4579 11.525 16.4423 11.566C16.4013 11.6728 16.3582 11.7774 16.3128 11.8821C15.9287 12.7633 15.3783 13.5565 14.6747 14.2367C13.9789 14.9136 13.1543 15.4531 12.2466 15.8253C11.3063 16.2109 10.2955 16.4088 9.27465 16.4072C8.24299 16.4072 7.2437 16.2126 6.30269 15.8253C5.39499 15.4531 4.5704 14.9136 3.87461 14.2367C3.17317 13.5565 2.6228 12.7633 2.23647 11.8821C1.84111 10.9697 1.63854 9.98967 1.64078 9C1.64078 7.99954 1.8415 7.02837 2.24078 6.11582C2.62496 5.23465 3.17532 4.4414 3.87893 3.76117C4.57472 3.08432 5.39931 2.54482 6.307 2.17256C7.2437 1.78535 8.24515 1.5907 9.27681 1.5907C10.3085 1.5907 11.3078 1.78535 12.2488 2.17256C13.1565 2.54482 13.9811 3.08432 14.6769 3.76117C14.897 3.97675 15.1042 4.2007 15.2941 4.43721L16.5848 3.45768C14.8862 1.3521 12.2423 -0.0020906 9.27249 2.42276e-06C4.10123 0.00209545 -0.0513196 4.07512 0.000479383 9.09209C0.0522783 14.0212 4.1854 18 9.27681 18C13.2804 18 16.6905 15.5386 17.9898 12.0914C18.0222 12.0035 17.9747 11.9051 17.8841 11.8737Z" fill="#43C9BA"/>
  )
});

export const BatteryIcon = createIcon({
  displayName: 'BatteryIcon',
  viewBox:"0 0 24 24",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <>
      <path d="M23.0672 13.8093C22.95 13.8772 22.875 14.0015 22.875 14.1351V16.4624C22.875 17.7186 21.8461 18.7476 20.5898 18.7476H2.28516C1.02891 18.7499 0 17.721 0 16.4647V7.5374C0 6.2788 1.02891 5.2499 2.28516 5.2499H20.5875C21.8438 5.2499 22.8727 6.2788 22.8727 7.53505V9.8624C22.8727 9.99834 22.9477 10.1202 23.0648 10.1882C23.6227 10.514 23.9977 11.1186 23.9977 11.8101V12.1851C24 12.8788 23.625 13.4858 23.0672 13.8093ZM21.375 7.53505C21.375 7.2538 21.2297 7.06865 21.143 6.98193C21.0563 6.89521 20.8688 6.7499 20.5898 6.7499H2.28516C2.00625 6.7499 1.81875 6.89521 1.73203 6.98193C1.64531 7.06865 1.5 7.25615 1.5 7.53505V16.4647C1.5 16.7436 1.64531 16.9311 1.73203 17.0179C1.81875 17.1046 2.00625 17.2499 2.28516 17.2499H20.5875C20.8664 17.2499 21.0539 17.1046 21.1406 17.0179C21.2273 16.9311 21.3727 16.7436 21.3727 16.4647V7.53505H21.375Z" fill="#43C9BA"/>
      <path d="M20.25 15.9375V8.0625C20.25 7.85625 20.0812 7.6875 19.875 7.6875H2.625C2.41875 7.6875 2.25 7.85625 2.25 8.0625V15.9375C2.25 16.1438 2.41875 16.3125 2.625 16.3125H19.875C20.0812 16.3125 20.25 16.1438 20.25 15.9375Z" fill="#43C9BA"/>
    </>

  )
});





