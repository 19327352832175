import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  Center,
  Button,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader, Checkbox, Divider, ModalCloseButton
} from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { publicConfig } from '../../config/public';
import InstallAppDialog from './components/InstallApp/InstallAppDialog';
import { AiOutlineRollback } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { Link } from '@chakra-ui/layout';
import { PrimaryLineButton, PrimaryButton } from '@/components/Button';
import { AppStatus } from './index';
import InstallFirmwareModal from './components/InstallFirmware/InstallFirmwareModal';
import { helper } from '@/lib/helper';

const AppDetail = observer((props) => {
  const { deviceStore, appStore, lang,god,accountStore,firmware } = useStore();
  //@ts-ignore
  const appId = props.match.params.appId;
  const history = useHistory();
  const intervalRef = useRef(null);

  const store = useLocalObservable(() => ({
    isSuccess: false,
    async installApp() {
      try {
        if (appId!=='Firmware Upgrade'){
          const res = await deviceStore.pebble.setConfig({ params: [appStore.currentInstallDeviceImei, appId] });
          const receipt = await res.wait();
          console.log(receipt);
        }else {
          await helper.promise.sleep(2000)
        }
        appStore.isOpenInstallAppModal = false;
        store.isSuccess = true;
      } catch (e) {
        console.log(e);
      }
    },
    addDevice(){
      if (!god.isConnect){
        return god.setShowConnecter(true)
      }
      if (god.isConnect&&!accountStore.isVisible){
        return  accountStore.openAccountDialog();
      }
      history.push('/device/add')
    },
    get currentApp() {
      return appStore.appList.find((app) => app.id === appId);
    },

    reset() {
      appStore.currentInstallDeviceImei = null;
      this.isSuccess = false;
      appStore.isOpenSelectDeviceModal = false;
      appStore.isOpenInstallAppModal = false;
    },
    get currentDevice() {
      return deviceStore.devices.find(device => device.id === appStore.currentInstallDeviceImei);
    },
    openInstallFirmwareModal(imei) {
      appStore.isOpenInstallFirmWareModal = true;
      appStore.isOpenSelectDeviceModal = false;
      appStore.currentInstallDeviceImei = imei;
    }
  }));
  useEffect(() => {
    if (appStore.appList.length == 0) {
      appStore.loadAPPS();
    }
    // const devices = appStore.installingDevices.value||[];
    // if (devices.length > 0) {
    //   intervalRef.current = setInterval(() => {
    //     deviceStore.loadInstallDevices();
    //   }, 5000);
    // } else {
    //   clearInterval(intervalRef.current);
    // }
    // return () => clearInterval(intervalRef.current);

  }, []);

  useEffect(()=>{
    deviceStore.loadDevices({}, {});

  },[appStore.isOpenSelectDeviceModal])

  return (
    <>
      {
        store.currentApp && (
          <>
            <Box position='relative' ml={5}>
              <Flex mt={10} justifyContent='space-between'>
                <Flex flexDirection={{base:'column',md:'row'}}>
                  <Flex justifyContent='space-between'>
                    <Center w={{base:'100px',md:'180px'}} h={{base:'100px',md:'180px'}}  position='relative'>
                      <Image borderRadius='20px' w={{base:'100px',md:'180px'}} h={{base:'100px',md:'180px'}} src={publicConfig.CDN + store.currentApp.logo} />
                    </Center>
                    <PrimaryLineButton
                      display={{base:'block',md:'none'}}
                      onClick={() => history.push('/apps')}
                      w={'84px'}
                      h={'30px'}
                      leftIcon={<AiOutlineRollback />}
                      bg={'mainColor'}
                    >
                      Back
                    </PrimaryLineButton>
                  </Flex>
                  <Flex ml={{base:0,md:10}} mt={{base:10,md:0}} direction='column' justifyContent='space-between'>
                    <Box>
                      <Text fontSize='28px' fontWeight={500}>{appId}</Text>
                      <Text fontSize='14px' color='brandColor'>{store.currentApp.author}</Text>
                    </Box>
                    <Flex mt={{base:5,md:0}}>
                      <Link href={store.currentApp.uri} _hover={{}} _focus={{}}>
                        <PrimaryLineButton h='48px'>
                          {lang.t('view.website')}
                        </PrimaryLineButton>
                      </Link>
                      {
                        deviceStore.confirmedDevices.length == 0 &&
                        <PrimaryLineButton w='180px' h='48px' text={lang.t('add.device')} ml={4}
                                           onClick={store.addDevice} />

                      }
                      {
                        store.currentApp.status === AppStatus.PUBLISH && deviceStore.confirmedDevices.length > 0 &&
                        <PrimaryButton ml={4} onClick={() => appStore.openSelectDeviceModal()} w='180px' h='48px'>
                          {lang.t('install')}
                        </PrimaryButton>
                      }
                      {
                        store.currentApp.status === AppStatus.COMING &&
                        <Center borderRadius='5' ml={4} bgColor='#43C9BA' w='180px' h='48px'><Text textAlign='center'>Coming
                          Soon</Text></Center>
                      }
                    </Flex>
                  </Flex>
                </Flex>

                <Center display={{base:'none',md:'block'}} w={'86px'} h={'32px'}>
                  <PrimaryLineButton
                    onClick={() => history.push('/apps')}
                    w={'84px'}
                    h={'30px'}
                    leftIcon={<AiOutlineRollback />}
                    bg={'mainColor'}
                  >
                    Back
                  </PrimaryLineButton>
                </Center>
              </Flex>
              <Box mt={5} fontSize={{base:'1.25rem',md:'14px'}}>
                <Text>{store.currentApp.content}</Text>
              </Box>
              <SimpleGrid columns={[1,3]} mt={10} spacing={3}>
                {
                  store.currentApp.previews.map(p => <Image borderRadius='8px' src={publicConfig.CDN + p} key={p} />)
                }
              </SimpleGrid>
            </Box>
          </>
        )
      }
      <Modal isOpen={appStore.isOpenSelectDeviceModal} size='2xl'
             onClose={() => appStore.isOpenSelectDeviceModal = false}
             isCentered>
        <ModalOverlay />
        <ModalContent w='600px' pb={4} px={5} bg='mainColor'>
          {
            appStore.isOpenInstallAppModal && !store.isSuccess && (
              <InstallAppDialog install={store.installApp} app={store.currentApp}/>
            )
          }
          {
            !appStore.isOpenInstallAppModal && !store.isSuccess && (
              <>
                <ModalHeader>
                  {lang.t('choose.device')}
                </ModalHeader>
                <ModalCloseButton _hover={{ color: 'brandColor' }} _focus={{}} onClick={store.reset} />
                <Flex flexDirection='column'>
                  {
                    deviceStore.confirmedDevices&&deviceStore.confirmedDevices.map(device => {
                      return (
                        <>
                          <Box px={5} key={device.id}>
                            <Checkbox mt={8} value={device.id}
                                      isChecked={device.id === appStore.currentInstallDeviceImei}
                                      isDisabled={device.status !== 2 || (store.currentApp&&store.currentApp.firmware && device.real_firmware !== store.currentApp.firmware)}
                                      onChange={(e) => {
                                        if (e.target.checked){
                                          appStore.currentInstallDeviceImei = e.target.value
                                        }else {
                                          appStore.currentInstallDeviceImei = ''
                                        }
                                      }}>
                              <Text>{device.name} - {device.real_firmware}</Text>
                            </Checkbox>
                            {
                              store.currentApp&&store.currentApp.firmware && device.real_firmware !== store.currentApp.firmware && device.status === 2 && (
                                <Link onClick={() => store.openInstallFirmwareModal(device.id)} display='block' ml={6}
                                      mt={1} color='brandColor'>Update Firmware to Install App</Link>
                              )
                            }
                          </Box>
                        </>
                      );
                    })
                  }
                </Flex>
                <Divider mt={8} />
                <Flex mt={5} w='100%' flexDirection='row' justifyContent='flex-end'>
                  <PrimaryLineButton
                    w='8.75rem' fontWeight='normal' mr={5}
                    onClick={store.reset}
                  >
                    {lang.t('cancel')}
                  </PrimaryLineButton>
                  <Button color='black' fontWeight='normal' w='8.75rem' onClick={() => {
                    appStore.isOpenInstallAppModal = true;
                  }} bg='btnPrimaryColor' isDisabled={!appStore.currentInstallDeviceImei}>{lang.t('next')}</Button>
                </Flex>
              </>

            )
          }
          {
            store.isSuccess && (
              <>
                <ModalHeader>
                  {lang.t('install.app')}
                </ModalHeader>
                <ModalCloseButton onClick={store.reset}/>
                <Flex alignItems='flex-start' mt={10}>
                  <Image src='/images/apps/succeed.png' />
                  <Text ml={10} whiteSpace='pre-line'>{lang.t('download.success')}</Text>
                </Flex>
                <Divider mt={20} />
                <Flex justifyContent='flex-end' mt={5}>
                  <Button
                    _hover={{}}
                    _focus={{}}
                    onClick={store.reset}
                    bg='btnPrimaryColor' color='black' fontWeight={400} border='unset'
                  >
                    {lang.t('app.launch.title')}
                  </Button>
                </Flex>
              </>
            )
          }
        </ModalContent>
      </Modal>

      <InstallFirmwareModal isOpen={appStore.isOpenInstallFirmWareModal} currentDevice={store.currentDevice}
                            currentApp={store.currentApp} />
    </>
  );
});

AppDetail.displayName = 'AppDetail';
export default AppDetail;
