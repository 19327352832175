import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { BrandButton } from '@/components/BrandButton';
import { useStore } from '@/store/index';

export const ConnectButton=observer(({...props}:any)=>{
  const { god,lang } = useStore();
  const store = useLocalObservable(() => ({
    showConnector() {
      god.setShowConnecter(true);
    },
  }));
  return(
      <BrandButton isLoading={false} w="80%" size="md" name={lang.t('connect.wallet')} onClick={()=>store.showConnector()} {...props}/>
  )
})
