import { makeAutoObservable } from 'mobx';
import { NetworkState } from './NetworkState';
import { SensorConfig, SensorData } from 'protogen/pebble';
import { DeviceRecordState } from '@/store/mydata';
import { fromBytes } from '@iotexproject/iotex-address-ts';

export class DeviceState {
  imei: string= '';
  owner: string = '';
  proposer: string = '';
  id: string = '';
  address: string = '';
  firmware: string = '';
  real_firmware: string = '';
  config: string = '';
  sensorConfig: SensorConfig = null;
  status: number = 0;
  data: string = '';
  type: number = 0;
  total_gas: string = '';
  sensorData: SensorData = null;
  name: string = '';
  avatar: string = '';
  lastDataTime: string = '';
  timestamp:number=0
  upload_period:number=0;
  network: NetworkState;
  deviceRecord:DeviceRecordState
  channels:string[];
  constructor(args: Partial<DeviceState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }

  static getTitle(status: number) {
    let stateColor = 'brandColor';
    let stateTitle = 'Created'
    if (status === 0) {
      stateColor = 'brandColor';
      stateTitle = ("device.status.created")
    } else if (status == 1) {
      stateColor = 'text45';
      stateTitle = ("device.status.proposed")
    } else if (status === 2) {
      stateColor = 'brandColor';
      stateTitle = ("device.status.confrimed")
    }
    return stateTitle
  }

  static getStateColor(status: number) {
    let stateColor = 'brandColor';
    if (status === 0) {
      stateColor = 'brandColor';
    } else if (status == 1) {
      stateColor = 'orange';
    } else if (status === 2) {
      stateColor = 'brandColor';
    }
    return stateColor
  }

  get ioAddress(){
    return fromBytes(Buffer.from(String(this.address).replace(/^0x/, ''), 'hex')).string()
  }
}

export class NewDeviceState{
  name:string;
  imei:string;
  avatar:string;
  SN:string;
}
