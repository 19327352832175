import React from 'react';
import { BatteryIcon } from '@/components/Icons';
import { HStack, Text } from '@chakra-ui/layout';

const DeviceBattery = ({ vbat, ...props }) => {
  return (
    <>
      {
        vbat>=0 && (
          <>
            <HStack px={2} py={1} color={vbat > 0 ? 'brandColor' : '#F5222D'}
                 bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
                 borderRadius='8px'
                    spacing={1}
                 {...props}
            >
              <BatteryIcon fontSize={24}/>
              <Text fontSize={12}>{vbat}%</Text>
            </HStack>
          </>
        )
      }
    </>
  );
};

export default DeviceBattery;
