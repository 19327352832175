import React from 'react';
import { Button } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';

interface IComponentProps {
  w?: string;
  size?: string;
  name?: string;
  onClick?: ()=>void;
  isLoading?: boolean;
}

export const BrandButton = observer((props: IComponentProps) => {
  const { w, size, name, onClick, isLoading } = props;
  return (
    <Button
      w={w}
      size={size || ''}
      bg="btnPrimaryColor"
      color="black"
      h='100%'
      disabled={isLoading || false}
      isLoading={isLoading || false}
      _active={{
        backgroundColor: 'brandColor',
        border: 'none'
      }}
      _hover={{
        backgroundColor: 'brandColor',
        border: 'none'
      }}
      fontWeight='400'
      onClick={onClick}
      {...props}
    >
      {name}
    </Button>
  );
});
