import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useColorMode, Flex, Button } from '@chakra-ui/react';
import { floor } from 'lodash';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

interface IPaginationProps {
  prePage: () => void;
  afterPage: () => void;
  total: number;
  pagesize?: number;
  setPage: (page) => void;
  currentPage: number;
}

export const CPagination: React.FC<IPaginationProps> = observer((
  {
    total, pagesize, prePage, afterPage, setPage, currentPage
  }
) => {



  const range=  (from: number, to: number)=>{
    const range = []
      from = from > 0 ? from : 1

    for (let i = from; i <= to; i++) {
      range.push(i)
    }

    return range
  }
  const result = ()=>{
    const totalVisible = 7 // maximum number of visible page
    const maxLength = Math.min(
      Math.max(0, totalVisible) || total, total
    )
    const totalPage = (total % pagesize === 0) ? floor(total / pagesize) : floor(total / pagesize) + 1;
    const even = maxLength % 2 === 0 ? 1 : 0
    const left = Math.floor(maxLength / 2)
    const right = totalPage - left + 1 + even
    if (totalPage<totalVisible){
      return [ ...range(1, totalPage),]
    } else if (currentPage > left && currentPage < right) {
      const firstItem = 1
      const lastItem = totalPage
      const start = currentPage - left + 2
      const end = currentPage + left - 2 - even
      const secondItem = start - 1 === firstItem + 1 ? 2 : '...'
      const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : '...'

      return [1, secondItem, ...range(start, end), beforeLastItem, totalPage]
    }else if (currentPage === left) {
      const end = currentPage + left - 1 - even
      return [...range(1, end), '...', totalPage]
    }else if (currentPage === right) {
      const start = currentPage- left + 1
      return [1, '...', ...range(start, totalPage)]
    } else {
      return [
        ...range(1, left),
        '...',
        ...range(right, totalPage),
      ]
    }
  }

  const { colorMode } = useColorMode();
  let selectedBorderColor = '0.5px solid #43C9BA';
  let unSelectedBorderColor: string;
  let bgColor: string;


  let selectedColor = '#43C9BA';
  let unSelectedColor;
  if (colorMode === 'light') {
    unSelectedBorderColor = '0.5px solid rgba(0, 0, 0, 0.1)';
    unSelectedColor = 'mainColor';
    bgColor = 'white';
  } else {
    unSelectedBorderColor = '0.5px solid #F0F0F0';
    unSelectedColor = 'borderLight';
    bgColor = '#19202b';
  }
  const [items,setItems] = useState([])
  useEffect(()=>{
    setItems(result())
  },[currentPage,total])

  return (
    <>
      {
        total>0&&(
          <Flex mr='3' my='3' width='' justifyContent='flex-end'>
            <Button size='sm' onClick={prePage} disabled={currentPage === 1} border={unSelectedBorderColor} bgColor={bgColor}
                    color={colorMode === 'light' ? 'text45' : 'white'}>
              <ChevronLeftIcon />
            </Button>
            <Flex overflow='hidden'>
              {
                items.map((item,index) => {
                  return item==='...'?(
                    <Flex size='sm' alignItems='flex-end' mx={2} key={`pagnation_${index}`}>
                      .....
                    </Flex>
                  ):(
                    <>
                      <Button
                        size='sm'
                        bgColor={bgColor}
                        border={currentPage === item  ? selectedBorderColor : unSelectedBorderColor}
                        key={`pagnation_${index}`}
                        mx='4px'
                        color={currentPage === item ? selectedColor : unSelectedColor}
                        onClick={() => {
                          setPage(item);
                        }}
                      >
                        {item}
                      </Button>
                    </>
                  );
                })
              }
            </Flex>
            <Button size='sm' onClick={afterPage} disabled={currentPage === items[items.length-1] || total === 0}
                    border={unSelectedBorderColor} bgColor={bgColor} color={colorMode === 'light' ? 'text45' : 'white'}>
              <ChevronRightIcon />
            </Button>
          </Flex>
        )
      }
    </>
  );
});

