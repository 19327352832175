import { makeAutoObservable } from 'mobx';
import  {EthNetworkConfig} from '../../config/NetworkConfig';
import { CallParams } from '../../../type';
import applicationAbi from '@/constants/abi/Application.json';
import { RootStore } from '../root';

export class FirmwareState {
  abi = applicationAbi;

  id: string = '';
  uri: string = '';
  avatar: string = '';
  version: string = '';


  rootStore: RootStore;
  network = EthNetworkConfig;


  constructor(args: Partial<FirmwareState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }

  get address(){
    return this.rootStore.god.currentChain.pebbleContracts.CORE
  }

  updateFirmware(args: Partial<CallParams>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: 'updateFirmware' }, args));
  }

  removeFirmware(args: Partial<CallParams>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: 'removeFirmware' }, args));
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }
}
