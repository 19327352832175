import React, { useRef } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import DeviceCard from './components/DeviceCard';
import { Box, WrapItem, Wrap, Link } from '@chakra-ui/layout';
import { Flex, Heading, Icon, Button, HStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { IDeviceStatusInfo } from '../status';
import { useStore } from '@/store/index';
import { useHistory } from 'react-router-dom';
import { DeviceState } from '@/store/lib/DeviceState';
import DeviceEmpty from './components/deviceEmpty';
import { AddIcon } from '@chakra-ui/icons';
import { GridIcon, ListIcon } from '@/components/Icons';
import DeviceTable from './components/DeviceTable';
import ChooseDeviceModal from './components/ChooseDeviceModal';
import { PrimaryLineButton } from '@/components/Button';
import ConnectWallet from './components/deviceEmpty/ConnectWallet';
enum ShowType {
  LIST = 'LIST',
  GRID = 'GRID'
}

export const Device = observer(() => {
  const { god, deviceStore, lang, accountStore } = useStore();
  const router = useHistory();
  const intervalRef = useRef(null);

  useEffect(() => {
    deviceStore.loadDevices({}, {});
  }, []);

  const store = useLocalObservable(() => ({
    isCollectionLoading: false,
    devices: new Array<DeviceState>(),
    isLoading: false,
    current: null as DeviceState,
    showType: ShowType.GRID,
    isOpenSelectDevice: false,
    openSelectDevice() {
      this.isOpenSelectDevice = true;
    },
    isOpenRemoveModal: false,
    openRemoveModal() {
      this.isOpenRemoveModal = true;
    },
    closeRemoveModal() {
      this.isOpenRemoveModal = false;
    },
    isCheckboxVisible: false,
    showCheckbox() {
      this.isCheckboxVisible = true;
    },
    hideCheckbox() {
      this.isCheckboxVisible = false;
    },
    isChecked: false,
    check() {
      this.isChecked = true;
    },
    uncheck() {
      this.isChecked = false;
    },
    isOpenConnectWallet: false,
    openModal() {
      this.isOpenConnectWallet = true;
    },
    closeModal() {
      this.isOpenConnectWallet = false;
    },
    closeSelectDevice() {
      this.isOpenSelectDevice = false;
    },
    toggleType(type: ShowType) {
      this.showType = type;
    },
    addDevice() {
      if (!god.isConnect) {
        return store.openModal();
      }
      if (god.isConnect && !accountStore.isVisible) {
        return accountStore.openAccountDialog();
      }
      store.openSelectDevice();
    },
    currentSelectItem: null as DeviceState,
    clickDeviceStatus: (item: DeviceState) => {
      store.currentSelectItem = item;
      deviceStore.currentDevice = item;
      router.push('/status');
    },
    getDeviceList: async () => {
      try {
        store.isLoading = true;
        store.devices = await deviceStore.loadDevices({}, {});
        store.isLoading = false;
      } catch (error) {
        store.isLoading = false;
      }
    },
    get isRefresh() {
      return deviceStore.devices.length > 0 && !!deviceStore.devices.find(device => device.status !== 2);
    }
  }));
  useEffect(() => {
    if (god.currentNetwork.account) {
      store.getDeviceList();
      if (store.isRefresh) {
        intervalRef.current = setInterval(() => {
          deviceStore.loadDevices({}, {});
        }, 5000);
      } else {
        clearInterval(intervalRef.current);
      }
    } else {
      deviceStore.devices = [];
    }
    return () => clearInterval(intervalRef.current);
  }, [god.isConnect, god.currentNetwork.account, store.devices.length, store.isRefresh]);

  return (
    <Box position="relative" px={5} h="100%">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <Heading as="h3" size="lg" color="white">
            {lang.t('device')}
          </Heading>
          <PrimaryLineButton onClick={store.addDevice} ml={5}>
            <AddIcon mr={3} /> {lang.t('add')}
          </PrimaryLineButton>
        </Flex>
        {deviceStore.devices.length > 0 && (
          <HStack>
            <Button
              hidden={ShowType.GRID === store.showType || !store.isCheckboxVisible}
              disabled={!store.isChecked}
              fontWeight={400}
              variant={'ghost'}
              color={'warnColor'}
              onClick={store.openRemoveModal}
            >
              {lang.t('remove.device')}
            </Button>
            {/*<Button*/}
            {/*  hidden={ShowType.GRID === store.showType || !store.isCheckboxVisible}*/}
            {/*  disabled={!store.isChecked}*/}
            {/*  fontWeight={500} variant={'ghost'} color={'brandColor'}*/}
            {/*>*/}
            {/*  {lang.t("reset.firmware")}*/}
            {/*</Button>*/}
            <Button
              hidden={ShowType.GRID === store.showType || store.isCheckboxVisible}
              onClick={() => {
                store.showCheckbox();
              }}
              fontWeight={400}
              variant={'ghost'}
              color={'brandColor'}
            >
              Select
            </Button>
            <Flex
              bg={ShowType.GRID === store.showType ? 'black' : ''}
              w="32px"
              h="32px"
              borderRadius="8px"
              alignItems="center"
              justifyContent="center"
              mr={4}
              onClick={() => store.toggleType(ShowType.GRID)}
            >
              <Icon as={GridIcon} color={ShowType.GRID === store.showType ? 'brandColor' : ''} w="24px" h="24px" cursor="pointer" />
            </Flex>
            <Flex
              bg={ShowType.LIST === store.showType ? 'black' : ''}
              w="32px"
              h="32px"
              borderRadius="8px"
              alignItems="center"
              justifyContent="center"
              mr={4}
              onClick={() => store.toggleType(ShowType.LIST)}
            >
              <Icon as={ListIcon} color={ShowType.LIST === store.showType ? 'brandColor' : ''} w="24px" h="24px" cursor="pointer" />
            </Flex>
          </HStack>
        )}
      </Flex>
      {deviceStore.devices.length === 0 ? (
        <DeviceEmpty isLoading={store.isLoading} openSelectDevice={store.openSelectDevice} />
      ) : (
        <>
          {store.showType === ShowType.GRID && (
            <Wrap spacing="30px" pt={10}>
              {deviceStore.devices.map((item, index) => {
                return (
                  <WrapItem key={item.id} w={{ base: '90%', md: '100%' }}>
                    <DeviceCard
                      deviceState={item}
                      onDeviceStatus={() => {
                        store.clickDeviceStatus(item);
                      }}
                    />
                  </WrapItem>
                );
              })}
            </Wrap>
          )}
          {store.showType === ShowType.LIST && (
            <DeviceTable
              isCheckboxVisible={store.isCheckboxVisible}
              isOpenRemoveModal={store.isOpenRemoveModal}
              closeRemoveModal={store.closeRemoveModal}
              check={store.check}
              uncheck={store.uncheck}
            />
          )}
        </>
      )}

      <ChooseDeviceModal isOpen={store.isOpenSelectDevice} onClose={store.closeSelectDevice} />
      <ConnectWallet isOpen={store.isOpenConnectWallet && !god.isConnect} onClose={store.closeModal} />
    </Box>
  );
});
