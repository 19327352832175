import React from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  SimpleGrid,
  chakra,
  Link,
  Heading,
  Wrap,
  WrapItem,
  Img,
  StackDivider
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../store';
import { customTheme } from '@/lib/theme';
import { HStack } from '@chakra-ui/layout';
import { ConnectYourButton } from '@/components/Button/ConnectYourButton';
import { BrandButton } from '@/components/BrandButton';

interface IComponentProps {
  isLoading: boolean;
}

const Connect = observer((props: IComponentProps) => {
  const { lang,god,accountStore } = useStore();
  const {  isLoading } = props;

  const config = [
    { name: lang.t('of.devices'), amount: '10K' },
    { name: lang.t('of.countries'), amount: '63' },
    { name: lang.t('data.pools'), amount: '50M' }
  ];

  return (
    <Box p={{ base: 0, lg: '3rem 5rem 0 3.25rem' }}  bgRepeat='no-repeat' bgPos='right bottom' h='100%'>
      <Flex mb="2rem" justify={{ base: 'center', md: 'space-between' }} direction={{ base: 'column-reverse', lg: 'row' }} alignItems="center">
        <Box maxWidth='100%' mt={{ base: 10, md: 12, lg: 0 }} >
          <Heading fontFamily='Poppins'  color='white' size={'2xl'} fontWeight='500' css={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{__html:lang.t('connect.title')}}/>
          <Text fontSize="1rem" mt="5" mb="7" color='white' w={{base:'100%',xl:'80%'}}>
            {lang.t('homepage.paragraph1')}
          </Text>
          <Text fontSize="1rem" mt="5" mb="7" color='white' w={{base:'100%',xl:'80%'}}>
            {lang.t('homepage.paragraph2')}
          </Text>
          <Text fontSize="1rem" mt="5" mb="7" color='white' w={{base:'100%',xl:'80%'}}>
            {lang.t('homepage.paragraph3')}
          </Text>
          <Wrap>
            <WrapItem>
              <Img boxSize={12}  src='images/metamask.svg' />
            </WrapItem>
            <WrapItem>
              <Img boxSize={12} name='Iopay' src='images/iopay.svg' />
            </WrapItem>
            <WrapItem>
              <Img boxSize={12} name='TrustWallet' src='images/trustwallet.svg' />
            </WrapItem>
            <WrapItem>
              <Img boxSize={12} name='MathWallet' src='images/mathwallet.svg' />
            </WrapItem>
            <WrapItem>
              <Img boxSize={12} name='Imtoken' src='images/imtoken.svg' />
            </WrapItem>
          </Wrap>
          <Flex mt={10}>
            {
              !god.isConnect&&(
                <Box h='50px' w={{ base: '100%', xl: '40%' }}>
                  <ConnectYourButton fontWeight={500}/>
                </Box>
              )
            }
            {
              god.isConnect&&god.currentNetwork.account&&!accountStore.isVisible&&(
                <Box h='50px' w={{ base: '100%', xl: '40%' }} fontWeight={500}>
                  <BrandButton  onClick={()=>accountStore.openAccountDialog()} isLoading={false} w="80%" size="md" name={lang.t('setup.account')}/>
                </Box>
              )
            }
          </Flex>
        </Box>
      </Flex>
      <HStack
        divider={<StackDivider minW={'2px'} bgGradient={'linear(to-b, #5EC989,#B74EC8)'}/>}
        spacing={{base:'15px',md:'30px'}}
        width={{base:'100%',xl:'80%'}}
        mt={20}
      >
        {config.map((item,index) => {
          return (
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              py="1.25rem"
              w="100%"
              key={item.name}
              textAlign="center"
            >
              <Text fontSize={{ base: '2rem', lg: '2.375rem','xl':'4rem','2xl':'4.5rem' }} fontWeight='400' >
                {item.amount}
              </Text>
              <Text fontSize={{base:'14px',xl:'1rem'}} >
                {item.name}
              </Text>
            </Flex>
          );
        })}
      </HStack>
    </Box>
  );
});

export default Connect;
