import { makeAutoObservable } from 'mobx';
import { RootStore } from './root';
import { NetworkState } from './lib/NetworkState';
import { SensorData } from '../protogen/pebble';
import { pointInPolygon } from '../lib/ploygon';
import { order_by } from '../generated/gql/schema';
import BigNumber from 'bignumber.js';


export class DeviceRecordState {
  id: string = '';
  imei: string = '';
  operator: string = '';
  gas: number = 0;
  realGas: string = '';
  raw: string = '';
  timestamp: number = 0;
  signature: string = '';
  sensorData: SensorData = null;
  longitude:number = 0;
  latitude:number = 0;
  upload_period:number=0
  network: NetworkState;
  vbat:number=0;
  snr:number=0;
  gas_resistance:number=0
  temperature:number=0
  temperature2:number=0
  pressure:number=0
  humidity:number=0;
  light:number=0;
  gyroscope:number[]=[];
  accelerometer:number[]=[];
  constructor(args: Partial<DeviceRecordState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }
}

// [ 121.373264,53.837278 ], [ 104.447751,42.914266 ], [88.846843,49.25389], [73.981827,41.047549], [85.608919,27.418705], [98.413437,27.812032],
// [104.300572,19.832074], [119.165588,25.163425], [135.796744,49.446199],

export class DeviceRecordStore {
  rootStore: RootStore;
  deviceRecords: DeviceRecordState[] = [];
  devicelogs: DeviceRecordState[] = [];
  totalDeviceLogs:number=0
  pointInPolygon = pointInPolygon
  polygons = [
    [
      120.498046875,
      53.592504809039376
    ],
    [
      113.73046875,
      48.28319289548349
    ],
    [
      110.654296875,
      45.02695045318546
    ],
    [
      102.216796875,
      43.26120612479979
    ],
    [
      91.845703125,
      47.39834920035926
    ],
    [
      87.099609375,
      50.3454604086048
    ],
    [
      71.71875,
      39.639537564366684
    ],
    [
      73.212890625,
      32.62087018318113
    ],
    [
      86.923828125,
      26.03704188651584
    ],
    [
      95.625,
      27.137368359795584
    ],
    [
      99.31640625,
      19.89072302399691
    ],
    [
      111.70898437499999,
      16.804541076383455
    ],
    [
      124.45312499999999,
      22.998851594142913
    ],
    [
      125.068359375,
      32.32427558887655
    ],
    [
      136.23046875,
      48.04870994288686
    ],
    [
      130.166015625,
      52.908902047770255
    ],
    [
      120.84960937499999,
      54.1109429427243
    ]
  ];

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getDDPushPin = value => {
    let val;
    if (value < 0) {
      val = Number(value.toString().split('-')[1]) / 100000;
    } else {
      val = value / 100000;
    }
    const result = val.toString().split('.');
    const d = Number(result[0].slice(0, result[0].length - 2));
    const m = Number(result[0].slice(result[0].length - 2, result[0].length) + '.' + result[1]);
    return value < 0 ? Number(`-${d + m / 60}`) : d + m / 60;
  };


  gpsTransfrom = async (msg, index) => {
    let wgLon = msg.longitude;
    let wgLat = msg.latitude;

    // @ts-ignore
    const isChain = this.pointInPolygon([wgLon, wgLat], this.polygons)

    // console.log('isChain false', isChain, wgLat, wgLon)
    if (!isChain) {
      this.deviceRecords[index].latitude = wgLat;
      this.deviceRecords[index].longitude = wgLon;
      return false
    }

    // console.log('isChain true', isChain, wgLat, wgLon)

    const pi = 3.14159265358979324;
    const a = 6378245.0;
    const ee = 0.00669342162296594323;
    let x = wgLon - 105.0;
    let y = wgLat - 35.0;
    let retLat = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
    retLat += ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) / 3.0;
    retLat += ((20.0 * Math.sin(y * pi) + 40.0 * Math.sin((y / 3.0) * pi)) * 2.0) / 3.0;
    retLat += ((160.0 * Math.sin((y / 12.0) * pi) + 320 * Math.sin((y * pi) / 30.0)) * 2.0) / 3.0;
    let dLat = retLat;
    let retLon = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
    retLon += ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) * 2.0) / 3.0;
    retLon += ((20.0 * Math.sin(x * pi) + 40.0 * Math.sin((x / 3.0) * pi)) * 2.0) / 3.0;
    retLon += ((150.0 * Math.sin((x / 12.0) * pi) + 300.0 * Math.sin((x / 30.0) * pi)) * 2.0) / 3.0;
    let dLon = retLon;
    let radLat = (wgLat / 180.0) * pi;
    let magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    let sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
    dLon = (dLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
    msg.latitude = wgLat + dLat;
    msg.longitude = wgLon + dLon;
    this.deviceRecords[index].latitude = wgLat + dLat;
    this.deviceRecords[index].longitude = wgLon + dLon;
  };

  get subgraphAPI(){
    return this.rootStore.pebbleApi.subgraphAPI
  }

  async loadDeviceRecords(imei: string) {
    this.deviceRecords = []
    const response = await this.subgraphAPI.query({
      pebble_device_record: [
        {
          limit: 100,
          order_by: [
            {timestamp: order_by.desc}
          ],
          where: {
            imei: {_eq: imei}
          }
        },
        {
          id: true,
          imei: true,
          operator: true,
          gas_resistance: true,
          timestamp: true,
          signature: true,
          latitude:true,
          longitude:true,
          pressure:true,
          temperature:true,
          temperature2:true,
          vbat:true,
          humidity:true
        }
      ]
    });
    // @ts-ignore
    this.deviceRecords = (response.data.pebble_device_record.map(i => {
      console.log(new BigNumber(i.longitude).toNumber())
      return new DeviceRecordState({
        ...i,
        longitude: new BigNumber(i.longitude).toNumber(),
        latitude: new BigNumber(i.latitude).toNumber()
      })
    }));
    this.deviceRecords.forEach((i, index) => {
      this.gpsTransfrom(this.deviceRecords[index], index)
    })
    return this.deviceRecords.reverse()
  }

  async loadDeviceLogs({ limit, skip }:{ limit: number, skip: number },filter) {
    const response = await this.subgraphAPI.query({
      pebble_device_record: [
        {
          limit: limit,
          offset: skip,
          order_by: [
            {timestamp : order_by.desc}
          ],
          where: {
            imei: {_eq: filter.imei}
          }
        },
        {
          id: true,
          imei: true,
          operator: true,
          gas_resistance: true,
          timestamp: true,
          signature: true,
          latitude:true,
          longitude:true,
          pressure:true,
          temperature:true,
          temperature2:true,
          vbat:true,
          humidity:true,
          accelerometer:true,
          light:true,
          gyroscope:true
        }
      ],
      pebble_device_record_aggregate:[
        {
          where: {
            imei: {_eq: filter.imei}
          }
        },
        {
          aggregate:{
            count:true
          }
        }
      ]
    });
    // @ts-ignore
    this.devicelogs = (response.data.pebble_device_record.map(i => new DeviceRecordState({
      ...i,
      longitude: new BigNumber(i.longitude).toNumber(),
      latitude: new BigNumber(i.latitude).toNumber()
    })));
    // @ts-ignoreg
    this.totalDeviceLogs = response.data.pebble_device_record_aggregate.aggregate.count
    return this.devicelogs
  }

  async loadLastLogsTime(imei){
    const response = await this.subgraphAPI.query({
      pebble_device_record:[
        {
          limit:1,
          where: {
            imei: {_eq: imei}
          },
          order_by: [
            {timestamp: order_by.desc}
          ],
        },
        {
          timestamp: true,
        }
      ]
    })

    // @ts-ignore
    return response.data.pebble_device_record[0].timestamp
  }
}
