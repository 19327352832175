import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '@/store/index';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { ModalBody, ModalCloseButton, ModalHeader, Modal, ModalOverlay, ModalContent, Text } from '@chakra-ui/react';
import Step1BeforeStart from './Step1BeforeStart';
import Step2EnterOta from './Step2EnterOta';
import Step3Confirm from './Step3Confirm';
import Step4Download from './Step4Download';

const InstallFirmwareModal = observer(({isOpen,currentDevice,currentApp }: any) => {
  const { lang,appStore,firmware } = useStore();
  const { nextStep, prevStep, activeStep,reset } = useSteps({
    initialStep: 0
  });
  const store = useLocalObservable(() => ({
    step:{
      0:'',
      1:'',
      2:'',
      3:''
    },
    reset:{
      0:'',
      1:'',
      2:'',
      3:''
    },
    title:{
      0:lang.t('before.you.start'),
      1:lang.t('enter.ota'),
      2:lang.t('confirm.update'),
      3:lang.t('download.firmware')
    },
    firmwareApp:null
  }));

  useEffect(()=>{
    store.step = Object.assign({},store.reset)
    store.step[activeStep] = store.title[activeStep]
  },[activeStep])

  useEffect(()=>{
    async function queryFirmware(){
      const [id,version] = currentApp.firmware.split(' ')
      store.firmwareApp = await firmware.queryFirmware({id,version})
    }
    reset()
    if (currentApp){
      queryFirmware()
    }

  },[appStore.isOpenInstallFirmWareModal])



  return (
    <>
      <Modal isOpen={isOpen} isCentered onClose={()=>appStore.closeInstallFirmwareModal()} size='2xl'>
        <ModalOverlay />
        <ModalContent bg='mainColor' w='600px'>
          <ModalHeader>
            {lang.t('update.firmware')}
          </ModalHeader>
          <ModalCloseButton _focus={{}} _hover={{ color: 'brandColor' }} />
          <ModalBody>
            {
              store.firmwareApp?(
                <>
                  <Steps activeStep={activeStep}>
                    <Step isCompletedStep={false}  label={store.step[0]} key={lang.t('before.you.start')}>
                      <Step1BeforeStart nextStep={nextStep}/>
                    </Step>
                    <Step isCompletedStep={false} label={store.step[1]} key={lang.t('enter.ota')}>
                      <Step2EnterOta nextStep={nextStep} prevStep={prevStep} />
                    </Step>
                    <Step isCompletedStep={false} label={store.step[2]} key={lang.t('confirm.update')}>
                      <Step3Confirm nextStep={nextStep} prevStep={prevStep} currentDevice={currentDevice} currentApp={currentApp}/>
                    </Step>
                    <Step isCompletedStep={false} label={store.step[3]} key={lang.t('download.firmware')}>
                      <Step4Download nextStep={nextStep} prevStep={prevStep} currentDevice={currentDevice} currentApp={currentApp}/>
                    </Step>
                  </Steps>
                </>
              ):(
                <>
                 <Text px={1} py={4}> {lang.t('error.no.firmware.message')}</Text>
                </>
              )
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});

InstallFirmwareModal.displayName = 'InstallFirmwareModal';
export default InstallFirmwareModal;
