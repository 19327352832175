import { observer } from 'mobx-react-lite';
import React from 'react';
import { Divider, Flex, Image, Text } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { PrimaryButton,PrimaryLineButton } from '@/components/Button';

const Step1BeforeStart = observer(({ nextStep }:any)=>{
  const {lang,appStore} = useStore();

  return(
    <>
      <Flex alignItems='flex-start' mt={10}>
        <Image w='7.5rem' h='7.5rem' src='/images/apps/install_firmware_before_start.png' />
        <Text ml={10} >
          {lang.t('install.firmware.tips1')}
        </Text>
      </Flex>
      <Divider mt={10} />
      <Flex justifyContent='flex-end' mt={5}>
        <PrimaryLineButton w='140px' mr={4} onClick={()=>{
          appStore.closeInstallFirmwareModal()
          appStore.openSelectDeviceModal();
        }}>
          {lang.t('cancel')}
        </PrimaryLineButton>
        <PrimaryButton w='140px' onClick={nextStep}>
          {lang.t('next')}
        </PrimaryButton>
      </Flex>
    </>
  )
})

export default Step1BeforeStart
