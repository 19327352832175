import { IDeviceItem } from './DeviceItem';

export const devices:IDeviceItem[] = [
  {
    title:'Ucam',
    desc:'Own your privacy with the first blockchain-powered home security camera.',
    img:'device/ucam.png',
    link:'https://iotex.io/ucam',
    bg:'linear(to-r, #58BDE9 20%, #788FF4 100%)'
  },
  {
    title:'Pebble',
    desc:'Bring real world data to IoTeX and earn digital assets in our ecosystem of Dapps. Go from data stream to revenue stream in a few clicks.',
    img:'device/pebble.svg',
    link:'https://iotex.io/pebble',
    bg:'linear(to-br, #43C9BA -0.53%, #9643C9 100%)'
  },
  {
    title:'Dev Boards',
    desc:'Connect & build on IoTeX: Raspberry Pi, Arduino, and ESP32 boards. View, manage, and monetize your data. ',
    img:'device/raspberry.png',
    link:'',
    bg:'linear(to-br, #39E268 0%, #29C3E4 104.54%)'
  },
  {
    title:'Virtual Pebble',
    desc:'Bring real world data to IoTeX and earn digital assets in our ecosystem of Dapps. Go from data stream to revenue stream in a few clicks.',
    img:'device/virtual.svg',
    link:'',
    bg:'linear(to-br, #FBA639 0%, #4E1BDE 125.99%)'
  }
]
