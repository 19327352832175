import { observer } from 'mobx-react-lite';
import React from 'react';
import { Flex, Icon, Image, Text } from '@chakra-ui/react';

export const SiderItem=observer(({item,pathname,collapsed,hoverPath,...props}:any)=>{
  return(
    <>
      <Flex
        direction='row'
        py='1.25rem'
        pl='1rem'
        cursor='pointer'
        align='center'
        justifyContent='space-between'
        bg={pathname === item.path ? '#121222' : 'transparent'}
        _hover={{ background: '#121222', borderRadius: '0 50px 50px 0px' }}
        borderRadius={pathname === item.path ? '0 50px 50px 0px' : null}
        w='90%'
        {...props}
      >
        <Flex align='center'>
          {item.icon&& <Icon as={item.icon} mr='0.8rem' color={pathname === item.path ? 'brandColor' : 'white'} />}
          {item.src&&<Image w='24px' h='24px' src={item.src} mr='0.8rem'
                            color={pathname === item.path ? 'brandColor' : 'white'}/>}
          {!collapsed && <Text color={pathname === item.path ? 'brandColor' : 'white'}
                               fontWeight='medium'>{item.name}</Text>}
        </Flex>

        {pathname === item.path||hoverPath===item.path ? <Image src='images/round.svg' w='8px' h='8px' mr={5} /> : null}
      </Flex>
    </>
  )
})
