export const publicConfig = {
  isProd: process.env.PROD === 'true',
  infuraId: import.meta.env.VITE_INFURA_ID,
  APIURL: import.meta['APIURL'],
  backendServiceURL: 'http://34.67.93.56:9000', // test backand url
  uploadURL: "https://pebble-api.onrender.com/api/upload",
  graphAPIURL: process.env.PROD?"https://app.iott.network/subgraph":"https://dev.iotex.io/v1/graphql",
  START_HEIGHT: 9818023,
  CDN:'https://iotexproject.iotex.io/machinefi-dapps/main'
};
