import { observer } from 'mobx-react-lite';
import React from 'react';
import { Flex, Image, Text, Divider, Button } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { CancelButton, NextButton } from '@/components/Button';

const BeforYouStart = ({ nextStep }) => {
  const { lang,appStore } = useStore();
  return (
    <>
      <Flex alignItems='flex-start' mt={10}>
        <Image w='7.5rem' h='7.5rem' src='/images/apps/download.png' />
        <Text ml={10} dangerouslySetInnerHTML={{__html:lang.t('install.app.tips1')}} whiteSpace='pre-line'/>
      </Flex>
      <Divider mt={10} />
      <Flex justifyContent='flex-end' mt={5}>
        <CancelButton onClick={()=>appStore.isOpenInstallAppModal =false} mr={4}/>
        <NextButton onClick={nextStep} />
      </Flex>
    </>
  );
};

export default observer(BeforYouStart);
