import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from '@/store/index';
import { Text } from '@chakra-ui/react';
import { helper } from '@/lib/helper';
import DeviceBattery from './DeviceBattery';
import DeviceSignal from './DeviceSignal';
import DeviceStatus from './DeviceStatus';
import { Button } from '@/components/Button';
import { Header } from '@/components/Table/type';
import ITable from '@/components/Table/Table';
import RemoveDeviceModal from './modal/RemoveDeviceModal';
import { DeviceAddress } from './DeviceAddress';
import { Checkbox } from '@chakra-ui/react'

const DeviceTable = observer(({ isOpenRemoveModal, closeRemoveModal, isCheckboxVisible, check, uncheck }:{isOpenRemoveModal:boolean, closeRemoveModal: any, isCheckboxVisible: boolean, check: any, uncheck: any}) => {
  const { deviceStore, lang,god } = useStore();
  const [ checkedItem, setCheckedItem ]  = useState<string>('')
  const [ singleCheck, setSinglecheck ] = useState(false)

  const store = useLocalObservable(() => ({
    fetchDevice: async (pagination) => {
      await deviceStore.loadDevices(pagination, {});
      return {
        items: deviceStore.devices,
        totalItems: deviceStore.totalNumber
      };
    },
    clearRemoveModal() {
      closeRemoveModal()
      this.device={}
    },
    handleCheck(e, item) {
      if (e.target.checked) {
        store.device = item
        setCheckedItem(e.target.value)
        setSinglecheck(true)
        check()
      } else {
        setSinglecheck(false)
        uncheck()
      }
    },
    device: {}
  }));

  const columns: Header[] = [
    {
      text: '',
      value: 'checkbox',
      children:(item)=>{
        return(
          <Checkbox
            colorScheme={'teal'}
            hidden={!isCheckboxVisible}
            value={item.id}
            isDisabled={checkedItem !== item.id && singleCheck}
            onChange={(e) => {store.handleCheck(e, item)}}
          />
        )
      }
    },
    {
      text: lang.t('device.table.name'),
      value: 'name'
    },
    {
      text: lang.t('device.address'),
      value: 'address',
      children:(item)=><DeviceAddress item={item}/>
    },
    {
      text: lang.t('device.table.firmware'),
      value: 'firmware',
      children: (item) => <Text>{item.real_firmware || '...'}</Text>
    },
    {
      text:lang.t('device.table.lastUpdatedTime'),
      value: 'deviceRecord.timestamp',
      formatter:(value)=>helper.formatTime(value)
    },
    {
      text: lang.t('battery'),
      value: 'vbat',
      width: '150px',
      children: (item) => <DeviceBattery w='fit-content' vbat={item.deviceRecord.vbat} />
    },
    {
      text: lang.t('device.table.signal'),
      value: 'signal',
      width: '150px',
      children: (item) => <DeviceSignal snr={item.deviceRecord.snr} />
    },
    {
      text: lang.t('device.table.status'),
      value: 'status',
      children: (item) => <DeviceStatus status={item.status} />
    }
  ];
  return (
    <>
      <ITable headers={columns} fetch={store.fetchDevice} />
      <RemoveDeviceModal isOpen={isOpenRemoveModal} onClose={store.clearRemoveModal} device={store.device} />
    </>
  );
});

DeviceTable.displayName = 'DeviceTable';

export default DeviceTable;
