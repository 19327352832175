import { Button } from '@chakra-ui/react';
import React from 'react';
import { useStore } from '@/store/index';
import { observer } from 'mobx-react-lite';

const CancelButton=({onClick,...props})=>{
  const {lang} = useStore()
  return(
    <>
      <Button
        onClick={onClick}
        borderColor="brandColor"
        backgroundImage="linear-gradient(#1C1B30, #1C1B30), linear-gradient(to right, #43C9BA,#9643C9)"
        css={{
          backgroundClip: "padding-box, border-box",
          backgroundOrigin: "border-box",
        }}
        border="double 1px transparent"
        w='140px'
        {...props}
        _hover={{
          color:'brandColor'
        }}
      >
        {lang.t('cancel')}
      </Button>
    </>
  )
}

export default observer(CancelButton)
