import { observer, useLocalObservable } from 'mobx-react-lite';
import {Text, Flex, Spinner, useColorMode} from '@chakra-ui/react';
import { useStore } from '../../store';
import React from 'react';

export const LoadingSpinner = observer(() => {
  const { lang } = useStore();
  const { colorMode } = useColorMode();

  return (
    <Flex justifyContent="center" h="70vh" alignItems="center">
      <Spinner color="brandColor" size="lg" />
      <Text fontWeight="medium" color={colorMode === 'light' ? "textPrimary" : 'lightColor'} fontSize="1rem" ml="1rem">
        {lang.t('loading')}
      </Text>
    </Flex>
  );
});
