import React from "react"
import { Button, ButtonProps } from '@chakra-ui/react';
interface IComponentProps extends ButtonProps{
  text?:string
  children?:React.ReactNode
}
const PrimaryLineButton=({text,children,...props}:IComponentProps)=>{
  return(
    <>
      <Button
        fontWeight={500}
        borderColor="brandColor"
        backgroundImage="linear-gradient(#1C1B30, #1C1B30), linear-gradient(to right, #43C9BA,#9643C9)"
        css={{
          backgroundClip: "padding-box, border-box",
          backgroundOrigin: "border-box",
        }}
        border="double 1px transparent"
        _hover={{
          color:'brandColor'
        }}
        _focus={{}}
        _active={{}}
        {...props}
      >
        {text}
        {children}
      </Button>
    </>
  )
}
export default PrimaryLineButton
