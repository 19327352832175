import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { Avatar, Box, Checkbox, Flex, Heading, Image, Input, Link, Text } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import BeforeStart from './components/AddDevice/BeforeStart';
import VerifyMetadata from './components/AddDevice/VerifyMetadata';
import ConfirmPebble from './components/AddDevice/ConfirmPebble';

export const AddDevice = observer(() => {
  const { lang,god,deviceStore } = useStore();

  const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    initialStep: 0
  });

  const store=useLocalObservable(()=>({
    isCompletedStep:false,
    setComplete(){
      this.isCompletedStep = true
    }
  }))

  return (
    <Box px={{base:0,md:0}}>
      <Heading as='h3' size='lg' color='white' pb={10}>{lang.t('add.new.device')}</Heading>
      <Steps activeStep={activeStep} >
        <Step label={lang.t('before.add.device')} key={lang.t('before.add.device')}>
          <Box mt={20} px={{base:5,md:0}}>
            <BeforeStart nextStep={nextStep}/>
          </Box>
        </Step>

        <Step label={lang.t('add.device.step2')} key='Verify IMEI1'>
          <VerifyMetadata nextStep={nextStep} prevStep={prevStep}/>
        </Step>

        <Step label={lang.t('add.device.step3')} key='Confirm on Pebble' isCompletedStep={store.isCompletedStep}>
          <ConfirmPebble nextStep={nextStep} prevStep={prevStep} setComplete={store.setComplete}/>
        </Step>
      </Steps>
    </Box>
  );
});
