import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '@/store/index';
import { Flex, Spinner, Text } from '@chakra-ui/react';

const LoadingMapData = observer(()=>{
  const { lang } = useStore();

  return(
    <>
      <Flex justifyContent="center" h="70vh" alignItems="center" flexDirection='column' bg='linear-gradient(0deg, #121222, #121222)' borderRadius='20px'>
        <Spinner color="brandColor" size="lg" />
        <Text mt={5} fontWeight="500"  bg='linear-gradient(to right,#43C9BA,#9643C9)'
              backgroundClip='text' fontSize="2rem" ml="1rem">
          {lang.t('loading.map.data')}
        </Text>
        <Text w='45%' textAlign='center' mt={5}>
          {lang.t('loading.map.data.tips')}
        </Text>
      </Flex>
    </>
  )
})

LoadingMapData.displayName = 'LoadingMapData'

export default LoadingMapData
