import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '../../store/index';
import { useWeb3React } from '@web3-react/core';
import { injected } from '../../lib/web3-react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Box, Flex, Text, HStack, VStack } from '@chakra-ui/layout';
import { Image, Button, Img, Avatar, AvatarBadge, createStandaloneToast, Center, Divider, AvatarGroup, ModalHeader, ModalCloseButton, ModalBody, Link, ModalFooter } from '@chakra-ui/react';
import { metamaskUtils } from '../../lib/metaskUtils';
import { useEffect } from 'react';
import { Network } from '@/store/god';
import { IotexMainnetConfig } from '../../config/IotexMainnetConfig';
import { PrimaryButton } from '@/components/Button';

const toast = createStandaloneToast();

export const WalletSelecter = observer(() => {
  const { god, lang } = useStore();
  const { active, error, activate } = useWeb3React();

  const store = useLocalObservable(() => ({
    get visible() {
      return god.eth.connector.showConnector;
    },
    get networks() {
      return [IotexMainnetConfig];
    },
    close() {
      god.eth.connector.showConnector = false;
    },
    async setChain(val) {
      const chain = god.currentNetwork.chain.map[val];
      if (chain.networkKey !== 'eth') {
        await metamaskUtils.setupNetwork({
          chainId: chain.chainId,
          blockExplorerUrls: [chain.explorerURL],
          chainName: chain.name,
          nativeCurrency: {
            decimals: chain.Coin.decimals || 18,
            name: chain.Coin.symbol,
            symbol: chain.Coin.symbol
          },
          rpcUrls: [chain.rpcUrl]
        });
        god.setChain(val);
      } else {
        toast({ title: 'Please connect to the  Ethereum network on metamask.', position: 'top', status: 'warning' });
      }
    },
    connectInejct() {
      god.setNetwork(Network.ETH);
      activate(injected);
      god.eth.connector.latestProvider.save('inject');
    }
  }));

  useEffect(() => {
    //@ts-ignore
    const { ethereum } = window;
    if (ethereum && ethereum.on && !active && !error) {
      const handleChainChanged = () => {
        store.connectInejct();
      };
      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          store.connectInejct();
        }
      };
      ethereum.on('networkChanged', handleChainChanged);
      ethereum.on('close', handleChainChanged);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('networkChanged', handleChainChanged);
          ethereum.removeListener('close', handleChainChanged);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
  }, [active, error, activate]);

  const config = [
    {
      title: 'Metamask',
      icon: '/images/metamask.svg'
    },
    {
      title: 'ioPay',
      icon: '/images/iopay.svg'
    },
    {
      title: 'Trust',
      icon: '/images/trustwallet.svg'
    },
    {
      title: 'Math',
      icon: '/images/mathwallet.svg'
    },
    {
      title: 'imToken',
      icon: '/images/imtoken.svg'
    }
  ];
  const names = config.map(item => item.title).join(', ');

  return (
    <Modal isOpen={store.visible} onClose={store.close} isCentered>
      <ModalOverlay />
      <ModalContent
        w={{ base: '90%', md: 'auto' }}
        minWidth={{ base: 0, md: '620px' }}
        boxShadow="unset"
        minH="450px"
        bg="linear-gradient(0deg, rgba(23, 22, 42, 0.5), rgba(23, 22, 42, 0.5)), #2E2D40"
        borderRadius="20px"
      >
        <ModalHeader>
          <Text fontSize="xl" fontWeight="bold">
            {lang.t('connect.wallet')}
          </Text>
        </ModalHeader>
        <ModalCloseButton _focus={{}} _hover={{ color: 'brandColor' }} />
        <ModalBody>
          {!god.currentNetwork.account && (
            <>
              <Flex mt={10} flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
                <Image src="/images/device/new_device_bg.png" w="143px" h="130px" />
                <Flex flexDirection="column" justifyContent="space-between" ml={{ base: 0, md: 10 }} mt={{ base: 5, md: 0 }}>
                  <Text fontSize="1rem">{lang.t('add.device.tips2')}</Text>
                  <Flex bg="textLight5" direction="column" mt="1.25rem" px={3} py={2} my={2}>
                    <Flex direction="column">
                      <Text fontSize="20" lineHeight="26.38px" fontStyle="normal" fontWeight="500">
                        {lang.t('browser.wallet')}
                      </Text>
                      <Text mt="3px" fontSize="12" lineHeight="16.38px" fontStyle="normal" fontWeight="500">
                        ({names})
                      </Text>
                    </Flex>
                    <Flex ml="2px" mt={2}>
                      <AvatarGroup size="sm" border="none">
                        {config.map((item, index) => {
                          return <Avatar name={item.title} key={item.title} src={item.icon} />;
                        })}
                      </AvatarGroup>
                    </Flex>
                    <Text fontSize="0.75rem" mt={2}>
                      Don’t have a ioPay wallet? Download it{' '}
                      <Link href="https://iotex.io/iopay " isExternal color="brandColor">
                        {'here >>'}
                      </Link>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Divider mt={10} />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Box w={{ base: '100%', md: '200px' }} h="40px">
            <PrimaryButton w="100%" onClick={store.connectInejct}>
              {lang.t('connect.wallet.now')}
            </PrimaryButton>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
