import { observer } from 'mobx-react-lite';
import React from 'react';
import { Divider, Flex, Image, Text } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { PrimaryButton, PrimaryLineButton } from '@/components/Button';

const Step2EnterOta = observer(({ nextStep, prevStep }:any)=>{
  const{lang} = useStore()
  return(
    <>
      <Flex alignItems='flex-start' mt={10}>
        <Image w='7.5rem' h='7.5rem' src='/images/apps/enter_ota.gif' />
        <Text ml={10} whiteSpace='pre-line'>
          {lang.t('enter.ota.desc')}
        </Text>
      </Flex>
      <Divider mt={10} />
      <Flex justifyContent='flex-end' mt={5}>
        <PrimaryLineButton w='140px' mr={4} onClick={prevStep}>
          {lang.t('back')}
        </PrimaryLineButton>
        <PrimaryButton w='140px' onClick={nextStep}>
          {lang.t('next')}
        </PrimaryButton>
      </Flex>
    </>
  )
})

Step2EnterOta.displayName = 'Step2EnterOta'
export default Step2EnterOta
