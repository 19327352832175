import { MappingState } from '@/store/standard/MappingState';
import { EthNetworkState } from '../store/lib/EthNetworkState';
import { IotexMainnetConfig } from './IotexMainnetConfig';
import { IotexTestnetConfig } from './IotexTestnetConfig';
import { publicConfig } from './public';

// const EthChains = [BSCMainnetConfig, BSCTestnetConfig, ETHMainnetConfig, ETHKovanConfig, IotexTestnetConfig, IotexMainnetConfig, PolygonMainnetConfig];
const EthChains = publicConfig.isProd ? [IotexMainnetConfig] : [IotexTestnetConfig];

export const EthNetworkConfig = new EthNetworkState({
  allowChains: EthChains.map((i) => i.chainId),
  info: {
    token: {
      tokenExample: '0x000000000000000000000000000000000000000'
    }
  },
  chain: new MappingState({
    currentId: publicConfig.isProd ? IotexMainnetConfig.chainId : IotexTestnetConfig.chainId,
    map: EthChains.reduce((p, c) => {
      p[c.chainId] = c;
      return p;
    }, {})
  })
});
