import { Button as ChakraButton, ButtonProps, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite'
import React from 'react';

export const Button = observer((props: ButtonProps) => {
  const theme = useTheme()
  const extendProps = (props: ButtonProps) => {
    const { colorScheme = 'default', ...restProps } = props


    switch (colorScheme) {
      case "primary":
        return { background: "brandColor", color: "textColor", ...restProps }
      case "default":
        return {
          border: "1px solid",
          borderColor: "currentColor",
          color: "brandColor",
          ...restProps
        }
      case "low":
        return {
          // bg: theme('#F1F1F1', 'rgba(255, 255, 255, 0.1)'),
          color: 'textColor',
          ...restProps
        }
      default:
        return props

    }
  }
  const buttonProps: ButtonProps = extendProps(props)
  const size = useBreakpointValue({
    md: "md",
    base: "sm"
  })
  return <ChakraButton size={size}

                       _hover={{
                         opacity: 0.8,
                       }}
                       {...buttonProps} />
})
