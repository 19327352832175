import { observer, useLocalObservable } from 'mobx-react-lite';
import { Box, Text,  Flex,  } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import React, { useEffect } from 'react';
import { BankRecordState } from '@/store/account';
import BigNumber from 'bignumber.js';
import { BigNumberState } from '@/store/standard/BigNumberState';
import { helper } from '@/lib/helper';
import { CopyIcon } from '@chakra-ui/icons';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';
import ITable  from '@/components/Table/Table';
import dayjs from 'dayjs';
import { Header } from '@/components/Table/type';
import { Link } from '@chakra-ui/layout';

export interface AccountTableInfo {
  index: number;
  item: BankRecordState;
}

export const AccountTable = observer(() => {
  const { lang,  god, accountStore } = useStore();
  const store = useLocalObservable(() => ({
    pageNum: 1,
    limit: 15,
    isLoading: false,
    bankRecords: new Array<BankRecordState>(),
    total: 100,
    skip: 0,
    timer: null,
    fetchBankRecords: async (pagination) => {
      await accountStore.loadBankRecords(pagination,{address: god.currentNetwork.account});
      return {
        items:accountStore.bankRecords,
        totalItems:accountStore.totalNumber
      }
    }
  }));


  const columns:Header[]=[
    {
      text:lang.t('action'),
      value:'type',
      children:(item)=>{
        return(
          <>
            {
              item.type===0&&lang.t('deposit')
            }
            {
              item.type===1&&lang.t('withdraw')
            }
            {
              item.type===2&&lang.t('paid')
            }
          </>
        )
      }
    },
    {
      text: lang.t('tx.hash'),
      value:'id',
      children:(item)=>{
        return(
          <>
            <Link color='brandColor' _hover={{color:'brandColor'}} href={`${god.currentChain.explorerURL}/tx/${item.id}`}  isExternal>{helper.string.truncate(item.id, 12, '...')}</Link>
          </>
        )
      }
    },
    {
      text: 'FROM',
      value:'from',
      children:(item)=>{
        return(
          <Link isExternal color='brandColor' _hover={{color:'brandColor'}} href={`${god.currentChain.explorerURL}/address/${item.from}`}>
            {helper.string.truncate(item.from, 12, '...')}
          </Link>
        )
      }
    },
    {
      text: 'TO',
      value: 'to',
      children:(item)=>{
        return(
          <Link isExternal color='brandColor' _hover={{color:'brandColor'}} href={`${god.currentChain.explorerURL}/address/${item.to}`}>
            {helper.string.truncate(item.to, 12, '...')}
          </Link>
        )
      }
    },
    {
      text: 'AMOUNT',
      value: 'amount',
      children:(item)=>{
        const iotxAmount = new BigNumberState({ decimals: 18, loading: true });

        iotxAmount.setValue(new BigNumber(item.amount));
        return(
          <>
            {iotxAmount.format} IOTX
          </>
        )
      }
    },
    {
      text: 'TIME',
      value: 'timestamp',
      formatter:(value)=>dayjs.unix(value).format('YYYY-MM-DD HH:mm:ss')
    },
  ]

  return (
    <Box w="100%">
      <Text mt={10} mb={10} fontSize='18px' fontWeight={500} ml={4}>{lang.t('recent.activities')}</Text>
      <ITable headers={columns} fetch={store.fetchBankRecords}/>
    </Box>
  );
});
