import { Box, Spinner, Text, Stack } from '@chakra-ui/react';
import React from 'react';
import { useStore } from '@/store/index';

export const AddDeviceLoading=()=>{
  const { lang } = useStore()

  return(
    <Box
      bg='url(/images/device/pebble-dark.svg)'
      bgSize='contain'
      bgRepeat='no-repeat'
      bgPosition='center'
    >
      <Stack
        mt={20}
        pt={32}
        alignItems='center'
        justifyContent='center'
        objectFit={'scale-down'}
        w='100'
        minH='272px'
        bg={'rgba(28, 27, 48, 0.85)'}
      >
        <Spinner size="lg" color='brandColor' mb={8}/>
        <Text>{lang.t("add.device.connecting")}</Text>
        <Text>{lang.t("add.device.tips3")}</Text>
        <Text>{lang.t("add.device.tips4")}</Text>
      </Stack>
    </Box>
  )
}
