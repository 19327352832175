import React, { useEffect } from 'react';
import { Box, Flex, Heading, Text, useColorMode } from '@chakra-ui/react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import BingMapsReact from './BingMapsReact';
import { useStore } from '@/store/index';
import { DeviceRecordState } from '@/store/mydata';
import moment from 'moment';
import LoadingMapData from '../../pages/myData/components/LoadingMapData';

interface IMapData {
  data: Array<DeviceRecordState>;
  isLoading:boolean
}

export const MapChart = observer((props: IMapData) => {
  const { lang } = useStore();
  const { data,isLoading } = props;
  const { colorMode } = useColorMode();
  let pushPins = new Array<any>();
  let pushPinsWithInfoboxes = new Array<any>();
  const location = data&&data.slice().reverse().find(i=>(i.latitude!==200&&i.longitude!==200)&&(i.latitude!==100&&i.longitude!==100))
  if (location) {
    const element = location;
    const pushpin = {
      center: {
        latitude:  Number(element.latitude.toFixed(8)),
        longitude: Number(element.longitude.toFixed(8))
      },
      options: {
        color: '#43C9BA',
      }
    };
    const infobox = {
      center: {
        latitude:  element.latitude,
        longitude: element.longitude
      },
      options: {
        color: '#43C9BA',
      },
      metadata: {
        description: `
            imei: ${element.imei} <br />
            latitude: ${element.latitude.toFixed(8)} <br />
            longitude: ${element.longitude.toFixed(8)} <br />
            date: ${moment(Number(element.timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')}
          `
      }
    }
    pushPins.push(pushpin);
    pushPinsWithInfoboxes.push(infobox)
  }

  return (
    <Box w="100%" p="6" h="100%">
      <Flex justifyContent='space-between' mb={5}>
        <Heading color={colorMode === "light" ? "blackAlpha.600" : "lightColor"} size="sm" height="5%">
          {lang.t('map.data')}
        </Heading>
        {
          location&& <Text color='textLight45'>Last updated: {moment(Number(location.timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')}</Text>
        }
      </Flex>
      <Box w="100%" h="94%" borderRadius='20px' overflow='hidden'>
        {
          isLoading&&<LoadingMapData/>
        }

        {!isLoading&&pushPins.length > 0 && pushPins[0].center && (
          <>
            <BingMapsReact
              bingMapsKey="ApMIM5i9_KIuSba1R299vZ8IUQbg-rwqruOO4JsAUXJ13gZPOHt19iBdzQkkjqPW"
              mapOptions={{
                navigationBarMode: 'square'
              }}
              width="100%"
              height="100%"
              pushPins={pushPins.length > 0 ? pushPins : null}
              pushPinsWithInfoboxes={pushPinsWithInfoboxes.length > 0 ? pushPinsWithInfoboxes : null}
              viewOptions={{
                center: {
                  latitude:pushPins[0].center.latitude,
                  longitude:pushPins[0].center.longitude
                },
                mapTypeId: 'road',
                zoom: 5
              }}
            />
          </>
        )
        }
      </Box>
    </Box>
  );
});
