import React from 'react';
import { Box, Avatar, Flex, Heading, Text, Button, useColorMode, Divider } from '@chakra-ui/react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '../../store';

import { DepositDialog } from '@/components/depositDialog';
import { WithdrawDialog } from '@/components/WithdrawDialog';
import { AccountTable } from './components/AccountTable'
import { Profile } from './components/Profile';


const AccountDetail = observer((props) => {
  const { god,accountStore,lang } = useStore();
  const store = useLocalObservable(() => ({
    isLoading: false,
    isOpen: false,
    isOpenWithdraw: false,
    openDialog(){
      this.isOpen = true
    },
    closeDialog(){
      this.isOpen = false
    },
    onOpenWithdraw() {
      this.isOpenWithdraw = true
    },
    closeDialogWithdraw(){
      this.isOpenWithdraw = false
    },
  }));

  return (
    <Box position="relative">
      {
        god.currentNetwork.account&&accountStore.isVisible&&(
          <>
            <Heading as="h3" size="lg" color='white'>
              {lang.t('account.details')}
            </Heading>
            <Profile onOpen={store.openDialog} onOpenWithdraw={store.onOpenWithdraw} />
            <AccountTable />
            <DepositDialog isOpen={store.isOpen} onClose={store.closeDialog} />
            <WithdrawDialog isOpen={store.isOpenWithdraw} onClose={store.closeDialogWithdraw} />
          </>
        )
      }
    </Box>
  );
});

export default AccountDetail;
