import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Modal, ModalContent, ModalOverlay, ModalCloseButton } from '@chakra-ui/modal';
import { useStore } from '../../store/index';
import { eventBus } from '../../lib/event';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import { Button, Text, Flex, ModalHeader, Box } from '@chakra-ui/react';
import { ViewIcon, CopyIcon } from '@chakra-ui/icons';

export const WalletInfo = observer(() => {
  const { god, lang } = useStore();

  const store = useLocalObservable(() => ({
    get visible() {
      return god.currentNetwork.walletInfo.visible;
    },
    close() {
      god.currentNetwork.walletInfo.visible = false;
    },
    copy() {
      copy(god.currentNetwork.account);
      toast.success(lang.t('address.copied'));
    },
    logout() {
      eventBus.emit('wallet.logout');
      store.close();
    }
  }));
  return (
    <Modal isOpen={store.visible} onClose={store.close} isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent background='white' w={{ base: '90%', md: '100%' }}>
        <ModalHeader color='black' >
					{lang.t('your.wallet')}
				</ModalHeader>
				<ModalCloseButton color='black'/>
        <Box px='3' border='0.8px solid rgba(12, 45, 112, 0.1)' py='3' mx='4' textAlign='center' color='black' bg='rgba(12, 45, 112, 0.1)'>
          {god.currentNetwork.account}
        </Box>
        <Flex p='4' fontSize='14px' lineHeight='20px' fontWeight='normal'  color='#0C2D70'>
          <Box cursor='pointer' onClick={() => {
            window.open(`https://iotexscan.io/address/${god.currentNetwork.account}`)
          }}>
            <ViewIcon/>
              {lang.t('iotexscan')}
            </Box>
          <Box cursor='pointer' ml='4' onClick={() => {store.copy()}}><CopyIcon /> {lang.t("copy.address")}</Box>
        </Flex>
        <Flex w='100%' justify='center' my='4'>
          <Button color='white' w='100px' bg='brandColor' onClick={store.logout} size="md">
              {lang.t('logout')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
});
