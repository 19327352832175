import React, { useRef } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Box, Flex, Text, Select, Icon, Link } from '@chakra-ui/react';
import { LineChart } from '@/components/LineChart';
import { MapChart } from '@/components/Map';
import { useEffect } from 'react';
import { useStore } from '@/store/index';
import { DeviceState } from '@/store/lib/DeviceState';
import { DeviceRecordState } from '@/store/mydata';
import toast from 'react-hot-toast';
import moment from 'moment';
import { GridIcon, ListIcon } from '@/components/Icons';
import MyDataTable from './components/MyDataTable';
import { PrimaryButton } from '@/components/Button';
import ChooseDeviceModal from '../device/components/ChooseDeviceModal';
import ConnectWallet from '../device/components/deviceEmpty/ConnectWallet';
import LoadingMapData from './components/LoadingMapData';
import { ChevronDownIcon } from '@chakra-ui/icons';

export interface IMapItem {
  latitude: number;
  longitude: number;
}

export interface IMyDataItem {
  timestamp: Array<number>;
  value: Array<number>;
}

export const MyData = observer(() => {
  const { lang, deviceRecordStore, deviceStore,god } = useStore();

  const selectDeviceContainer = useRef()

  enum ShowType {
    LIST = 'LIST',
    GRID = 'GRID'
  }

  const store = useLocalObservable(() => ({
    deviceName: '',
    isShowDeviceList:false,
    isOpenSelectDevice:false,
    openSelectDevice(){
      this.isOpenSelectDevice = true
    },
    closeSelectDevice(){
      this.isOpenSelectDevice = false
    },
    showType: ShowType.GRID,
    toggleType(type: ShowType) {
      this.showType = type;
    },
    snr: new Array<number>(),
    vbat: new Array<number>(),
    gasResistance: new Array<number>(),
    temperature: new Array<number>(),
    pressure: new Array<number>(),
    humidity: new Array<number>(),
    light: new Array<number>(),
    gyroscope: new Array<string>(),
    accelerometer: new Array<string>(),
    time: new Array<number>(),
    map: new Array<IMapItem>(),
    pickedDevice: null as DeviceState,
    isLoading: false,
    deviceRecords: new Array<DeviceRecordState>(),
    isChange: false,
    isOpenConnectWallet:false,
    openModal(){
      this.isOpenConnectWallet = true
    },
    closeModal(){
      this.isOpenConnectWallet = false
    },
    get confirmedDevices() {
      return deviceStore.devices.filter(item => item.status === 2);
    },
    async loadMydata() {
      try {
        store.deviceRecords = await deviceRecordStore.loadDeviceRecords(store.pickedDevice.id);
        if (store.deviceRecords.length !== 0) {
          store.deviceRecords.map(item => {
            const { snr, vbat, gas_resistance, pressure, humidity, temperature2, accelerometer, gyroscope, light } = item;
            store.temperature.push(temperature2);
            store.pressure.push(pressure);
            store.humidity.push(humidity);
            store.gasResistance.push(gas_resistance);
            store.snr.push(snr);
            store.vbat.push(vbat);
            store.light.push(light);
            store.accelerometer.push(JSON.stringify(accelerometer));
            store.gyroscope.push(JSON.stringify(gyroscope));
            store.time.push(item.timestamp * 1000);
          });
        }
        store.isLoading = false;
      } catch (error) {
        console.log(error);
        store.isLoading = false;
      }
    },
    onSelectDevice: imei => {
      store.deviceRecords = [];
      store.reset();
      store.isLoading = true;
      const arr = store.confirmedDevices.filter(item => item.id === imei);
      if (arr[0].status !== 2) toast.error('');
      store.pickedDevice = arr[0];
      store.isLoading = true;
      store.isChange = true;
      store.loadMydata();
      store.isShowDeviceList = false;
    },
    getDate(timestamp) {
      const time = Number(timestamp) * 1000;
      return moment(time).format('YYYY-MM-DD HH:mm:ss');
    },
    reset() {
      store.temperature = [];
      store.pressure = [];
      store.humidity = [];
      store.gasResistance = [];
      store.snr = [];
      store.vbat = [];
      store.light = [];
      store.accelerometer = [];
      store.gyroscope = [];
      store.time = [];
    }
  }));

  useEffect(() => {
    store.isLoading = true;
    if (store.confirmedDevices.length !== 0) {
      store.pickedDevice = store.confirmedDevices[0];
      store.loadMydata();
    }
    if (!god.isConnect){
      deviceStore.devices=[]
    }

  }, [god.isConnect,store.confirmedDevices]);

  useEffect(()=>{
    function handleClickOutside(event) {
      // @ts-ignore
      if (selectDeviceContainer.current && !selectDeviceContainer.current.contains(event.target)) {
        store.isShowDeviceList =false;
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  },[selectDeviceContainer])

  return (
    <Box h="100%">
      {deviceStore.confirmedDevices.length === 0 && (
        <Flex h="100%" justifyContent="center" w="100%">
          <Flex flexDirection="column" width="fit-content" mt={40} alignItems="center" bgImage="url(images/mydata/bg.png)" bgRepeat="no-repeat" bgPos="top center" pt={14}>
            <Text bg="linear-gradient(to right,#43C9BA,#9643C9)" backgroundClip="text" fontWeight={700} fontSize={{ lg: '2rem', xl: '3rem', '2xl': '4rem' }}>
              {lang.t('no.device.connect')}
            </Text>
            <Text mt={20}>{lang.t('add.device.tips1')}</Text>
            <Link _hover={{}}>
              <PrimaryButton w="220px" h="52px" mt={10} onClick={god.isConnect?store.openSelectDevice:store.openModal}>
                {lang.t('add.device')}
              </PrimaryButton>
            </Link>
          </Flex>
        </Flex>
      )}
      {deviceStore.confirmedDevices.length > 0 && store.pickedDevice !== null && (
        <Box>
          <Flex mb="1.5rem" alignItems="center" justifyContent="space-between" w={{ base: '90%', md: '100%' }}>
            <Flex alignItems="center" flexDirection='column' position='relative' ref={selectDeviceContainer}>
              <Flex alignItems='center' id='dropdown'  cursor='pointer' onClick={()=>store.isShowDeviceList = !store.isShowDeviceList}>
                <Text fontSize='1.75rem' flex='none' mr='0.6rem' fontWeight='500'>
                  {store.pickedDevice.name}
                </Text>
                <ChevronDownIcon color='brandColor' w={8} h={8}/>
              </Flex>
              {
                store.isShowDeviceList&&(
                  <>
                    <Box id='dropdown-content' mt={10} position='absolute'  minW='fit-content' borderRadius='20px'  bg='#28283B' zIndex={2}>
                      {
                        store.confirmedDevices.map(device=>{
                          return(
                            <>
                              <Text onClick={()=>store.onSelectDevice(device.id)} display='flex' alignItems='center' px={6} key={device.id} cursor='pointer' fontSize='14px' _hover={{color:'brandColor'}} minH='45px'>{device.name}</Text>
                            </>
                          )
                        })
                      }
                    </Box>
                  </>
                )
              }
              <Box flex="1">
                {/*<Select _focus={{}} value={store.pickedDevice.id} onChange={store.onSelectDevice}>*/}
                {/*  {store.confirmedDevices.map(item => {*/}
                {/*    return (*/}
                {/*      <option key={item.id} value={item.id}>*/}
                {/*        {item.name}*/}
                {/*      </option>*/}
                {/*    );*/}
                {/*  })}*/}
                {/*</Select>*/}
              </Box>
            </Flex>
            <Flex>
              <Flex
                bg={ShowType.GRID === store.showType ? 'black' : ''}
                w="32px"
                h="32px"
                borderRadius="8px"
                alignItems="center"
                justifyContent="center"
                mr={4}
                onClick={() => store.toggleType(ShowType.GRID)}
              >
                <Icon as={GridIcon} color={ShowType.GRID === store.showType ? 'brandColor' : ''} w="24px" h="24px" cursor="pointer" />
              </Flex>
              <Flex
                bg={ShowType.LIST === store.showType ? 'black' : ''}
                w="32px"
                h="32px"
                borderRadius="8px"
                alignItems="center"
                justifyContent="center"
                mr={4}
                onClick={() => store.toggleType(ShowType.LIST)}
              >
                <Icon as={ListIcon} color={ShowType.LIST === store.showType ? 'brandColor' : ''} w="24px" h="24px" cursor="pointer" />
              </Flex>
            </Flex>
          </Flex>
          {store.deviceRecords.length === 0 ? (
           <LoadingMapData/>
          ) : (
            <>
              <Flex direction="column">
                <Flex w="100%" direction="column">
                  <Box w="100%" height={{ base: '50vh', lg: '70vh' }} mb="5%">
                    <MapChart data={store.deviceRecords} isLoading={store.isLoading} />
                  </Box>
                </Flex>
                {store.showType === ShowType.GRID && (
                  <>
                    <Flex w="100%" direction={{base: "column", md: "row"}} flexWrap="wrap" justifyContent="space-between" pb="2rem">
                      {store.temperature.length !== 0 && <Box w={{base: "100%", md: "48%"}}>
                        <LineChart type="tep" showSubTitle={true} unit="℃" time={store.time} data={store.temperature} title="Temperature" lineColor="#56CCF2" />
                      </Box>}
                      {store.pressure.length !== 0 && <Box w={{base: "100%", md: "48%"}} mt={{base: "1rem", md:"0"}}>
                        <LineChart type="air" showSubTitle={true} unit="PA" time={store.time} data={store.pressure} title={lang.t('pressure')} lineColor="#9290FE" />
                      </Box>}
                      {store.humidity.length !== 0 && <Box w={{base: "100%", md: "48%"}} mt={{base: "1rem", md:"3rem"}}>
                        <LineChart type="hum" showSubTitle={true} unit="%" time={store.time} data={store.humidity} title="Humidity" lineColor="#FFB572" />
                      </Box>}
                      {store.gasResistance.length !== 0 && <Box w={{base: "100%", md: "48%"}} mt={{base: "1rem", md:"3rem"}}>
                        <LineChart type="gas" showSubTitle={true} unit="ohms" time={store.time} data={store.gasResistance} title="Gas" lineColor="#B1C7DF" />
                      </Box>}
                      {/*<LineChart type="snr" showSubTitle={true} unit="db" time={store.time} data={store.snr} title="SNR" />*/}
                      {/*<LineChart type="bat" showSubTitle={true} unit="V" time={store.time} data={store.vbat} title="Battery" />*/}
                      {/*<LineChart type="light" showSubTitle={false} unit="" time={store.time} data={store.light} title="intensity" />*/}
                      {/*<LineChart type="acc" time={store.time} showSubTitle={false} unit="" data={store.accelerometer} title="Acceleromete" />*/}
                      {/*<LineChart type="gry" time={store.time} showSubTitle={false} unit="" data={store.gyroscope} title="Gyroscope" />*/}
                    </Flex>
                  </>
                )}
                {store.showType === ShowType.LIST && (
                  <>
                    <MyDataTable imei={store.pickedDevice.id} />
                  </>
                )}
              </Flex>
            </>
          )}
        </Box>
      )}
      <ChooseDeviceModal isOpen={store.isOpenSelectDevice} onClose={store.closeSelectDevice}/>
      <ConnectWallet isOpen={store.isOpenConnectWallet&&!god.isConnect} onClose={store.closeModal}/>
    </Box>
  );
});
