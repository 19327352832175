import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  MenuList,
  Menu,
  Button,
  Icon,
  MenuButton,
  MenuItem,
  Drawer, useDisclosure, DrawerBody, DrawerContent, DrawerOverlay, Divider
} from '@chakra-ui/react';
import { observer, useLocalObservable, useObserver } from 'mobx-react-lite';
import { useStore } from '../../store';
import { IoMenu } from 'react-icons/io5';
import { AiFillAppstore, AiFillBuild, AiFillHome } from 'react-icons/ai';
import { BsBarChartFill } from 'react-icons/bs';
// import { IotexConnector } from '@/store/lib/IotexNetworkState';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { DepositDialog } from '../depositDialog';
import { notification } from 'antd';
import { helper } from '@/lib/helper';
import { WalletInfo } from '../WalletInfo';
import { isAddress as isEthAddress } from '@ethersproject/address';
import { ConnectButton } from '@/components/Button';
import { fromBytes } from '@iotexproject/iotex-address-ts';
import { publicConfig } from '../../config/public';
import _, { size } from 'lodash';
import { Sider } from '../../layouts/Sider';
import { AccountIcon } from '@/components/Icons';
import { SiderItem } from '../../layouts/Sider/SiderItem';


export const Header = observer(() => {
  const { lang, god, deviceStore, accountStore } = useStore();
  const router = useLocation();
  const { pathname } = router;
  const { isOpen, onOpen, onClose,onToggle } = useDisclosure()

  const externalLinks = [
    {
      name: lang.t('github'),
      src: '/images/icon_gitHub.png',
      path: 'https://github.com/iotexproject/pebble-data-spec'
    },
    { name: lang.t('dev.docs'), src: '/images/icon_docs.png', path: 'https://github.com/iotexproject' },
    { name: lang.t('onboarding.pack'), src: '/images/icon_onboarding.png', path: 'https://onboard.iotex.io/' },
    { name: lang.t('ecosystem '), src: '/images/icon_ecopage.png', path: 'https://ecosystem.iotex.io/' }
  ];

  const navConfig = [
    { name: lang.t('home'), icon: AiFillHome, path: '/' },
    { name: lang.t('device'), icon: AiFillBuild, path: '/device' },
    { name: lang.t('apps'), icon: AiFillAppstore, path: '/apps' },
    { name: lang.t('my.data'), icon: BsBarChartFill, path: '/myData' }
  ];

  const store = useLocalObservable(() => ({
    address: '',
    isOpen: false,
    get configs() {
      if (god.currentNetwork.account && accountStore.isVisible) {
        const account = { name: lang.t('account'), icon: AccountIcon, path: '/account' };
        const cloneConfig = _.clone(navConfig);
        // @ts-ignore
        cloneConfig.splice(1, 0, account);
        return cloneConfig;
      } else {
        return navConfig;
      }
    },
    hoverPath:'',
    onConnectWallet() {
      // god.iotex.activeConnector(IotexConnector.IopayDesktop);
    },
    showWalletInfo() {
      god.currentNetwork.walletInfo.visible = true;
    },
    depositSuccess() {
      deviceStore.isDeposit = false;
      notification.success({ message: lang.t('deposit.success') });
    },
    get addressText() {
      return isEthAddress(store.address) ? '0x' : 'io';
    },
    toggleAddress() {
      if (isEthAddress(store.address)) {
        god.setDisplayAddressType('io')
        this.address = fromBytes(Buffer.from(String(this.address).replace(/^0x/, ''), 'hex')).string();
      } else {
        god.setDisplayAddressType('0x')
        this.address = god.currentNetwork.account;
      }
    }
  }));

  useEffect(() => {
    store.address = god.currentNetwork.account;
  }, [god.currentNetwork.account]);


  const CreditBalance = useObserver(() => {
    return (
      <Flex bg='#17162A' borderRadius='8px' mr={4} minWidth='140px' fontSize='14px'>
        <Text bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
              borderRadius='8px' px={2}
              py={1}>{deviceStore.bank.balanceStatus ? lang.t('credit.balance') : lang.t('insufficient.balance')}</Text>
        <Text color={deviceStore.bank.balanceStatus?'brandColor':'warnColor'} textAlign='center' px={2} py={1}>{deviceStore.bank.balance.format} IOTX</Text>
      </Flex>
    );
  });

  return (
    <Box bg='mainColor' color='white'>
      <Flex minH={'64px'} align={'center'} justify='flex-end'>

        <Flex pr={['1rem', '2rem']} direction='row' alignItems='center'>
          <Flex mr={4} bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
                borderRadius='8px' px={2}
                alignItems='center'
                py={1}>
            <Box
              background='brandColor'
              w='6px'
              h='6px'
              borderRadius='3px'
            />
            <Text ml={1}>{publicConfig.isProd?'Mainnet':'Testnet'}</Text>
          </Flex>
          {
            (!god.isConnect) && (
              <Box h='37px' w='200px' >
                <ConnectButton fontWeight={500}/>
              </Box>
            )
          }
          {god.isConnect && (
            <Flex cursor='pointer' onClick={store.toggleAddress} alignItems='center' justifyContent='space-between'
                  bgColor='#17162A'
                  borderRadius='8px' mr={4} minWidth='140px'>
              <Flex align='center' justify='center' borderRadius='8px' boxSize='32px'
                    bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
                    display={{ base: 'none', md: 'flex' }}>
                <Text fontWeight={700} bg='linear-gradient(to right,#43C9BA,#9643C9)'
                      backgroundClip='text'>{store.addressText}</Text>
              </Flex>
              {store.address && <Text textAlign='center' fontWeight={400} color='brandColor'
                                      mr={4}>{helper.string.truncate(store.address, 12, '...')}</Text>}
            </Flex>
          )}
          {accountStore.isVisible && god.isConnect && (
            <Flex alignItems='center' display={{ base: 'none', md: 'flex' }}>
              {CreditBalance}
              {
                !deviceStore.bank.balanceStatus && <Button
                  bg='brandColor'
                  size='md'
                  color='black'
                  _active={{}}
                  _hover={{}}
                  onClick={() => (store.isOpen = true)}
                  fontFamily='Poppins'
                  fontWeight={500}
                  borderRadius='8px'
                  boxShadow='0px 2px 0px rgba(0, 0, 0, 0.043)'
                  mr={5}
                >
                  {lang.t('deposit.credit')}
                </Button>
              }
            </Flex>
          )}

          <Menu>
            <MenuButton onClick={onToggle} display={{ base: 'block', md: 'none' }} as={Text} variant='ghost'>
              <IoMenu fontSize='1.5rem' />
            </MenuButton>
          </Menu>
          <Drawer onClose={onClose} isOpen={isOpen} size={'xs'}>
            <DrawerOverlay />
            <DrawerContent style={{width:'50% !important'}}>
              <DrawerBody bg='#232236' p='0'>
                {store.configs.map((item) => {
                  return (
                    <Link to={item.path} key={item.name}>
                      <SiderItem
                        onMouseEnter={()=>store.hoverPath=item.path}
                        onMouseLeave={()=>store.hoverPath=''}
                        item={item} pathname={pathname}
                        hoverPath={store.hoverPath}
                      />
                    </Link>
                  );
                })}
                <Divider w='90%' mt={2} />
                {
                  externalLinks.map(link => {
                    return (
                      <a href={link.path} target='_blank' key={link.name} rel='noreferrer'>
                        <SiderItem
                          onMouseEnter={()=>store.hoverPath=link.path}
                          onMouseLeave={()=>store.hoverPath=''}
                          item={link} pathname={pathname}
                          hoverPath={store.hoverPath}
                          fontSize='14px'
                        />
                      </a>
                    );
                  })
                }
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
      </Flex>
      <WalletInfo />
      <DepositDialog isOpen={store.isOpen} onClose={() => (store.isOpen = false)} />
    </Box>
  );
});
