import React from "react"
import { Center } from "@chakra-ui/layout"
import { chakra } from "@chakra-ui/react"

export const NoData = () => {
  return (
    <Center h="200px">
      <chakra.span opacity={0.3}>No Data</chakra.span>
    </Center>
  )
}
