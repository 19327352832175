import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Home } from './pages/home/Home';
import { Device } from './pages/device/Device';
import { useStore } from '@/store/index';
import { Header } from '@/components/Header-pebble/index';
import { ChakraProvider } from '@chakra-ui/react';
import { DeviceStatus } from './pages/status/index';
import { DeviceApps } from './pages/app/index';
import { MyData } from './pages/myData/index';
import { customTheme } from './lib/theme';
import { Layout } from './layouts/Layout';
import { WalletSelecter } from './components/WalletSelecter';
import { ETHProvider } from './components/EthProvider';
import { Toaster } from 'react-hot-toast';
import { getLibrary } from './lib/web3-react';
import { Web3ReactProvider } from '@web3-react/core';
import { Learn } from './pages/Learn';
import { Ship } from './pages/ship';
import AppDetail from './pages/app/AppDetail';
import { AddDevice } from './pages/device/AddDevice';
import { FirmwareStore } from './pages/FirmwareStore';
import AccountDetail from './pages/AccountDetail';
import { PrivacyPolicy } from './pages/term/PrivacyPolicy';
import { TermOfService } from './pages/term/TermOfService';
import { WalletSetupDialog } from '@/components/walletSetup';

export const App = observer(() => {
  const { lang, god, accountStore, deviceStore } = useStore();

  useEffect(() => {
    setInterval(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const address = urlParams.get('address');
      if (address && address !== god.currentNetwork.account) {
        god.currentNetwork.account = address;
      }
    }, 1000);
  }, []);

  useEffect(() => {
    lang.init();
    if (god.currentNetwork.account) {
      god.loadBalance();
      accountStore.loadAccountDetails();
      deviceStore.loadDevices({}, {});
      deviceStore.loadBankBalanceOf();
    }
  }, [god.currentNetwork.account]);
  return (
    <ChakraProvider theme={customTheme}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <WalletSelecter />
        <WalletSetupDialog />
        <ETHProvider />
        <Toaster />
        <Router>
          <Switch>
            <Route exact path="/">
              <Layout breadName={lang.t('home')} breadPath="/home">
                <Home />
              </Layout>
            </Route>
            <Route exact path="/account">
              <Layout breadName={lang.t('account')} breadPath="/account">
                <AccountDetail />
              </Layout>
            </Route>
            <Route exact path="/device">
              <Layout breadName={lang.t('device')} breadPath="/device">
                <Device />
              </Layout>
            </Route>
            <Route exact path="/device/add">
              <Layout breadName={lang.t('add.device')} breadPath="/device/add">
                <AddDevice />
              </Layout>
            </Route>
            <Route exact path="/status">
              <Layout breadName={lang.t('device.status')} breadPath="/status">
                <DeviceStatus />
              </Layout>
            </Route>
            <Route exact path="/apps">
              <Layout breadPath="/apps" breadName={lang.t('apps')}>
                <DeviceApps />
              </Layout>
            </Route>
            <Route
              exact
              path="/apps/:appId"
              render={props => {
                return (
                  <>
                    <Layout breadPath="/apps" breadName={lang.t('apps')}>
                      <AppDetail {...props} />
                    </Layout>
                  </>
                );
              }}
            ></Route>
            <Route exact path="/myData">
              <Layout breadPath="/myData" breadName={lang.t('my.data')}>
                <MyData />
              </Layout>
            </Route>
            <Route exact path="/learn">
              <Layout breadPath="/learn" breadName={lang.t('learn')}>
                <Learn />
              </Layout>
            </Route>
            <Route exact path="/privacy-policy">
              <Layout breadPath="/privacy-policy" breadName={lang.t('ship')}>
                <PrivacyPolicy />
              </Layout>
            </Route>
            <Route exact path="/term-of-use">
              <Layout breadPath="/term-of-use" breadName={lang.t('ship')}>
                <TermOfService />
              </Layout>
            </Route>
            <Route exact path="/admin/ship">
              <Layout breadPath="/admin/ship" breadName={lang.t('ship')}>
                <Ship />
              </Layout>
            </Route>
          </Switch>
        </Router>
      </Web3ReactProvider>
    </ChakraProvider>
  );
});

export default App;
