import { makeAutoObservable } from 'mobx';
import { NetworkState } from './NetworkState';
import { TokenState } from './TokenState';

interface PebbleContracts {
  APP_CONTRACT: string;
  BANK_CONTRACT: string;
  REGISTRATION_CONTRACT: string;
  PEBBLE_CONTRACT: string;
  ACCOUNT_CONTRACT: string;
  SHIPPER?: string;
  CORE?: string
}

export class ChainState {
  name: string;
  network: NetworkState;
  networkKey: string;
  chainId: number;
  logoUrl: string;
  rpcUrl: string;
  explorerName: string;
  explorerURL: string;
  Coin: TokenState;
  info: {
    blockPerSeconds: number;
    multicallAddr?: string;
    theme?: {
      bgGradient: string;
    };
  };
  //@ts-ignore
  pebbleContracts: PebbleContracts = {}
  graphAPIURL: string = ''
  constructor(args: Partial<ChainState>) {
    Object.assign(this, args);
    makeAutoObservable(this, { network: false });
  }
}
