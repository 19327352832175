import React from 'react';
import {
  Flex,
  Box,
  Text,
  Center,
  Icon,
  Image,
  Divider,
  Menu,
  Button
} from '@chakra-ui/react';
import { AiFillHome } from 'react-icons/ai';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '../../store';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import {
  AccountIcon,
  AppStoreIcon,
  CollapseLeftIcon, CollapseRightIcon,
  DataIcon,
  DeviceIcon,
  FirmwareStoreIcon
} from '@/components/Icons';
import { SIDEBAR_WIDTH_FULL, SIDEBAR_WIDTH_REDUCED } from './config';
import _ from 'lodash';
import { SiderItem } from './SiderItem';

export const Sider = observer(({ collapsed, toggleCollapsed }: { collapsed: boolean, toggleCollapsed: () => void }) => {
  const { lang, god, accountStore } = useStore();
  const router = useLocation();
  const { pathname } = router;

  const navConfig = [
    { name: lang.t('home'), icon: AiFillHome, path: '/' },
    { name: lang.t('device'), icon: DeviceIcon, path: '/device' },
    { name: lang.t('my.data'), icon: DataIcon, path: '/myData' },
    { name: lang.t('apps'), icon: AppStoreIcon, path: '/apps' },
    // { name: lang.t('firmware.store'), icon: FirmwareStoreIcon, path: '/firmware-store' }
  ];

  const externalLinks = [
    {
      name: lang.t('github'),
      src: '/images/icon_gitHub.png',
      path: 'https://github.com/iotexproject/pebble-data-spec'
    },
    { name: lang.t('dev.docs'), src: '/images/icon_docs.png', path: 'https://github.com/iotexproject' },
    { name: lang.t('onboarding.pack'), src: '/images/icon_onboarding.png', path: 'https://onboard.iotex.io/' },
    { name: lang.t('ecosystem '), src: '/images/icon_ecopage.png', path: 'https://ecosystem.iotex.io/' }
  ];
  const langGroups = {
    en: { name: 'EN', src: '/images/EN.png', text: 'en' },
    zh_CN: { name: 'CN', src: '/images/CN.png', text: 'zh_CN' }
  };
  const store = useLocalObservable(() => ({
    get curLang() {
      if (!langGroups[lang.lang]) return langGroups.en;
      return langGroups[lang.lang];
    },
    hoverPath:'',
    async toggleLang() {
      if (store.curLang.name === 'EN') {
        await lang.setLang('zh_CN');
      } else {
        await lang.setLang('en');

      }
    },
    get configs() {
      if (god.currentNetwork.account && accountStore.isVisible) {
        const account = { name: lang.t('account'), icon: AccountIcon, path: '/account' };
        const cloneConfig = _.clone(navConfig);
        cloneConfig.splice(1, 0, account);
        return cloneConfig;
      } else {
        return navConfig;
      }
    }
  }));


  return (
    <Box
      bg='#232236'
      w={`${collapsed ? SIDEBAR_WIDTH_REDUCED : SIDEBAR_WIDTH_FULL}px`}
      display={{ base: 'none', md: 'block' }}
      css={{
        transition: 'all .2s',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
        position: 'absolute',
        left: 0,
        top: '0',
        height: '100%',
        zIndex: 2
      }}
    >
      <Flex direction='column' css={{ height: '100%' }}>
        <Box flex='1' fontSize='1.125rem'>
          <Link to='/'>
            <Flex minH='64px'>
              <Center w='64px'>
                <Image src='/images/machinefi-logo.png'
                       alt='logo' />
              </Center>
              {
                !collapsed && <Center>
                  <Text color='white' fontFamily='Poppins' fontWeight='700' fontSize='20px'>MachineFi Portal</Text>
                </Center>
              }
            </Flex>
          </Link>
          {store.configs.map((item) => {
            return (
              <Link to={item.path} key={item.name}>
                <SiderItem
                  onMouseEnter={()=>store.hoverPath=item.path}
                  onMouseLeave={()=>store.hoverPath=''}
                  item={item} pathname={pathname} collapsed={collapsed}
                  hoverPath={store.hoverPath}
                />
              </Link>
            );
          })}
          <Divider w='90%' mt={2} />
          {
            externalLinks.map(link => {
              return (
                <a href={link.path} target='_blank' key={link.name} rel='noreferrer'>
                  <SiderItem
                    onMouseEnter={()=>store.hoverPath=link.path}
                    onMouseLeave={()=>store.hoverPath=''}
                    item={link} pathname={pathname} collapsed={collapsed}
                    hoverPath={store.hoverPath}
                    fontSize='14px'
                  />
                </a>
              );
            })
          }
        </Box>
        <Flex justifyContent='space-between' alignItems='center' flexDirection={collapsed ? 'column' : 'row'}
              px={collapsed ? 0 : 10} mb={2}>
          <Menu isLazy>
            <Button padding={3} boxSize={{ base: '2rem', md: 'max-content' }}
                    bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
                    borderRadius='8px'
                    mb={collapsed ? 4 : 0}
                    onClick={store.toggleLang}
                    _focus={{}}
            >
              <Image src={store.curLang.name === 'EN' ? '/images/translate.svg' : '/images/translate_zh.svg'} />
            </Button>
          </Menu>
          <Box onClick={toggleCollapsed} cursor='pointer'>
            {collapsed ? (
              <CollapseRightIcon size={20} />
            ) : (
              <CollapseLeftIcon size={20} />
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
});
