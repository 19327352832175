import ReactDOM from 'react-dom';
import React from 'react';
import App from './index';
import { Buffer } from 'buffer';
//@ts-ignore
globalThis.Buffer = Buffer;
import 'antd/lib/style/index.css';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
// Sentry.init({
//   dsn: "https://bad85e0c64b14b37af0b53e92f488aa8@sentry.iotex.me/3",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
