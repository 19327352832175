import React from "react"
import { Thead, Tr,Th } from "@chakra-ui/table"

export const IHeader=({ headers })=>{
  return(
    <Thead>
      <Tr>
        {
          headers.map((header)=><Th key={header.text}>{header.text}</Th>)
        }
      </Tr>
    </Thead>
  )
}
