import React from 'react';
import { Box, Avatar, Flex, Heading, Text, Button, useColorMode, Divider, Image } from '@chakra-ui/react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { fromRau } from 'iotex-antenna/lib/account/utils';
import { helper } from '@/lib/helper';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { CopyIcon } from '@chakra-ui/icons';
import { DeviceState } from '@/store/lib/DeviceState';
import { useStore } from '@/store/index';
import { DeleteIconRed, ResetIcon } from '@/components/Icons';
import DeviceBattery from './DeviceBattery';
import DeviceSignal from './DeviceSignal';
import DeviceStatus from './DeviceStatus';
import RemoveDeviceModal from './modal/RemoveDeviceModal';
import { DeviceAddress } from './DeviceAddress';
import { HStack } from '@chakra-ui/layout';

interface IComponentProps {
  onDeviceStatus: () => void;
  deviceState?: DeviceState;
}

const DeviceCard = observer((props: IComponentProps) => {
  const { deviceState } = props;
  const { lang, god } = useStore();

  const store = useLocalObservable(() => ({
    isOpenRemoveModal:false,
    openRemoveModal(){
      this.isOpenRemoveModal = true
    },
    closeRemoveModal(){
      this.isOpenRemoveModal = false
    }
  }));

  const renderChannel=(channels:string[])=>{
    let result = ''
    channels.map((channle,index)=>{
      if (channle!=='TEMP2'){
        // @ts-ignore
        result +=index===channels.length-1?lang.t(channle): lang.t(channle)+', '
      }
    })
    if (channels.length>0){
      return result;
    }
    return null
  }

  return (
    <>
      <Flex pos='relative'
            flexDirection={{base:'column',md:'row'}}
            bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
            p={10}
            borderRadius='20px'
            w='100%'>
        <Box pos='relative' w='fit-content' h='fit-content'>
          <Image src='images/device/pebble.svg' />
          <Image pos='absolute' right='-3' bottom='-2' src={deviceState.avatar || 'images/device/default_avatar.png'} w='24px' h='24px' />
        </Box>
        <Flex flexDirection='column' w='100%' ml={{base:0,md:10}} mt={{base:5,md:0}} justifyContent='space-between'>
          <Flex flexDirection='row' justifyContent='space-between'>
            <Box>
              <Text fontSize='1.5rem'>
                {deviceState.name || ' '}
              </Text>
              {deviceState.real_firmware && <Text color='textWhite50'>{lang.t('device.table.firmware')}: {deviceState.real_firmware||'...'}</Text>}
            </Box>
            <Flex height='fit-content' display={{base:'none',md:'flex'}}>

              <DeviceBattery vbat={deviceState.deviceRecord.vbat} />

              <Flex alignItems='center' justifyContent='center' p={1} ml={3} color='#52C41A'
                    bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
                    borderRadius='8px'>
                {deviceState.deviceRecord.snr}
                <DeviceSignal snr={deviceState.deviceRecord.snr} />
              </Flex>
              <Box ml={3}  bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
                   px={2} py={1}
                   borderRadius='8px'>
                <DeviceStatus status={deviceState.status} />
              </Box>
            </Flex>
          </Flex>
          <Flex flexDirection={{base:'column',md:'row'}} justifyContent='space-between'>
            <Flex flexDirection='column'>
              <Flex flexDirection={{base:'column',md:'row'}} mt={{base:5,md:0}}>
                <Text minW='150px' color='textWhite50'>{lang.t('device.imei')}</Text>
                <Text>{deviceState.id || `""`}</Text>
              </Flex>
              <Flex flexDirection={{base:'column',md:'row'}} mt={{base:5,md:0}}>
                <Text minW='150px'  color='textWhite50'>{lang.t('device.address')}</Text>
                <DeviceAddress item={deviceState}/>
              </Flex>
              <Flex flexDirection={{base:'column',md:'row'}} mt={{base:5,md:0}}>
                <Text minW='150px'  color='textWhite50'>{lang.t('cost.balance')}</Text>
                <Text color='brandColor'>{fromRau(deviceState.total_gas, 'Iotx')} IOTX</Text>
              </Flex>
            </Flex>
            <Box  w='50%'>
              {deviceState.upload_period !== 0 && <Flex flexDirection={{base:'column',md:'row'}} mt={{base:5,md:0}}>
                <Text color='textWhite50' minW='145px'>{lang.t('device.table.emitFreq')}</Text>
                {
                  deviceState.upload_period && <Text>{deviceState.upload_period}S</Text>
                }
              </Flex>}
              {deviceState.deviceRecord?.timestamp && <Flex flexDirection={{base:'column',md:'row'}} mt={{base:5,md:0}}>
                <Text color='textWhite50' minW='145px'>{lang.t('device.table.lastUpdatedTime')}</Text>
                {
                  deviceState.deviceRecord?.timestamp &&
                    <Text>{deviceState.deviceRecord.timestamp&&helper.formatTime(deviceState.deviceRecord.timestamp)}</Text>
                }
              </Flex>}
              {renderChannel(deviceState.channels) && <Flex flexDirection={{base:'column',md:'row'}} mt={{base:5,md:0}}>
                <Text color='textWhite50' minW='145px'>{lang.t('data.emits')}</Text>
                <Text wordBreak='break-word'>{renderChannel(deviceState.channels)}</Text>
              </Flex>}
            </Box>
          </Flex>
          <Divider mt={5}/>
          {deviceState.status === 2 &&
          <HStack justify={'end'}>
            <Button leftIcon={<DeleteIconRed/>} fontWeight={400} variant={'ghost'} color={'warnColor'} onClick={store.openRemoveModal}>
              {lang.t("remove.device")}
            </Button>
            {/*<Button leftIcon={<ResetIcon/>} fontWeight={500} variant={'ghost'} color={'brandColor'}>{lang.t("reset.firmware")}</Button>*/}
          </HStack>
          }
        </Flex>
      </Flex>
      <RemoveDeviceModal isOpen={store.isOpenRemoveModal} onClose={store.closeRemoveModal} device={deviceState}/>
    </>
  );
});
DeviceCard.displayName = 'DeviceCard';
export default DeviceCard;
