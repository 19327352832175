import React from 'react';
import { Box, Flex, Text, SimpleGrid, Img, Link, Stack, Center } from '@chakra-ui/react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { BrandButton } from '@/components/BrandButton';
import { useStore } from '@/store/index';
import { DeviceItem, IDeviceItem } from './DeviceItem';
import { useHistory } from 'react-router-dom';
import ConnectWallet from './ConnectWallet';
import { PrimaryButton, PrimaryLineButton } from '@/components/Button';
import {devices} from './devicesMeta';

interface IComponentProps {
  openAddNewDevice?: any;
  isLoading: boolean;
  openSelectDevice?:any;
}

const DeviceEmpty = observer((props: IComponentProps) => {
  const {  isLoading } = props;
  const { lang,god,accountStore } = useStore();
  const router = useHistory()

  const store = useLocalStore(()=>({
    isOpenConnectWallet:false,
    openModal(){
      this.isOpenConnectWallet = true
    },
    closeModal(){
      this.isOpenConnectWallet = false
    }
  }))

  return (
    <>
      <Box position="relative">
        <Flex direction="column" alignItems="center" justify="center" bgImage='url(images/device/bg.png)' bgPos='top center' bgRepeat='no-repeat'>
          {/*<Link position={'absolute'} top={16} href={'/'} target={'_blank'}>*/}
          {/*  <Img*/}
          {/*    src={'images/btn_play.svg'}*/}
          {/*    _hover={{*/}
          {/*      cursor: "pointer",*/}
          {/*      transform: "scale(1.1)"*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Link>*/}
          <Box maxWidth={{base:'100%',md:'80%'}} textAlign="center">
            <Text
              bg="linear-gradient(to right, #43C9BA, #9643C9)"
              color='transparent'
              backgroundClip='text'
              fontSize={{lg:'3.5rem'}}
              mt={20}
              fontWeight='bold'
            >
              {lang.t('add.device.tips')}
            </Text>
            <Text mt={20}>
              <Link
                fontWeight={500}
                fontSize={20}
                color={'brandColor'}
                href='https://docs.iotex.io/verifiable-data/pebble-tracker/quick-start'
                target={'_blank'}
                _hover={{
                  textDecoration: "none",
                  textColor: "brandColor"
                }}
              >
                {lang.t('pebble.user.manual')}
              </Link>
            </Text>
            <Text fontSize="sm"  mb="2.75rem" align="center" mt={4}>
              {lang.t('no.device.tips')}
            </Text>
            <Stack
              direction={{base: 'column', md: 'row'}}
              h={{base: "50px", md: "50px"}}
              w={'100%'}
              justify={'center'}
              align={'center'}
            >
              {
                (!god.isConnect||accountStore.isVisible)&&<PrimaryButton
                  h={'full'}
                  w="360px"
                  size="md"
                  isLoading={isLoading}
                  onClick={god.isConnect?props.openSelectDevice:store.openModal}
                >
                  {lang.t('add.device')}
                </PrimaryButton>
              }

              {
                god.isConnect&&!accountStore.isVisible&&(
                  <PrimaryButton
                    h={'full'}
                    w="360px"
                    size="md"
                    isLoading={isLoading}
                    onClick={()=>accountStore.openAccountDialog()}
                  >
                    {lang.t('setup.account')}
                  </PrimaryButton>
                )
              }
              {/*<PrimaryLineButton*/}
              {/*  w="360px"*/}
              {/*  size="md"*/}
              {/*  h={'full'}*/}
              {/*  isLoading={isLoading}*/}
              {/*>*/}
              {/*  {lang.t('explore.virtual.device')}*/}
              {/*</PrimaryLineButton>*/}
            </Stack>
            <Text color={'textLight45'} fontSize="sm"  mb="2.75rem" align="center" mt={4}>
              {lang.t('no.device.question')}{' '}
              <Link
                color={'brandColor'}
                href={'/'}
                target={'_blank'}
                _hover={{
                  textDecoration: "none",
                  textColor: "brandColor"
                }}
              >
                {lang.t('buy.pebble')}
              </Link>
            </Text>
          </Box>
        </Flex>
        <Center>
          <a href={'#devices-section'}>
            <Img
              w={"1.3rem"}
              h={"2rem"}
              src={'images/scroll_btn.svg'}
              _hover={{
                cursor: "pointer",
                transform: "scale(1.1)"
              }}
            />
          </a>
        </Center>
        <SimpleGrid columns={[1, 2, 4]} spacing="30px" mt="4.875rem" pb={24} id="devices-section">
          {
            devices.map(device=><DeviceItem title={device.title} desc={device.desc} img={device.img} key={device.title} link={device.link} bg={device.bg}/>)
          }

        </SimpleGrid>
      </Box>
      <ConnectWallet isOpen={store.isOpenConnectWallet&&!god.isConnect} onClose={store.closeModal}/>
    </>
  );
});

export default DeviceEmpty;
