import { makeAutoObservable } from 'mobx';
import { RootStore } from './root';
import { NetworkState } from './lib/NetworkState';
import { AccountState } from './lib/AccountState';
import { order_by } from '../generated/gql/schema';

export class BankRecordState {
  id: string = '';
  from: string = '';
  to: string = '';
  amount: number;
  type:string=''
  network: NetworkState;

  constructor(args: Partial<BankRecordState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }
}

export class AccountStore {
  rootStore: RootStore;
  details = new AccountState({});
  isOperators: boolean = false;
  isVisible: boolean = null;
  bankRecords: BankRecordState[] = [];
  totalNumber:number=0;
  isShowSetupAccountDialog:boolean = false;
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.details.rootStore = this.rootStore;
    makeAutoObservable(this);
  }

  get god() {
    return this.rootStore.god;
  }

  get currentNetwork() {
    return this.god.currentNetwork;
  }


  async loadBankRecords({ limit=0,skip=0 },filter) {
    const query=[
      {
        from: {
          _eq: filter.address
        }
      },
      {
        to: {
          _eq: filter.address
        }
      }
    ]
    const response = await this.rootStore.pebbleApi.subgraphAPI.query({
        pebble_bank_record: [
          {
            limit: limit,
            offset: skip,
            where: {
              _or:query
            },
            order_by:[
              {timestamp:order_by.desc}
            ]
          },
          {
            id: true,
            from: true,
            to: true,
            amount: true,
            timestamp:true,
            type:true
          }
        ],
        pebble_bank_record_aggregate: [
          {
            where: {
              _or:query
            }
          },
          {
            aggregate:{
              count:true
            }
          }
        ]
      }
    );
    // @ts-ignore
    this.bankRecords = (response.data.pebble_bank_record.map(i => new BankRecordState(i)));
    // @ts-ignore
    this.totalNumber = response.data.pebble_bank_record_aggregate.aggregate.count
    return this.bankRecords;
  }

  async loadAccountDetails() {
    await this.currentNetwork.multicall([
      this.details.preMulticall({
        method: 'accounts',
        address:this.god.currentChain.pebbleContracts.ACCOUNT_CONTRACT,
        params: [this.currentNetwork.account], handler: (v) => {
          console.info('v', v);
          this.isVisible = !!v.name;
          this.details.name = v.name;
          if (v.avatar.startsWith('https://storageapi.fleek.co/raullenchai-team-bucket')){
            this.details.avatar = v.avatar.replace('storageapi.fleek.co/raullenchai-team-bucket','mfi-ipfs-staging.b-cdn.net');
          }
          if (v.avatar.startsWith('https://storageapi.fleek.co/uu-z-team-bucket')){
            this.details.avatar = v.avatar.replace('storageapi.fleek.co/uu-z-team-bucket','mfi-ipfs.b-cdn.net');
          }
        }
      })
    ]);
  }

  openAccountDialog(){
    this.isShowSetupAccountDialog = true;
  }

  closeAccountDialog(){
    this.isShowSetupAccountDialog = false;
  }
}
