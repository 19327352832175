import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Divider,
  Flex,
  Image, Link, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { ConnectButton, PrimaryButton } from '@/components/Button';
import { useStore } from '@/store/index';
import { Network } from '@/store/god';
import { injected } from '@/lib/web3-react';
import { useWeb3React } from '@web3-react/core';

const ConnectWallet = observer(({ isOpen, onClose }: any) => {
  const { lang,god } = useStore();
  const { active, error, activate } = useWeb3React();

  const config = [
    {
      title: "Metamask",
      icon: '/images/metamask.svg',
    },
    {
      title: "ioPay",
      icon: '/images/iopay.svg',
    },
    {
      title: "Trust",
      icon: '/images/trustwallet.svg',
    },
    {
      title: "Math",
      icon: '/images/mathwallet.svg',
    },
    {
      title: "imToken",
      icon: '/images/imtoken.svg',
    },
  ]
  const names = config.map(item => item.title).join(', ')

  const store=useLocalObservable(()=>({
    connectInejct() {
      god.setNetwork(Network.ETH);
      activate(injected);
      god.eth.connector.latestProvider.save('inject');
    }
  }))

  useEffect(() => {
    //@ts-ignore
    const { ethereum } = window;
    if (ethereum && ethereum.on && !active && !error) {
      const handleChainChanged = () => {
        store.connectInejct();
      };
      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          store.connectInejct();
        }
      };
      ethereum.on('networkChanged', handleChainChanged);
      ethereum.on('close', handleChainChanged);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('networkChanged', handleChainChanged);
          ethereum.removeListener('close', handleChainChanged);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
  }, [active, error, activate]);

  return (
    <>
      <Modal motionPreset='slideInBottom' onClose={onClose} isOpen={isOpen} isCentered
             closeOnOverlayClick={false} size='xl' >
        <ModalOverlay />
        <ModalContent w={{ base: '90%', md: 'auto' }} minWidth='620px'
                      boxShadow='unset'
                      minH='450px'
                      bg='linear-gradient(0deg, rgba(23, 22, 42, 0.5), rgba(23, 22, 42, 0.5)), #2E2D40'
                      borderRadius='20px'>
          <ModalHeader>
            {lang.t('before.add.device.no.connect')}
          </ModalHeader>
          <ModalCloseButton _focus={{}}  _hover={{ color: 'brandColor' }} />
          <ModalBody>
            <Flex mt={10}>
              <Image src='images/device/new_device_bg.png' w='143px' h='130px'/>
              <Flex flexDirection='column' justifyContent='space-between' ml={10}>
                <Text fontSize='1rem'>{lang.t('add.device.tips2')}</Text>
                <Flex bg='textLight5' direction='column' mt='1.25rem' px={3} py={2} my={2}>
                  <Flex direction='column'>
                    <Text fontSize='20' lineHeight='26.38px' fontStyle='normal' fontWeight='500'>{lang.t('browser.wallet')}</Text>
                    <Text mt='3px'  fontSize='12' lineHeight='16.38px' fontStyle='normal' fontWeight='500'>({names})</Text>
                  </Flex>
                  <Flex ml='2px' mt={2}>
                    <AvatarGroup size='sm' border='none'>
                      {
                        config.map((item, index) => {
                          return <Avatar name={item.title} key={item.title} src={item.icon} />
                        })
                      }
                    </AvatarGroup>
                  </Flex>
                  <Text fontSize='0.75rem' mt={2}>
                    Don’t have a ioPay wallet? Download it <Link href='https://iotex.io/iopay ' isExternal color='brandColor'>{'here >>'}</Link>
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Divider mt={10} />
          </ModalBody>
          <ModalFooter>
            <Box w='200px' h='40px'>
              <PrimaryButton onClick={store.connectInejct}>
                {lang.t('connect.wallet.now')}
              </PrimaryButton>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});

ConnectWallet.displayName='ConnectWallet'

export default ConnectWallet
