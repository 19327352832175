import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import {  Flex, Image, Text } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { toast } from 'react-hot-toast';

const Step4Download = observer(({ nextStep, prevStep,currentDevice,currentApp }: any) => {
  const { lang,deviceStore,appStore } = useStore();
  const store = useLocalObservable(() => ({
    downloadAppFile: async (deviceId: string, firmwareId: string) => {
      try {
        const res = await deviceStore.pebble.setFirmware({params: [deviceId, firmwareId]});
        console.log('downloadAppFile', res)
        const receipt = await res.wait();
        console.log('setFirmware', receipt);
        if (receipt.status) {
          toast.success(receipt.blockHash);
          store.subscribeInstallFirmwareDevice()
        } else {
          toast.error(lang.t('download.fail'));
        }
      } catch (error) {
        if (error.code===4001){
          appStore.closeInstallFirmwareModal();
          return toast.error(error.message);
        }
        toast.error(error.data.message);
      }
    },
    subscribeInstallFirmwareDevice(){
      deviceStore.subscribeInstallFirmwareDevice(currentDevice.id).subscribe({
        next:(res)=>{
          // @ts-ignore
          const [device] = res.data.pebble_device
          if (device.firmware===device.real_firmware){
            appStore.closeInstallFirmwareModal()
          }
        }
      })
    }
  }));
  useEffect(() => {
    const [firmware] = currentApp.firmware.split(' ')
    store.downloadAppFile(currentDevice.id,firmware);
  },[]);

  return (
    <>
      <Flex alignItems='flex-start' mt={10} mb={20}>
        <Image ml={10} w='160px' src='/images/apps/Install_2.gif' />
        <Text ml={10} whiteSpace='pre-line'>
          {lang.t('download.firmware.desc')}
        </Text>

      </Flex>
    </>
  );
});


export default Step4Download;
