import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  Box,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
  Checkbox,
  Button,
  Image,
  Table,
  Th,
  Td,
  Tr,
  Thead,
  Tbody,
  TableCaption,
  Spinner
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useStore } from '@/store/index';
import React, { useEffect, useRef } from 'react';
import { DATA_TYPES } from '@/components/status/data-typs';
import dayjs from 'dayjs';
import { CPagination } from '@/components/pagnation';
import { backendApi } from '@/lib/backendApi';
import { useHistory } from 'react-router-dom';
import { fromRau } from 'iotex-antenna/lib/account/utils';
import { DeviceState } from '@/store/lib/DeviceState';

function formatTime(time: number): string {
  return dayjs.unix(time).format('YYYY-MM-DD HH:mm:ss');
}

const PAGE_SIZE = 15;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export enum EDeviceStatus {
  offline = 'offline',
  online = 'online',
  running = 'running',
  error = 'error'
}

export interface IDeviceStatusInfo {
  totalGas: string;
  data_channel: number;
  verifyPublicKey: string;
  deviceAvatar: string;
  imei: string;
  deviceName: string;
  deviceState: number;
  firmware: string;
  snr: number;
  updatedAt: number;
  vbat: number;
  upload_period: number;
}

export const DeviceStatus = observer(() => {
  const { lang, deviceStore, god } = useStore();
  const { colorMode } = useColorMode();
  const router = useHistory()
  const inputRef = useRef<HTMLInputElement>(null);

  const search = () => {
    if (store.deviceName !== deviceStore.currentDevice?.name) {
      deviceStore.currentDevice = undefined;
    }
    let dataType: number = 0;
    store.dataTypes.forEach((item) => {
      if (item.isChecked) {
        dataType = dataType | item.value;
      }
    });
    store.dataType = dataType;
    store.devices = []
    store.getDeviceList();
  };

  const store = useLocalObservable(() => ({
    pageNum: 0,
    dataTypes: DATA_TYPES,
    deviceName: deviceStore.currentDevice?.name,
    dataType: 0,
    isLoading: false,
    devices: new Array<DeviceState>(),
    total: 0,
    getDeviceList: async () => {
      try {
        store.isLoading = true
        const name = store.deviceName
        store.devices =  await deviceStore.loadDevices({},{})
        store.isLoading = false
      } catch (error) {
        store.isLoading = false
        console.log(error);
      }
    },
    boxChange(index: number) {
      this.dataTypes[index].isChecked = !this.dataTypes[index].isChecked;
    },
    clear() {
      this.dataTypes = this.dataTypes.map((item) => {
        return { ...item, isChecked: false };
      });
    },
    onPageChange(page: number) {
      store.pageNum = page;
    }
  }));

  useEffect(() => {
    if(god.currentNetwork.account) {
      store.getDeviceList();
    }
  }, [god.isConnect]);

  return (
    <Box px="3">
      <Flex pl="2" align="baseline">
        <Text fontSize="md" color={colorMode === 'light' ? 'blackColor' : 'borderLight'}>
          {`${lang.t('device.name')}:`}
        </Text>
        <InputGroup ml="1.5rem" w="15rem" size="md">
          <Input
            placeholder={lang.t("please.enter")}
            ref={inputRef}
            value={store.deviceName || ''}
            onChange={(e) => {
              store.deviceName = e.target.value;
            }}
          />
          <InputRightElement
            border="1px solid rgba(0, 0, 0, 0.18)"
            borderRightRadius="md"
            onClick={() => {
              search();
            }}
            children={<SearchIcon color={colorMode === 'light' ? 'text45' : 'borderLight'} />}
          />
        </InputGroup>
      </Flex>
      {/* <DeviceStatusTable /> */}
      <Box shadow="" position="relative" mt="5" pt="2" w="auto" >
        {store.isLoading && (
          <Spinner
            position="absolute"
            css={{ top: '50%', left: '50%', transform: ' translateX(-50%) translateY(-50%' }}
            color="brandColor"
            size="xl"
          />
        )}
        <Table variant="simple" shadow="md" pb="100px">
          <Thead>
            <DeiviceStatusHead />
          </Thead>

          <Tbody>
            {store.devices &&
              store.devices.length > 0 &&
              store.devices.map((item, index) => {
                return (
                  <DeviceStatusRow
                    key={index}
                    totalGas={item.total_gas}
                    data_channel={index}
                    verifyPublicKey={item.address}
                    deviceAvatar={item.avatar}
                    imei={item.id}
                    deviceName={item.name}
                    deviceState={item.status}
                    firmware={item.sensorConfig ? item.sensorConfig.firmware : ''}
                    snr={item.sensorData ? item.sensorData.snr : 0}
                    updatedAt={Number(item.lastDataTime)}
                    vbat={0}
                    upload_period={item.sensorConfig ? item.sensorConfig.uploadPeriod : 0}
                  />
                );
              })}
          </Tbody>
          {/*{store.total > 0 && <TableCaption>*/}
          {/*  <CPagination current={store.pageNum} onChange={store.onPageChange} total={store.total} />*/}
          {/*</TableCaption>}*/}
        </Table>
      </Box>
    </Box>
  );
});

export const DeiviceStatusHead: React.FC = observer(() => {
  const { lang } = useStore();
  const headCols = [
    lang.t('device.table.device'),
    lang.t('device.table.pulibckey'),
    lang.t('cost.balance'),
    lang.t('device.table.battery'),
    lang.t('device.table.signal'),
    lang.t('device.table.firmware'),
    lang.t('device.table.emitFreq'),
    lang.t('device.table.status'),
    lang.t('device.table.lastUpdatedTime')
  ];
  return (
    <Tr>
      {headCols.map((item, index) => {
        return (
          <Th textAlign="center" key={index}>
            {item}
          </Th>
        );
      })}
    </Tr>
  );
});

export const DeviceStatusRow: React.FC<IDeviceStatusInfo> = observer(
  ({ totalGas, data_channel, verifyPublicKey, deviceAvatar, imei, deviceName, deviceState, firmware, snr, updatedAt, vbat, upload_period }) => {
    const { colorMode } = useColorMode();
    const { lang } = useStore()
    let stateColor = DeviceState.getStateColor(deviceState)
    // @ts-ignore
    let stateTitle = lang.t(DeviceState.getTitle(deviceState))

    const data_channels = {
      0x1: "gps",
      0x2: "snr",
      0x4: "vbat",
      0x6: "devinfo",
      0x10: "gas",
      0x20: "tmp",
      0x40: "pressure",
      0x80: "humidity",
      0x100: "temp2",
      0x200: "gyroscope",
      0x400: "accelerometer",
      0x800: "custom_motion",
      0x1000: "light_sensor",
    }

    const data_channelKey = [
      0x1,
      0x2,
      0x4,
      0x6,
      0x10,
      0x20,
      0x40,
      0x80,
      0x100,
      0x200,
      0x400,
      0x800,
      0x1000,
    ]

    const todata_channel = (data: number) => {
      let channelMap: string[] = []
      for (let index = 0; index < data_channelKey.length; index++) {
        const v = data_channelKey[index];
        if ((data & v) === v) {
          channelMap.push(data_channels[v])
        }
      }
      return channelMap
    }

    const getSnr = (snr: number) => {
      let img = "images/wifi_2.png"
      if (snr >= 21 ) {
        img = "images/wifi_1.png"
      } else if (snr < 21 && snr >= 14 ) {
        img = "images/wifi_2.png"
      } else if (snr < 14 && snr >= 7 ) {
        img = "images/wifi_3.png"
      } else if (snr < 7 && snr >= 0 ) {
        img = "images/wifi_4.png"
      }
      return img
    }
    const bat =  parseInt(`${((vbat - 320) / 90 ) > 1 ? 100 : ((vbat - 320) / 90 ) < 0.1 ? 0.1 : ((vbat - 320) / 90*100 )}`)
    return (
      <Tr>
        <Td>
          <Text py="3" textAlign="center" fontSize="ms" color={colorMode === 'light' ? 'blackColor' : 'borderLight'}>
            {deviceName}
          </Text>
        </Td>
        <Td>
          <Text py="3" textAlign="center" fontSize="ms" color="brandColor">
            {verifyPublicKey}
          </Text>
        </Td>
        <Td>
          <Text textAlign="center" fontSize="ms" color={colorMode === 'light' ? 'blackColor' : 'borderLight'}>
            {fromRau(totalGas, "Iotx")} IOTX
          </Text>
        </Td>

        <Td>
          <Flex
            direction="column"
            justify="flex-end"
            margin="auto"
            fontSize="12px"
            lineHeight="22px"
            textAlign="center"
            w="46px"
            borderRadius="6px"
            h="22px"
            border={vbat > 0 ? '1px solid #B7EB8F' : '1px solid #FFA39E'}
            background={bat > 15 ? '#F6FFED' : colorMode === 'light' ? '#FFF1F0' : 'clear'}
            color={vbat > 0 ? 'brandColor' : '#F5222D'}
            size="sm"
          >
            {`${ bat } %`}
          </Flex>
        </Td>

        <Td>
          <Box class="pie">
            <Box class='slice-1 slice'> </Box>
            {/* <Image margin="0 auto" src={getSnr(snr)} /> */}
          </Box>
        </Td>

        <Td>
          <Text textAlign="center" fontSize="ms" color={colorMode === 'light' ? 'blackColor' : 'borderLight'}>
            {firmware}
          </Text>
        </Td>

        {/* <Td>
          <Text
            maxWidth="140px"
            textAlign="center"
            fontSize="ms"
            color={colorMode === 'light' ? 'blackColor' : 'borderLight'}
          >
            {todata_channel(data_channel).join(',')}
          </Text>
        </Td> */}

        <Td>
          <Text
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            maxWidth="140px"
            textAlign="center"
            fontSize="ms"
            color={colorMode === 'light' ? 'blackColor' : 'borderLight'}
          >
            {upload_period ? `${upload_period} s` : ''}
          </Text>
        </Td>
        <Td>
          <Flex margin="0 auto" align="center">
            <Box background={stateColor} w="6px" h="6px" borderRadius="3px"></Box>
            <Text ml="5px" textAlign="center" fontSize="ms" color={colorMode === 'light' ? 'blackColor' : 'borderLight'}>
              {stateTitle}
            </Text>
          </Flex>
        </Td>

        <Td>
          <Text textAlign="center" fontSize="ms" color={colorMode === 'light' ? 'blackColor' : 'borderLight'}>
            {updatedAt?formatTime(updatedAt):null}
          </Text>
        </Td>
      </Tr>
    );
  }
);
