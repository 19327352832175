import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig } from 'chakra-ui-steps';

const config={
  useSystemColorMode: false,
  initialColorMode: 'dark'
}
const CustomSteps = {
  ...StepsStyleConfig,
  baseStyle:{
    stepIconContainer:{
      _activeStep:{
        bg:'brandColor',
        color:'black'
      },
      _highlighted: {
        borderColor: 'brandColor',
        color: 'brandColor',
        bg:'unset'
      },
    },
    label:{
      color: 'brandColor',
    }
  }
};
const ModalCloseButton ={
  baseStyle:{
    _hover:{
      color:'brandColor'
    },
    _focus:{
      boxShadow:'none'
    }
  }
}

const ModalContent = {
  variants:{
    'primary':{
      bg:'mainColor',
    }
  }
}

const Button={
  baseStyle:{
    _focus:{
      boxShadow:'none'
    },
    _active:{
      boxShadow:'none'
    }
  }
}

const Link={
  baseStyle:{
    _hover:{
      color:'unset'
    },
    _focus:{
      boxShadow:'none'
    },
  }
}

export const customTheme = extendTheme({
  config,
  colors: {
    mainColor: '#1C1B30',
    brandColor: '#43C9BA',
    lightColor: '#FCFCFC',
    borderBtn: '#D9D9D9',
    borderLight: '#F0F0F0',
    textPrimary: 'rgba(0, 0, 0, 0.85)',
    text45: 'rgba(0, 0, 0, 0.45)',
    textLight85: 'rgba(255, 255, 255, 0.85)',
    textLight5:'rgba(255, 255, 255, 0.05)',
    textLight45: 'rgba(252, 252, 252, 0.45)',
    text75: 'rgba(0, 0, 0, 0.75)',
    textWhite50:'rgba(255, 255, 255, 0.5)',
    white10: 'rgba(255, 255, 255, 0.1)',
    warning:'#FAAD14',
    warnColor: '#FF4D4F',
    btnPrimaryColor:' linear-gradient(90deg, #43C9BA 4.38%, #9643C9 108.12%)',
    brand: {
      100: '#f7fafc',
      // ...
      900: '#1a202c'
    },
    primary: {
      500: '#43C9BA'
    }
  },
  fonts: {
    body: 'Poppins'
  },
  variants: {
    brandColor: '#43C9BA'
  },
  styles: {
    global: {
      button: {
        outline: 'none',
        '&:foucs, &:hover': {
          outline: 'none'
        }
      },
      '.highcharts-credits': {
        display: 'none !important'
      },
     " .pie": {
        position: "relative",
        margin: " auto",
        padding: 0,
        width: "40PX",
        height: "40PX",
        borderRadius: "100%",
        listStyle: "none",
        overflow: "hidden",
        transform: "rotate(0deg)" /*针对mac safari浏览器兼容*/
      },
      ".slice" :  {
        width: "16px",
        height: "16px",
        overflow: "hidden",
        position: "relative",
        transform: "translateX(-1px)translateY(-1px)rotate(296deg)"
      },
      ".circle": {
        position: "absolute",
        right: 0,
        top: "-2px",
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        overflow: "hidden",
      },
      ".wifibg" : {
        transform: "skewX(331deg)",
        transformOrigin: "0 100%",
        position: "absolute",
        right: 0,
        top: 0,
        background: "rgba(67, 201, 186, 0.3)",
        width: "16px",
        height: "16px",
      },
      ".slice2" :  {
        width: "8px",
        height: "8px",
        overflow: "hidden",
        top: 0,
        position: "absolute",
        transform: "translateX(-1px)translateY(-1px)rotate(296deg)",
      },
      ".circle2": {
        position: "absolute",
        right: 0,
        top: "-2px",
        width: "16px",
        height: "16px",
        borderRadius: "50%",
        overflow: "hidden",
      },
      ".wifi" : {
        transform: "skewX(331deg)",
        transformOrigin: "0 100%",
        position: "absolute",
        right: 0,
        top: 0,
        background: "rgba(67, 201, 186, 0.3)",
        width: "8px",
        height: "8px",
      },
     "div[aria-current='step']>span":{
        color: 'black'
     },
      "div[aria-current='false']>span":{
        color:'textLight45'
      },
      "div[data-highlighted]>div>svg":{
        color:'brandColor'
      },
      "div[data-highlighted]~div>span":{
        color:'white'
      },
      "div[aria-disabled='true']>div>div>span":{
        color:'textLight45'
      },
      ".chakra-slide.chakra-modal__content":{
        width:'50%'
      }
    }
  },
  components:{
    Steps:CustomSteps,
    ModalCloseButton,
    ModalContent,
    Button,
    Link
  }
});
