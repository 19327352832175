import { makeAutoObservable } from 'mobx';
import  {EthNetworkConfig} from '../../config/NetworkConfig';
import { CallParams } from '../../../type';
import bankAbi from '@/constants/abi/Bank.json';
import { BigNumberInputState } from '../standard/BigNumberInputState';
import { RootStore } from '../root';
import BigNumber from 'bignumber.js';
import { BigNumberState } from '../standard/BigNumberState';

export class BankState {
  abi = bankAbi;
  chainId: number;
  decimals: number = 18;
  balance: BigNumberState;
  minBalance= new BigNumber(0).multipliedBy(10 ** this.decimals);

  rootStore: RootStore;
  network = EthNetworkConfig;


  constructor(args: Partial<BankState>) {
    Object.assign(this, args);
    this.balance = new BigNumberState({ decimals: this.decimals, loading: true });
    makeAutoObservable(this);
  }

  get address(){
    return this.rootStore.god.currentChain.pebbleContracts.BANK_CONTRACT
  }

  get tokenBalance() {
    return this.rootStore.god.Coin.balance
  }

  tokenStatus({ amount }: { amount: BigNumberInputState;}) {
    const required = amount.value.comparedTo(0) > 0;
    if (this.tokenBalance.loading) {
      return { required, enough: true };
    }
    const enough = this.tokenBalance.value.comparedTo(amount.value) > 0;
    return {
      required,
      enough: enough,
      valid: required ? enough : false,
    };
  }

  get balanceStatus() {
    return this.balance.value.comparedTo(this.minBalance) > 0
  }

  withdraw(args: Partial<CallParams>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: 'withdraw'}, args));
  }

  deposit(args: Partial<CallParams>, amount: BigNumberInputState) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: 'deposit', options: {value: new BigNumber(amount.format).multipliedBy(10 ** this.decimals).toFixed()} }, args));
  }

  approve(args: Partial<CallParams>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: 'approve' }, args));
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }
}
