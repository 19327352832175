import React from 'react';
import { Tbody } from '@chakra-ui/table';
import { Row } from './Row';
import { observer } from 'mobx-react-lite';
import { Header } from '@/components/Table/type';
import { Spinner, Td, Tr } from '@chakra-ui/react';
import { NoData } from '@/components/NoData';

interface ITbodyProps {
  headers: Header[],
  items?: any[],
  isLoading: boolean
}

export const ITbody = observer(({ headers, items = [], isLoading }: ITbodyProps) => {
  return (
    <Tbody>
      {
        !isLoading&&items.length > 0 && items.map((item, index) => <Row headers={headers} item={item} key={`row_${index}`} />)
      }
      {
        isLoading && (
          <>
            <Tr>
              <Td textAlign='center' colSpan={headers.length}><Spinner color='brandColor'/></Td>
            </Tr>
          </>
        )
      }
      {
        !isLoading&&items.length===0&&(
          <>
            <Tr>
              <Td textAlign='center' colSpan={headers.length}><NoData /></Td>
            </Tr>
          </>
        )
      }
    </Tbody>
  );
});
