import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import Connect from '@/components/connect';
import { Box } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { useHistory } from 'react-router-dom';
import { ConnectFailDialog } from '@/components/ConnectFail';
import { WalletSetupDialog } from '@/components/walletSetup';

export const Home = observer(() => {
  const { god, deviceStore, accountStore } = useStore();
  const router = useHistory()

  const store = useLocalObservable(() => ({
    isConnectFail: false,
    isWalletInfoDialog: true,
    isCollectionLoading: false,
    showConnecter() {
      god.setShowConnecter(true);
    },
    showWalletInfo() {
      god.currentNetwork.walletInfo.visible = true;
    },
  }));

  useEffect(() => {
   if(god.currentNetwork.account) {
    accountStore.isShowSetupAccountDialog = !accountStore.isVisible && accountStore.isVisible !== null;
   }
  }, [god.currentNetwork.account, accountStore.isVisible])

  return (
    <Box position="relative">
      <Connect  isLoading={store.isCollectionLoading}/>
      <ConnectFailDialog isCollectFail={store.isConnectFail} onClose={() => {store.isConnectFail = false; god.setShowConnecter(false)}} onTryAgain={() => {store.isConnectFail = false;store.showConnecter()}}/>
    </Box>
  );
});
