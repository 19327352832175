import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { ModalBody, ModalCloseButton, ModalHeader } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import BeforYouStart from './BeforYouStart';
import Download from './Download';

const InstallAppDialog = ({ install,app }) => {
  const { lang } = useStore();
  const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    initialStep: 0
  });
  return (
    <>
      <ModalHeader>
        {lang.t('install.app')}
      </ModalHeader>
      <ModalCloseButton _focus={{}}  _hover={{ color: 'brandColor' }} />
      <ModalBody>
        <Steps activeStep={activeStep}>
          <Step label={lang.t('before.add.device')} key={lang.t('before.add.device')}>
            <BeforYouStart nextStep={nextStep} />
          </Step>
          <Step label={lang.t('download.config')} key={lang.t('download.config')}>
            <Download nextStep={nextStep} prevStep={prevStep} install={install} app={app}/>
          </Step>
        </Steps>
      </ModalBody>
    </>
  );
};

export default observer(InstallAppDialog);
