import { observer } from 'mobx-react-lite';
import { Image, Box, Flex, Text, useColorMode, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { useStore } from '@/store/index';

export const Learn = observer(() => {
  const { colorMode } = useColorMode();
  const { god, lang, appStore, deviceStore } = useStore();

  const config = [
    { name: lang.t('github'), link: 'https://github.com/iotexproject', icon: '/images/icon_gitHub.png' },
    { name: lang.t('dev.docs'), link: 'https://github.com/iotexproject/pebble-data-spec', icon: '/images/icon_docs.png' },
    { name: lang.t('onboarding.pack'), link: 'https://onboard.iotex.io/', icon: '/images/icon_onboarding.png' },
    { name: lang.t('ecosystem '), link: 'https://ecosystem.iotex.io/', icon: '/images/icon_ecopage.png' }
  ];

  return (
    <Box px="2rem">
      <SimpleGrid columns={[1, 2, 2, 4]} spacing="60px">
        {config.map((item, index) => {
          return (
            <a href={item.link} target="_blank" key={item.name} rel='noreferrer'>
              <Flex
                w="100%"
                align="center"
                justify="space-around"
                direction="column"
                key={index}
                boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15)"
                borderRadius="8px"
                pt="2.75rem"
                py="1.75rem"
                css={{
                  '&:hover': {
                    boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.15)'
                  }
                }}
              >
                <Image mb="1rem" rounded="xl" src={item.icon} alt="Logo" h="96px" w="96px" />
                <Text mb="1rem" fontSize="1.25rem" fontWeight="semibold" color={colorMode === 'light' ? 'textPrimary' : 'lightColor'}>
                  {item.name}
                </Text>
              </Flex>
            </a>
          );
        })}
      </SimpleGrid>
    </Box>
  );
});
