import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Box, Flex, Input, Text, Button } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import publicKeyToAddress from 'ethereum-public-key-to-address';
import { ethers } from 'ethers';

export const Ship = observer(() => {
  const { lang, deviceStore, god } = useStore();
  type InputKey = 'imei' | 'address' | 'sn'
  const store = useLocalObservable(() => ({
    imei: '',
    address: '',
    sn: '',
    transmitLoading: false,
    errors: {
      imei: ''
    },
    onInputChange(e: any, key: InputKey) {
      if (key === 'sn') {
        return this[key] = e.target.value.toUpperCase();
      }
      this[key] = e.target.value;
    },
    get isDisabled() {
      return !!this.imei && this.address && this.sn;
    },
    onChangeAddress(e) {
      console.log('onChangeAddress', e.target.value, publicKeyToAddress(e.target.value));
      store.address = publicKeyToAddress(e.target.value);
    },
    get showShip() {
      return god.currentChain.name === 'IoTeX Testnet';
    },
    async shipClick() {
      store.transmitLoading = true;
      if (store.imei.length < 8 || store.imei.length > 20) {
        store.transmitLoading = false;
        return this.errors.imei = 'imei must more then 8 and less then 20';
      }
      try {
        const sn = ethers.utils.keccak256(Buffer.from(store.sn, 'utf-8'));
        const res = await deviceStore.shiper.ship({ params: [[store.imei], [store.address], [sn]] });
        const receipt = await res.wait();
        console.log('ship', receipt);
        if (receipt.status) {
          store.transmitLoading = false;
          toast.success(receipt.blockHash);
        }
      } catch (error) {
        console.log(error);
        store.transmitLoading = false;
        toast.error(error.data.message);
      }
    }
  }));


  return (
    <>
      {
        store.showShip ? (
          <>

            <Box position='relative'>
              <Box width={{ base: '90%', md: '40vw' }}>
                <Flex alignItems={['flex-start', 'center']} mb='3' direction={['column', 'row']}>
                  <Text fontSize='sm' w='180px' mb={[2, 0]}>
                    {lang.t('device.imei')}：
                  </Text>
                  <Input
                    onChange={e => {
                      store.onInputChange(e, 'imei');
                    }}
                    value={store.imei}
                    placeholder={lang.t('device.imei')}
                    size='sm'
                  />
                </Flex>
                {
                  store.errors.imei && (<Text ml='140px' color='red' mb={2}>{store.errors.imei}</Text>)
                }
                <Flex alignItems={['flex-start', 'center']} mb='3' direction={['column', 'row']}>
                  <Text fontSize='sm' w='180px' mb={[2, 0]}>
                    {lang.t('device.address')}：
                  </Text>
                  <Input
                    onChange={e => {
                      store.onInputChange(e, 'address');
                    }}
                    value={store.address}
                    placeholder={lang.t('device.address')}
                    size='sm'
                  />
                </Flex>
                <Flex alignItems={['flex-start', 'center']} mb='3' direction={['column', 'row']}>
                  <Text fontSize='sm' w='180px' mb={[2, 0]}>
                    {lang.t('device.SN')}：
                  </Text>
                  <Input
                    onChange={e => {
                      store.onInputChange(e, 'sn');
                    }}
                    value={store.sn}
                    placeholder={lang.t('device.SN')}
                    size='sm'
                  />
                </Flex>
                <Button bg='brandColor' color='white' _active={{}} _hover={{}}
                        disabled={!store.isDisabled} onClick={store.shipClick}
                        isLoading={store.transmitLoading}>
                  {lang.t('ship.btn')}
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <Box position='relative'>
            <Text>Please switch to Testnet</Text>
          </Box>
        )
      }
    </>
  );
});
