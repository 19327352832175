import { Button } from '@chakra-ui/react';
import React from 'react';
import { useStore } from '@/store/index';
import { observer } from 'mobx-react-lite';

const NextButton = ({ onClick, text }: { onClick: () => void, text?: string }) => {
  const { lang } = useStore();
  return (
    <>
      <Button _hover={{
        color: 'white'
      }}
              _focus={{}}
              w='140px' bg='btnPrimaryColor' color='black' fontWeight={400} border='unset'
              onClick={onClick}>{text ? text : lang.t('next')}</Button>
    </>
  );
};
export default observer(NextButton);
