import { observer, useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { Divider, Flex, Image, Text } from '@chakra-ui/react';
import { CancelButton, NextButton } from '@/components/Button';
import { useStore } from '@/store/index';

const Download =({nextStep,prevStep,install,app})=>{
  const {lang} = useStore()
  const store = useLocalObservable(() => ({
    isLoading:false,
    async download(){
      store.isLoading=true
      try {
        await install()
      }catch (e) {
        console.log(e);
      }
      store.isLoading=false
    }
  }));

  return(
    <>
      <Flex alignItems='flex-start' mt={10}>
        <Image src='/images/apps/Install_2.gif' w='160px' h='160px' objectFit={"contain"}/>
        <Text ml={10} whiteSpace={'pre-line'}>{store.isLoading?lang.t('installing.app'):lang.t('before.app.installing',{app:app.id})}</Text>
      </Flex>
      {
        !store.isLoading&&(
          <>
            <Divider mt={10} />
            <Flex justifyContent='flex-end' mt={5}>
              <CancelButton onClick={prevStep} mr={4}/>
              <NextButton onClick={store.download} text={lang.t('download')}/>
            </Flex>
          </>
        )
      }
    </>
  )
}

export default observer(Download)
