import { Observable } from 'graphql-typed-client'

export interface query_root {
  /** fetch data from the table: "lonely.area" */
  lonely_area: lonely_area[]
  /** fetch aggregated fields from the table: "lonely.area" */
  lonely_area_aggregate: lonely_area_aggregate
  /** fetch data from the table: "lonely.area" using primary key columns */
  lonely_area_by_pk: lonely_area | null
  /** fetch data from the table: "lonely.device" */
  lonely_device: lonely_device[]
  /** fetch aggregated fields from the table: "lonely.device" */
  lonely_device_aggregate: lonely_device_aggregate
  /** fetch data from the table: "lonely.device" using primary key columns */
  lonely_device_by_pk: lonely_device | null
  /** fetch data from the table: "lonely.status" */
  lonely_status: lonely_status[]
  /** fetch aggregated fields from the table: "lonely.status" */
  lonely_status_aggregate: lonely_status_aggregate
  /** fetch data from the table: "lonely.status" using primary key columns */
  lonely_status_by_pk: lonely_status | null
  /** fetch data from the table: "pebble.account" */
  pebble_account: pebble_account[]
  /** fetch aggregated fields from the table: "pebble.account" */
  pebble_account_aggregate: pebble_account_aggregate
  /** fetch data from the table: "pebble.account" using primary key columns */
  pebble_account_by_pk: pebble_account | null
  /** fetch data from the table: "pebble.app" */
  pebble_app: pebble_app[]
  /** fetch aggregated fields from the table: "pebble.app" */
  pebble_app_aggregate: pebble_app_aggregate
  /** fetch data from the table: "pebble.app" using primary key columns */
  pebble_app_by_pk: pebble_app | null
  /** fetch data from the table: "pebble.app_v2" */
  pebble_app_v2: pebble_app_v2[]
  /** fetch aggregated fields from the table: "pebble.app_v2" */
  pebble_app_v2_aggregate: pebble_app_v2_aggregate
  /** fetch data from the table: "pebble.app_v2" using primary key columns */
  pebble_app_v2_by_pk: pebble_app_v2 | null
  /** fetch data from the table: "pebble.bank" */
  pebble_bank: pebble_bank[]
  /** fetch aggregated fields from the table: "pebble.bank" */
  pebble_bank_aggregate: pebble_bank_aggregate
  /** fetch data from the table: "pebble.bank" using primary key columns */
  pebble_bank_by_pk: pebble_bank | null
  /** fetch data from the table: "pebble.bank_record" */
  pebble_bank_record: pebble_bank_record[]
  /** fetch aggregated fields from the table: "pebble.bank_record" */
  pebble_bank_record_aggregate: pebble_bank_record_aggregate
  /** fetch data from the table: "pebble.bank_record" using primary key columns */
  pebble_bank_record_by_pk: pebble_bank_record | null
  /** fetch data from the table: "pebble.config" */
  pebble_config: pebble_config[]
  /** fetch aggregated fields from the table: "pebble.config" */
  pebble_config_aggregate: pebble_config_aggregate
  /** fetch data from the table: "pebble.config" using primary key columns */
  pebble_config_by_pk: pebble_config | null
  /** fetch data from the table: "pebble.data_cache" */
  pebble_data_cache: pebble_data_cache[]
  /** fetch aggregated fields from the table: "pebble.data_cache" */
  pebble_data_cache_aggregate: pebble_data_cache_aggregate
  /** fetch data from the table: "pebble.data_cache" using primary key columns */
  pebble_data_cache_by_pk: pebble_data_cache | null
  /** fetch data from the table: "pebble.device" */
  pebble_device: pebble_device[]
  /** fetch aggregated fields from the table: "pebble.device" */
  pebble_device_aggregate: pebble_device_aggregate
  /** fetch data from the table: "pebble.device" using primary key columns */
  pebble_device_by_pk: pebble_device | null
  /** fetch data from the table: "pebble.device_record" */
  pebble_device_record: pebble_device_record[]
  /** fetch aggregated fields from the table: "pebble.device_record" */
  pebble_device_record_aggregate: pebble_device_record_aggregate
  /** fetch data from the table: "pebble.device_record" using primary key columns */
  pebble_device_record_by_pk: pebble_device_record | null
  /** fetch data from the table: "pebble.status" */
  pebble_status: pebble_status[]
  /** fetch aggregated fields from the table: "pebble.status" */
  pebble_status_aggregate: pebble_status_aggregate
  /** fetch data from the table: "pebble.status" using primary key columns */
  pebble_status_by_pk: pebble_status | null
  __typename: 'query_root'
}

/** select columns of table "lonely.area" */
export enum lonely_area_select_column {
  /** column name */
  count = 'count',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  latitude = 'latitude',
  /** column name */
  longitude = 'longitude',
  /** column name */
  updated_at = 'updated_at',
}

/** The `Int` scalar type represents non-fractional signed whole numeric values. Int can represent values between -(2^31) and 2^31 - 1. */
export type Int = number

/** column ordering options */
export enum order_by {
  /** in ascending order, nulls last */
  asc = 'asc',
  /** in ascending order, nulls first */
  asc_nulls_first = 'asc_nulls_first',
  /** in ascending order, nulls last */
  asc_nulls_last = 'asc_nulls_last',
  /** in descending order, nulls first */
  desc = 'desc',
  /** in descending order, nulls first */
  desc_nulls_first = 'desc_nulls_first',
  /** in descending order, nulls last */
  desc_nulls_last = 'desc_nulls_last',
}

/** The `Boolean` scalar type represents `true` or `false`. */
export type Boolean = boolean

export type timestamptz = any

/** The `String` scalar type represents textual data, represented as UTF-8 character sequences. The String type is most often used by GraphQL to represent free-form human-readable text. */
export type String = string

export type numeric = any

/** columns and relationships of "lonely.area" */
export interface lonely_area {
  count: Int
  created_at: timestamptz
  id: String
  latitude: numeric
  longitude: numeric
  updated_at: timestamptz
  __typename: 'lonely_area'
}

/** aggregated selection of "lonely.area" */
export interface lonely_area_aggregate {
  aggregate: lonely_area_aggregate_fields | null
  nodes: lonely_area[]
  __typename: 'lonely_area_aggregate'
}

/** aggregate fields of "lonely.area" */
export interface lonely_area_aggregate_fields {
  avg: lonely_area_avg_fields | null
  count: Int
  max: lonely_area_max_fields | null
  min: lonely_area_min_fields | null
  stddev: lonely_area_stddev_fields | null
  stddev_pop: lonely_area_stddev_pop_fields | null
  stddev_samp: lonely_area_stddev_samp_fields | null
  sum: lonely_area_sum_fields | null
  var_pop: lonely_area_var_pop_fields | null
  var_samp: lonely_area_var_samp_fields | null
  variance: lonely_area_variance_fields | null
  __typename: 'lonely_area_aggregate_fields'
}

/** aggregate avg on columns */
export interface lonely_area_avg_fields {
  count: Float | null
  latitude: Float | null
  longitude: Float | null
  __typename: 'lonely_area_avg_fields'
}

/** The `Float` scalar type represents signed double-precision fractional values as specified by [IEEE 754](https://en.wikipedia.org/wiki/IEEE_floating_point). */
export type Float = number

/** aggregate max on columns */
export interface lonely_area_max_fields {
  count: Int | null
  created_at: timestamptz | null
  id: String | null
  latitude: numeric | null
  longitude: numeric | null
  updated_at: timestamptz | null
  __typename: 'lonely_area_max_fields'
}

/** aggregate min on columns */
export interface lonely_area_min_fields {
  count: Int | null
  created_at: timestamptz | null
  id: String | null
  latitude: numeric | null
  longitude: numeric | null
  updated_at: timestamptz | null
  __typename: 'lonely_area_min_fields'
}

/** aggregate stddev on columns */
export interface lonely_area_stddev_fields {
  count: Float | null
  latitude: Float | null
  longitude: Float | null
  __typename: 'lonely_area_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface lonely_area_stddev_pop_fields {
  count: Float | null
  latitude: Float | null
  longitude: Float | null
  __typename: 'lonely_area_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface lonely_area_stddev_samp_fields {
  count: Float | null
  latitude: Float | null
  longitude: Float | null
  __typename: 'lonely_area_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface lonely_area_sum_fields {
  count: Int | null
  latitude: numeric | null
  longitude: numeric | null
  __typename: 'lonely_area_sum_fields'
}

/** aggregate var_pop on columns */
export interface lonely_area_var_pop_fields {
  count: Float | null
  latitude: Float | null
  longitude: Float | null
  __typename: 'lonely_area_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface lonely_area_var_samp_fields {
  count: Float | null
  latitude: Float | null
  longitude: Float | null
  __typename: 'lonely_area_var_samp_fields'
}

/** aggregate variance on columns */
export interface lonely_area_variance_fields {
  count: Float | null
  latitude: Float | null
  longitude: Float | null
  __typename: 'lonely_area_variance_fields'
}

/** select columns of table "lonely.device" */
export enum lonely_device_select_column {
  /** column name */
  address = 'address',
  /** column name */
  area = 'area',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  last_report_time = 'last_report_time',
  /** column name */
  latitude = 'latitude',
  /** column name */
  location = 'location',
  /** column name */
  longitude = 'longitude',
  /** column name */
  owner = 'owner',
  /** column name */
  to_be_claimed = 'to_be_claimed',
  /** column name */
  updated_at = 'updated_at',
}

// @ts-ignore
export type bigint = any

/** columns and relationships of "lonely.device" */
export interface lonely_device {
  address: String | null
  area: String
  created_at: timestamptz
  id: String
  last_report_time: bigint
  latitude: numeric
  location: String | null
  longitude: numeric
  owner: String | null
  to_be_claimed: Int
  updated_at: timestamptz
  __typename: 'lonely_device'
}

/** aggregated selection of "lonely.device" */
export interface lonely_device_aggregate {
  aggregate: lonely_device_aggregate_fields | null
  nodes: lonely_device[]
  __typename: 'lonely_device_aggregate'
}

/** aggregate fields of "lonely.device" */
export interface lonely_device_aggregate_fields {
  avg: lonely_device_avg_fields | null
  count: Int
  max: lonely_device_max_fields | null
  min: lonely_device_min_fields | null
  stddev: lonely_device_stddev_fields | null
  stddev_pop: lonely_device_stddev_pop_fields | null
  stddev_samp: lonely_device_stddev_samp_fields | null
  sum: lonely_device_sum_fields | null
  var_pop: lonely_device_var_pop_fields | null
  var_samp: lonely_device_var_samp_fields | null
  variance: lonely_device_variance_fields | null
  __typename: 'lonely_device_aggregate_fields'
}

/** aggregate avg on columns */
export interface lonely_device_avg_fields {
  last_report_time: Float | null
  latitude: Float | null
  longitude: Float | null
  to_be_claimed: Float | null
  __typename: 'lonely_device_avg_fields'
}

/** aggregate max on columns */
export interface lonely_device_max_fields {
  address: String | null
  area: String | null
  created_at: timestamptz | null
  id: String | null
  last_report_time: bigint | null
  latitude: numeric | null
  location: String | null
  longitude: numeric | null
  owner: String | null
  to_be_claimed: Int | null
  updated_at: timestamptz | null
  __typename: 'lonely_device_max_fields'
}

/** aggregate min on columns */
export interface lonely_device_min_fields {
  address: String | null
  area: String | null
  created_at: timestamptz | null
  id: String | null
  last_report_time: bigint | null
  latitude: numeric | null
  location: String | null
  longitude: numeric | null
  owner: String | null
  to_be_claimed: Int | null
  updated_at: timestamptz | null
  __typename: 'lonely_device_min_fields'
}

/** aggregate stddev on columns */
export interface lonely_device_stddev_fields {
  last_report_time: Float | null
  latitude: Float | null
  longitude: Float | null
  to_be_claimed: Float | null
  __typename: 'lonely_device_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface lonely_device_stddev_pop_fields {
  last_report_time: Float | null
  latitude: Float | null
  longitude: Float | null
  to_be_claimed: Float | null
  __typename: 'lonely_device_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface lonely_device_stddev_samp_fields {
  last_report_time: Float | null
  latitude: Float | null
  longitude: Float | null
  to_be_claimed: Float | null
  __typename: 'lonely_device_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface lonely_device_sum_fields {
  last_report_time: bigint | null
  latitude: numeric | null
  longitude: numeric | null
  to_be_claimed: Int | null
  __typename: 'lonely_device_sum_fields'
}

/** aggregate var_pop on columns */
export interface lonely_device_var_pop_fields {
  last_report_time: Float | null
  latitude: Float | null
  longitude: Float | null
  to_be_claimed: Float | null
  __typename: 'lonely_device_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface lonely_device_var_samp_fields {
  last_report_time: Float | null
  latitude: Float | null
  longitude: Float | null
  to_be_claimed: Float | null
  __typename: 'lonely_device_var_samp_fields'
}

/** aggregate variance on columns */
export interface lonely_device_variance_fields {
  last_report_time: Float | null
  latitude: Float | null
  longitude: Float | null
  to_be_claimed: Float | null
  __typename: 'lonely_device_variance_fields'
}

/** select columns of table "lonely.status" */
export enum lonely_status_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** columns and relationships of "lonely.status" */
export interface lonely_status {
  created_at: timestamptz
  id: Int
  updated_at: timestamptz
  value: Int
  __typename: 'lonely_status'
}

/** aggregated selection of "lonely.status" */
export interface lonely_status_aggregate {
  aggregate: lonely_status_aggregate_fields | null
  nodes: lonely_status[]
  __typename: 'lonely_status_aggregate'
}

/** aggregate fields of "lonely.status" */
export interface lonely_status_aggregate_fields {
  avg: lonely_status_avg_fields | null
  count: Int
  max: lonely_status_max_fields | null
  min: lonely_status_min_fields | null
  stddev: lonely_status_stddev_fields | null
  stddev_pop: lonely_status_stddev_pop_fields | null
  stddev_samp: lonely_status_stddev_samp_fields | null
  sum: lonely_status_sum_fields | null
  var_pop: lonely_status_var_pop_fields | null
  var_samp: lonely_status_var_samp_fields | null
  variance: lonely_status_variance_fields | null
  __typename: 'lonely_status_aggregate_fields'
}

/** aggregate avg on columns */
export interface lonely_status_avg_fields {
  id: Float | null
  value: Float | null
  __typename: 'lonely_status_avg_fields'
}

/** aggregate max on columns */
export interface lonely_status_max_fields {
  created_at: timestamptz | null
  id: Int | null
  updated_at: timestamptz | null
  value: Int | null
  __typename: 'lonely_status_max_fields'
}

/** aggregate min on columns */
export interface lonely_status_min_fields {
  created_at: timestamptz | null
  id: Int | null
  updated_at: timestamptz | null
  value: Int | null
  __typename: 'lonely_status_min_fields'
}

/** aggregate stddev on columns */
export interface lonely_status_stddev_fields {
  id: Float | null
  value: Float | null
  __typename: 'lonely_status_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface lonely_status_stddev_pop_fields {
  id: Float | null
  value: Float | null
  __typename: 'lonely_status_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface lonely_status_stddev_samp_fields {
  id: Float | null
  value: Float | null
  __typename: 'lonely_status_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface lonely_status_sum_fields {
  id: Int | null
  value: Int | null
  __typename: 'lonely_status_sum_fields'
}

/** aggregate var_pop on columns */
export interface lonely_status_var_pop_fields {
  id: Float | null
  value: Float | null
  __typename: 'lonely_status_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface lonely_status_var_samp_fields {
  id: Float | null
  value: Float | null
  __typename: 'lonely_status_var_samp_fields'
}

/** aggregate variance on columns */
export interface lonely_status_variance_fields {
  id: Float | null
  value: Float | null
  __typename: 'lonely_status_variance_fields'
}

/** select columns of table "pebble.account" */
export enum pebble_account_select_column {
  /** column name */
  avatar = 'avatar',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  updated_at = 'updated_at',
}

/** columns and relationships of "pebble.account" */
export interface pebble_account {
  avatar: String
  created_at: timestamptz
  id: String
  name: String
  updated_at: timestamptz
  __typename: 'pebble_account'
}

/** aggregated selection of "pebble.account" */
export interface pebble_account_aggregate {
  aggregate: pebble_account_aggregate_fields | null
  nodes: pebble_account[]
  __typename: 'pebble_account_aggregate'
}

/** aggregate fields of "pebble.account" */
export interface pebble_account_aggregate_fields {
  count: Int
  max: pebble_account_max_fields | null
  min: pebble_account_min_fields | null
  __typename: 'pebble_account_aggregate_fields'
}

/** aggregate max on columns */
export interface pebble_account_max_fields {
  avatar: String | null
  created_at: timestamptz | null
  id: String | null
  name: String | null
  updated_at: timestamptz | null
  __typename: 'pebble_account_max_fields'
}

/** aggregate min on columns */
export interface pebble_account_min_fields {
  avatar: String | null
  created_at: timestamptz | null
  id: String | null
  name: String | null
  updated_at: timestamptz | null
  __typename: 'pebble_account_min_fields'
}

/** select columns of table "pebble.app" */
export enum pebble_app_select_column {
  /** column name */
  avatar = 'avatar',
  /** column name */
  content = 'content',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  uri = 'uri',
  /** column name */
  version = 'version',
}

/** columns and relationships of "pebble.app" */
export interface pebble_app {
  avatar: String
  content: String | null
  created_at: timestamptz
  id: String
  updated_at: timestamptz
  uri: String
  version: String
  __typename: 'pebble_app'
}

/** aggregated selection of "pebble.app" */
export interface pebble_app_aggregate {
  aggregate: pebble_app_aggregate_fields | null
  nodes: pebble_app[]
  __typename: 'pebble_app_aggregate'
}

/** aggregate fields of "pebble.app" */
export interface pebble_app_aggregate_fields {
  count: Int
  max: pebble_app_max_fields | null
  min: pebble_app_min_fields | null
  __typename: 'pebble_app_aggregate_fields'
}

/** aggregate max on columns */
export interface pebble_app_max_fields {
  avatar: String | null
  content: String | null
  created_at: timestamptz | null
  id: String | null
  updated_at: timestamptz | null
  uri: String | null
  version: String | null
  __typename: 'pebble_app_max_fields'
}

/** aggregate min on columns */
export interface pebble_app_min_fields {
  avatar: String | null
  content: String | null
  created_at: timestamptz | null
  id: String | null
  updated_at: timestamptz | null
  uri: String | null
  version: String | null
  __typename: 'pebble_app_min_fields'
}

/** select columns of table "pebble.app_v2" */
export enum pebble_app_v2_select_column {
  /** column name */
  author = 'author',
  /** column name */
  category = 'category',
  /** column name */
  content = 'content',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  date = 'date',
  /** column name */
  direct_link = 'direct_link',
  /** column name */
  firmware = 'firmware',
  /** column name */
  id = 'id',
  /** column name */
  logo = 'logo',
  /** column name */
  order = 'order',
  /** column name */
  previews = 'previews',
  /** column name */
  slug = 'slug',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  uri = 'uri',
}

export type date = any

export type jsonb = any

export type _text = any

/** columns and relationships of "pebble.app_v2" */
export interface pebble_app_v2 {
  author: String | null
  category: Int | null
  content: String | null
  created_at: date | null
  data: jsonb | null
  date: date | null
  direct_link: String | null
  firmware: String | null
  id: String
  logo: String | null
  order: Int | null
  previews: _text | null
  slug: String | null
  status: String | null
  updated_at: date | null
  uri: String | null
  __typename: 'pebble_app_v2'
}

/** aggregated selection of "pebble.app_v2" */
export interface pebble_app_v2_aggregate {
  aggregate: pebble_app_v2_aggregate_fields | null
  nodes: pebble_app_v2[]
  __typename: 'pebble_app_v2_aggregate'
}

/** aggregate fields of "pebble.app_v2" */
export interface pebble_app_v2_aggregate_fields {
  avg: pebble_app_v2_avg_fields | null
  count: Int
  max: pebble_app_v2_max_fields | null
  min: pebble_app_v2_min_fields | null
  stddev: pebble_app_v2_stddev_fields | null
  stddev_pop: pebble_app_v2_stddev_pop_fields | null
  stddev_samp: pebble_app_v2_stddev_samp_fields | null
  sum: pebble_app_v2_sum_fields | null
  var_pop: pebble_app_v2_var_pop_fields | null
  var_samp: pebble_app_v2_var_samp_fields | null
  variance: pebble_app_v2_variance_fields | null
  __typename: 'pebble_app_v2_aggregate_fields'
}

/** aggregate avg on columns */
export interface pebble_app_v2_avg_fields {
  category: Float | null
  order: Float | null
  __typename: 'pebble_app_v2_avg_fields'
}

/** aggregate max on columns */
export interface pebble_app_v2_max_fields {
  author: String | null
  category: Int | null
  content: String | null
  created_at: date | null
  date: date | null
  direct_link: String | null
  firmware: String | null
  id: String | null
  logo: String | null
  order: Int | null
  slug: String | null
  status: String | null
  updated_at: date | null
  uri: String | null
  __typename: 'pebble_app_v2_max_fields'
}

/** aggregate min on columns */
export interface pebble_app_v2_min_fields {
  author: String | null
  category: Int | null
  content: String | null
  created_at: date | null
  date: date | null
  direct_link: String | null
  firmware: String | null
  id: String | null
  logo: String | null
  order: Int | null
  slug: String | null
  status: String | null
  updated_at: date | null
  uri: String | null
  __typename: 'pebble_app_v2_min_fields'
}

/** aggregate stddev on columns */
export interface pebble_app_v2_stddev_fields {
  category: Float | null
  order: Float | null
  __typename: 'pebble_app_v2_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface pebble_app_v2_stddev_pop_fields {
  category: Float | null
  order: Float | null
  __typename: 'pebble_app_v2_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface pebble_app_v2_stddev_samp_fields {
  category: Float | null
  order: Float | null
  __typename: 'pebble_app_v2_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface pebble_app_v2_sum_fields {
  category: Int | null
  order: Int | null
  __typename: 'pebble_app_v2_sum_fields'
}

/** aggregate var_pop on columns */
export interface pebble_app_v2_var_pop_fields {
  category: Float | null
  order: Float | null
  __typename: 'pebble_app_v2_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface pebble_app_v2_var_samp_fields {
  category: Float | null
  order: Float | null
  __typename: 'pebble_app_v2_var_samp_fields'
}

/** aggregate variance on columns */
export interface pebble_app_v2_variance_fields {
  category: Float | null
  order: Float | null
  __typename: 'pebble_app_v2_variance_fields'
}

/** select columns of table "pebble.bank" */
export enum pebble_bank_select_column {
  /** column name */
  address = 'address',
  /** column name */
  balance = 'balance',
  /** column name */
  created_at = 'created_at',
  /** column name */
  updated_at = 'updated_at',
}

/** columns and relationships of "pebble.bank" */
export interface pebble_bank {
  address: String
  balance: String
  created_at: timestamptz
  updated_at: timestamptz
  __typename: 'pebble_bank'
}

/** aggregated selection of "pebble.bank" */
export interface pebble_bank_aggregate {
  aggregate: pebble_bank_aggregate_fields | null
  nodes: pebble_bank[]
  __typename: 'pebble_bank_aggregate'
}

/** aggregate fields of "pebble.bank" */
export interface pebble_bank_aggregate_fields {
  count: Int
  max: pebble_bank_max_fields | null
  min: pebble_bank_min_fields | null
  __typename: 'pebble_bank_aggregate_fields'
}

/** aggregate max on columns */
export interface pebble_bank_max_fields {
  address: String | null
  balance: String | null
  created_at: timestamptz | null
  updated_at: timestamptz | null
  __typename: 'pebble_bank_max_fields'
}

/** aggregate min on columns */
export interface pebble_bank_min_fields {
  address: String | null
  balance: String | null
  created_at: timestamptz | null
  updated_at: timestamptz | null
  __typename: 'pebble_bank_min_fields'
}

/** select columns of table "pebble.bank_record" */
export enum pebble_bank_record_select_column {
  /** column name */
  amount = 'amount',
  /** column name */
  created_at = 'created_at',
  /** column name */
  from = 'from',
  /** column name */
  id = 'id',
  /** column name */
  timestamp = 'timestamp',
  /** column name */
  to = 'to',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
}

/** columns and relationships of "pebble.bank_record" */
export interface pebble_bank_record {
  amount: String
  created_at: timestamptz
  from: String
  id: String
  timestamp: Int
  to: String
  type: Int
  updated_at: timestamptz
  __typename: 'pebble_bank_record'
}

/** aggregated selection of "pebble.bank_record" */
export interface pebble_bank_record_aggregate {
  aggregate: pebble_bank_record_aggregate_fields | null
  nodes: pebble_bank_record[]
  __typename: 'pebble_bank_record_aggregate'
}

/** aggregate fields of "pebble.bank_record" */
export interface pebble_bank_record_aggregate_fields {
  avg: pebble_bank_record_avg_fields | null
  count: Int
  max: pebble_bank_record_max_fields | null
  min: pebble_bank_record_min_fields | null
  stddev: pebble_bank_record_stddev_fields | null
  stddev_pop: pebble_bank_record_stddev_pop_fields | null
  stddev_samp: pebble_bank_record_stddev_samp_fields | null
  sum: pebble_bank_record_sum_fields | null
  var_pop: pebble_bank_record_var_pop_fields | null
  var_samp: pebble_bank_record_var_samp_fields | null
  variance: pebble_bank_record_variance_fields | null
  __typename: 'pebble_bank_record_aggregate_fields'
}

/** aggregate avg on columns */
export interface pebble_bank_record_avg_fields {
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_bank_record_avg_fields'
}

/** aggregate max on columns */
export interface pebble_bank_record_max_fields {
  amount: String | null
  created_at: timestamptz | null
  from: String | null
  id: String | null
  timestamp: Int | null
  to: String | null
  type: Int | null
  updated_at: timestamptz | null
  __typename: 'pebble_bank_record_max_fields'
}

/** aggregate min on columns */
export interface pebble_bank_record_min_fields {
  amount: String | null
  created_at: timestamptz | null
  from: String | null
  id: String | null
  timestamp: Int | null
  to: String | null
  type: Int | null
  updated_at: timestamptz | null
  __typename: 'pebble_bank_record_min_fields'
}

/** aggregate stddev on columns */
export interface pebble_bank_record_stddev_fields {
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_bank_record_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface pebble_bank_record_stddev_pop_fields {
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_bank_record_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface pebble_bank_record_stddev_samp_fields {
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_bank_record_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface pebble_bank_record_sum_fields {
  timestamp: Int | null
  type: Int | null
  __typename: 'pebble_bank_record_sum_fields'
}

/** aggregate var_pop on columns */
export interface pebble_bank_record_var_pop_fields {
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_bank_record_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface pebble_bank_record_var_samp_fields {
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_bank_record_var_samp_fields'
}

/** aggregate variance on columns */
export interface pebble_bank_record_variance_fields {
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_bank_record_variance_fields'
}

/** select columns of table "pebble.config" */
export enum pebble_config_select_column {
  /** column name */
  avatar = 'avatar',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  uri = 'uri',
  /** column name */
  version = 'version',
}

/** columns and relationships of "pebble.config" */
export interface pebble_config {
  avatar: String
  created_at: timestamptz
  data: String
  id: String
  updated_at: timestamptz
  uri: String
  version: String
  __typename: 'pebble_config'
}

/** aggregated selection of "pebble.config" */
export interface pebble_config_aggregate {
  aggregate: pebble_config_aggregate_fields | null
  nodes: pebble_config[]
  __typename: 'pebble_config_aggregate'
}

/** aggregate fields of "pebble.config" */
export interface pebble_config_aggregate_fields {
  count: Int
  max: pebble_config_max_fields | null
  min: pebble_config_min_fields | null
  __typename: 'pebble_config_aggregate_fields'
}

/** aggregate max on columns */
export interface pebble_config_max_fields {
  avatar: String | null
  created_at: timestamptz | null
  data: String | null
  id: String | null
  updated_at: timestamptz | null
  uri: String | null
  version: String | null
  __typename: 'pebble_config_max_fields'
}

/** aggregate min on columns */
export interface pebble_config_min_fields {
  avatar: String | null
  created_at: timestamptz | null
  data: String | null
  id: String | null
  updated_at: timestamptz | null
  uri: String | null
  version: String | null
  __typename: 'pebble_config_min_fields'
}

/** select columns of table "pebble.data_cache" */
export enum pebble_data_cache_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  imei = 'imei',
  /** column name */
  signature = 'signature',
  /** column name */
  timestamp = 'timestamp',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
}

/** columns and relationships of "pebble.data_cache" */
export interface pebble_data_cache {
  created_at: timestamptz
  data: String
  id: Int
  imei: String
  signature: String
  timestamp: Int
  type: Int
  updated_at: timestamptz
  __typename: 'pebble_data_cache'
}

/** aggregated selection of "pebble.data_cache" */
export interface pebble_data_cache_aggregate {
  aggregate: pebble_data_cache_aggregate_fields | null
  nodes: pebble_data_cache[]
  __typename: 'pebble_data_cache_aggregate'
}

/** aggregate fields of "pebble.data_cache" */
export interface pebble_data_cache_aggregate_fields {
  avg: pebble_data_cache_avg_fields | null
  count: Int
  max: pebble_data_cache_max_fields | null
  min: pebble_data_cache_min_fields | null
  stddev: pebble_data_cache_stddev_fields | null
  stddev_pop: pebble_data_cache_stddev_pop_fields | null
  stddev_samp: pebble_data_cache_stddev_samp_fields | null
  sum: pebble_data_cache_sum_fields | null
  var_pop: pebble_data_cache_var_pop_fields | null
  var_samp: pebble_data_cache_var_samp_fields | null
  variance: pebble_data_cache_variance_fields | null
  __typename: 'pebble_data_cache_aggregate_fields'
}

/** aggregate avg on columns */
export interface pebble_data_cache_avg_fields {
  id: Float | null
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_data_cache_avg_fields'
}

/** aggregate max on columns */
export interface pebble_data_cache_max_fields {
  created_at: timestamptz | null
  data: String | null
  id: Int | null
  imei: String | null
  signature: String | null
  timestamp: Int | null
  type: Int | null
  updated_at: timestamptz | null
  __typename: 'pebble_data_cache_max_fields'
}

/** aggregate min on columns */
export interface pebble_data_cache_min_fields {
  created_at: timestamptz | null
  data: String | null
  id: Int | null
  imei: String | null
  signature: String | null
  timestamp: Int | null
  type: Int | null
  updated_at: timestamptz | null
  __typename: 'pebble_data_cache_min_fields'
}

/** aggregate stddev on columns */
export interface pebble_data_cache_stddev_fields {
  id: Float | null
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_data_cache_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface pebble_data_cache_stddev_pop_fields {
  id: Float | null
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_data_cache_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface pebble_data_cache_stddev_samp_fields {
  id: Float | null
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_data_cache_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface pebble_data_cache_sum_fields {
  id: Int | null
  timestamp: Int | null
  type: Int | null
  __typename: 'pebble_data_cache_sum_fields'
}

/** aggregate var_pop on columns */
export interface pebble_data_cache_var_pop_fields {
  id: Float | null
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_data_cache_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface pebble_data_cache_var_samp_fields {
  id: Float | null
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_data_cache_var_samp_fields'
}

/** aggregate variance on columns */
export interface pebble_data_cache_variance_fields {
  id: Float | null
  timestamp: Float | null
  type: Float | null
  __typename: 'pebble_data_cache_variance_fields'
}

/** select columns of table "pebble.device" */
export enum pebble_device_select_column {
  /** column name */
  address = 'address',
  /** column name */
  avatar = 'avatar',
  /** column name */
  beep = 'beep',
  /** column name */
  bulk_upload = 'bulk_upload',
  /** column name */
  bulk_upload_sampling_cnt = 'bulk_upload_sampling_cnt',
  /** column name */
  bulk_upload_sampling_freq = 'bulk_upload_sampling_freq',
  /** column name */
  config = 'config',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data_channel = 'data_channel',
  /** column name */
  firmware = 'firmware',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  owner = 'owner',
  /** column name */
  proposer = 'proposer',
  /** column name */
  real_firmware = 'real_firmware',
  /** column name */
  state = 'state',
  /** column name */
  status = 'status',
  /** column name */
  total_gas = 'total_gas',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  upload_period = 'upload_period',
}

/** columns and relationships of "pebble.device" */
export interface pebble_device {
  address: String | null
  avatar: String | null
  beep: Int
  bulk_upload: Int
  bulk_upload_sampling_cnt: Int
  bulk_upload_sampling_freq: Int
  config: String | null
  created_at: timestamptz
  data_channel: Int
  /** An array relationship */
  deviceRecord: pebble_device_record[]
  /** An aggregate relationship */
  deviceRecord_aggregate: pebble_device_record_aggregate
  firmware: String | null
  id: String
  name: String | null
  owner: String | null
  proposer: String | null
  real_firmware: String | null
  state: Int
  status: Int
  total_gas: numeric
  type: Int
  updated_at: timestamptz
  upload_period: Int
  __typename: 'pebble_device'
}

/** select columns of table "pebble.device_record" */
export enum pebble_device_record_select_column {
  /** column name */
  accelerometer = 'accelerometer',
  /** column name */
  created_at = 'created_at',
  /** column name */
  gas_resistance = 'gas_resistance',
  /** column name */
  gyroscope = 'gyroscope',
  /** column name */
  hash = 'hash',
  /** column name */
  humidity = 'humidity',
  /** column name */
  id = 'id',
  /** column name */
  imei = 'imei',
  /** column name */
  latitude = 'latitude',
  /** column name */
  light = 'light',
  /** column name */
  longitude = 'longitude',
  /** column name */
  operator = 'operator',
  /** column name */
  pressure = 'pressure',
  /** column name */
  signature = 'signature',
  /** column name */
  snr = 'snr',
  /** column name */
  temperature = 'temperature',
  /** column name */
  temperature2 = 'temperature2',
  /** column name */
  timestamp = 'timestamp',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  vbat = 'vbat',
}

/** columns and relationships of "pebble.device_record" */
export interface pebble_device_record {
  accelerometer: String
  created_at: timestamptz
  gas_resistance: numeric
  gyroscope: String
  hash: String | null
  humidity: numeric
  id: String
  imei: String
  latitude: numeric
  light: numeric
  longitude: numeric
  operator: String
  pressure: numeric
  signature: String
  snr: numeric
  temperature: numeric
  temperature2: numeric
  timestamp: Int
  updated_at: timestamptz
  vbat: numeric
  __typename: 'pebble_device_record'
}

/** aggregated selection of "pebble.device_record" */
export interface pebble_device_record_aggregate {
  aggregate: pebble_device_record_aggregate_fields | null
  nodes: pebble_device_record[]
  __typename: 'pebble_device_record_aggregate'
}

/** aggregate fields of "pebble.device_record" */
export interface pebble_device_record_aggregate_fields {
  avg: pebble_device_record_avg_fields | null
  count: Int
  max: pebble_device_record_max_fields | null
  min: pebble_device_record_min_fields | null
  stddev: pebble_device_record_stddev_fields | null
  stddev_pop: pebble_device_record_stddev_pop_fields | null
  stddev_samp: pebble_device_record_stddev_samp_fields | null
  sum: pebble_device_record_sum_fields | null
  var_pop: pebble_device_record_var_pop_fields | null
  var_samp: pebble_device_record_var_samp_fields | null
  variance: pebble_device_record_variance_fields | null
  __typename: 'pebble_device_record_aggregate_fields'
}

/** aggregate avg on columns */
export interface pebble_device_record_avg_fields {
  gas_resistance: Float | null
  humidity: Float | null
  latitude: Float | null
  light: Float | null
  longitude: Float | null
  pressure: Float | null
  snr: Float | null
  temperature: Float | null
  temperature2: Float | null
  timestamp: Float | null
  vbat: Float | null
  __typename: 'pebble_device_record_avg_fields'
}

/** aggregate max on columns */
export interface pebble_device_record_max_fields {
  accelerometer: String | null
  created_at: timestamptz | null
  gas_resistance: numeric | null
  gyroscope: String | null
  hash: String | null
  humidity: numeric | null
  id: String | null
  imei: String | null
  latitude: numeric | null
  light: numeric | null
  longitude: numeric | null
  operator: String | null
  pressure: numeric | null
  signature: String | null
  snr: numeric | null
  temperature: numeric | null
  temperature2: numeric | null
  timestamp: Int | null
  updated_at: timestamptz | null
  vbat: numeric | null
  __typename: 'pebble_device_record_max_fields'
}

/** aggregate min on columns */
export interface pebble_device_record_min_fields {
  accelerometer: String | null
  created_at: timestamptz | null
  gas_resistance: numeric | null
  gyroscope: String | null
  hash: String | null
  humidity: numeric | null
  id: String | null
  imei: String | null
  latitude: numeric | null
  light: numeric | null
  longitude: numeric | null
  operator: String | null
  pressure: numeric | null
  signature: String | null
  snr: numeric | null
  temperature: numeric | null
  temperature2: numeric | null
  timestamp: Int | null
  updated_at: timestamptz | null
  vbat: numeric | null
  __typename: 'pebble_device_record_min_fields'
}

/** aggregate stddev on columns */
export interface pebble_device_record_stddev_fields {
  gas_resistance: Float | null
  humidity: Float | null
  latitude: Float | null
  light: Float | null
  longitude: Float | null
  pressure: Float | null
  snr: Float | null
  temperature: Float | null
  temperature2: Float | null
  timestamp: Float | null
  vbat: Float | null
  __typename: 'pebble_device_record_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface pebble_device_record_stddev_pop_fields {
  gas_resistance: Float | null
  humidity: Float | null
  latitude: Float | null
  light: Float | null
  longitude: Float | null
  pressure: Float | null
  snr: Float | null
  temperature: Float | null
  temperature2: Float | null
  timestamp: Float | null
  vbat: Float | null
  __typename: 'pebble_device_record_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface pebble_device_record_stddev_samp_fields {
  gas_resistance: Float | null
  humidity: Float | null
  latitude: Float | null
  light: Float | null
  longitude: Float | null
  pressure: Float | null
  snr: Float | null
  temperature: Float | null
  temperature2: Float | null
  timestamp: Float | null
  vbat: Float | null
  __typename: 'pebble_device_record_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface pebble_device_record_sum_fields {
  gas_resistance: numeric | null
  humidity: numeric | null
  latitude: numeric | null
  light: numeric | null
  longitude: numeric | null
  pressure: numeric | null
  snr: numeric | null
  temperature: numeric | null
  temperature2: numeric | null
  timestamp: Int | null
  vbat: numeric | null
  __typename: 'pebble_device_record_sum_fields'
}

/** aggregate var_pop on columns */
export interface pebble_device_record_var_pop_fields {
  gas_resistance: Float | null
  humidity: Float | null
  latitude: Float | null
  light: Float | null
  longitude: Float | null
  pressure: Float | null
  snr: Float | null
  temperature: Float | null
  temperature2: Float | null
  timestamp: Float | null
  vbat: Float | null
  __typename: 'pebble_device_record_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface pebble_device_record_var_samp_fields {
  gas_resistance: Float | null
  humidity: Float | null
  latitude: Float | null
  light: Float | null
  longitude: Float | null
  pressure: Float | null
  snr: Float | null
  temperature: Float | null
  temperature2: Float | null
  timestamp: Float | null
  vbat: Float | null
  __typename: 'pebble_device_record_var_samp_fields'
}

/** aggregate variance on columns */
export interface pebble_device_record_variance_fields {
  gas_resistance: Float | null
  humidity: Float | null
  latitude: Float | null
  light: Float | null
  longitude: Float | null
  pressure: Float | null
  snr: Float | null
  temperature: Float | null
  temperature2: Float | null
  timestamp: Float | null
  vbat: Float | null
  __typename: 'pebble_device_record_variance_fields'
}

/** aggregated selection of "pebble.device" */
export interface pebble_device_aggregate {
  aggregate: pebble_device_aggregate_fields | null
  nodes: pebble_device[]
  __typename: 'pebble_device_aggregate'
}

/** aggregate fields of "pebble.device" */
export interface pebble_device_aggregate_fields {
  avg: pebble_device_avg_fields | null
  count: Int
  max: pebble_device_max_fields | null
  min: pebble_device_min_fields | null
  stddev: pebble_device_stddev_fields | null
  stddev_pop: pebble_device_stddev_pop_fields | null
  stddev_samp: pebble_device_stddev_samp_fields | null
  sum: pebble_device_sum_fields | null
  var_pop: pebble_device_var_pop_fields | null
  var_samp: pebble_device_var_samp_fields | null
  variance: pebble_device_variance_fields | null
  __typename: 'pebble_device_aggregate_fields'
}

/** aggregate avg on columns */
export interface pebble_device_avg_fields {
  beep: Float | null
  bulk_upload: Float | null
  bulk_upload_sampling_cnt: Float | null
  bulk_upload_sampling_freq: Float | null
  data_channel: Float | null
  state: Float | null
  status: Float | null
  total_gas: Float | null
  type: Float | null
  upload_period: Float | null
  __typename: 'pebble_device_avg_fields'
}

/** aggregate max on columns */
export interface pebble_device_max_fields {
  address: String | null
  avatar: String | null
  beep: Int | null
  bulk_upload: Int | null
  bulk_upload_sampling_cnt: Int | null
  bulk_upload_sampling_freq: Int | null
  config: String | null
  created_at: timestamptz | null
  data_channel: Int | null
  firmware: String | null
  id: String | null
  name: String | null
  owner: String | null
  proposer: String | null
  real_firmware: String | null
  state: Int | null
  status: Int | null
  total_gas: numeric | null
  type: Int | null
  updated_at: timestamptz | null
  upload_period: Int | null
  __typename: 'pebble_device_max_fields'
}

/** aggregate min on columns */
export interface pebble_device_min_fields {
  address: String | null
  avatar: String | null
  beep: Int | null
  bulk_upload: Int | null
  bulk_upload_sampling_cnt: Int | null
  bulk_upload_sampling_freq: Int | null
  config: String | null
  created_at: timestamptz | null
  data_channel: Int | null
  firmware: String | null
  id: String | null
  name: String | null
  owner: String | null
  proposer: String | null
  real_firmware: String | null
  state: Int | null
  status: Int | null
  total_gas: numeric | null
  type: Int | null
  updated_at: timestamptz | null
  upload_period: Int | null
  __typename: 'pebble_device_min_fields'
}

/** aggregate stddev on columns */
export interface pebble_device_stddev_fields {
  beep: Float | null
  bulk_upload: Float | null
  bulk_upload_sampling_cnt: Float | null
  bulk_upload_sampling_freq: Float | null
  data_channel: Float | null
  state: Float | null
  status: Float | null
  total_gas: Float | null
  type: Float | null
  upload_period: Float | null
  __typename: 'pebble_device_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface pebble_device_stddev_pop_fields {
  beep: Float | null
  bulk_upload: Float | null
  bulk_upload_sampling_cnt: Float | null
  bulk_upload_sampling_freq: Float | null
  data_channel: Float | null
  state: Float | null
  status: Float | null
  total_gas: Float | null
  type: Float | null
  upload_period: Float | null
  __typename: 'pebble_device_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface pebble_device_stddev_samp_fields {
  beep: Float | null
  bulk_upload: Float | null
  bulk_upload_sampling_cnt: Float | null
  bulk_upload_sampling_freq: Float | null
  data_channel: Float | null
  state: Float | null
  status: Float | null
  total_gas: Float | null
  type: Float | null
  upload_period: Float | null
  __typename: 'pebble_device_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface pebble_device_sum_fields {
  beep: Int | null
  bulk_upload: Int | null
  bulk_upload_sampling_cnt: Int | null
  bulk_upload_sampling_freq: Int | null
  data_channel: Int | null
  state: Int | null
  status: Int | null
  total_gas: numeric | null
  type: Int | null
  upload_period: Int | null
  __typename: 'pebble_device_sum_fields'
}

/** aggregate var_pop on columns */
export interface pebble_device_var_pop_fields {
  beep: Float | null
  bulk_upload: Float | null
  bulk_upload_sampling_cnt: Float | null
  bulk_upload_sampling_freq: Float | null
  data_channel: Float | null
  state: Float | null
  status: Float | null
  total_gas: Float | null
  type: Float | null
  upload_period: Float | null
  __typename: 'pebble_device_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface pebble_device_var_samp_fields {
  beep: Float | null
  bulk_upload: Float | null
  bulk_upload_sampling_cnt: Float | null
  bulk_upload_sampling_freq: Float | null
  data_channel: Float | null
  state: Float | null
  status: Float | null
  total_gas: Float | null
  type: Float | null
  upload_period: Float | null
  __typename: 'pebble_device_var_samp_fields'
}

/** aggregate variance on columns */
export interface pebble_device_variance_fields {
  beep: Float | null
  bulk_upload: Float | null
  bulk_upload_sampling_cnt: Float | null
  bulk_upload_sampling_freq: Float | null
  data_channel: Float | null
  state: Float | null
  status: Float | null
  total_gas: Float | null
  type: Float | null
  upload_period: Float | null
  __typename: 'pebble_device_variance_fields'
}

/** select columns of table "pebble.status" */
export enum pebble_status_select_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** columns and relationships of "pebble.status" */
export interface pebble_status {
  created_at: timestamptz
  id: Int
  updated_at: timestamptz
  value: Int
  __typename: 'pebble_status'
}

/** aggregated selection of "pebble.status" */
export interface pebble_status_aggregate {
  aggregate: pebble_status_aggregate_fields | null
  nodes: pebble_status[]
  __typename: 'pebble_status_aggregate'
}

/** aggregate fields of "pebble.status" */
export interface pebble_status_aggregate_fields {
  avg: pebble_status_avg_fields | null
  count: Int
  max: pebble_status_max_fields | null
  min: pebble_status_min_fields | null
  stddev: pebble_status_stddev_fields | null
  stddev_pop: pebble_status_stddev_pop_fields | null
  stddev_samp: pebble_status_stddev_samp_fields | null
  sum: pebble_status_sum_fields | null
  var_pop: pebble_status_var_pop_fields | null
  var_samp: pebble_status_var_samp_fields | null
  variance: pebble_status_variance_fields | null
  __typename: 'pebble_status_aggregate_fields'
}

/** aggregate avg on columns */
export interface pebble_status_avg_fields {
  id: Float | null
  value: Float | null
  __typename: 'pebble_status_avg_fields'
}

/** aggregate max on columns */
export interface pebble_status_max_fields {
  created_at: timestamptz | null
  id: Int | null
  updated_at: timestamptz | null
  value: Int | null
  __typename: 'pebble_status_max_fields'
}

/** aggregate min on columns */
export interface pebble_status_min_fields {
  created_at: timestamptz | null
  id: Int | null
  updated_at: timestamptz | null
  value: Int | null
  __typename: 'pebble_status_min_fields'
}

/** aggregate stddev on columns */
export interface pebble_status_stddev_fields {
  id: Float | null
  value: Float | null
  __typename: 'pebble_status_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface pebble_status_stddev_pop_fields {
  id: Float | null
  value: Float | null
  __typename: 'pebble_status_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface pebble_status_stddev_samp_fields {
  id: Float | null
  value: Float | null
  __typename: 'pebble_status_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface pebble_status_sum_fields {
  id: Int | null
  value: Int | null
  __typename: 'pebble_status_sum_fields'
}

/** aggregate var_pop on columns */
export interface pebble_status_var_pop_fields {
  id: Float | null
  value: Float | null
  __typename: 'pebble_status_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface pebble_status_var_samp_fields {
  id: Float | null
  value: Float | null
  __typename: 'pebble_status_var_samp_fields'
}

/** aggregate variance on columns */
export interface pebble_status_variance_fields {
  id: Float | null
  value: Float | null
  __typename: 'pebble_status_variance_fields'
}

/** mutation root */
export interface mutation_root {
  /** delete data from the table: "lonely.area" */
  delete_lonely_area: lonely_area_mutation_response | null
  /** delete single row from the table: "lonely.area" */
  delete_lonely_area_by_pk: lonely_area | null
  /** delete data from the table: "lonely.device" */
  delete_lonely_device: lonely_device_mutation_response | null
  /** delete single row from the table: "lonely.device" */
  delete_lonely_device_by_pk: lonely_device | null
  /** delete data from the table: "lonely.status" */
  delete_lonely_status: lonely_status_mutation_response | null
  /** delete single row from the table: "lonely.status" */
  delete_lonely_status_by_pk: lonely_status | null
  /** delete data from the table: "pebble.account" */
  delete_pebble_account: pebble_account_mutation_response | null
  /** delete single row from the table: "pebble.account" */
  delete_pebble_account_by_pk: pebble_account | null
  /** delete data from the table: "pebble.app" */
  delete_pebble_app: pebble_app_mutation_response | null
  /** delete single row from the table: "pebble.app" */
  delete_pebble_app_by_pk: pebble_app | null
  /** delete data from the table: "pebble.app_v2" */
  delete_pebble_app_v2: pebble_app_v2_mutation_response | null
  /** delete single row from the table: "pebble.app_v2" */
  delete_pebble_app_v2_by_pk: pebble_app_v2 | null
  /** delete data from the table: "pebble.bank" */
  delete_pebble_bank: pebble_bank_mutation_response | null
  /** delete single row from the table: "pebble.bank" */
  delete_pebble_bank_by_pk: pebble_bank | null
  /** delete data from the table: "pebble.bank_record" */
  delete_pebble_bank_record: pebble_bank_record_mutation_response | null
  /** delete single row from the table: "pebble.bank_record" */
  delete_pebble_bank_record_by_pk: pebble_bank_record | null
  /** delete data from the table: "pebble.config" */
  delete_pebble_config: pebble_config_mutation_response | null
  /** delete single row from the table: "pebble.config" */
  delete_pebble_config_by_pk: pebble_config | null
  /** delete data from the table: "pebble.data_cache" */
  delete_pebble_data_cache: pebble_data_cache_mutation_response | null
  /** delete single row from the table: "pebble.data_cache" */
  delete_pebble_data_cache_by_pk: pebble_data_cache | null
  /** delete data from the table: "pebble.device" */
  delete_pebble_device: pebble_device_mutation_response | null
  /** delete single row from the table: "pebble.device" */
  delete_pebble_device_by_pk: pebble_device | null
  /** delete data from the table: "pebble.device_record" */
  delete_pebble_device_record: pebble_device_record_mutation_response | null
  /** delete single row from the table: "pebble.device_record" */
  delete_pebble_device_record_by_pk: pebble_device_record | null
  /** delete data from the table: "pebble.status" */
  delete_pebble_status: pebble_status_mutation_response | null
  /** delete single row from the table: "pebble.status" */
  delete_pebble_status_by_pk: pebble_status | null
  /** insert data into the table: "lonely.area" */
  insert_lonely_area: lonely_area_mutation_response | null
  /** insert a single row into the table: "lonely.area" */
  insert_lonely_area_one: lonely_area | null
  /** insert data into the table: "lonely.device" */
  insert_lonely_device: lonely_device_mutation_response | null
  /** insert a single row into the table: "lonely.device" */
  insert_lonely_device_one: lonely_device | null
  /** insert data into the table: "lonely.status" */
  insert_lonely_status: lonely_status_mutation_response | null
  /** insert a single row into the table: "lonely.status" */
  insert_lonely_status_one: lonely_status | null
  /** insert data into the table: "pebble.account" */
  insert_pebble_account: pebble_account_mutation_response | null
  /** insert a single row into the table: "pebble.account" */
  insert_pebble_account_one: pebble_account | null
  /** insert data into the table: "pebble.app" */
  insert_pebble_app: pebble_app_mutation_response | null
  /** insert a single row into the table: "pebble.app" */
  insert_pebble_app_one: pebble_app | null
  /** insert data into the table: "pebble.app_v2" */
  insert_pebble_app_v2: pebble_app_v2_mutation_response | null
  /** insert a single row into the table: "pebble.app_v2" */
  insert_pebble_app_v2_one: pebble_app_v2 | null
  /** insert data into the table: "pebble.bank" */
  insert_pebble_bank: pebble_bank_mutation_response | null
  /** insert a single row into the table: "pebble.bank" */
  insert_pebble_bank_one: pebble_bank | null
  /** insert data into the table: "pebble.bank_record" */
  insert_pebble_bank_record: pebble_bank_record_mutation_response | null
  /** insert a single row into the table: "pebble.bank_record" */
  insert_pebble_bank_record_one: pebble_bank_record | null
  /** insert data into the table: "pebble.config" */
  insert_pebble_config: pebble_config_mutation_response | null
  /** insert a single row into the table: "pebble.config" */
  insert_pebble_config_one: pebble_config | null
  /** insert data into the table: "pebble.data_cache" */
  insert_pebble_data_cache: pebble_data_cache_mutation_response | null
  /** insert a single row into the table: "pebble.data_cache" */
  insert_pebble_data_cache_one: pebble_data_cache | null
  /** insert data into the table: "pebble.device" */
  insert_pebble_device: pebble_device_mutation_response | null
  /** insert a single row into the table: "pebble.device" */
  insert_pebble_device_one: pebble_device | null
  /** insert data into the table: "pebble.device_record" */
  insert_pebble_device_record: pebble_device_record_mutation_response | null
  /** insert a single row into the table: "pebble.device_record" */
  insert_pebble_device_record_one: pebble_device_record | null
  /** insert data into the table: "pebble.status" */
  insert_pebble_status: pebble_status_mutation_response | null
  /** insert a single row into the table: "pebble.status" */
  insert_pebble_status_one: pebble_status | null
  /** update data of the table: "lonely.area" */
  update_lonely_area: lonely_area_mutation_response | null
  /** update single row of the table: "lonely.area" */
  update_lonely_area_by_pk: lonely_area | null
  /** update data of the table: "lonely.device" */
  update_lonely_device: lonely_device_mutation_response | null
  /** update single row of the table: "lonely.device" */
  update_lonely_device_by_pk: lonely_device | null
  /** update data of the table: "lonely.status" */
  update_lonely_status: lonely_status_mutation_response | null
  /** update single row of the table: "lonely.status" */
  update_lonely_status_by_pk: lonely_status | null
  /** update data of the table: "pebble.account" */
  update_pebble_account: pebble_account_mutation_response | null
  /** update single row of the table: "pebble.account" */
  update_pebble_account_by_pk: pebble_account | null
  /** update data of the table: "pebble.app" */
  update_pebble_app: pebble_app_mutation_response | null
  /** update single row of the table: "pebble.app" */
  update_pebble_app_by_pk: pebble_app | null
  /** update data of the table: "pebble.app_v2" */
  update_pebble_app_v2: pebble_app_v2_mutation_response | null
  /** update single row of the table: "pebble.app_v2" */
  update_pebble_app_v2_by_pk: pebble_app_v2 | null
  /** update data of the table: "pebble.bank" */
  update_pebble_bank: pebble_bank_mutation_response | null
  /** update single row of the table: "pebble.bank" */
  update_pebble_bank_by_pk: pebble_bank | null
  /** update data of the table: "pebble.bank_record" */
  update_pebble_bank_record: pebble_bank_record_mutation_response | null
  /** update single row of the table: "pebble.bank_record" */
  update_pebble_bank_record_by_pk: pebble_bank_record | null
  /** update data of the table: "pebble.config" */
  update_pebble_config: pebble_config_mutation_response | null
  /** update single row of the table: "pebble.config" */
  update_pebble_config_by_pk: pebble_config | null
  /** update data of the table: "pebble.data_cache" */
  update_pebble_data_cache: pebble_data_cache_mutation_response | null
  /** update single row of the table: "pebble.data_cache" */
  update_pebble_data_cache_by_pk: pebble_data_cache | null
  /** update data of the table: "pebble.device" */
  update_pebble_device: pebble_device_mutation_response | null
  /** update single row of the table: "pebble.device" */
  update_pebble_device_by_pk: pebble_device | null
  /** update data of the table: "pebble.device_record" */
  update_pebble_device_record: pebble_device_record_mutation_response | null
  /** update single row of the table: "pebble.device_record" */
  update_pebble_device_record_by_pk: pebble_device_record | null
  /** update data of the table: "pebble.status" */
  update_pebble_status: pebble_status_mutation_response | null
  /** update single row of the table: "pebble.status" */
  update_pebble_status_by_pk: pebble_status | null
  __typename: 'mutation_root'
}

/** response of any mutation on the table "lonely.area" */
export interface lonely_area_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: lonely_area[]
  __typename: 'lonely_area_mutation_response'
}

/** response of any mutation on the table "lonely.device" */
export interface lonely_device_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: lonely_device[]
  __typename: 'lonely_device_mutation_response'
}

/** response of any mutation on the table "lonely.status" */
export interface lonely_status_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: lonely_status[]
  __typename: 'lonely_status_mutation_response'
}

/** response of any mutation on the table "pebble.account" */
export interface pebble_account_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_account[]
  __typename: 'pebble_account_mutation_response'
}

/** response of any mutation on the table "pebble.app" */
export interface pebble_app_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_app[]
  __typename: 'pebble_app_mutation_response'
}

/** response of any mutation on the table "pebble.app_v2" */
export interface pebble_app_v2_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_app_v2[]
  __typename: 'pebble_app_v2_mutation_response'
}

/** response of any mutation on the table "pebble.bank" */
export interface pebble_bank_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_bank[]
  __typename: 'pebble_bank_mutation_response'
}

/** response of any mutation on the table "pebble.bank_record" */
export interface pebble_bank_record_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_bank_record[]
  __typename: 'pebble_bank_record_mutation_response'
}

/** response of any mutation on the table "pebble.config" */
export interface pebble_config_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_config[]
  __typename: 'pebble_config_mutation_response'
}

/** response of any mutation on the table "pebble.data_cache" */
export interface pebble_data_cache_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_data_cache[]
  __typename: 'pebble_data_cache_mutation_response'
}

/** response of any mutation on the table "pebble.device" */
export interface pebble_device_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_device[]
  __typename: 'pebble_device_mutation_response'
}

/** response of any mutation on the table "pebble.device_record" */
export interface pebble_device_record_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_device_record[]
  __typename: 'pebble_device_record_mutation_response'
}

/** response of any mutation on the table "pebble.status" */
export interface pebble_status_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Int
  /** data from the rows affected by the mutation */
  returning: pebble_status[]
  __typename: 'pebble_status_mutation_response'
}

/** unique or primary key constraints on table "lonely.area" */
export enum lonely_area_constraint {
  /** unique or primary key constraint */
  area_pkey = 'area_pkey',
}

/** update columns of table "lonely.area" */
export enum lonely_area_update_column {
  /** column name */
  count = 'count',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  latitude = 'latitude',
  /** column name */
  longitude = 'longitude',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "lonely.device" */
export enum lonely_device_constraint {
  /** unique or primary key constraint */
  address = 'address',
  /** unique or primary key constraint */
  device_pkey = 'device_pkey',
}

/** update columns of table "lonely.device" */
export enum lonely_device_update_column {
  /** column name */
  address = 'address',
  /** column name */
  area = 'area',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  last_report_time = 'last_report_time',
  /** column name */
  latitude = 'latitude',
  /** column name */
  location = 'location',
  /** column name */
  longitude = 'longitude',
  /** column name */
  owner = 'owner',
  /** column name */
  to_be_claimed = 'to_be_claimed',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "lonely.status" */
export enum lonely_status_constraint {
  /** unique or primary key constraint */
  status_pkey = 'status_pkey',
}

/** update columns of table "lonely.status" */
export enum lonely_status_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

/** unique or primary key constraints on table "pebble.account" */
export enum pebble_account_constraint {
  /** unique or primary key constraint */
  account_pkey = 'account_pkey',
}

/** update columns of table "pebble.account" */
export enum pebble_account_update_column {
  /** column name */
  avatar = 'avatar',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "pebble.app" */
export enum pebble_app_constraint {
  /** unique or primary key constraint */
  app_pkey = 'app_pkey',
}

/** update columns of table "pebble.app" */
export enum pebble_app_update_column {
  /** column name */
  avatar = 'avatar',
  /** column name */
  content = 'content',
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  uri = 'uri',
  /** column name */
  version = 'version',
}

/** unique or primary key constraints on table "pebble.app_v2" */
export enum pebble_app_v2_constraint {
  /** unique or primary key constraint */
  app_meta_pkey = 'app_meta_pkey',
}

/** update columns of table "pebble.app_v2" */
export enum pebble_app_v2_update_column {
  /** column name */
  author = 'author',
  /** column name */
  category = 'category',
  /** column name */
  content = 'content',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  date = 'date',
  /** column name */
  direct_link = 'direct_link',
  /** column name */
  firmware = 'firmware',
  /** column name */
  id = 'id',
  /** column name */
  logo = 'logo',
  /** column name */
  order = 'order',
  /** column name */
  previews = 'previews',
  /** column name */
  slug = 'slug',
  /** column name */
  status = 'status',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  uri = 'uri',
}

/** unique or primary key constraints on table "pebble.bank" */
export enum pebble_bank_constraint {
  /** unique or primary key constraint */
  bank_pkey = 'bank_pkey',
}

/** update columns of table "pebble.bank" */
export enum pebble_bank_update_column {
  /** column name */
  address = 'address',
  /** column name */
  balance = 'balance',
  /** column name */
  created_at = 'created_at',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "pebble.bank_record" */
export enum pebble_bank_record_constraint {
  /** unique or primary key constraint */
  bank_record_pkey = 'bank_record_pkey',
}

/** update columns of table "pebble.bank_record" */
export enum pebble_bank_record_update_column {
  /** column name */
  amount = 'amount',
  /** column name */
  created_at = 'created_at',
  /** column name */
  from = 'from',
  /** column name */
  id = 'id',
  /** column name */
  timestamp = 'timestamp',
  /** column name */
  to = 'to',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "pebble.config" */
export enum pebble_config_constraint {
  /** unique or primary key constraint */
  config_pkey = 'config_pkey',
}

/** update columns of table "pebble.config" */
export enum pebble_config_update_column {
  /** column name */
  avatar = 'avatar',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  uri = 'uri',
  /** column name */
  version = 'version',
}

/** unique or primary key constraints on table "pebble.data_cache" */
export enum pebble_data_cache_constraint {
  /** unique or primary key constraint */
  data_cache_pkey = 'data_cache_pkey',
}

/** update columns of table "pebble.data_cache" */
export enum pebble_data_cache_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  data = 'data',
  /** column name */
  id = 'id',
  /** column name */
  imei = 'imei',
  /** column name */
  signature = 'signature',
  /** column name */
  timestamp = 'timestamp',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
}

/** unique or primary key constraints on table "pebble.device_record" */
export enum pebble_device_record_constraint {
  /** unique or primary key constraint */
  device_record_pkey = 'device_record_pkey',
}

/** update columns of table "pebble.device_record" */
export enum pebble_device_record_update_column {
  /** column name */
  accelerometer = 'accelerometer',
  /** column name */
  created_at = 'created_at',
  /** column name */
  gas_resistance = 'gas_resistance',
  /** column name */
  gyroscope = 'gyroscope',
  /** column name */
  hash = 'hash',
  /** column name */
  humidity = 'humidity',
  /** column name */
  id = 'id',
  /** column name */
  imei = 'imei',
  /** column name */
  latitude = 'latitude',
  /** column name */
  light = 'light',
  /** column name */
  longitude = 'longitude',
  /** column name */
  operator = 'operator',
  /** column name */
  pressure = 'pressure',
  /** column name */
  signature = 'signature',
  /** column name */
  snr = 'snr',
  /** column name */
  temperature = 'temperature',
  /** column name */
  temperature2 = 'temperature2',
  /** column name */
  timestamp = 'timestamp',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  vbat = 'vbat',
}

/** unique or primary key constraints on table "pebble.device" */
export enum pebble_device_constraint {
  /** unique or primary key constraint */
  address = 'address',
  /** unique or primary key constraint */
  device_pkey = 'device_pkey',
}

/** update columns of table "pebble.device" */
export enum pebble_device_update_column {
  /** column name */
  address = 'address',
  /** column name */
  avatar = 'avatar',
  /** column name */
  beep = 'beep',
  /** column name */
  bulk_upload = 'bulk_upload',
  /** column name */
  bulk_upload_sampling_cnt = 'bulk_upload_sampling_cnt',
  /** column name */
  bulk_upload_sampling_freq = 'bulk_upload_sampling_freq',
  /** column name */
  config = 'config',
  /** column name */
  created_at = 'created_at',
  /** column name */
  data_channel = 'data_channel',
  /** column name */
  firmware = 'firmware',
  /** column name */
  id = 'id',
  /** column name */
  name = 'name',
  /** column name */
  owner = 'owner',
  /** column name */
  proposer = 'proposer',
  /** column name */
  real_firmware = 'real_firmware',
  /** column name */
  state = 'state',
  /** column name */
  status = 'status',
  /** column name */
  total_gas = 'total_gas',
  /** column name */
  type = 'type',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  upload_period = 'upload_period',
}

/** unique or primary key constraints on table "pebble.status" */
export enum pebble_status_constraint {
  /** unique or primary key constraint */
  status_pkey = 'status_pkey',
}

/** update columns of table "pebble.status" */
export enum pebble_status_update_column {
  /** column name */
  created_at = 'created_at',
  /** column name */
  id = 'id',
  /** column name */
  updated_at = 'updated_at',
  /** column name */
  value = 'value',
}

export interface subscription_root {
  /** fetch data from the table: "lonely.area" */
  lonely_area: lonely_area[]
  /** fetch aggregated fields from the table: "lonely.area" */
  lonely_area_aggregate: lonely_area_aggregate
  /** fetch data from the table: "lonely.area" using primary key columns */
  lonely_area_by_pk: lonely_area | null
  /** fetch data from the table: "lonely.device" */
  lonely_device: lonely_device[]
  /** fetch aggregated fields from the table: "lonely.device" */
  lonely_device_aggregate: lonely_device_aggregate
  /** fetch data from the table: "lonely.device" using primary key columns */
  lonely_device_by_pk: lonely_device | null
  /** fetch data from the table: "lonely.status" */
  lonely_status: lonely_status[]
  /** fetch aggregated fields from the table: "lonely.status" */
  lonely_status_aggregate: lonely_status_aggregate
  /** fetch data from the table: "lonely.status" using primary key columns */
  lonely_status_by_pk: lonely_status | null
  /** fetch data from the table: "pebble.account" */
  pebble_account: pebble_account[]
  /** fetch aggregated fields from the table: "pebble.account" */
  pebble_account_aggregate: pebble_account_aggregate
  /** fetch data from the table: "pebble.account" using primary key columns */
  pebble_account_by_pk: pebble_account | null
  /** fetch data from the table: "pebble.app" */
  pebble_app: pebble_app[]
  /** fetch aggregated fields from the table: "pebble.app" */
  pebble_app_aggregate: pebble_app_aggregate
  /** fetch data from the table: "pebble.app" using primary key columns */
  pebble_app_by_pk: pebble_app | null
  /** fetch data from the table: "pebble.app_v2" */
  pebble_app_v2: pebble_app_v2[]
  /** fetch aggregated fields from the table: "pebble.app_v2" */
  pebble_app_v2_aggregate: pebble_app_v2_aggregate
  /** fetch data from the table: "pebble.app_v2" using primary key columns */
  pebble_app_v2_by_pk: pebble_app_v2 | null
  /** fetch data from the table: "pebble.bank" */
  pebble_bank: pebble_bank[]
  /** fetch aggregated fields from the table: "pebble.bank" */
  pebble_bank_aggregate: pebble_bank_aggregate
  /** fetch data from the table: "pebble.bank" using primary key columns */
  pebble_bank_by_pk: pebble_bank | null
  /** fetch data from the table: "pebble.bank_record" */
  pebble_bank_record: pebble_bank_record[]
  /** fetch aggregated fields from the table: "pebble.bank_record" */
  pebble_bank_record_aggregate: pebble_bank_record_aggregate
  /** fetch data from the table: "pebble.bank_record" using primary key columns */
  pebble_bank_record_by_pk: pebble_bank_record | null
  /** fetch data from the table: "pebble.config" */
  pebble_config: pebble_config[]
  /** fetch aggregated fields from the table: "pebble.config" */
  pebble_config_aggregate: pebble_config_aggregate
  /** fetch data from the table: "pebble.config" using primary key columns */
  pebble_config_by_pk: pebble_config | null
  /** fetch data from the table: "pebble.data_cache" */
  pebble_data_cache: pebble_data_cache[]
  /** fetch aggregated fields from the table: "pebble.data_cache" */
  pebble_data_cache_aggregate: pebble_data_cache_aggregate
  /** fetch data from the table: "pebble.data_cache" using primary key columns */
  pebble_data_cache_by_pk: pebble_data_cache | null
  /** fetch data from the table: "pebble.device" */
  pebble_device: pebble_device[]
  /** fetch aggregated fields from the table: "pebble.device" */
  pebble_device_aggregate: pebble_device_aggregate
  /** fetch data from the table: "pebble.device" using primary key columns */
  pebble_device_by_pk: pebble_device | null
  /** fetch data from the table: "pebble.device_record" */
  pebble_device_record: pebble_device_record[]
  /** fetch aggregated fields from the table: "pebble.device_record" */
  pebble_device_record_aggregate: pebble_device_record_aggregate
  /** fetch data from the table: "pebble.device_record" using primary key columns */
  pebble_device_record_by_pk: pebble_device_record | null
  /** fetch data from the table: "pebble.status" */
  pebble_status: pebble_status[]
  /** fetch aggregated fields from the table: "pebble.status" */
  pebble_status_aggregate: pebble_status_aggregate
  /** fetch data from the table: "pebble.status" using primary key columns */
  pebble_status_by_pk: pebble_status | null
  __typename: 'subscription_root'
}

export interface query_rootRequest {
  /** fetch data from the table: "lonely.area" */
  lonely_area?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_area_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_area_order_by[] | null
          /** filter the rows returned */
          where?: lonely_area_bool_exp | null
        },
        lonely_areaRequest,
      ]
    | lonely_areaRequest
  /** fetch aggregated fields from the table: "lonely.area" */
  lonely_area_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_area_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_area_order_by[] | null
          /** filter the rows returned */
          where?: lonely_area_bool_exp | null
        },
        lonely_area_aggregateRequest,
      ]
    | lonely_area_aggregateRequest
  /** fetch data from the table: "lonely.area" using primary key columns */
  lonely_area_by_pk?: [{ id: String }, lonely_areaRequest]
  /** fetch data from the table: "lonely.device" */
  lonely_device?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_device_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_device_order_by[] | null
          /** filter the rows returned */
          where?: lonely_device_bool_exp | null
        },
        lonely_deviceRequest,
      ]
    | lonely_deviceRequest
  /** fetch aggregated fields from the table: "lonely.device" */
  lonely_device_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_device_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_device_order_by[] | null
          /** filter the rows returned */
          where?: lonely_device_bool_exp | null
        },
        lonely_device_aggregateRequest,
      ]
    | lonely_device_aggregateRequest
  /** fetch data from the table: "lonely.device" using primary key columns */
  lonely_device_by_pk?: [{ id: String }, lonely_deviceRequest]
  /** fetch data from the table: "lonely.status" */
  lonely_status?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_status_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_status_order_by[] | null
          /** filter the rows returned */
          where?: lonely_status_bool_exp | null
        },
        lonely_statusRequest,
      ]
    | lonely_statusRequest
  /** fetch aggregated fields from the table: "lonely.status" */
  lonely_status_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_status_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_status_order_by[] | null
          /** filter the rows returned */
          where?: lonely_status_bool_exp | null
        },
        lonely_status_aggregateRequest,
      ]
    | lonely_status_aggregateRequest
  /** fetch data from the table: "lonely.status" using primary key columns */
  lonely_status_by_pk?: [{ id: Int }, lonely_statusRequest]
  /** fetch data from the table: "pebble.account" */
  pebble_account?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_account_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_account_order_by[] | null
          /** filter the rows returned */
          where?: pebble_account_bool_exp | null
        },
        pebble_accountRequest,
      ]
    | pebble_accountRequest
  /** fetch aggregated fields from the table: "pebble.account" */
  pebble_account_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_account_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_account_order_by[] | null
          /** filter the rows returned */
          where?: pebble_account_bool_exp | null
        },
        pebble_account_aggregateRequest,
      ]
    | pebble_account_aggregateRequest
  /** fetch data from the table: "pebble.account" using primary key columns */
  pebble_account_by_pk?: [{ id: String }, pebble_accountRequest]
  /** fetch data from the table: "pebble.app" */
  pebble_app?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_app_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_app_order_by[] | null
          /** filter the rows returned */
          where?: pebble_app_bool_exp | null
        },
        pebble_appRequest,
      ]
    | pebble_appRequest
  /** fetch aggregated fields from the table: "pebble.app" */
  pebble_app_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_app_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_app_order_by[] | null
          /** filter the rows returned */
          where?: pebble_app_bool_exp | null
        },
        pebble_app_aggregateRequest,
      ]
    | pebble_app_aggregateRequest
  /** fetch data from the table: "pebble.app" using primary key columns */
  pebble_app_by_pk?: [{ id: String }, pebble_appRequest]
  /** fetch data from the table: "pebble.app_v2" */
  pebble_app_v2?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_app_v2_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_app_v2_order_by[] | null
          /** filter the rows returned */
          where?: pebble_app_v2_bool_exp | null
        },
        pebble_app_v2Request,
      ]
    | pebble_app_v2Request
  /** fetch aggregated fields from the table: "pebble.app_v2" */
  pebble_app_v2_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_app_v2_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_app_v2_order_by[] | null
          /** filter the rows returned */
          where?: pebble_app_v2_bool_exp | null
        },
        pebble_app_v2_aggregateRequest,
      ]
    | pebble_app_v2_aggregateRequest
  /** fetch data from the table: "pebble.app_v2" using primary key columns */
  pebble_app_v2_by_pk?: [{ id: String }, pebble_app_v2Request]
  /** fetch data from the table: "pebble.bank" */
  pebble_bank?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_bank_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_bank_order_by[] | null
          /** filter the rows returned */
          where?: pebble_bank_bool_exp | null
        },
        pebble_bankRequest,
      ]
    | pebble_bankRequest
  /** fetch aggregated fields from the table: "pebble.bank" */
  pebble_bank_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_bank_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_bank_order_by[] | null
          /** filter the rows returned */
          where?: pebble_bank_bool_exp | null
        },
        pebble_bank_aggregateRequest,
      ]
    | pebble_bank_aggregateRequest
  /** fetch data from the table: "pebble.bank" using primary key columns */
  pebble_bank_by_pk?: [{ address: String }, pebble_bankRequest]
  /** fetch data from the table: "pebble.bank_record" */
  pebble_bank_record?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_bank_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_bank_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_bank_record_bool_exp | null
        },
        pebble_bank_recordRequest,
      ]
    | pebble_bank_recordRequest
  /** fetch aggregated fields from the table: "pebble.bank_record" */
  pebble_bank_record_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_bank_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_bank_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_bank_record_bool_exp | null
        },
        pebble_bank_record_aggregateRequest,
      ]
    | pebble_bank_record_aggregateRequest
  /** fetch data from the table: "pebble.bank_record" using primary key columns */
  pebble_bank_record_by_pk?: [{ id: String }, pebble_bank_recordRequest]
  /** fetch data from the table: "pebble.config" */
  pebble_config?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_config_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_config_order_by[] | null
          /** filter the rows returned */
          where?: pebble_config_bool_exp | null
        },
        pebble_configRequest,
      ]
    | pebble_configRequest
  /** fetch aggregated fields from the table: "pebble.config" */
  pebble_config_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_config_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_config_order_by[] | null
          /** filter the rows returned */
          where?: pebble_config_bool_exp | null
        },
        pebble_config_aggregateRequest,
      ]
    | pebble_config_aggregateRequest
  /** fetch data from the table: "pebble.config" using primary key columns */
  pebble_config_by_pk?: [{ id: String }, pebble_configRequest]
  /** fetch data from the table: "pebble.data_cache" */
  pebble_data_cache?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_data_cache_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_data_cache_order_by[] | null
          /** filter the rows returned */
          where?: pebble_data_cache_bool_exp | null
        },
        pebble_data_cacheRequest,
      ]
    | pebble_data_cacheRequest
  /** fetch aggregated fields from the table: "pebble.data_cache" */
  pebble_data_cache_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_data_cache_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_data_cache_order_by[] | null
          /** filter the rows returned */
          where?: pebble_data_cache_bool_exp | null
        },
        pebble_data_cache_aggregateRequest,
      ]
    | pebble_data_cache_aggregateRequest
  /** fetch data from the table: "pebble.data_cache" using primary key columns */
  pebble_data_cache_by_pk?: [{ id: Int }, pebble_data_cacheRequest]
  /** fetch data from the table: "pebble.device" */
  pebble_device?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_bool_exp | null
        },
        pebble_deviceRequest,
      ]
    | pebble_deviceRequest
  /** fetch aggregated fields from the table: "pebble.device" */
  pebble_device_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_bool_exp | null
        },
        pebble_device_aggregateRequest,
      ]
    | pebble_device_aggregateRequest
  /** fetch data from the table: "pebble.device" using primary key columns */
  pebble_device_by_pk?: [{ id: String }, pebble_deviceRequest]
  /** fetch data from the table: "pebble.device_record" */
  pebble_device_record?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_record_bool_exp | null
        },
        pebble_device_recordRequest,
      ]
    | pebble_device_recordRequest
  /** fetch aggregated fields from the table: "pebble.device_record" */
  pebble_device_record_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_record_bool_exp | null
        },
        pebble_device_record_aggregateRequest,
      ]
    | pebble_device_record_aggregateRequest
  /** fetch data from the table: "pebble.device_record" using primary key columns */
  pebble_device_record_by_pk?: [{ id: String }, pebble_device_recordRequest]
  /** fetch data from the table: "pebble.status" */
  pebble_status?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_status_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_status_order_by[] | null
          /** filter the rows returned */
          where?: pebble_status_bool_exp | null
        },
        pebble_statusRequest,
      ]
    | pebble_statusRequest
  /** fetch aggregated fields from the table: "pebble.status" */
  pebble_status_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_status_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_status_order_by[] | null
          /** filter the rows returned */
          where?: pebble_status_bool_exp | null
        },
        pebble_status_aggregateRequest,
      ]
    | pebble_status_aggregateRequest
  /** fetch data from the table: "pebble.status" using primary key columns */
  pebble_status_by_pk?: [{ id: Int }, pebble_statusRequest]
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "lonely.area". */
export interface lonely_area_order_by {
  count?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  latitude?: order_by | null
  longitude?: order_by | null
  updated_at?: order_by | null
}

/** Boolean expression to filter rows from the table "lonely.area". All fields are combined with a logical 'AND'. */
export interface lonely_area_bool_exp {
  _and?: lonely_area_bool_exp[] | null
  _not?: lonely_area_bool_exp | null
  _or?: lonely_area_bool_exp[] | null
  count?: Int_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  id?: String_comparison_exp | null
  latitude?: numeric_comparison_exp | null
  longitude?: numeric_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface Int_comparison_exp {
  _eq?: Int | null
  _gt?: Int | null
  _gte?: Int | null
  _in?: Int[] | null
  _is_null?: Boolean | null
  _lt?: Int | null
  _lte?: Int | null
  _neq?: Int | null
  _nin?: Int[] | null
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface timestamptz_comparison_exp {
  _eq?: timestamptz | null
  _gt?: timestamptz | null
  _gte?: timestamptz | null
  _in?: timestamptz[] | null
  _is_null?: Boolean | null
  _lt?: timestamptz | null
  _lte?: timestamptz | null
  _neq?: timestamptz | null
  _nin?: timestamptz[] | null
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_comparison_exp {
  _eq?: String | null
  _gt?: String | null
  _gte?: String | null
  /** does the column match the given case-insensitive pattern */
  _ilike?: String | null
  _in?: String[] | null
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: String | null
  _is_null?: Boolean | null
  /** does the column match the given pattern */
  _like?: String | null
  _lt?: String | null
  _lte?: String | null
  _neq?: String | null
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: String | null
  _nin?: String[] | null
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: String | null
  /** does the column NOT match the given pattern */
  _nlike?: String | null
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: String | null
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: String | null
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: String | null
  /** does the column match the given SQL regular expression */
  _similar?: String | null
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface numeric_comparison_exp {
  _eq?: numeric | null
  _gt?: numeric | null
  _gte?: numeric | null
  _in?: numeric[] | null
  _is_null?: Boolean | null
  _lt?: numeric | null
  _lte?: numeric | null
  _neq?: numeric | null
  _nin?: numeric[] | null
}

/** columns and relationships of "lonely.area" */
export interface lonely_areaRequest {
  count?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "lonely.area" */
export interface lonely_area_aggregateRequest {
  aggregate?: lonely_area_aggregate_fieldsRequest
  nodes?: lonely_areaRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "lonely.area" */
export interface lonely_area_aggregate_fieldsRequest {
  avg?: lonely_area_avg_fieldsRequest
  count?: [{ columns?: lonely_area_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: lonely_area_max_fieldsRequest
  min?: lonely_area_min_fieldsRequest
  stddev?: lonely_area_stddev_fieldsRequest
  stddev_pop?: lonely_area_stddev_pop_fieldsRequest
  stddev_samp?: lonely_area_stddev_samp_fieldsRequest
  sum?: lonely_area_sum_fieldsRequest
  var_pop?: lonely_area_var_pop_fieldsRequest
  var_samp?: lonely_area_var_samp_fieldsRequest
  variance?: lonely_area_variance_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface lonely_area_avg_fieldsRequest {
  count?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface lonely_area_max_fieldsRequest {
  count?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface lonely_area_min_fieldsRequest {
  count?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev on columns */
export interface lonely_area_stddev_fieldsRequest {
  count?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface lonely_area_stddev_pop_fieldsRequest {
  count?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface lonely_area_stddev_samp_fieldsRequest {
  count?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate sum on columns */
export interface lonely_area_sum_fieldsRequest {
  count?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface lonely_area_var_pop_fieldsRequest {
  count?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface lonely_area_var_samp_fieldsRequest {
  count?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface lonely_area_variance_fieldsRequest {
  count?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "lonely.device". */
export interface lonely_device_order_by {
  address?: order_by | null
  area?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  last_report_time?: order_by | null
  latitude?: order_by | null
  location?: order_by | null
  longitude?: order_by | null
  owner?: order_by | null
  to_be_claimed?: order_by | null
  updated_at?: order_by | null
}

/** Boolean expression to filter rows from the table "lonely.device". All fields are combined with a logical 'AND'. */
export interface lonely_device_bool_exp {
  _and?: lonely_device_bool_exp[] | null
  _not?: lonely_device_bool_exp | null
  _or?: lonely_device_bool_exp[] | null
  address?: String_comparison_exp | null
  area?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  id?: String_comparison_exp | null
  last_report_time?: bigint_comparison_exp | null
  latitude?: numeric_comparison_exp | null
  location?: String_comparison_exp | null
  longitude?: numeric_comparison_exp | null
  owner?: String_comparison_exp | null
  to_be_claimed?: Int_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface bigint_comparison_exp {
  _eq?: bigint | null
  _gt?: bigint | null
  _gte?: bigint | null
  _in?: bigint[] | null
  _is_null?: Boolean | null
  _lt?: bigint | null
  _lte?: bigint | null
  _neq?: bigint | null
  _nin?: bigint[] | null
}

/** columns and relationships of "lonely.device" */
export interface lonely_deviceRequest {
  address?: boolean | number
  area?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  last_report_time?: boolean | number
  latitude?: boolean | number
  location?: boolean | number
  longitude?: boolean | number
  owner?: boolean | number
  to_be_claimed?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "lonely.device" */
export interface lonely_device_aggregateRequest {
  aggregate?: lonely_device_aggregate_fieldsRequest
  nodes?: lonely_deviceRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "lonely.device" */
export interface lonely_device_aggregate_fieldsRequest {
  avg?: lonely_device_avg_fieldsRequest
  count?: [{ columns?: lonely_device_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: lonely_device_max_fieldsRequest
  min?: lonely_device_min_fieldsRequest
  stddev?: lonely_device_stddev_fieldsRequest
  stddev_pop?: lonely_device_stddev_pop_fieldsRequest
  stddev_samp?: lonely_device_stddev_samp_fieldsRequest
  sum?: lonely_device_sum_fieldsRequest
  var_pop?: lonely_device_var_pop_fieldsRequest
  var_samp?: lonely_device_var_samp_fieldsRequest
  variance?: lonely_device_variance_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface lonely_device_avg_fieldsRequest {
  last_report_time?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  to_be_claimed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface lonely_device_max_fieldsRequest {
  address?: boolean | number
  area?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  last_report_time?: boolean | number
  latitude?: boolean | number
  location?: boolean | number
  longitude?: boolean | number
  owner?: boolean | number
  to_be_claimed?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface lonely_device_min_fieldsRequest {
  address?: boolean | number
  area?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  last_report_time?: boolean | number
  latitude?: boolean | number
  location?: boolean | number
  longitude?: boolean | number
  owner?: boolean | number
  to_be_claimed?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev on columns */
export interface lonely_device_stddev_fieldsRequest {
  last_report_time?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  to_be_claimed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface lonely_device_stddev_pop_fieldsRequest {
  last_report_time?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  to_be_claimed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface lonely_device_stddev_samp_fieldsRequest {
  last_report_time?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  to_be_claimed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate sum on columns */
export interface lonely_device_sum_fieldsRequest {
  last_report_time?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  to_be_claimed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface lonely_device_var_pop_fieldsRequest {
  last_report_time?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  to_be_claimed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface lonely_device_var_samp_fieldsRequest {
  last_report_time?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  to_be_claimed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface lonely_device_variance_fieldsRequest {
  last_report_time?: boolean | number
  latitude?: boolean | number
  longitude?: boolean | number
  to_be_claimed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "lonely.status". */
export interface lonely_status_order_by {
  created_at?: order_by | null
  id?: order_by | null
  updated_at?: order_by | null
  value?: order_by | null
}

/** Boolean expression to filter rows from the table "lonely.status". All fields are combined with a logical 'AND'. */
export interface lonely_status_bool_exp {
  _and?: lonely_status_bool_exp[] | null
  _not?: lonely_status_bool_exp | null
  _or?: lonely_status_bool_exp[] | null
  created_at?: timestamptz_comparison_exp | null
  id?: Int_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
  value?: Int_comparison_exp | null
}

/** columns and relationships of "lonely.status" */
export interface lonely_statusRequest {
  created_at?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "lonely.status" */
export interface lonely_status_aggregateRequest {
  aggregate?: lonely_status_aggregate_fieldsRequest
  nodes?: lonely_statusRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "lonely.status" */
export interface lonely_status_aggregate_fieldsRequest {
  avg?: lonely_status_avg_fieldsRequest
  count?: [{ columns?: lonely_status_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: lonely_status_max_fieldsRequest
  min?: lonely_status_min_fieldsRequest
  stddev?: lonely_status_stddev_fieldsRequest
  stddev_pop?: lonely_status_stddev_pop_fieldsRequest
  stddev_samp?: lonely_status_stddev_samp_fieldsRequest
  sum?: lonely_status_sum_fieldsRequest
  var_pop?: lonely_status_var_pop_fieldsRequest
  var_samp?: lonely_status_var_samp_fieldsRequest
  variance?: lonely_status_variance_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface lonely_status_avg_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface lonely_status_max_fieldsRequest {
  created_at?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface lonely_status_min_fieldsRequest {
  created_at?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev on columns */
export interface lonely_status_stddev_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface lonely_status_stddev_pop_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface lonely_status_stddev_samp_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate sum on columns */
export interface lonely_status_sum_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface lonely_status_var_pop_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface lonely_status_var_samp_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface lonely_status_variance_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.account". */
export interface pebble_account_order_by {
  avatar?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  name?: order_by | null
  updated_at?: order_by | null
}

/** Boolean expression to filter rows from the table "pebble.account". All fields are combined with a logical 'AND'. */
export interface pebble_account_bool_exp {
  _and?: pebble_account_bool_exp[] | null
  _not?: pebble_account_bool_exp | null
  _or?: pebble_account_bool_exp[] | null
  avatar?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  id?: String_comparison_exp | null
  name?: String_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** columns and relationships of "pebble.account" */
export interface pebble_accountRequest {
  avatar?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  name?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.account" */
export interface pebble_account_aggregateRequest {
  aggregate?: pebble_account_aggregate_fieldsRequest
  nodes?: pebble_accountRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.account" */
export interface pebble_account_aggregate_fieldsRequest {
  count?: [{ columns?: pebble_account_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_account_max_fieldsRequest
  min?: pebble_account_min_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_account_max_fieldsRequest {
  avatar?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  name?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_account_min_fieldsRequest {
  avatar?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  name?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.app". */
export interface pebble_app_order_by {
  avatar?: order_by | null
  content?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  updated_at?: order_by | null
  uri?: order_by | null
  version?: order_by | null
}

/** Boolean expression to filter rows from the table "pebble.app". All fields are combined with a logical 'AND'. */
export interface pebble_app_bool_exp {
  _and?: pebble_app_bool_exp[] | null
  _not?: pebble_app_bool_exp | null
  _or?: pebble_app_bool_exp[] | null
  avatar?: String_comparison_exp | null
  content?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  id?: String_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
  uri?: String_comparison_exp | null
  version?: String_comparison_exp | null
}

/** columns and relationships of "pebble.app" */
export interface pebble_appRequest {
  avatar?: boolean | number
  content?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  uri?: boolean | number
  version?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.app" */
export interface pebble_app_aggregateRequest {
  aggregate?: pebble_app_aggregate_fieldsRequest
  nodes?: pebble_appRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.app" */
export interface pebble_app_aggregate_fieldsRequest {
  count?: [{ columns?: pebble_app_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_app_max_fieldsRequest
  min?: pebble_app_min_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_app_max_fieldsRequest {
  avatar?: boolean | number
  content?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  uri?: boolean | number
  version?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_app_min_fieldsRequest {
  avatar?: boolean | number
  content?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  uri?: boolean | number
  version?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.app_v2". */
export interface pebble_app_v2_order_by {
  author?: order_by | null
  category?: order_by | null
  content?: order_by | null
  created_at?: order_by | null
  data?: order_by | null
  date?: order_by | null
  direct_link?: order_by | null
  firmware?: order_by | null
  id?: order_by | null
  logo?: order_by | null
  order?: order_by | null
  previews?: order_by | null
  slug?: order_by | null
  status?: order_by | null
  updated_at?: order_by | null
  uri?: order_by | null
}

/** Boolean expression to filter rows from the table "pebble.app_v2". All fields are combined with a logical 'AND'. */
export interface pebble_app_v2_bool_exp {
  _and?: pebble_app_v2_bool_exp[] | null
  _not?: pebble_app_v2_bool_exp | null
  _or?: pebble_app_v2_bool_exp[] | null
  author?: String_comparison_exp | null
  category?: Int_comparison_exp | null
  content?: String_comparison_exp | null
  created_at?: date_comparison_exp | null
  data?: jsonb_comparison_exp | null
  date?: date_comparison_exp | null
  direct_link?: String_comparison_exp | null
  firmware?: String_comparison_exp | null
  id?: String_comparison_exp | null
  logo?: String_comparison_exp | null
  order?: Int_comparison_exp | null
  previews?: _text_comparison_exp | null
  slug?: String_comparison_exp | null
  status?: String_comparison_exp | null
  updated_at?: date_comparison_exp | null
  uri?: String_comparison_exp | null
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export interface date_comparison_exp {
  _eq?: date | null
  _gt?: date | null
  _gte?: date | null
  _in?: date[] | null
  _is_null?: Boolean | null
  _lt?: date | null
  _lte?: date | null
  _neq?: date | null
  _nin?: date[] | null
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface jsonb_comparison_exp {
  /** is the column contained in the given json value */
  _contained_in?: jsonb | null
  /** does the column contain the given json value at the top level */
  _contains?: jsonb | null
  _eq?: jsonb | null
  _gt?: jsonb | null
  _gte?: jsonb | null
  /** does the string exist as a top-level key in the column */
  _has_key?: String | null
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: String[] | null
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: String[] | null
  _in?: jsonb[] | null
  _is_null?: Boolean | null
  _lt?: jsonb | null
  _lte?: jsonb | null
  _neq?: jsonb | null
  _nin?: jsonb[] | null
}

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export interface _text_comparison_exp {
  _eq?: _text | null
  _gt?: _text | null
  _gte?: _text | null
  _in?: _text[] | null
  _is_null?: Boolean | null
  _lt?: _text | null
  _lte?: _text | null
  _neq?: _text | null
  _nin?: _text[] | null
}

/** columns and relationships of "pebble.app_v2" */
export interface pebble_app_v2Request {
  author?: boolean | number
  category?: boolean | number
  content?: boolean | number
  created_at?: boolean | number
  data?:
    | [
        {
          /** JSON select path */
          path?: String | null
        },
      ]
    | boolean
    | number
  date?: boolean | number
  direct_link?: boolean | number
  firmware?: boolean | number
  id?: boolean | number
  logo?: boolean | number
  order?: boolean | number
  previews?: boolean | number
  slug?: boolean | number
  status?: boolean | number
  updated_at?: boolean | number
  uri?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.app_v2" */
export interface pebble_app_v2_aggregateRequest {
  aggregate?: pebble_app_v2_aggregate_fieldsRequest
  nodes?: pebble_app_v2Request
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.app_v2" */
export interface pebble_app_v2_aggregate_fieldsRequest {
  avg?: pebble_app_v2_avg_fieldsRequest
  count?: [{ columns?: pebble_app_v2_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_app_v2_max_fieldsRequest
  min?: pebble_app_v2_min_fieldsRequest
  stddev?: pebble_app_v2_stddev_fieldsRequest
  stddev_pop?: pebble_app_v2_stddev_pop_fieldsRequest
  stddev_samp?: pebble_app_v2_stddev_samp_fieldsRequest
  sum?: pebble_app_v2_sum_fieldsRequest
  var_pop?: pebble_app_v2_var_pop_fieldsRequest
  var_samp?: pebble_app_v2_var_samp_fieldsRequest
  variance?: pebble_app_v2_variance_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface pebble_app_v2_avg_fieldsRequest {
  category?: boolean | number
  order?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_app_v2_max_fieldsRequest {
  author?: boolean | number
  category?: boolean | number
  content?: boolean | number
  created_at?: boolean | number
  date?: boolean | number
  direct_link?: boolean | number
  firmware?: boolean | number
  id?: boolean | number
  logo?: boolean | number
  order?: boolean | number
  slug?: boolean | number
  status?: boolean | number
  updated_at?: boolean | number
  uri?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_app_v2_min_fieldsRequest {
  author?: boolean | number
  category?: boolean | number
  content?: boolean | number
  created_at?: boolean | number
  date?: boolean | number
  direct_link?: boolean | number
  firmware?: boolean | number
  id?: boolean | number
  logo?: boolean | number
  order?: boolean | number
  slug?: boolean | number
  status?: boolean | number
  updated_at?: boolean | number
  uri?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev on columns */
export interface pebble_app_v2_stddev_fieldsRequest {
  category?: boolean | number
  order?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface pebble_app_v2_stddev_pop_fieldsRequest {
  category?: boolean | number
  order?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface pebble_app_v2_stddev_samp_fieldsRequest {
  category?: boolean | number
  order?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate sum on columns */
export interface pebble_app_v2_sum_fieldsRequest {
  category?: boolean | number
  order?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface pebble_app_v2_var_pop_fieldsRequest {
  category?: boolean | number
  order?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface pebble_app_v2_var_samp_fieldsRequest {
  category?: boolean | number
  order?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface pebble_app_v2_variance_fieldsRequest {
  category?: boolean | number
  order?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.bank". */
export interface pebble_bank_order_by {
  address?: order_by | null
  balance?: order_by | null
  created_at?: order_by | null
  updated_at?: order_by | null
}

/** Boolean expression to filter rows from the table "pebble.bank". All fields are combined with a logical 'AND'. */
export interface pebble_bank_bool_exp {
  _and?: pebble_bank_bool_exp[] | null
  _not?: pebble_bank_bool_exp | null
  _or?: pebble_bank_bool_exp[] | null
  address?: String_comparison_exp | null
  balance?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** columns and relationships of "pebble.bank" */
export interface pebble_bankRequest {
  address?: boolean | number
  balance?: boolean | number
  created_at?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.bank" */
export interface pebble_bank_aggregateRequest {
  aggregate?: pebble_bank_aggregate_fieldsRequest
  nodes?: pebble_bankRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.bank" */
export interface pebble_bank_aggregate_fieldsRequest {
  count?: [{ columns?: pebble_bank_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_bank_max_fieldsRequest
  min?: pebble_bank_min_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_bank_max_fieldsRequest {
  address?: boolean | number
  balance?: boolean | number
  created_at?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_bank_min_fieldsRequest {
  address?: boolean | number
  balance?: boolean | number
  created_at?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.bank_record". */
export interface pebble_bank_record_order_by {
  amount?: order_by | null
  created_at?: order_by | null
  from?: order_by | null
  id?: order_by | null
  timestamp?: order_by | null
  to?: order_by | null
  type?: order_by | null
  updated_at?: order_by | null
}

/** Boolean expression to filter rows from the table "pebble.bank_record". All fields are combined with a logical 'AND'. */
export interface pebble_bank_record_bool_exp {
  _and?: pebble_bank_record_bool_exp[] | null
  _not?: pebble_bank_record_bool_exp | null
  _or?: pebble_bank_record_bool_exp[] | null
  amount?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  from?: String_comparison_exp | null
  id?: String_comparison_exp | null
  timestamp?: Int_comparison_exp | null
  to?: String_comparison_exp | null
  type?: Int_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** columns and relationships of "pebble.bank_record" */
export interface pebble_bank_recordRequest {
  amount?: boolean | number
  created_at?: boolean | number
  from?: boolean | number
  id?: boolean | number
  timestamp?: boolean | number
  to?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.bank_record" */
export interface pebble_bank_record_aggregateRequest {
  aggregate?: pebble_bank_record_aggregate_fieldsRequest
  nodes?: pebble_bank_recordRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.bank_record" */
export interface pebble_bank_record_aggregate_fieldsRequest {
  avg?: pebble_bank_record_avg_fieldsRequest
  count?: [{ columns?: pebble_bank_record_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_bank_record_max_fieldsRequest
  min?: pebble_bank_record_min_fieldsRequest
  stddev?: pebble_bank_record_stddev_fieldsRequest
  stddev_pop?: pebble_bank_record_stddev_pop_fieldsRequest
  stddev_samp?: pebble_bank_record_stddev_samp_fieldsRequest
  sum?: pebble_bank_record_sum_fieldsRequest
  var_pop?: pebble_bank_record_var_pop_fieldsRequest
  var_samp?: pebble_bank_record_var_samp_fieldsRequest
  variance?: pebble_bank_record_variance_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface pebble_bank_record_avg_fieldsRequest {
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_bank_record_max_fieldsRequest {
  amount?: boolean | number
  created_at?: boolean | number
  from?: boolean | number
  id?: boolean | number
  timestamp?: boolean | number
  to?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_bank_record_min_fieldsRequest {
  amount?: boolean | number
  created_at?: boolean | number
  from?: boolean | number
  id?: boolean | number
  timestamp?: boolean | number
  to?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev on columns */
export interface pebble_bank_record_stddev_fieldsRequest {
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface pebble_bank_record_stddev_pop_fieldsRequest {
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface pebble_bank_record_stddev_samp_fieldsRequest {
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate sum on columns */
export interface pebble_bank_record_sum_fieldsRequest {
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface pebble_bank_record_var_pop_fieldsRequest {
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface pebble_bank_record_var_samp_fieldsRequest {
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface pebble_bank_record_variance_fieldsRequest {
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.config". */
export interface pebble_config_order_by {
  avatar?: order_by | null
  created_at?: order_by | null
  data?: order_by | null
  id?: order_by | null
  updated_at?: order_by | null
  uri?: order_by | null
  version?: order_by | null
}

/** Boolean expression to filter rows from the table "pebble.config". All fields are combined with a logical 'AND'. */
export interface pebble_config_bool_exp {
  _and?: pebble_config_bool_exp[] | null
  _not?: pebble_config_bool_exp | null
  _or?: pebble_config_bool_exp[] | null
  avatar?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  data?: String_comparison_exp | null
  id?: String_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
  uri?: String_comparison_exp | null
  version?: String_comparison_exp | null
}

/** columns and relationships of "pebble.config" */
export interface pebble_configRequest {
  avatar?: boolean | number
  created_at?: boolean | number
  data?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  uri?: boolean | number
  version?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.config" */
export interface pebble_config_aggregateRequest {
  aggregate?: pebble_config_aggregate_fieldsRequest
  nodes?: pebble_configRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.config" */
export interface pebble_config_aggregate_fieldsRequest {
  count?: [{ columns?: pebble_config_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_config_max_fieldsRequest
  min?: pebble_config_min_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_config_max_fieldsRequest {
  avatar?: boolean | number
  created_at?: boolean | number
  data?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  uri?: boolean | number
  version?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_config_min_fieldsRequest {
  avatar?: boolean | number
  created_at?: boolean | number
  data?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  uri?: boolean | number
  version?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.data_cache". */
export interface pebble_data_cache_order_by {
  created_at?: order_by | null
  data?: order_by | null
  id?: order_by | null
  imei?: order_by | null
  signature?: order_by | null
  timestamp?: order_by | null
  type?: order_by | null
  updated_at?: order_by | null
}

/** Boolean expression to filter rows from the table "pebble.data_cache". All fields are combined with a logical 'AND'. */
export interface pebble_data_cache_bool_exp {
  _and?: pebble_data_cache_bool_exp[] | null
  _not?: pebble_data_cache_bool_exp | null
  _or?: pebble_data_cache_bool_exp[] | null
  created_at?: timestamptz_comparison_exp | null
  data?: String_comparison_exp | null
  id?: Int_comparison_exp | null
  imei?: String_comparison_exp | null
  signature?: String_comparison_exp | null
  timestamp?: Int_comparison_exp | null
  type?: Int_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** columns and relationships of "pebble.data_cache" */
export interface pebble_data_cacheRequest {
  created_at?: boolean | number
  data?: boolean | number
  id?: boolean | number
  imei?: boolean | number
  signature?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.data_cache" */
export interface pebble_data_cache_aggregateRequest {
  aggregate?: pebble_data_cache_aggregate_fieldsRequest
  nodes?: pebble_data_cacheRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.data_cache" */
export interface pebble_data_cache_aggregate_fieldsRequest {
  avg?: pebble_data_cache_avg_fieldsRequest
  count?: [{ columns?: pebble_data_cache_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_data_cache_max_fieldsRequest
  min?: pebble_data_cache_min_fieldsRequest
  stddev?: pebble_data_cache_stddev_fieldsRequest
  stddev_pop?: pebble_data_cache_stddev_pop_fieldsRequest
  stddev_samp?: pebble_data_cache_stddev_samp_fieldsRequest
  sum?: pebble_data_cache_sum_fieldsRequest
  var_pop?: pebble_data_cache_var_pop_fieldsRequest
  var_samp?: pebble_data_cache_var_samp_fieldsRequest
  variance?: pebble_data_cache_variance_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface pebble_data_cache_avg_fieldsRequest {
  id?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_data_cache_max_fieldsRequest {
  created_at?: boolean | number
  data?: boolean | number
  id?: boolean | number
  imei?: boolean | number
  signature?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_data_cache_min_fieldsRequest {
  created_at?: boolean | number
  data?: boolean | number
  id?: boolean | number
  imei?: boolean | number
  signature?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev on columns */
export interface pebble_data_cache_stddev_fieldsRequest {
  id?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface pebble_data_cache_stddev_pop_fieldsRequest {
  id?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface pebble_data_cache_stddev_samp_fieldsRequest {
  id?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate sum on columns */
export interface pebble_data_cache_sum_fieldsRequest {
  id?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface pebble_data_cache_var_pop_fieldsRequest {
  id?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface pebble_data_cache_var_samp_fieldsRequest {
  id?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface pebble_data_cache_variance_fieldsRequest {
  id?: boolean | number
  timestamp?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.device". */
export interface pebble_device_order_by {
  address?: order_by | null
  avatar?: order_by | null
  beep?: order_by | null
  bulk_upload?: order_by | null
  bulk_upload_sampling_cnt?: order_by | null
  bulk_upload_sampling_freq?: order_by | null
  config?: order_by | null
  created_at?: order_by | null
  data_channel?: order_by | null
  deviceRecord_aggregate?: pebble_device_record_aggregate_order_by | null
  firmware?: order_by | null
  id?: order_by | null
  name?: order_by | null
  owner?: order_by | null
  proposer?: order_by | null
  real_firmware?: order_by | null
  state?: order_by | null
  status?: order_by | null
  total_gas?: order_by | null
  type?: order_by | null
  updated_at?: order_by | null
  upload_period?: order_by | null
}

/** order by aggregate values of table "pebble.device_record" */
export interface pebble_device_record_aggregate_order_by {
  avg?: pebble_device_record_avg_order_by | null
  count?: order_by | null
  max?: pebble_device_record_max_order_by | null
  min?: pebble_device_record_min_order_by | null
  stddev?: pebble_device_record_stddev_order_by | null
  stddev_pop?: pebble_device_record_stddev_pop_order_by | null
  stddev_samp?: pebble_device_record_stddev_samp_order_by | null
  sum?: pebble_device_record_sum_order_by | null
  var_pop?: pebble_device_record_var_pop_order_by | null
  var_samp?: pebble_device_record_var_samp_order_by | null
  variance?: pebble_device_record_variance_order_by | null
}

/** order by avg() on columns of table "pebble.device_record" */
export interface pebble_device_record_avg_order_by {
  gas_resistance?: order_by | null
  humidity?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  pressure?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  vbat?: order_by | null
}

/** order by max() on columns of table "pebble.device_record" */
export interface pebble_device_record_max_order_by {
  accelerometer?: order_by | null
  created_at?: order_by | null
  gas_resistance?: order_by | null
  gyroscope?: order_by | null
  hash?: order_by | null
  humidity?: order_by | null
  id?: order_by | null
  imei?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  operator?: order_by | null
  pressure?: order_by | null
  signature?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  updated_at?: order_by | null
  vbat?: order_by | null
}

/** order by min() on columns of table "pebble.device_record" */
export interface pebble_device_record_min_order_by {
  accelerometer?: order_by | null
  created_at?: order_by | null
  gas_resistance?: order_by | null
  gyroscope?: order_by | null
  hash?: order_by | null
  humidity?: order_by | null
  id?: order_by | null
  imei?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  operator?: order_by | null
  pressure?: order_by | null
  signature?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  updated_at?: order_by | null
  vbat?: order_by | null
}

/** order by stddev() on columns of table "pebble.device_record" */
export interface pebble_device_record_stddev_order_by {
  gas_resistance?: order_by | null
  humidity?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  pressure?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  vbat?: order_by | null
}

/** order by stddev_pop() on columns of table "pebble.device_record" */
export interface pebble_device_record_stddev_pop_order_by {
  gas_resistance?: order_by | null
  humidity?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  pressure?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  vbat?: order_by | null
}

/** order by stddev_samp() on columns of table "pebble.device_record" */
export interface pebble_device_record_stddev_samp_order_by {
  gas_resistance?: order_by | null
  humidity?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  pressure?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  vbat?: order_by | null
}

/** order by sum() on columns of table "pebble.device_record" */
export interface pebble_device_record_sum_order_by {
  gas_resistance?: order_by | null
  humidity?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  pressure?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  vbat?: order_by | null
}

/** order by var_pop() on columns of table "pebble.device_record" */
export interface pebble_device_record_var_pop_order_by {
  gas_resistance?: order_by | null
  humidity?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  pressure?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  vbat?: order_by | null
}

/** order by var_samp() on columns of table "pebble.device_record" */
export interface pebble_device_record_var_samp_order_by {
  gas_resistance?: order_by | null
  humidity?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  pressure?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  vbat?: order_by | null
}

/** order by variance() on columns of table "pebble.device_record" */
export interface pebble_device_record_variance_order_by {
  gas_resistance?: order_by | null
  humidity?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  pressure?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  vbat?: order_by | null
}

/** Boolean expression to filter rows from the table "pebble.device". All fields are combined with a logical 'AND'. */
export interface pebble_device_bool_exp {
  _and?: pebble_device_bool_exp[] | null
  _not?: pebble_device_bool_exp | null
  _or?: pebble_device_bool_exp[] | null
  address?: String_comparison_exp | null
  avatar?: String_comparison_exp | null
  beep?: Int_comparison_exp | null
  bulk_upload?: Int_comparison_exp | null
  bulk_upload_sampling_cnt?: Int_comparison_exp | null
  bulk_upload_sampling_freq?: Int_comparison_exp | null
  config?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  data_channel?: Int_comparison_exp | null
  deviceRecord?: pebble_device_record_bool_exp | null
  firmware?: String_comparison_exp | null
  id?: String_comparison_exp | null
  name?: String_comparison_exp | null
  owner?: String_comparison_exp | null
  proposer?: String_comparison_exp | null
  real_firmware?: String_comparison_exp | null
  state?: Int_comparison_exp | null
  status?: Int_comparison_exp | null
  total_gas?: numeric_comparison_exp | null
  type?: Int_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
  upload_period?: Int_comparison_exp | null
}

/** Boolean expression to filter rows from the table "pebble.device_record". All fields are combined with a logical 'AND'. */
export interface pebble_device_record_bool_exp {
  _and?: pebble_device_record_bool_exp[] | null
  _not?: pebble_device_record_bool_exp | null
  _or?: pebble_device_record_bool_exp[] | null
  accelerometer?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  gas_resistance?: numeric_comparison_exp | null
  gyroscope?: String_comparison_exp | null
  hash?: String_comparison_exp | null
  humidity?: numeric_comparison_exp | null
  id?: String_comparison_exp | null
  imei?: String_comparison_exp | null
  latitude?: numeric_comparison_exp | null
  light?: numeric_comparison_exp | null
  longitude?: numeric_comparison_exp | null
  operator?: String_comparison_exp | null
  pressure?: numeric_comparison_exp | null
  signature?: String_comparison_exp | null
  snr?: numeric_comparison_exp | null
  temperature?: numeric_comparison_exp | null
  temperature2?: numeric_comparison_exp | null
  timestamp?: Int_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
  vbat?: numeric_comparison_exp | null
}

/** columns and relationships of "pebble.device" */
export interface pebble_deviceRequest {
  address?: boolean | number
  avatar?: boolean | number
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  config?: boolean | number
  created_at?: boolean | number
  data_channel?: boolean | number
  /** An array relationship */
  deviceRecord?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_record_bool_exp | null
        },
        pebble_device_recordRequest,
      ]
    | pebble_device_recordRequest
  /** An aggregate relationship */
  deviceRecord_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_record_bool_exp | null
        },
        pebble_device_record_aggregateRequest,
      ]
    | pebble_device_record_aggregateRequest
  firmware?: boolean | number
  id?: boolean | number
  name?: boolean | number
  owner?: boolean | number
  proposer?: boolean | number
  real_firmware?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.device_record". */
export interface pebble_device_record_order_by {
  accelerometer?: order_by | null
  created_at?: order_by | null
  gas_resistance?: order_by | null
  gyroscope?: order_by | null
  hash?: order_by | null
  humidity?: order_by | null
  id?: order_by | null
  imei?: order_by | null
  latitude?: order_by | null
  light?: order_by | null
  longitude?: order_by | null
  operator?: order_by | null
  pressure?: order_by | null
  signature?: order_by | null
  snr?: order_by | null
  temperature?: order_by | null
  temperature2?: order_by | null
  timestamp?: order_by | null
  updated_at?: order_by | null
  vbat?: order_by | null
}

/** columns and relationships of "pebble.device_record" */
export interface pebble_device_recordRequest {
  accelerometer?: boolean | number
  created_at?: boolean | number
  gas_resistance?: boolean | number
  gyroscope?: boolean | number
  hash?: boolean | number
  humidity?: boolean | number
  id?: boolean | number
  imei?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  operator?: boolean | number
  pressure?: boolean | number
  signature?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  updated_at?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.device_record" */
export interface pebble_device_record_aggregateRequest {
  aggregate?: pebble_device_record_aggregate_fieldsRequest
  nodes?: pebble_device_recordRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.device_record" */
export interface pebble_device_record_aggregate_fieldsRequest {
  avg?: pebble_device_record_avg_fieldsRequest
  count?: [{ columns?: pebble_device_record_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_device_record_max_fieldsRequest
  min?: pebble_device_record_min_fieldsRequest
  stddev?: pebble_device_record_stddev_fieldsRequest
  stddev_pop?: pebble_device_record_stddev_pop_fieldsRequest
  stddev_samp?: pebble_device_record_stddev_samp_fieldsRequest
  sum?: pebble_device_record_sum_fieldsRequest
  var_pop?: pebble_device_record_var_pop_fieldsRequest
  var_samp?: pebble_device_record_var_samp_fieldsRequest
  variance?: pebble_device_record_variance_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface pebble_device_record_avg_fieldsRequest {
  gas_resistance?: boolean | number
  humidity?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  pressure?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_device_record_max_fieldsRequest {
  accelerometer?: boolean | number
  created_at?: boolean | number
  gas_resistance?: boolean | number
  gyroscope?: boolean | number
  hash?: boolean | number
  humidity?: boolean | number
  id?: boolean | number
  imei?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  operator?: boolean | number
  pressure?: boolean | number
  signature?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  updated_at?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_device_record_min_fieldsRequest {
  accelerometer?: boolean | number
  created_at?: boolean | number
  gas_resistance?: boolean | number
  gyroscope?: boolean | number
  hash?: boolean | number
  humidity?: boolean | number
  id?: boolean | number
  imei?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  operator?: boolean | number
  pressure?: boolean | number
  signature?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  updated_at?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev on columns */
export interface pebble_device_record_stddev_fieldsRequest {
  gas_resistance?: boolean | number
  humidity?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  pressure?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface pebble_device_record_stddev_pop_fieldsRequest {
  gas_resistance?: boolean | number
  humidity?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  pressure?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface pebble_device_record_stddev_samp_fieldsRequest {
  gas_resistance?: boolean | number
  humidity?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  pressure?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate sum on columns */
export interface pebble_device_record_sum_fieldsRequest {
  gas_resistance?: boolean | number
  humidity?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  pressure?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface pebble_device_record_var_pop_fieldsRequest {
  gas_resistance?: boolean | number
  humidity?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  pressure?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface pebble_device_record_var_samp_fieldsRequest {
  gas_resistance?: boolean | number
  humidity?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  pressure?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface pebble_device_record_variance_fieldsRequest {
  gas_resistance?: boolean | number
  humidity?: boolean | number
  latitude?: boolean | number
  light?: boolean | number
  longitude?: boolean | number
  pressure?: boolean | number
  snr?: boolean | number
  temperature?: boolean | number
  temperature2?: boolean | number
  timestamp?: boolean | number
  vbat?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.device" */
export interface pebble_device_aggregateRequest {
  aggregate?: pebble_device_aggregate_fieldsRequest
  nodes?: pebble_deviceRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.device" */
export interface pebble_device_aggregate_fieldsRequest {
  avg?: pebble_device_avg_fieldsRequest
  count?: [{ columns?: pebble_device_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_device_max_fieldsRequest
  min?: pebble_device_min_fieldsRequest
  stddev?: pebble_device_stddev_fieldsRequest
  stddev_pop?: pebble_device_stddev_pop_fieldsRequest
  stddev_samp?: pebble_device_stddev_samp_fieldsRequest
  sum?: pebble_device_sum_fieldsRequest
  var_pop?: pebble_device_var_pop_fieldsRequest
  var_samp?: pebble_device_var_samp_fieldsRequest
  variance?: pebble_device_variance_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface pebble_device_avg_fieldsRequest {
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  data_channel?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_device_max_fieldsRequest {
  address?: boolean | number
  avatar?: boolean | number
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  config?: boolean | number
  created_at?: boolean | number
  data_channel?: boolean | number
  firmware?: boolean | number
  id?: boolean | number
  name?: boolean | number
  owner?: boolean | number
  proposer?: boolean | number
  real_firmware?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_device_min_fieldsRequest {
  address?: boolean | number
  avatar?: boolean | number
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  config?: boolean | number
  created_at?: boolean | number
  data_channel?: boolean | number
  firmware?: boolean | number
  id?: boolean | number
  name?: boolean | number
  owner?: boolean | number
  proposer?: boolean | number
  real_firmware?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev on columns */
export interface pebble_device_stddev_fieldsRequest {
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  data_channel?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface pebble_device_stddev_pop_fieldsRequest {
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  data_channel?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface pebble_device_stddev_samp_fieldsRequest {
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  data_channel?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate sum on columns */
export interface pebble_device_sum_fieldsRequest {
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  data_channel?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface pebble_device_var_pop_fieldsRequest {
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  data_channel?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface pebble_device_var_samp_fieldsRequest {
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  data_channel?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface pebble_device_variance_fieldsRequest {
  beep?: boolean | number
  bulk_upload?: boolean | number
  bulk_upload_sampling_cnt?: boolean | number
  bulk_upload_sampling_freq?: boolean | number
  data_channel?: boolean | number
  state?: boolean | number
  status?: boolean | number
  total_gas?: boolean | number
  type?: boolean | number
  upload_period?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "pebble.status". */
export interface pebble_status_order_by {
  created_at?: order_by | null
  id?: order_by | null
  updated_at?: order_by | null
  value?: order_by | null
}

/** Boolean expression to filter rows from the table "pebble.status". All fields are combined with a logical 'AND'. */
export interface pebble_status_bool_exp {
  _and?: pebble_status_bool_exp[] | null
  _not?: pebble_status_bool_exp | null
  _or?: pebble_status_bool_exp[] | null
  created_at?: timestamptz_comparison_exp | null
  id?: Int_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
  value?: Int_comparison_exp | null
}

/** columns and relationships of "pebble.status" */
export interface pebble_statusRequest {
  created_at?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "pebble.status" */
export interface pebble_status_aggregateRequest {
  aggregate?: pebble_status_aggregate_fieldsRequest
  nodes?: pebble_statusRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "pebble.status" */
export interface pebble_status_aggregate_fieldsRequest {
  avg?: pebble_status_avg_fieldsRequest
  count?: [{ columns?: pebble_status_select_column[] | null; distinct?: Boolean | null }] | boolean | number
  max?: pebble_status_max_fieldsRequest
  min?: pebble_status_min_fieldsRequest
  stddev?: pebble_status_stddev_fieldsRequest
  stddev_pop?: pebble_status_stddev_pop_fieldsRequest
  stddev_samp?: pebble_status_stddev_samp_fieldsRequest
  sum?: pebble_status_sum_fieldsRequest
  var_pop?: pebble_status_var_pop_fieldsRequest
  var_samp?: pebble_status_var_samp_fieldsRequest
  variance?: pebble_status_variance_fieldsRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface pebble_status_avg_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate max on columns */
export interface pebble_status_max_fieldsRequest {
  created_at?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface pebble_status_min_fieldsRequest {
  created_at?: boolean | number
  id?: boolean | number
  updated_at?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev on columns */
export interface pebble_status_stddev_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface pebble_status_stddev_pop_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface pebble_status_stddev_samp_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate sum on columns */
export interface pebble_status_sum_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface pebble_status_var_pop_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface pebble_status_var_samp_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface pebble_status_variance_fieldsRequest {
  id?: boolean | number
  value?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** mutation root */
export interface mutation_rootRequest {
  /** delete data from the table: "lonely.area" */
  delete_lonely_area?: [
    {
      /** filter the rows which have to be deleted */
      where: lonely_area_bool_exp
    },
    lonely_area_mutation_responseRequest,
  ]
  /** delete single row from the table: "lonely.area" */
  delete_lonely_area_by_pk?: [{ id: String }, lonely_areaRequest]
  /** delete data from the table: "lonely.device" */
  delete_lonely_device?: [
    {
      /** filter the rows which have to be deleted */
      where: lonely_device_bool_exp
    },
    lonely_device_mutation_responseRequest,
  ]
  /** delete single row from the table: "lonely.device" */
  delete_lonely_device_by_pk?: [{ id: String }, lonely_deviceRequest]
  /** delete data from the table: "lonely.status" */
  delete_lonely_status?: [
    {
      /** filter the rows which have to be deleted */
      where: lonely_status_bool_exp
    },
    lonely_status_mutation_responseRequest,
  ]
  /** delete single row from the table: "lonely.status" */
  delete_lonely_status_by_pk?: [{ id: Int }, lonely_statusRequest]
  /** delete data from the table: "pebble.account" */
  delete_pebble_account?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_account_bool_exp
    },
    pebble_account_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.account" */
  delete_pebble_account_by_pk?: [{ id: String }, pebble_accountRequest]
  /** delete data from the table: "pebble.app" */
  delete_pebble_app?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_app_bool_exp
    },
    pebble_app_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.app" */
  delete_pebble_app_by_pk?: [{ id: String }, pebble_appRequest]
  /** delete data from the table: "pebble.app_v2" */
  delete_pebble_app_v2?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_app_v2_bool_exp
    },
    pebble_app_v2_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.app_v2" */
  delete_pebble_app_v2_by_pk?: [{ id: String }, pebble_app_v2Request]
  /** delete data from the table: "pebble.bank" */
  delete_pebble_bank?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_bank_bool_exp
    },
    pebble_bank_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.bank" */
  delete_pebble_bank_by_pk?: [{ address: String }, pebble_bankRequest]
  /** delete data from the table: "pebble.bank_record" */
  delete_pebble_bank_record?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_bank_record_bool_exp
    },
    pebble_bank_record_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.bank_record" */
  delete_pebble_bank_record_by_pk?: [{ id: String }, pebble_bank_recordRequest]
  /** delete data from the table: "pebble.config" */
  delete_pebble_config?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_config_bool_exp
    },
    pebble_config_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.config" */
  delete_pebble_config_by_pk?: [{ id: String }, pebble_configRequest]
  /** delete data from the table: "pebble.data_cache" */
  delete_pebble_data_cache?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_data_cache_bool_exp
    },
    pebble_data_cache_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.data_cache" */
  delete_pebble_data_cache_by_pk?: [{ id: Int }, pebble_data_cacheRequest]
  /** delete data from the table: "pebble.device" */
  delete_pebble_device?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_device_bool_exp
    },
    pebble_device_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.device" */
  delete_pebble_device_by_pk?: [{ id: String }, pebble_deviceRequest]
  /** delete data from the table: "pebble.device_record" */
  delete_pebble_device_record?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_device_record_bool_exp
    },
    pebble_device_record_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.device_record" */
  delete_pebble_device_record_by_pk?: [{ id: String }, pebble_device_recordRequest]
  /** delete data from the table: "pebble.status" */
  delete_pebble_status?: [
    {
      /** filter the rows which have to be deleted */
      where: pebble_status_bool_exp
    },
    pebble_status_mutation_responseRequest,
  ]
  /** delete single row from the table: "pebble.status" */
  delete_pebble_status_by_pk?: [{ id: Int }, pebble_statusRequest]
  /** insert data into the table: "lonely.area" */
  insert_lonely_area?: [
    {
      /** the rows to be inserted */
      objects: lonely_area_insert_input[]
      /** on conflict condition */
      on_conflict?: lonely_area_on_conflict | null
    },
    lonely_area_mutation_responseRequest,
  ]
  /** insert a single row into the table: "lonely.area" */
  insert_lonely_area_one?: [
    {
      /** the row to be inserted */
      object: lonely_area_insert_input
      /** on conflict condition */
      on_conflict?: lonely_area_on_conflict | null
    },
    lonely_areaRequest,
  ]
  /** insert data into the table: "lonely.device" */
  insert_lonely_device?: [
    {
      /** the rows to be inserted */
      objects: lonely_device_insert_input[]
      /** on conflict condition */
      on_conflict?: lonely_device_on_conflict | null
    },
    lonely_device_mutation_responseRequest,
  ]
  /** insert a single row into the table: "lonely.device" */
  insert_lonely_device_one?: [
    {
      /** the row to be inserted */
      object: lonely_device_insert_input
      /** on conflict condition */
      on_conflict?: lonely_device_on_conflict | null
    },
    lonely_deviceRequest,
  ]
  /** insert data into the table: "lonely.status" */
  insert_lonely_status?: [
    {
      /** the rows to be inserted */
      objects: lonely_status_insert_input[]
      /** on conflict condition */
      on_conflict?: lonely_status_on_conflict | null
    },
    lonely_status_mutation_responseRequest,
  ]
  /** insert a single row into the table: "lonely.status" */
  insert_lonely_status_one?: [
    {
      /** the row to be inserted */
      object: lonely_status_insert_input
      /** on conflict condition */
      on_conflict?: lonely_status_on_conflict | null
    },
    lonely_statusRequest,
  ]
  /** insert data into the table: "pebble.account" */
  insert_pebble_account?: [
    {
      /** the rows to be inserted */
      objects: pebble_account_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_account_on_conflict | null
    },
    pebble_account_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.account" */
  insert_pebble_account_one?: [
    {
      /** the row to be inserted */
      object: pebble_account_insert_input
      /** on conflict condition */
      on_conflict?: pebble_account_on_conflict | null
    },
    pebble_accountRequest,
  ]
  /** insert data into the table: "pebble.app" */
  insert_pebble_app?: [
    {
      /** the rows to be inserted */
      objects: pebble_app_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_app_on_conflict | null
    },
    pebble_app_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.app" */
  insert_pebble_app_one?: [
    {
      /** the row to be inserted */
      object: pebble_app_insert_input
      /** on conflict condition */
      on_conflict?: pebble_app_on_conflict | null
    },
    pebble_appRequest,
  ]
  /** insert data into the table: "pebble.app_v2" */
  insert_pebble_app_v2?: [
    {
      /** the rows to be inserted */
      objects: pebble_app_v2_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_app_v2_on_conflict | null
    },
    pebble_app_v2_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.app_v2" */
  insert_pebble_app_v2_one?: [
    {
      /** the row to be inserted */
      object: pebble_app_v2_insert_input
      /** on conflict condition */
      on_conflict?: pebble_app_v2_on_conflict | null
    },
    pebble_app_v2Request,
  ]
  /** insert data into the table: "pebble.bank" */
  insert_pebble_bank?: [
    {
      /** the rows to be inserted */
      objects: pebble_bank_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_bank_on_conflict | null
    },
    pebble_bank_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.bank" */
  insert_pebble_bank_one?: [
    {
      /** the row to be inserted */
      object: pebble_bank_insert_input
      /** on conflict condition */
      on_conflict?: pebble_bank_on_conflict | null
    },
    pebble_bankRequest,
  ]
  /** insert data into the table: "pebble.bank_record" */
  insert_pebble_bank_record?: [
    {
      /** the rows to be inserted */
      objects: pebble_bank_record_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_bank_record_on_conflict | null
    },
    pebble_bank_record_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.bank_record" */
  insert_pebble_bank_record_one?: [
    {
      /** the row to be inserted */
      object: pebble_bank_record_insert_input
      /** on conflict condition */
      on_conflict?: pebble_bank_record_on_conflict | null
    },
    pebble_bank_recordRequest,
  ]
  /** insert data into the table: "pebble.config" */
  insert_pebble_config?: [
    {
      /** the rows to be inserted */
      objects: pebble_config_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_config_on_conflict | null
    },
    pebble_config_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.config" */
  insert_pebble_config_one?: [
    {
      /** the row to be inserted */
      object: pebble_config_insert_input
      /** on conflict condition */
      on_conflict?: pebble_config_on_conflict | null
    },
    pebble_configRequest,
  ]
  /** insert data into the table: "pebble.data_cache" */
  insert_pebble_data_cache?: [
    {
      /** the rows to be inserted */
      objects: pebble_data_cache_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_data_cache_on_conflict | null
    },
    pebble_data_cache_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.data_cache" */
  insert_pebble_data_cache_one?: [
    {
      /** the row to be inserted */
      object: pebble_data_cache_insert_input
      /** on conflict condition */
      on_conflict?: pebble_data_cache_on_conflict | null
    },
    pebble_data_cacheRequest,
  ]
  /** insert data into the table: "pebble.device" */
  insert_pebble_device?: [
    {
      /** the rows to be inserted */
      objects: pebble_device_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_device_on_conflict | null
    },
    pebble_device_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.device" */
  insert_pebble_device_one?: [
    {
      /** the row to be inserted */
      object: pebble_device_insert_input
      /** on conflict condition */
      on_conflict?: pebble_device_on_conflict | null
    },
    pebble_deviceRequest,
  ]
  /** insert data into the table: "pebble.device_record" */
  insert_pebble_device_record?: [
    {
      /** the rows to be inserted */
      objects: pebble_device_record_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_device_record_on_conflict | null
    },
    pebble_device_record_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.device_record" */
  insert_pebble_device_record_one?: [
    {
      /** the row to be inserted */
      object: pebble_device_record_insert_input
      /** on conflict condition */
      on_conflict?: pebble_device_record_on_conflict | null
    },
    pebble_device_recordRequest,
  ]
  /** insert data into the table: "pebble.status" */
  insert_pebble_status?: [
    {
      /** the rows to be inserted */
      objects: pebble_status_insert_input[]
      /** on conflict condition */
      on_conflict?: pebble_status_on_conflict | null
    },
    pebble_status_mutation_responseRequest,
  ]
  /** insert a single row into the table: "pebble.status" */
  insert_pebble_status_one?: [
    {
      /** the row to be inserted */
      object: pebble_status_insert_input
      /** on conflict condition */
      on_conflict?: pebble_status_on_conflict | null
    },
    pebble_statusRequest,
  ]
  /** update data of the table: "lonely.area" */
  update_lonely_area?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: lonely_area_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: lonely_area_set_input | null
      /** filter the rows which have to be updated */
      where: lonely_area_bool_exp
    },
    lonely_area_mutation_responseRequest,
  ]
  /** update single row of the table: "lonely.area" */
  update_lonely_area_by_pk?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: lonely_area_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: lonely_area_set_input | null
      pk_columns: lonely_area_pk_columns_input
    },
    lonely_areaRequest,
  ]
  /** update data of the table: "lonely.device" */
  update_lonely_device?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: lonely_device_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: lonely_device_set_input | null
      /** filter the rows which have to be updated */
      where: lonely_device_bool_exp
    },
    lonely_device_mutation_responseRequest,
  ]
  /** update single row of the table: "lonely.device" */
  update_lonely_device_by_pk?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: lonely_device_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: lonely_device_set_input | null
      pk_columns: lonely_device_pk_columns_input
    },
    lonely_deviceRequest,
  ]
  /** update data of the table: "lonely.status" */
  update_lonely_status?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: lonely_status_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: lonely_status_set_input | null
      /** filter the rows which have to be updated */
      where: lonely_status_bool_exp
    },
    lonely_status_mutation_responseRequest,
  ]
  /** update single row of the table: "lonely.status" */
  update_lonely_status_by_pk?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: lonely_status_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: lonely_status_set_input | null
      pk_columns: lonely_status_pk_columns_input
    },
    lonely_statusRequest,
  ]
  /** update data of the table: "pebble.account" */
  update_pebble_account?: [
    {
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_account_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_account_bool_exp
    },
    pebble_account_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.account" */
  update_pebble_account_by_pk?: [
    {
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_account_set_input | null
      pk_columns: pebble_account_pk_columns_input
    },
    pebble_accountRequest,
  ]
  /** update data of the table: "pebble.app" */
  update_pebble_app?: [
    {
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_app_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_app_bool_exp
    },
    pebble_app_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.app" */
  update_pebble_app_by_pk?: [
    {
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_app_set_input | null
      pk_columns: pebble_app_pk_columns_input
    },
    pebble_appRequest,
  ]
  /** update data of the table: "pebble.app_v2" */
  update_pebble_app_v2?: [
    {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: pebble_app_v2_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: pebble_app_v2_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: pebble_app_v2_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: pebble_app_v2_delete_key_input | null
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_app_v2_inc_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: pebble_app_v2_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_app_v2_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_app_v2_bool_exp
    },
    pebble_app_v2_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.app_v2" */
  update_pebble_app_v2_by_pk?: [
    {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: pebble_app_v2_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: pebble_app_v2_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: pebble_app_v2_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: pebble_app_v2_delete_key_input | null
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_app_v2_inc_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: pebble_app_v2_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_app_v2_set_input | null
      pk_columns: pebble_app_v2_pk_columns_input
    },
    pebble_app_v2Request,
  ]
  /** update data of the table: "pebble.bank" */
  update_pebble_bank?: [
    {
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_bank_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_bank_bool_exp
    },
    pebble_bank_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.bank" */
  update_pebble_bank_by_pk?: [
    {
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_bank_set_input | null
      pk_columns: pebble_bank_pk_columns_input
    },
    pebble_bankRequest,
  ]
  /** update data of the table: "pebble.bank_record" */
  update_pebble_bank_record?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_bank_record_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_bank_record_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_bank_record_bool_exp
    },
    pebble_bank_record_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.bank_record" */
  update_pebble_bank_record_by_pk?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_bank_record_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_bank_record_set_input | null
      pk_columns: pebble_bank_record_pk_columns_input
    },
    pebble_bank_recordRequest,
  ]
  /** update data of the table: "pebble.config" */
  update_pebble_config?: [
    {
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_config_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_config_bool_exp
    },
    pebble_config_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.config" */
  update_pebble_config_by_pk?: [
    {
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_config_set_input | null
      pk_columns: pebble_config_pk_columns_input
    },
    pebble_configRequest,
  ]
  /** update data of the table: "pebble.data_cache" */
  update_pebble_data_cache?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_data_cache_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_data_cache_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_data_cache_bool_exp
    },
    pebble_data_cache_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.data_cache" */
  update_pebble_data_cache_by_pk?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_data_cache_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_data_cache_set_input | null
      pk_columns: pebble_data_cache_pk_columns_input
    },
    pebble_data_cacheRequest,
  ]
  /** update data of the table: "pebble.device" */
  update_pebble_device?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_device_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_device_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_device_bool_exp
    },
    pebble_device_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.device" */
  update_pebble_device_by_pk?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_device_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_device_set_input | null
      pk_columns: pebble_device_pk_columns_input
    },
    pebble_deviceRequest,
  ]
  /** update data of the table: "pebble.device_record" */
  update_pebble_device_record?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_device_record_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_device_record_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_device_record_bool_exp
    },
    pebble_device_record_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.device_record" */
  update_pebble_device_record_by_pk?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_device_record_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_device_record_set_input | null
      pk_columns: pebble_device_record_pk_columns_input
    },
    pebble_device_recordRequest,
  ]
  /** update data of the table: "pebble.status" */
  update_pebble_status?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_status_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_status_set_input | null
      /** filter the rows which have to be updated */
      where: pebble_status_bool_exp
    },
    pebble_status_mutation_responseRequest,
  ]
  /** update single row of the table: "pebble.status" */
  update_pebble_status_by_pk?: [
    {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: pebble_status_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: pebble_status_set_input | null
      pk_columns: pebble_status_pk_columns_input
    },
    pebble_statusRequest,
  ]
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "lonely.area" */
export interface lonely_area_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: lonely_areaRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "lonely.device" */
export interface lonely_device_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: lonely_deviceRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "lonely.status" */
export interface lonely_status_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: lonely_statusRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.account" */
export interface pebble_account_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_accountRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.app" */
export interface pebble_app_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_appRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.app_v2" */
export interface pebble_app_v2_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_app_v2Request
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.bank" */
export interface pebble_bank_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_bankRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.bank_record" */
export interface pebble_bank_record_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_bank_recordRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.config" */
export interface pebble_config_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_configRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.data_cache" */
export interface pebble_data_cache_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_data_cacheRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.device" */
export interface pebble_device_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_deviceRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.device_record" */
export interface pebble_device_record_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_device_recordRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "pebble.status" */
export interface pebble_status_mutation_responseRequest {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: pebble_statusRequest
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** input type for inserting data into table "lonely.area" */
export interface lonely_area_insert_input {
  count?: Int | null
  created_at?: timestamptz | null
  id?: String | null
  latitude?: numeric | null
  longitude?: numeric | null
  updated_at?: timestamptz | null
}

/** on conflict condition type for table "lonely.area" */
export interface lonely_area_on_conflict {
  constraint: lonely_area_constraint
  update_columns: lonely_area_update_column[]
  where?: lonely_area_bool_exp | null
}

/** input type for inserting data into table "lonely.device" */
export interface lonely_device_insert_input {
  address?: String | null
  area?: String | null
  created_at?: timestamptz | null
  id?: String | null
  last_report_time?: bigint | null
  latitude?: numeric | null
  location?: String | null
  longitude?: numeric | null
  owner?: String | null
  to_be_claimed?: Int | null
  updated_at?: timestamptz | null
}

/** on conflict condition type for table "lonely.device" */
export interface lonely_device_on_conflict {
  constraint: lonely_device_constraint
  update_columns: lonely_device_update_column[]
  where?: lonely_device_bool_exp | null
}

/** input type for inserting data into table "lonely.status" */
export interface lonely_status_insert_input {
  created_at?: timestamptz | null
  id?: Int | null
  updated_at?: timestamptz | null
  value?: Int | null
}

/** on conflict condition type for table "lonely.status" */
export interface lonely_status_on_conflict {
  constraint: lonely_status_constraint
  update_columns: lonely_status_update_column[]
  where?: lonely_status_bool_exp | null
}

/** input type for inserting data into table "pebble.account" */
export interface pebble_account_insert_input {
  avatar?: String | null
  created_at?: timestamptz | null
  id?: String | null
  name?: String | null
  updated_at?: timestamptz | null
}

/** on conflict condition type for table "pebble.account" */
export interface pebble_account_on_conflict {
  constraint: pebble_account_constraint
  update_columns: pebble_account_update_column[]
  where?: pebble_account_bool_exp | null
}

/** input type for inserting data into table "pebble.app" */
export interface pebble_app_insert_input {
  avatar?: String | null
  content?: String | null
  created_at?: timestamptz | null
  id?: String | null
  updated_at?: timestamptz | null
  uri?: String | null
  version?: String | null
}

/** on conflict condition type for table "pebble.app" */
export interface pebble_app_on_conflict {
  constraint: pebble_app_constraint
  update_columns: pebble_app_update_column[]
  where?: pebble_app_bool_exp | null
}

/** input type for inserting data into table "pebble.app_v2" */
export interface pebble_app_v2_insert_input {
  author?: String | null
  category?: Int | null
  content?: String | null
  created_at?: date | null
  data?: jsonb | null
  date?: date | null
  direct_link?: String | null
  firmware?: String | null
  id?: String | null
  logo?: String | null
  order?: Int | null
  previews?: _text | null
  slug?: String | null
  status?: String | null
  updated_at?: date | null
  uri?: String | null
}

/** on conflict condition type for table "pebble.app_v2" */
export interface pebble_app_v2_on_conflict {
  constraint: pebble_app_v2_constraint
  update_columns: pebble_app_v2_update_column[]
  where?: pebble_app_v2_bool_exp | null
}

/** input type for inserting data into table "pebble.bank" */
export interface pebble_bank_insert_input {
  address?: String | null
  balance?: String | null
  created_at?: timestamptz | null
  updated_at?: timestamptz | null
}

/** on conflict condition type for table "pebble.bank" */
export interface pebble_bank_on_conflict {
  constraint: pebble_bank_constraint
  update_columns: pebble_bank_update_column[]
  where?: pebble_bank_bool_exp | null
}

/** input type for inserting data into table "pebble.bank_record" */
export interface pebble_bank_record_insert_input {
  amount?: String | null
  created_at?: timestamptz | null
  from?: String | null
  id?: String | null
  timestamp?: Int | null
  to?: String | null
  type?: Int | null
  updated_at?: timestamptz | null
}

/** on conflict condition type for table "pebble.bank_record" */
export interface pebble_bank_record_on_conflict {
  constraint: pebble_bank_record_constraint
  update_columns: pebble_bank_record_update_column[]
  where?: pebble_bank_record_bool_exp | null
}

/** input type for inserting data into table "pebble.config" */
export interface pebble_config_insert_input {
  avatar?: String | null
  created_at?: timestamptz | null
  data?: String | null
  id?: String | null
  updated_at?: timestamptz | null
  uri?: String | null
  version?: String | null
}

/** on conflict condition type for table "pebble.config" */
export interface pebble_config_on_conflict {
  constraint: pebble_config_constraint
  update_columns: pebble_config_update_column[]
  where?: pebble_config_bool_exp | null
}

/** input type for inserting data into table "pebble.data_cache" */
export interface pebble_data_cache_insert_input {
  created_at?: timestamptz | null
  data?: String | null
  id?: Int | null
  imei?: String | null
  signature?: String | null
  timestamp?: Int | null
  type?: Int | null
  updated_at?: timestamptz | null
}

/** on conflict condition type for table "pebble.data_cache" */
export interface pebble_data_cache_on_conflict {
  constraint: pebble_data_cache_constraint
  update_columns: pebble_data_cache_update_column[]
  where?: pebble_data_cache_bool_exp | null
}

/** input type for inserting data into table "pebble.device" */
export interface pebble_device_insert_input {
  address?: String | null
  avatar?: String | null
  beep?: Int | null
  bulk_upload?: Int | null
  bulk_upload_sampling_cnt?: Int | null
  bulk_upload_sampling_freq?: Int | null
  config?: String | null
  created_at?: timestamptz | null
  data_channel?: Int | null
  deviceRecord?: pebble_device_record_arr_rel_insert_input | null
  firmware?: String | null
  id?: String | null
  name?: String | null
  owner?: String | null
  proposer?: String | null
  real_firmware?: String | null
  state?: Int | null
  status?: Int | null
  total_gas?: numeric | null
  type?: Int | null
  updated_at?: timestamptz | null
  upload_period?: Int | null
}

/** input type for inserting array relation for remote table "pebble.device_record" */
export interface pebble_device_record_arr_rel_insert_input {
  data: pebble_device_record_insert_input[]
  /** on conflict condition */
  on_conflict?: pebble_device_record_on_conflict | null
}

/** input type for inserting data into table "pebble.device_record" */
export interface pebble_device_record_insert_input {
  accelerometer?: String | null
  created_at?: timestamptz | null
  gas_resistance?: numeric | null
  gyroscope?: String | null
  hash?: String | null
  humidity?: numeric | null
  id?: String | null
  imei?: String | null
  latitude?: numeric | null
  light?: numeric | null
  longitude?: numeric | null
  operator?: String | null
  pressure?: numeric | null
  signature?: String | null
  snr?: numeric | null
  temperature?: numeric | null
  temperature2?: numeric | null
  timestamp?: Int | null
  updated_at?: timestamptz | null
  vbat?: numeric | null
}

/** on conflict condition type for table "pebble.device_record" */
export interface pebble_device_record_on_conflict {
  constraint: pebble_device_record_constraint
  update_columns: pebble_device_record_update_column[]
  where?: pebble_device_record_bool_exp | null
}

/** on conflict condition type for table "pebble.device" */
export interface pebble_device_on_conflict {
  constraint: pebble_device_constraint
  update_columns: pebble_device_update_column[]
  where?: pebble_device_bool_exp | null
}

/** input type for inserting data into table "pebble.status" */
export interface pebble_status_insert_input {
  created_at?: timestamptz | null
  id?: Int | null
  updated_at?: timestamptz | null
  value?: Int | null
}

/** on conflict condition type for table "pebble.status" */
export interface pebble_status_on_conflict {
  constraint: pebble_status_constraint
  update_columns: pebble_status_update_column[]
  where?: pebble_status_bool_exp | null
}

/** input type for incrementing numeric columns in table "lonely.area" */
export interface lonely_area_inc_input {
  count?: Int | null
  latitude?: numeric | null
  longitude?: numeric | null
}

/** input type for updating data in table "lonely.area" */
export interface lonely_area_set_input {
  count?: Int | null
  created_at?: timestamptz | null
  id?: String | null
  latitude?: numeric | null
  longitude?: numeric | null
  updated_at?: timestamptz | null
}

/** primary key columns input for table: lonely_area */
export interface lonely_area_pk_columns_input {
  id: String
}

/** input type for incrementing numeric columns in table "lonely.device" */
export interface lonely_device_inc_input {
  last_report_time?: bigint | null
  latitude?: numeric | null
  longitude?: numeric | null
  to_be_claimed?: Int | null
}

/** input type for updating data in table "lonely.device" */
export interface lonely_device_set_input {
  address?: String | null
  area?: String | null
  created_at?: timestamptz | null
  id?: String | null
  last_report_time?: bigint | null
  latitude?: numeric | null
  location?: String | null
  longitude?: numeric | null
  owner?: String | null
  to_be_claimed?: Int | null
  updated_at?: timestamptz | null
}

/** primary key columns input for table: lonely_device */
export interface lonely_device_pk_columns_input {
  id: String
}

/** input type for incrementing numeric columns in table "lonely.status" */
export interface lonely_status_inc_input {
  id?: Int | null
  value?: Int | null
}

/** input type for updating data in table "lonely.status" */
export interface lonely_status_set_input {
  created_at?: timestamptz | null
  id?: Int | null
  updated_at?: timestamptz | null
  value?: Int | null
}

/** primary key columns input for table: lonely_status */
export interface lonely_status_pk_columns_input {
  id: Int
}

/** input type for updating data in table "pebble.account" */
export interface pebble_account_set_input {
  avatar?: String | null
  created_at?: timestamptz | null
  id?: String | null
  name?: String | null
  updated_at?: timestamptz | null
}

/** primary key columns input for table: pebble_account */
export interface pebble_account_pk_columns_input {
  id: String
}

/** input type for updating data in table "pebble.app" */
export interface pebble_app_set_input {
  avatar?: String | null
  content?: String | null
  created_at?: timestamptz | null
  id?: String | null
  updated_at?: timestamptz | null
  uri?: String | null
  version?: String | null
}

/** primary key columns input for table: pebble_app */
export interface pebble_app_pk_columns_input {
  id: String
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface pebble_app_v2_append_input {
  data?: jsonb | null
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface pebble_app_v2_delete_at_path_input {
  data?: String[] | null
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface pebble_app_v2_delete_elem_input {
  data?: Int | null
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface pebble_app_v2_delete_key_input {
  data?: String | null
}

/** input type for incrementing numeric columns in table "pebble.app_v2" */
export interface pebble_app_v2_inc_input {
  category?: Int | null
  order?: Int | null
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface pebble_app_v2_prepend_input {
  data?: jsonb | null
}

/** input type for updating data in table "pebble.app_v2" */
export interface pebble_app_v2_set_input {
  author?: String | null
  category?: Int | null
  content?: String | null
  created_at?: date | null
  data?: jsonb | null
  date?: date | null
  direct_link?: String | null
  firmware?: String | null
  id?: String | null
  logo?: String | null
  order?: Int | null
  previews?: _text | null
  slug?: String | null
  status?: String | null
  updated_at?: date | null
  uri?: String | null
}

/** primary key columns input for table: pebble_app_v2 */
export interface pebble_app_v2_pk_columns_input {
  id: String
}

/** input type for updating data in table "pebble.bank" */
export interface pebble_bank_set_input {
  address?: String | null
  balance?: String | null
  created_at?: timestamptz | null
  updated_at?: timestamptz | null
}

/** primary key columns input for table: pebble_bank */
export interface pebble_bank_pk_columns_input {
  address: String
}

/** input type for incrementing numeric columns in table "pebble.bank_record" */
export interface pebble_bank_record_inc_input {
  timestamp?: Int | null
  type?: Int | null
}

/** input type for updating data in table "pebble.bank_record" */
export interface pebble_bank_record_set_input {
  amount?: String | null
  created_at?: timestamptz | null
  from?: String | null
  id?: String | null
  timestamp?: Int | null
  to?: String | null
  type?: Int | null
  updated_at?: timestamptz | null
}

/** primary key columns input for table: pebble_bank_record */
export interface pebble_bank_record_pk_columns_input {
  id: String
}

/** input type for updating data in table "pebble.config" */
export interface pebble_config_set_input {
  avatar?: String | null
  created_at?: timestamptz | null
  data?: String | null
  id?: String | null
  updated_at?: timestamptz | null
  uri?: String | null
  version?: String | null
}

/** primary key columns input for table: pebble_config */
export interface pebble_config_pk_columns_input {
  id: String
}

/** input type for incrementing numeric columns in table "pebble.data_cache" */
export interface pebble_data_cache_inc_input {
  id?: Int | null
  timestamp?: Int | null
  type?: Int | null
}

/** input type for updating data in table "pebble.data_cache" */
export interface pebble_data_cache_set_input {
  created_at?: timestamptz | null
  data?: String | null
  id?: Int | null
  imei?: String | null
  signature?: String | null
  timestamp?: Int | null
  type?: Int | null
  updated_at?: timestamptz | null
}

/** primary key columns input for table: pebble_data_cache */
export interface pebble_data_cache_pk_columns_input {
  id: Int
}

/** input type for incrementing numeric columns in table "pebble.device" */
export interface pebble_device_inc_input {
  beep?: Int | null
  bulk_upload?: Int | null
  bulk_upload_sampling_cnt?: Int | null
  bulk_upload_sampling_freq?: Int | null
  data_channel?: Int | null
  state?: Int | null
  status?: Int | null
  total_gas?: numeric | null
  type?: Int | null
  upload_period?: Int | null
}

/** input type for updating data in table "pebble.device" */
export interface pebble_device_set_input {
  address?: String | null
  avatar?: String | null
  beep?: Int | null
  bulk_upload?: Int | null
  bulk_upload_sampling_cnt?: Int | null
  bulk_upload_sampling_freq?: Int | null
  config?: String | null
  created_at?: timestamptz | null
  data_channel?: Int | null
  firmware?: String | null
  id?: String | null
  name?: String | null
  owner?: String | null
  proposer?: String | null
  real_firmware?: String | null
  state?: Int | null
  status?: Int | null
  total_gas?: numeric | null
  type?: Int | null
  updated_at?: timestamptz | null
  upload_period?: Int | null
}

/** primary key columns input for table: pebble_device */
export interface pebble_device_pk_columns_input {
  id: String
}

/** input type for incrementing numeric columns in table "pebble.device_record" */
export interface pebble_device_record_inc_input {
  gas_resistance?: numeric | null
  humidity?: numeric | null
  latitude?: numeric | null
  light?: numeric | null
  longitude?: numeric | null
  pressure?: numeric | null
  snr?: numeric | null
  temperature?: numeric | null
  temperature2?: numeric | null
  timestamp?: Int | null
  vbat?: numeric | null
}

/** input type for updating data in table "pebble.device_record" */
export interface pebble_device_record_set_input {
  accelerometer?: String | null
  created_at?: timestamptz | null
  gas_resistance?: numeric | null
  gyroscope?: String | null
  hash?: String | null
  humidity?: numeric | null
  id?: String | null
  imei?: String | null
  latitude?: numeric | null
  light?: numeric | null
  longitude?: numeric | null
  operator?: String | null
  pressure?: numeric | null
  signature?: String | null
  snr?: numeric | null
  temperature?: numeric | null
  temperature2?: numeric | null
  timestamp?: Int | null
  updated_at?: timestamptz | null
  vbat?: numeric | null
}

/** primary key columns input for table: pebble_device_record */
export interface pebble_device_record_pk_columns_input {
  id: String
}

/** input type for incrementing numeric columns in table "pebble.status" */
export interface pebble_status_inc_input {
  id?: Int | null
  value?: Int | null
}

/** input type for updating data in table "pebble.status" */
export interface pebble_status_set_input {
  created_at?: timestamptz | null
  id?: Int | null
  updated_at?: timestamptz | null
  value?: Int | null
}

/** primary key columns input for table: pebble_status */
export interface pebble_status_pk_columns_input {
  id: Int
}

export interface subscription_rootRequest {
  /** fetch data from the table: "lonely.area" */
  lonely_area?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_area_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_area_order_by[] | null
          /** filter the rows returned */
          where?: lonely_area_bool_exp | null
        },
        lonely_areaRequest,
      ]
    | lonely_areaRequest
  /** fetch aggregated fields from the table: "lonely.area" */
  lonely_area_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_area_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_area_order_by[] | null
          /** filter the rows returned */
          where?: lonely_area_bool_exp | null
        },
        lonely_area_aggregateRequest,
      ]
    | lonely_area_aggregateRequest
  /** fetch data from the table: "lonely.area" using primary key columns */
  lonely_area_by_pk?: [{ id: String }, lonely_areaRequest]
  /** fetch data from the table: "lonely.device" */
  lonely_device?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_device_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_device_order_by[] | null
          /** filter the rows returned */
          where?: lonely_device_bool_exp | null
        },
        lonely_deviceRequest,
      ]
    | lonely_deviceRequest
  /** fetch aggregated fields from the table: "lonely.device" */
  lonely_device_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_device_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_device_order_by[] | null
          /** filter the rows returned */
          where?: lonely_device_bool_exp | null
        },
        lonely_device_aggregateRequest,
      ]
    | lonely_device_aggregateRequest
  /** fetch data from the table: "lonely.device" using primary key columns */
  lonely_device_by_pk?: [{ id: String }, lonely_deviceRequest]
  /** fetch data from the table: "lonely.status" */
  lonely_status?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_status_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_status_order_by[] | null
          /** filter the rows returned */
          where?: lonely_status_bool_exp | null
        },
        lonely_statusRequest,
      ]
    | lonely_statusRequest
  /** fetch aggregated fields from the table: "lonely.status" */
  lonely_status_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: lonely_status_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: lonely_status_order_by[] | null
          /** filter the rows returned */
          where?: lonely_status_bool_exp | null
        },
        lonely_status_aggregateRequest,
      ]
    | lonely_status_aggregateRequest
  /** fetch data from the table: "lonely.status" using primary key columns */
  lonely_status_by_pk?: [{ id: Int }, lonely_statusRequest]
  /** fetch data from the table: "pebble.account" */
  pebble_account?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_account_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_account_order_by[] | null
          /** filter the rows returned */
          where?: pebble_account_bool_exp | null
        },
        pebble_accountRequest,
      ]
    | pebble_accountRequest
  /** fetch aggregated fields from the table: "pebble.account" */
  pebble_account_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_account_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_account_order_by[] | null
          /** filter the rows returned */
          where?: pebble_account_bool_exp | null
        },
        pebble_account_aggregateRequest,
      ]
    | pebble_account_aggregateRequest
  /** fetch data from the table: "pebble.account" using primary key columns */
  pebble_account_by_pk?: [{ id: String }, pebble_accountRequest]
  /** fetch data from the table: "pebble.app" */
  pebble_app?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_app_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_app_order_by[] | null
          /** filter the rows returned */
          where?: pebble_app_bool_exp | null
        },
        pebble_appRequest,
      ]
    | pebble_appRequest
  /** fetch aggregated fields from the table: "pebble.app" */
  pebble_app_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_app_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_app_order_by[] | null
          /** filter the rows returned */
          where?: pebble_app_bool_exp | null
        },
        pebble_app_aggregateRequest,
      ]
    | pebble_app_aggregateRequest
  /** fetch data from the table: "pebble.app" using primary key columns */
  pebble_app_by_pk?: [{ id: String }, pebble_appRequest]
  /** fetch data from the table: "pebble.app_v2" */
  pebble_app_v2?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_app_v2_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_app_v2_order_by[] | null
          /** filter the rows returned */
          where?: pebble_app_v2_bool_exp | null
        },
        pebble_app_v2Request,
      ]
    | pebble_app_v2Request
  /** fetch aggregated fields from the table: "pebble.app_v2" */
  pebble_app_v2_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_app_v2_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_app_v2_order_by[] | null
          /** filter the rows returned */
          where?: pebble_app_v2_bool_exp | null
        },
        pebble_app_v2_aggregateRequest,
      ]
    | pebble_app_v2_aggregateRequest
  /** fetch data from the table: "pebble.app_v2" using primary key columns */
  pebble_app_v2_by_pk?: [{ id: String }, pebble_app_v2Request]
  /** fetch data from the table: "pebble.bank" */
  pebble_bank?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_bank_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_bank_order_by[] | null
          /** filter the rows returned */
          where?: pebble_bank_bool_exp | null
        },
        pebble_bankRequest,
      ]
    | pebble_bankRequest
  /** fetch aggregated fields from the table: "pebble.bank" */
  pebble_bank_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_bank_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_bank_order_by[] | null
          /** filter the rows returned */
          where?: pebble_bank_bool_exp | null
        },
        pebble_bank_aggregateRequest,
      ]
    | pebble_bank_aggregateRequest
  /** fetch data from the table: "pebble.bank" using primary key columns */
  pebble_bank_by_pk?: [{ address: String }, pebble_bankRequest]
  /** fetch data from the table: "pebble.bank_record" */
  pebble_bank_record?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_bank_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_bank_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_bank_record_bool_exp | null
        },
        pebble_bank_recordRequest,
      ]
    | pebble_bank_recordRequest
  /** fetch aggregated fields from the table: "pebble.bank_record" */
  pebble_bank_record_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_bank_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_bank_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_bank_record_bool_exp | null
        },
        pebble_bank_record_aggregateRequest,
      ]
    | pebble_bank_record_aggregateRequest
  /** fetch data from the table: "pebble.bank_record" using primary key columns */
  pebble_bank_record_by_pk?: [{ id: String }, pebble_bank_recordRequest]
  /** fetch data from the table: "pebble.config" */
  pebble_config?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_config_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_config_order_by[] | null
          /** filter the rows returned */
          where?: pebble_config_bool_exp | null
        },
        pebble_configRequest,
      ]
    | pebble_configRequest
  /** fetch aggregated fields from the table: "pebble.config" */
  pebble_config_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_config_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_config_order_by[] | null
          /** filter the rows returned */
          where?: pebble_config_bool_exp | null
        },
        pebble_config_aggregateRequest,
      ]
    | pebble_config_aggregateRequest
  /** fetch data from the table: "pebble.config" using primary key columns */
  pebble_config_by_pk?: [{ id: String }, pebble_configRequest]
  /** fetch data from the table: "pebble.data_cache" */
  pebble_data_cache?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_data_cache_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_data_cache_order_by[] | null
          /** filter the rows returned */
          where?: pebble_data_cache_bool_exp | null
        },
        pebble_data_cacheRequest,
      ]
    | pebble_data_cacheRequest
  /** fetch aggregated fields from the table: "pebble.data_cache" */
  pebble_data_cache_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_data_cache_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_data_cache_order_by[] | null
          /** filter the rows returned */
          where?: pebble_data_cache_bool_exp | null
        },
        pebble_data_cache_aggregateRequest,
      ]
    | pebble_data_cache_aggregateRequest
  /** fetch data from the table: "pebble.data_cache" using primary key columns */
  pebble_data_cache_by_pk?: [{ id: Int }, pebble_data_cacheRequest]
  /** fetch data from the table: "pebble.device" */
  pebble_device?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_bool_exp | null
        },
        pebble_deviceRequest,
      ]
    | pebble_deviceRequest
  /** fetch aggregated fields from the table: "pebble.device" */
  pebble_device_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_bool_exp | null
        },
        pebble_device_aggregateRequest,
      ]
    | pebble_device_aggregateRequest
  /** fetch data from the table: "pebble.device" using primary key columns */
  pebble_device_by_pk?: [{ id: String }, pebble_deviceRequest]
  /** fetch data from the table: "pebble.device_record" */
  pebble_device_record?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_record_bool_exp | null
        },
        pebble_device_recordRequest,
      ]
    | pebble_device_recordRequest
  /** fetch aggregated fields from the table: "pebble.device_record" */
  pebble_device_record_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_device_record_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_device_record_order_by[] | null
          /** filter the rows returned */
          where?: pebble_device_record_bool_exp | null
        },
        pebble_device_record_aggregateRequest,
      ]
    | pebble_device_record_aggregateRequest
  /** fetch data from the table: "pebble.device_record" using primary key columns */
  pebble_device_record_by_pk?: [{ id: String }, pebble_device_recordRequest]
  /** fetch data from the table: "pebble.status" */
  pebble_status?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_status_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_status_order_by[] | null
          /** filter the rows returned */
          where?: pebble_status_bool_exp | null
        },
        pebble_statusRequest,
      ]
    | pebble_statusRequest
  /** fetch aggregated fields from the table: "pebble.status" */
  pebble_status_aggregate?:
    | [
        {
          /** distinct select on columns */
          distinct_on?: pebble_status_select_column[] | null
          /** limit the number of rows returned */
          limit?: Int | null
          /** skip the first n rows. Use only with order_by */
          offset?: Int | null
          /** sort the rows by one or more columns */
          order_by?: pebble_status_order_by[] | null
          /** filter the rows returned */
          where?: pebble_status_bool_exp | null
        },
        pebble_status_aggregateRequest,
      ]
    | pebble_status_aggregateRequest
  /** fetch data from the table: "pebble.status" using primary key columns */
  pebble_status_by_pk?: [{ id: Int }, pebble_statusRequest]
  __typename?: boolean | number
  __scalar?: boolean | number
}

const query_root_possibleTypes = ['query_root']
export const isquery_root = (obj: { __typename: String }): obj is query_root => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return query_root_possibleTypes.includes(obj.__typename)
}

const lonely_area_possibleTypes = ['lonely_area']
export const islonely_area = (obj: { __typename: String }): obj is lonely_area => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_possibleTypes.includes(obj.__typename)
}

const lonely_area_aggregate_possibleTypes = ['lonely_area_aggregate']
export const islonely_area_aggregate = (obj: { __typename: String }): obj is lonely_area_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_aggregate_possibleTypes.includes(obj.__typename)
}

const lonely_area_aggregate_fields_possibleTypes = ['lonely_area_aggregate_fields']
export const islonely_area_aggregate_fields = (obj: { __typename: String }): obj is lonely_area_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_avg_fields_possibleTypes = ['lonely_area_avg_fields']
export const islonely_area_avg_fields = (obj: { __typename: String }): obj is lonely_area_avg_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_avg_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_max_fields_possibleTypes = ['lonely_area_max_fields']
export const islonely_area_max_fields = (obj: { __typename: String }): obj is lonely_area_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_max_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_min_fields_possibleTypes = ['lonely_area_min_fields']
export const islonely_area_min_fields = (obj: { __typename: String }): obj is lonely_area_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_min_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_stddev_fields_possibleTypes = ['lonely_area_stddev_fields']
export const islonely_area_stddev_fields = (obj: { __typename: String }): obj is lonely_area_stddev_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_stddev_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_stddev_pop_fields_possibleTypes = ['lonely_area_stddev_pop_fields']
export const islonely_area_stddev_pop_fields = (obj: { __typename: String }): obj is lonely_area_stddev_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_stddev_samp_fields_possibleTypes = ['lonely_area_stddev_samp_fields']
export const islonely_area_stddev_samp_fields = (obj: { __typename: String }): obj is lonely_area_stddev_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_sum_fields_possibleTypes = ['lonely_area_sum_fields']
export const islonely_area_sum_fields = (obj: { __typename: String }): obj is lonely_area_sum_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_sum_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_var_pop_fields_possibleTypes = ['lonely_area_var_pop_fields']
export const islonely_area_var_pop_fields = (obj: { __typename: String }): obj is lonely_area_var_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_var_samp_fields_possibleTypes = ['lonely_area_var_samp_fields']
export const islonely_area_var_samp_fields = (obj: { __typename: String }): obj is lonely_area_var_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const lonely_area_variance_fields_possibleTypes = ['lonely_area_variance_fields']
export const islonely_area_variance_fields = (obj: { __typename: String }): obj is lonely_area_variance_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_variance_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_possibleTypes = ['lonely_device']
export const islonely_device = (obj: { __typename: String }): obj is lonely_device => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_possibleTypes.includes(obj.__typename)
}

const lonely_device_aggregate_possibleTypes = ['lonely_device_aggregate']
export const islonely_device_aggregate = (obj: { __typename: String }): obj is lonely_device_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_aggregate_possibleTypes.includes(obj.__typename)
}

const lonely_device_aggregate_fields_possibleTypes = ['lonely_device_aggregate_fields']
export const islonely_device_aggregate_fields = (obj: { __typename: String }): obj is lonely_device_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_avg_fields_possibleTypes = ['lonely_device_avg_fields']
export const islonely_device_avg_fields = (obj: { __typename: String }): obj is lonely_device_avg_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_avg_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_max_fields_possibleTypes = ['lonely_device_max_fields']
export const islonely_device_max_fields = (obj: { __typename: String }): obj is lonely_device_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_max_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_min_fields_possibleTypes = ['lonely_device_min_fields']
export const islonely_device_min_fields = (obj: { __typename: String }): obj is lonely_device_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_min_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_stddev_fields_possibleTypes = ['lonely_device_stddev_fields']
export const islonely_device_stddev_fields = (obj: { __typename: String }): obj is lonely_device_stddev_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_stddev_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_stddev_pop_fields_possibleTypes = ['lonely_device_stddev_pop_fields']
export const islonely_device_stddev_pop_fields = (obj: { __typename: String }): obj is lonely_device_stddev_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_stddev_samp_fields_possibleTypes = ['lonely_device_stddev_samp_fields']
export const islonely_device_stddev_samp_fields = (obj: { __typename: String }): obj is lonely_device_stddev_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_sum_fields_possibleTypes = ['lonely_device_sum_fields']
export const islonely_device_sum_fields = (obj: { __typename: String }): obj is lonely_device_sum_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_sum_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_var_pop_fields_possibleTypes = ['lonely_device_var_pop_fields']
export const islonely_device_var_pop_fields = (obj: { __typename: String }): obj is lonely_device_var_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_var_samp_fields_possibleTypes = ['lonely_device_var_samp_fields']
export const islonely_device_var_samp_fields = (obj: { __typename: String }): obj is lonely_device_var_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const lonely_device_variance_fields_possibleTypes = ['lonely_device_variance_fields']
export const islonely_device_variance_fields = (obj: { __typename: String }): obj is lonely_device_variance_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_variance_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_possibleTypes = ['lonely_status']
export const islonely_status = (obj: { __typename: String }): obj is lonely_status => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_possibleTypes.includes(obj.__typename)
}

const lonely_status_aggregate_possibleTypes = ['lonely_status_aggregate']
export const islonely_status_aggregate = (obj: { __typename: String }): obj is lonely_status_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_aggregate_possibleTypes.includes(obj.__typename)
}

const lonely_status_aggregate_fields_possibleTypes = ['lonely_status_aggregate_fields']
export const islonely_status_aggregate_fields = (obj: { __typename: String }): obj is lonely_status_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_avg_fields_possibleTypes = ['lonely_status_avg_fields']
export const islonely_status_avg_fields = (obj: { __typename: String }): obj is lonely_status_avg_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_avg_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_max_fields_possibleTypes = ['lonely_status_max_fields']
export const islonely_status_max_fields = (obj: { __typename: String }): obj is lonely_status_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_max_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_min_fields_possibleTypes = ['lonely_status_min_fields']
export const islonely_status_min_fields = (obj: { __typename: String }): obj is lonely_status_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_min_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_stddev_fields_possibleTypes = ['lonely_status_stddev_fields']
export const islonely_status_stddev_fields = (obj: { __typename: String }): obj is lonely_status_stddev_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_stddev_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_stddev_pop_fields_possibleTypes = ['lonely_status_stddev_pop_fields']
export const islonely_status_stddev_pop_fields = (obj: { __typename: String }): obj is lonely_status_stddev_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_stddev_samp_fields_possibleTypes = ['lonely_status_stddev_samp_fields']
export const islonely_status_stddev_samp_fields = (obj: { __typename: String }): obj is lonely_status_stddev_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_sum_fields_possibleTypes = ['lonely_status_sum_fields']
export const islonely_status_sum_fields = (obj: { __typename: String }): obj is lonely_status_sum_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_sum_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_var_pop_fields_possibleTypes = ['lonely_status_var_pop_fields']
export const islonely_status_var_pop_fields = (obj: { __typename: String }): obj is lonely_status_var_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_var_samp_fields_possibleTypes = ['lonely_status_var_samp_fields']
export const islonely_status_var_samp_fields = (obj: { __typename: String }): obj is lonely_status_var_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const lonely_status_variance_fields_possibleTypes = ['lonely_status_variance_fields']
export const islonely_status_variance_fields = (obj: { __typename: String }): obj is lonely_status_variance_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_variance_fields_possibleTypes.includes(obj.__typename)
}

const pebble_account_possibleTypes = ['pebble_account']
export const ispebble_account = (obj: { __typename: String }): obj is pebble_account => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_account_possibleTypes.includes(obj.__typename)
}

const pebble_account_aggregate_possibleTypes = ['pebble_account_aggregate']
export const ispebble_account_aggregate = (obj: { __typename: String }): obj is pebble_account_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_account_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_account_aggregate_fields_possibleTypes = ['pebble_account_aggregate_fields']
export const ispebble_account_aggregate_fields = (obj: { __typename: String }): obj is pebble_account_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_account_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_account_max_fields_possibleTypes = ['pebble_account_max_fields']
export const ispebble_account_max_fields = (obj: { __typename: String }): obj is pebble_account_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_account_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_account_min_fields_possibleTypes = ['pebble_account_min_fields']
export const ispebble_account_min_fields = (obj: { __typename: String }): obj is pebble_account_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_account_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_possibleTypes = ['pebble_app']
export const ispebble_app = (obj: { __typename: String }): obj is pebble_app => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_possibleTypes.includes(obj.__typename)
}

const pebble_app_aggregate_possibleTypes = ['pebble_app_aggregate']
export const ispebble_app_aggregate = (obj: { __typename: String }): obj is pebble_app_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_app_aggregate_fields_possibleTypes = ['pebble_app_aggregate_fields']
export const ispebble_app_aggregate_fields = (obj: { __typename: String }): obj is pebble_app_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_max_fields_possibleTypes = ['pebble_app_max_fields']
export const ispebble_app_max_fields = (obj: { __typename: String }): obj is pebble_app_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_min_fields_possibleTypes = ['pebble_app_min_fields']
export const ispebble_app_min_fields = (obj: { __typename: String }): obj is pebble_app_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_possibleTypes = ['pebble_app_v2']
export const ispebble_app_v2 = (obj: { __typename: String }): obj is pebble_app_v2 => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_aggregate_possibleTypes = ['pebble_app_v2_aggregate']
export const ispebble_app_v2_aggregate = (obj: { __typename: String }): obj is pebble_app_v2_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_aggregate_fields_possibleTypes = ['pebble_app_v2_aggregate_fields']
export const ispebble_app_v2_aggregate_fields = (obj: { __typename: String }): obj is pebble_app_v2_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_avg_fields_possibleTypes = ['pebble_app_v2_avg_fields']
export const ispebble_app_v2_avg_fields = (obj: { __typename: String }): obj is pebble_app_v2_avg_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_avg_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_max_fields_possibleTypes = ['pebble_app_v2_max_fields']
export const ispebble_app_v2_max_fields = (obj: { __typename: String }): obj is pebble_app_v2_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_min_fields_possibleTypes = ['pebble_app_v2_min_fields']
export const ispebble_app_v2_min_fields = (obj: { __typename: String }): obj is pebble_app_v2_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_stddev_fields_possibleTypes = ['pebble_app_v2_stddev_fields']
export const ispebble_app_v2_stddev_fields = (obj: { __typename: String }): obj is pebble_app_v2_stddev_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_stddev_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_stddev_pop_fields_possibleTypes = ['pebble_app_v2_stddev_pop_fields']
export const ispebble_app_v2_stddev_pop_fields = (obj: { __typename: String }): obj is pebble_app_v2_stddev_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_stddev_samp_fields_possibleTypes = ['pebble_app_v2_stddev_samp_fields']
export const ispebble_app_v2_stddev_samp_fields = (obj: { __typename: String }): obj is pebble_app_v2_stddev_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_sum_fields_possibleTypes = ['pebble_app_v2_sum_fields']
export const ispebble_app_v2_sum_fields = (obj: { __typename: String }): obj is pebble_app_v2_sum_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_sum_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_var_pop_fields_possibleTypes = ['pebble_app_v2_var_pop_fields']
export const ispebble_app_v2_var_pop_fields = (obj: { __typename: String }): obj is pebble_app_v2_var_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_var_samp_fields_possibleTypes = ['pebble_app_v2_var_samp_fields']
export const ispebble_app_v2_var_samp_fields = (obj: { __typename: String }): obj is pebble_app_v2_var_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_variance_fields_possibleTypes = ['pebble_app_v2_variance_fields']
export const ispebble_app_v2_variance_fields = (obj: { __typename: String }): obj is pebble_app_v2_variance_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_variance_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_possibleTypes = ['pebble_bank']
export const ispebble_bank = (obj: { __typename: String }): obj is pebble_bank => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_possibleTypes.includes(obj.__typename)
}

const pebble_bank_aggregate_possibleTypes = ['pebble_bank_aggregate']
export const ispebble_bank_aggregate = (obj: { __typename: String }): obj is pebble_bank_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_bank_aggregate_fields_possibleTypes = ['pebble_bank_aggregate_fields']
export const ispebble_bank_aggregate_fields = (obj: { __typename: String }): obj is pebble_bank_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_max_fields_possibleTypes = ['pebble_bank_max_fields']
export const ispebble_bank_max_fields = (obj: { __typename: String }): obj is pebble_bank_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_min_fields_possibleTypes = ['pebble_bank_min_fields']
export const ispebble_bank_min_fields = (obj: { __typename: String }): obj is pebble_bank_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_possibleTypes = ['pebble_bank_record']
export const ispebble_bank_record = (obj: { __typename: String }): obj is pebble_bank_record => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_aggregate_possibleTypes = ['pebble_bank_record_aggregate']
export const ispebble_bank_record_aggregate = (obj: { __typename: String }): obj is pebble_bank_record_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_aggregate_fields_possibleTypes = ['pebble_bank_record_aggregate_fields']
export const ispebble_bank_record_aggregate_fields = (obj: {
  __typename: String
}): obj is pebble_bank_record_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_avg_fields_possibleTypes = ['pebble_bank_record_avg_fields']
export const ispebble_bank_record_avg_fields = (obj: { __typename: String }): obj is pebble_bank_record_avg_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_avg_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_max_fields_possibleTypes = ['pebble_bank_record_max_fields']
export const ispebble_bank_record_max_fields = (obj: { __typename: String }): obj is pebble_bank_record_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_min_fields_possibleTypes = ['pebble_bank_record_min_fields']
export const ispebble_bank_record_min_fields = (obj: { __typename: String }): obj is pebble_bank_record_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_stddev_fields_possibleTypes = ['pebble_bank_record_stddev_fields']
export const ispebble_bank_record_stddev_fields = (obj: { __typename: String }): obj is pebble_bank_record_stddev_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_stddev_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_stddev_pop_fields_possibleTypes = ['pebble_bank_record_stddev_pop_fields']
export const ispebble_bank_record_stddev_pop_fields = (obj: {
  __typename: String
}): obj is pebble_bank_record_stddev_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_stddev_samp_fields_possibleTypes = ['pebble_bank_record_stddev_samp_fields']
export const ispebble_bank_record_stddev_samp_fields = (obj: {
  __typename: String
}): obj is pebble_bank_record_stddev_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_sum_fields_possibleTypes = ['pebble_bank_record_sum_fields']
export const ispebble_bank_record_sum_fields = (obj: { __typename: String }): obj is pebble_bank_record_sum_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_sum_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_var_pop_fields_possibleTypes = ['pebble_bank_record_var_pop_fields']
export const ispebble_bank_record_var_pop_fields = (obj: {
  __typename: String
}): obj is pebble_bank_record_var_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_var_samp_fields_possibleTypes = ['pebble_bank_record_var_samp_fields']
export const ispebble_bank_record_var_samp_fields = (obj: {
  __typename: String
}): obj is pebble_bank_record_var_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_variance_fields_possibleTypes = ['pebble_bank_record_variance_fields']
export const ispebble_bank_record_variance_fields = (obj: {
  __typename: String
}): obj is pebble_bank_record_variance_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_variance_fields_possibleTypes.includes(obj.__typename)
}

const pebble_config_possibleTypes = ['pebble_config']
export const ispebble_config = (obj: { __typename: String }): obj is pebble_config => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_config_possibleTypes.includes(obj.__typename)
}

const pebble_config_aggregate_possibleTypes = ['pebble_config_aggregate']
export const ispebble_config_aggregate = (obj: { __typename: String }): obj is pebble_config_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_config_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_config_aggregate_fields_possibleTypes = ['pebble_config_aggregate_fields']
export const ispebble_config_aggregate_fields = (obj: { __typename: String }): obj is pebble_config_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_config_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_config_max_fields_possibleTypes = ['pebble_config_max_fields']
export const ispebble_config_max_fields = (obj: { __typename: String }): obj is pebble_config_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_config_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_config_min_fields_possibleTypes = ['pebble_config_min_fields']
export const ispebble_config_min_fields = (obj: { __typename: String }): obj is pebble_config_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_config_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_possibleTypes = ['pebble_data_cache']
export const ispebble_data_cache = (obj: { __typename: String }): obj is pebble_data_cache => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_aggregate_possibleTypes = ['pebble_data_cache_aggregate']
export const ispebble_data_cache_aggregate = (obj: { __typename: String }): obj is pebble_data_cache_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_aggregate_fields_possibleTypes = ['pebble_data_cache_aggregate_fields']
export const ispebble_data_cache_aggregate_fields = (obj: {
  __typename: String
}): obj is pebble_data_cache_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_avg_fields_possibleTypes = ['pebble_data_cache_avg_fields']
export const ispebble_data_cache_avg_fields = (obj: { __typename: String }): obj is pebble_data_cache_avg_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_avg_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_max_fields_possibleTypes = ['pebble_data_cache_max_fields']
export const ispebble_data_cache_max_fields = (obj: { __typename: String }): obj is pebble_data_cache_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_min_fields_possibleTypes = ['pebble_data_cache_min_fields']
export const ispebble_data_cache_min_fields = (obj: { __typename: String }): obj is pebble_data_cache_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_stddev_fields_possibleTypes = ['pebble_data_cache_stddev_fields']
export const ispebble_data_cache_stddev_fields = (obj: { __typename: String }): obj is pebble_data_cache_stddev_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_stddev_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_stddev_pop_fields_possibleTypes = ['pebble_data_cache_stddev_pop_fields']
export const ispebble_data_cache_stddev_pop_fields = (obj: {
  __typename: String
}): obj is pebble_data_cache_stddev_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_stddev_samp_fields_possibleTypes = ['pebble_data_cache_stddev_samp_fields']
export const ispebble_data_cache_stddev_samp_fields = (obj: {
  __typename: String
}): obj is pebble_data_cache_stddev_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_sum_fields_possibleTypes = ['pebble_data_cache_sum_fields']
export const ispebble_data_cache_sum_fields = (obj: { __typename: String }): obj is pebble_data_cache_sum_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_sum_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_var_pop_fields_possibleTypes = ['pebble_data_cache_var_pop_fields']
export const ispebble_data_cache_var_pop_fields = (obj: { __typename: String }): obj is pebble_data_cache_var_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_var_samp_fields_possibleTypes = ['pebble_data_cache_var_samp_fields']
export const ispebble_data_cache_var_samp_fields = (obj: {
  __typename: String
}): obj is pebble_data_cache_var_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_variance_fields_possibleTypes = ['pebble_data_cache_variance_fields']
export const ispebble_data_cache_variance_fields = (obj: {
  __typename: String
}): obj is pebble_data_cache_variance_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_variance_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_possibleTypes = ['pebble_device']
export const ispebble_device = (obj: { __typename: String }): obj is pebble_device => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_possibleTypes = ['pebble_device_record']
export const ispebble_device_record = (obj: { __typename: String }): obj is pebble_device_record => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_aggregate_possibleTypes = ['pebble_device_record_aggregate']
export const ispebble_device_record_aggregate = (obj: { __typename: String }): obj is pebble_device_record_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_aggregate_fields_possibleTypes = ['pebble_device_record_aggregate_fields']
export const ispebble_device_record_aggregate_fields = (obj: {
  __typename: String
}): obj is pebble_device_record_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_avg_fields_possibleTypes = ['pebble_device_record_avg_fields']
export const ispebble_device_record_avg_fields = (obj: { __typename: String }): obj is pebble_device_record_avg_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_avg_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_max_fields_possibleTypes = ['pebble_device_record_max_fields']
export const ispebble_device_record_max_fields = (obj: { __typename: String }): obj is pebble_device_record_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_min_fields_possibleTypes = ['pebble_device_record_min_fields']
export const ispebble_device_record_min_fields = (obj: { __typename: String }): obj is pebble_device_record_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_stddev_fields_possibleTypes = ['pebble_device_record_stddev_fields']
export const ispebble_device_record_stddev_fields = (obj: {
  __typename: String
}): obj is pebble_device_record_stddev_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_stddev_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_stddev_pop_fields_possibleTypes = ['pebble_device_record_stddev_pop_fields']
export const ispebble_device_record_stddev_pop_fields = (obj: {
  __typename: String
}): obj is pebble_device_record_stddev_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_stddev_samp_fields_possibleTypes = ['pebble_device_record_stddev_samp_fields']
export const ispebble_device_record_stddev_samp_fields = (obj: {
  __typename: String
}): obj is pebble_device_record_stddev_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_sum_fields_possibleTypes = ['pebble_device_record_sum_fields']
export const ispebble_device_record_sum_fields = (obj: { __typename: String }): obj is pebble_device_record_sum_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_sum_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_var_pop_fields_possibleTypes = ['pebble_device_record_var_pop_fields']
export const ispebble_device_record_var_pop_fields = (obj: {
  __typename: String
}): obj is pebble_device_record_var_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_var_samp_fields_possibleTypes = ['pebble_device_record_var_samp_fields']
export const ispebble_device_record_var_samp_fields = (obj: {
  __typename: String
}): obj is pebble_device_record_var_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_variance_fields_possibleTypes = ['pebble_device_record_variance_fields']
export const ispebble_device_record_variance_fields = (obj: {
  __typename: String
}): obj is pebble_device_record_variance_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_variance_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_aggregate_possibleTypes = ['pebble_device_aggregate']
export const ispebble_device_aggregate = (obj: { __typename: String }): obj is pebble_device_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_device_aggregate_fields_possibleTypes = ['pebble_device_aggregate_fields']
export const ispebble_device_aggregate_fields = (obj: { __typename: String }): obj is pebble_device_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_avg_fields_possibleTypes = ['pebble_device_avg_fields']
export const ispebble_device_avg_fields = (obj: { __typename: String }): obj is pebble_device_avg_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_avg_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_max_fields_possibleTypes = ['pebble_device_max_fields']
export const ispebble_device_max_fields = (obj: { __typename: String }): obj is pebble_device_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_min_fields_possibleTypes = ['pebble_device_min_fields']
export const ispebble_device_min_fields = (obj: { __typename: String }): obj is pebble_device_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_stddev_fields_possibleTypes = ['pebble_device_stddev_fields']
export const ispebble_device_stddev_fields = (obj: { __typename: String }): obj is pebble_device_stddev_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_stddev_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_stddev_pop_fields_possibleTypes = ['pebble_device_stddev_pop_fields']
export const ispebble_device_stddev_pop_fields = (obj: { __typename: String }): obj is pebble_device_stddev_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_stddev_samp_fields_possibleTypes = ['pebble_device_stddev_samp_fields']
export const ispebble_device_stddev_samp_fields = (obj: { __typename: String }): obj is pebble_device_stddev_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_sum_fields_possibleTypes = ['pebble_device_sum_fields']
export const ispebble_device_sum_fields = (obj: { __typename: String }): obj is pebble_device_sum_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_sum_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_var_pop_fields_possibleTypes = ['pebble_device_var_pop_fields']
export const ispebble_device_var_pop_fields = (obj: { __typename: String }): obj is pebble_device_var_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_var_samp_fields_possibleTypes = ['pebble_device_var_samp_fields']
export const ispebble_device_var_samp_fields = (obj: { __typename: String }): obj is pebble_device_var_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_device_variance_fields_possibleTypes = ['pebble_device_variance_fields']
export const ispebble_device_variance_fields = (obj: { __typename: String }): obj is pebble_device_variance_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_variance_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_possibleTypes = ['pebble_status']
export const ispebble_status = (obj: { __typename: String }): obj is pebble_status => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_possibleTypes.includes(obj.__typename)
}

const pebble_status_aggregate_possibleTypes = ['pebble_status_aggregate']
export const ispebble_status_aggregate = (obj: { __typename: String }): obj is pebble_status_aggregate => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_aggregate_possibleTypes.includes(obj.__typename)
}

const pebble_status_aggregate_fields_possibleTypes = ['pebble_status_aggregate_fields']
export const ispebble_status_aggregate_fields = (obj: { __typename: String }): obj is pebble_status_aggregate_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_avg_fields_possibleTypes = ['pebble_status_avg_fields']
export const ispebble_status_avg_fields = (obj: { __typename: String }): obj is pebble_status_avg_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_avg_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_max_fields_possibleTypes = ['pebble_status_max_fields']
export const ispebble_status_max_fields = (obj: { __typename: String }): obj is pebble_status_max_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_max_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_min_fields_possibleTypes = ['pebble_status_min_fields']
export const ispebble_status_min_fields = (obj: { __typename: String }): obj is pebble_status_min_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_min_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_stddev_fields_possibleTypes = ['pebble_status_stddev_fields']
export const ispebble_status_stddev_fields = (obj: { __typename: String }): obj is pebble_status_stddev_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_stddev_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_stddev_pop_fields_possibleTypes = ['pebble_status_stddev_pop_fields']
export const ispebble_status_stddev_pop_fields = (obj: { __typename: String }): obj is pebble_status_stddev_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_stddev_samp_fields_possibleTypes = ['pebble_status_stddev_samp_fields']
export const ispebble_status_stddev_samp_fields = (obj: { __typename: String }): obj is pebble_status_stddev_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_sum_fields_possibleTypes = ['pebble_status_sum_fields']
export const ispebble_status_sum_fields = (obj: { __typename: String }): obj is pebble_status_sum_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_sum_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_var_pop_fields_possibleTypes = ['pebble_status_var_pop_fields']
export const ispebble_status_var_pop_fields = (obj: { __typename: String }): obj is pebble_status_var_pop_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_var_samp_fields_possibleTypes = ['pebble_status_var_samp_fields']
export const ispebble_status_var_samp_fields = (obj: { __typename: String }): obj is pebble_status_var_samp_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const pebble_status_variance_fields_possibleTypes = ['pebble_status_variance_fields']
export const ispebble_status_variance_fields = (obj: { __typename: String }): obj is pebble_status_variance_fields => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_variance_fields_possibleTypes.includes(obj.__typename)
}

const mutation_root_possibleTypes = ['mutation_root']
export const ismutation_root = (obj: { __typename: String }): obj is mutation_root => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return mutation_root_possibleTypes.includes(obj.__typename)
}

const lonely_area_mutation_response_possibleTypes = ['lonely_area_mutation_response']
export const islonely_area_mutation_response = (obj: { __typename: String }): obj is lonely_area_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_area_mutation_response_possibleTypes.includes(obj.__typename)
}

const lonely_device_mutation_response_possibleTypes = ['lonely_device_mutation_response']
export const islonely_device_mutation_response = (obj: { __typename: String }): obj is lonely_device_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_device_mutation_response_possibleTypes.includes(obj.__typename)
}

const lonely_status_mutation_response_possibleTypes = ['lonely_status_mutation_response']
export const islonely_status_mutation_response = (obj: { __typename: String }): obj is lonely_status_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return lonely_status_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_account_mutation_response_possibleTypes = ['pebble_account_mutation_response']
export const ispebble_account_mutation_response = (obj: { __typename: String }): obj is pebble_account_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_account_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_app_mutation_response_possibleTypes = ['pebble_app_mutation_response']
export const ispebble_app_mutation_response = (obj: { __typename: String }): obj is pebble_app_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_app_v2_mutation_response_possibleTypes = ['pebble_app_v2_mutation_response']
export const ispebble_app_v2_mutation_response = (obj: { __typename: String }): obj is pebble_app_v2_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_app_v2_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_bank_mutation_response_possibleTypes = ['pebble_bank_mutation_response']
export const ispebble_bank_mutation_response = (obj: { __typename: String }): obj is pebble_bank_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_bank_record_mutation_response_possibleTypes = ['pebble_bank_record_mutation_response']
export const ispebble_bank_record_mutation_response = (obj: {
  __typename: String
}): obj is pebble_bank_record_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_bank_record_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_config_mutation_response_possibleTypes = ['pebble_config_mutation_response']
export const ispebble_config_mutation_response = (obj: { __typename: String }): obj is pebble_config_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_config_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_data_cache_mutation_response_possibleTypes = ['pebble_data_cache_mutation_response']
export const ispebble_data_cache_mutation_response = (obj: {
  __typename: String
}): obj is pebble_data_cache_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_data_cache_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_device_mutation_response_possibleTypes = ['pebble_device_mutation_response']
export const ispebble_device_mutation_response = (obj: { __typename: String }): obj is pebble_device_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_device_record_mutation_response_possibleTypes = ['pebble_device_record_mutation_response']
export const ispebble_device_record_mutation_response = (obj: {
  __typename: String
}): obj is pebble_device_record_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_device_record_mutation_response_possibleTypes.includes(obj.__typename)
}

const pebble_status_mutation_response_possibleTypes = ['pebble_status_mutation_response']
export const ispebble_status_mutation_response = (obj: { __typename: String }): obj is pebble_status_mutation_response => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return pebble_status_mutation_response_possibleTypes.includes(obj.__typename)
}

const subscription_root_possibleTypes = ['subscription_root']
export const issubscription_root = (obj: { __typename: String }): obj is subscription_root => {
  if (!obj.__typename) throw new Error('__typename is missing')
  return subscription_root_possibleTypes.includes(obj.__typename)
}

export interface query_rootPromiseChain {
  /** fetch data from the table: "lonely.area" */
  lonely_area: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_area_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_area_order_by[] | null
    /** filter the rows returned */
    where?: lonely_area_bool_exp | null
  }) => { execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Promise<lonely_area[]> }) & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Promise<lonely_area[]>
  }
  /** fetch aggregated fields from the table: "lonely.area" */
  lonely_area_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_area_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_area_order_by[] | null
    /** filter the rows returned */
    where?: lonely_area_bool_exp | null
  }) => lonely_area_aggregatePromiseChain & {
    execute: (request: lonely_area_aggregateRequest, defaultValue?: lonely_area_aggregate) => Promise<lonely_area_aggregate>
  }) &
    (lonely_area_aggregatePromiseChain & {
      execute: (
        request: lonely_area_aggregateRequest,
        defaultValue?: lonely_area_aggregate,
      ) => Promise<lonely_area_aggregate>
    })
  /** fetch data from the table: "lonely.area" using primary key columns */
  lonely_area_by_pk: (args: {
    id: String
  }) => lonely_areaPromiseChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Promise<lonely_area | null>
  }
  /** fetch data from the table: "lonely.device" */
  lonely_device: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_device_order_by[] | null
    /** filter the rows returned */
    where?: lonely_device_bool_exp | null
  }) => { execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Promise<lonely_device[]> }) & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Promise<lonely_device[]>
  }
  /** fetch aggregated fields from the table: "lonely.device" */
  lonely_device_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_device_order_by[] | null
    /** filter the rows returned */
    where?: lonely_device_bool_exp | null
  }) => lonely_device_aggregatePromiseChain & {
    execute: (
      request: lonely_device_aggregateRequest,
      defaultValue?: lonely_device_aggregate,
    ) => Promise<lonely_device_aggregate>
  }) &
    (lonely_device_aggregatePromiseChain & {
      execute: (
        request: lonely_device_aggregateRequest,
        defaultValue?: lonely_device_aggregate,
      ) => Promise<lonely_device_aggregate>
    })
  /** fetch data from the table: "lonely.device" using primary key columns */
  lonely_device_by_pk: (args: {
    id: String
  }) => lonely_devicePromiseChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Promise<lonely_device | null>
  }
  /** fetch data from the table: "lonely.status" */
  lonely_status: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_status_order_by[] | null
    /** filter the rows returned */
    where?: lonely_status_bool_exp | null
  }) => { execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Promise<lonely_status[]> }) & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Promise<lonely_status[]>
  }
  /** fetch aggregated fields from the table: "lonely.status" */
  lonely_status_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_status_order_by[] | null
    /** filter the rows returned */
    where?: lonely_status_bool_exp | null
  }) => lonely_status_aggregatePromiseChain & {
    execute: (
      request: lonely_status_aggregateRequest,
      defaultValue?: lonely_status_aggregate,
    ) => Promise<lonely_status_aggregate>
  }) &
    (lonely_status_aggregatePromiseChain & {
      execute: (
        request: lonely_status_aggregateRequest,
        defaultValue?: lonely_status_aggregate,
      ) => Promise<lonely_status_aggregate>
    })
  /** fetch data from the table: "lonely.status" using primary key columns */
  lonely_status_by_pk: (args: {
    id: Int
  }) => lonely_statusPromiseChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Promise<lonely_status | null>
  }
  /** fetch data from the table: "pebble.account" */
  pebble_account: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_account_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_account_order_by[] | null
    /** filter the rows returned */
    where?: pebble_account_bool_exp | null
  }) => { execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Promise<pebble_account[]> }) & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Promise<pebble_account[]>
  }
  /** fetch aggregated fields from the table: "pebble.account" */
  pebble_account_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_account_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_account_order_by[] | null
    /** filter the rows returned */
    where?: pebble_account_bool_exp | null
  }) => pebble_account_aggregatePromiseChain & {
    execute: (
      request: pebble_account_aggregateRequest,
      defaultValue?: pebble_account_aggregate,
    ) => Promise<pebble_account_aggregate>
  }) &
    (pebble_account_aggregatePromiseChain & {
      execute: (
        request: pebble_account_aggregateRequest,
        defaultValue?: pebble_account_aggregate,
      ) => Promise<pebble_account_aggregate>
    })
  /** fetch data from the table: "pebble.account" using primary key columns */
  pebble_account_by_pk: (args: {
    id: String
  }) => pebble_accountPromiseChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Promise<pebble_account | null>
  }
  /** fetch data from the table: "pebble.app" */
  pebble_app: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_bool_exp | null
  }) => { execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Promise<pebble_app[]> }) & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Promise<pebble_app[]>
  }
  /** fetch aggregated fields from the table: "pebble.app" */
  pebble_app_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_bool_exp | null
  }) => pebble_app_aggregatePromiseChain & {
    execute: (request: pebble_app_aggregateRequest, defaultValue?: pebble_app_aggregate) => Promise<pebble_app_aggregate>
  }) &
    (pebble_app_aggregatePromiseChain & {
      execute: (request: pebble_app_aggregateRequest, defaultValue?: pebble_app_aggregate) => Promise<pebble_app_aggregate>
    })
  /** fetch data from the table: "pebble.app" using primary key columns */
  pebble_app_by_pk: (args: {
    id: String
  }) => pebble_appPromiseChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Promise<pebble_app | null>
  }
  /** fetch data from the table: "pebble.app_v2" */
  pebble_app_v2: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_v2_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_v2_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_v2_bool_exp | null
  }) => { execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Promise<pebble_app_v2[]> }) & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Promise<pebble_app_v2[]>
  }
  /** fetch aggregated fields from the table: "pebble.app_v2" */
  pebble_app_v2_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_v2_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_v2_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_v2_bool_exp | null
  }) => pebble_app_v2_aggregatePromiseChain & {
    execute: (
      request: pebble_app_v2_aggregateRequest,
      defaultValue?: pebble_app_v2_aggregate,
    ) => Promise<pebble_app_v2_aggregate>
  }) &
    (pebble_app_v2_aggregatePromiseChain & {
      execute: (
        request: pebble_app_v2_aggregateRequest,
        defaultValue?: pebble_app_v2_aggregate,
      ) => Promise<pebble_app_v2_aggregate>
    })
  /** fetch data from the table: "pebble.app_v2" using primary key columns */
  pebble_app_v2_by_pk: (args: {
    id: String
  }) => pebble_app_v2PromiseChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Promise<pebble_app_v2 | null>
  }
  /** fetch data from the table: "pebble.bank" */
  pebble_bank: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_bool_exp | null
  }) => { execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Promise<pebble_bank[]> }) & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Promise<pebble_bank[]>
  }
  /** fetch aggregated fields from the table: "pebble.bank" */
  pebble_bank_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_bool_exp | null
  }) => pebble_bank_aggregatePromiseChain & {
    execute: (request: pebble_bank_aggregateRequest, defaultValue?: pebble_bank_aggregate) => Promise<pebble_bank_aggregate>
  }) &
    (pebble_bank_aggregatePromiseChain & {
      execute: (
        request: pebble_bank_aggregateRequest,
        defaultValue?: pebble_bank_aggregate,
      ) => Promise<pebble_bank_aggregate>
    })
  /** fetch data from the table: "pebble.bank" using primary key columns */
  pebble_bank_by_pk: (args: {
    address: String
  }) => pebble_bankPromiseChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Promise<pebble_bank | null>
  }
  /** fetch data from the table: "pebble.bank_record" */
  pebble_bank_record: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_record_bool_exp | null
  }) => {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Promise<pebble_bank_record[]>
  }) & {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Promise<pebble_bank_record[]>
  }
  /** fetch aggregated fields from the table: "pebble.bank_record" */
  pebble_bank_record_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_record_bool_exp | null
  }) => pebble_bank_record_aggregatePromiseChain & {
    execute: (
      request: pebble_bank_record_aggregateRequest,
      defaultValue?: pebble_bank_record_aggregate,
    ) => Promise<pebble_bank_record_aggregate>
  }) &
    (pebble_bank_record_aggregatePromiseChain & {
      execute: (
        request: pebble_bank_record_aggregateRequest,
        defaultValue?: pebble_bank_record_aggregate,
      ) => Promise<pebble_bank_record_aggregate>
    })
  /** fetch data from the table: "pebble.bank_record" using primary key columns */
  pebble_bank_record_by_pk: (args: {
    id: String
  }) => pebble_bank_recordPromiseChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Promise<pebble_bank_record | null>
  }
  /** fetch data from the table: "pebble.config" */
  pebble_config: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_config_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_config_order_by[] | null
    /** filter the rows returned */
    where?: pebble_config_bool_exp | null
  }) => { execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Promise<pebble_config[]> }) & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Promise<pebble_config[]>
  }
  /** fetch aggregated fields from the table: "pebble.config" */
  pebble_config_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_config_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_config_order_by[] | null
    /** filter the rows returned */
    where?: pebble_config_bool_exp | null
  }) => pebble_config_aggregatePromiseChain & {
    execute: (
      request: pebble_config_aggregateRequest,
      defaultValue?: pebble_config_aggregate,
    ) => Promise<pebble_config_aggregate>
  }) &
    (pebble_config_aggregatePromiseChain & {
      execute: (
        request: pebble_config_aggregateRequest,
        defaultValue?: pebble_config_aggregate,
      ) => Promise<pebble_config_aggregate>
    })
  /** fetch data from the table: "pebble.config" using primary key columns */
  pebble_config_by_pk: (args: {
    id: String
  }) => pebble_configPromiseChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Promise<pebble_config | null>
  }
  /** fetch data from the table: "pebble.data_cache" */
  pebble_data_cache: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_data_cache_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_data_cache_order_by[] | null
    /** filter the rows returned */
    where?: pebble_data_cache_bool_exp | null
  }) => {
    execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Promise<pebble_data_cache[]>
  }) & { execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Promise<pebble_data_cache[]> }
  /** fetch aggregated fields from the table: "pebble.data_cache" */
  pebble_data_cache_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_data_cache_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_data_cache_order_by[] | null
    /** filter the rows returned */
    where?: pebble_data_cache_bool_exp | null
  }) => pebble_data_cache_aggregatePromiseChain & {
    execute: (
      request: pebble_data_cache_aggregateRequest,
      defaultValue?: pebble_data_cache_aggregate,
    ) => Promise<pebble_data_cache_aggregate>
  }) &
    (pebble_data_cache_aggregatePromiseChain & {
      execute: (
        request: pebble_data_cache_aggregateRequest,
        defaultValue?: pebble_data_cache_aggregate,
      ) => Promise<pebble_data_cache_aggregate>
    })
  /** fetch data from the table: "pebble.data_cache" using primary key columns */
  pebble_data_cache_by_pk: (args: {
    id: Int
  }) => pebble_data_cachePromiseChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Promise<pebble_data_cache | null>
  }
  /** fetch data from the table: "pebble.device" */
  pebble_device: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_bool_exp | null
  }) => { execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Promise<pebble_device[]> }) & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Promise<pebble_device[]>
  }
  /** fetch aggregated fields from the table: "pebble.device" */
  pebble_device_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_bool_exp | null
  }) => pebble_device_aggregatePromiseChain & {
    execute: (
      request: pebble_device_aggregateRequest,
      defaultValue?: pebble_device_aggregate,
    ) => Promise<pebble_device_aggregate>
  }) &
    (pebble_device_aggregatePromiseChain & {
      execute: (
        request: pebble_device_aggregateRequest,
        defaultValue?: pebble_device_aggregate,
      ) => Promise<pebble_device_aggregate>
    })
  /** fetch data from the table: "pebble.device" using primary key columns */
  pebble_device_by_pk: (args: {
    id: String
  }) => pebble_devicePromiseChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Promise<pebble_device | null>
  }
  /** fetch data from the table: "pebble.device_record" */
  pebble_device_record: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => {
    execute: (request: pebble_device_recordRequest, defaultValue?: pebble_device_record[]) => Promise<pebble_device_record[]>
  }) & {
    execute: (request: pebble_device_recordRequest, defaultValue?: pebble_device_record[]) => Promise<pebble_device_record[]>
  }
  /** fetch aggregated fields from the table: "pebble.device_record" */
  pebble_device_record_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => pebble_device_record_aggregatePromiseChain & {
    execute: (
      request: pebble_device_record_aggregateRequest,
      defaultValue?: pebble_device_record_aggregate,
    ) => Promise<pebble_device_record_aggregate>
  }) &
    (pebble_device_record_aggregatePromiseChain & {
      execute: (
        request: pebble_device_record_aggregateRequest,
        defaultValue?: pebble_device_record_aggregate,
      ) => Promise<pebble_device_record_aggregate>
    })
  /** fetch data from the table: "pebble.device_record" using primary key columns */
  pebble_device_record_by_pk: (args: {
    id: String
  }) => pebble_device_recordPromiseChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Promise<pebble_device_record | null>
  }
  /** fetch data from the table: "pebble.status" */
  pebble_status: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_status_order_by[] | null
    /** filter the rows returned */
    where?: pebble_status_bool_exp | null
  }) => { execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Promise<pebble_status[]> }) & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Promise<pebble_status[]>
  }
  /** fetch aggregated fields from the table: "pebble.status" */
  pebble_status_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_status_order_by[] | null
    /** filter the rows returned */
    where?: pebble_status_bool_exp | null
  }) => pebble_status_aggregatePromiseChain & {
    execute: (
      request: pebble_status_aggregateRequest,
      defaultValue?: pebble_status_aggregate,
    ) => Promise<pebble_status_aggregate>
  }) &
    (pebble_status_aggregatePromiseChain & {
      execute: (
        request: pebble_status_aggregateRequest,
        defaultValue?: pebble_status_aggregate,
      ) => Promise<pebble_status_aggregate>
    })
  /** fetch data from the table: "pebble.status" using primary key columns */
  pebble_status_by_pk: (args: {
    id: Int
  }) => pebble_statusPromiseChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Promise<pebble_status | null>
  }
}

export interface query_rootObservableChain {
  /** fetch data from the table: "lonely.area" */
  lonely_area: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_area_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_area_order_by[] | null
    /** filter the rows returned */
    where?: lonely_area_bool_exp | null
  }) => { execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Observable<lonely_area[]> }) & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Observable<lonely_area[]>
  }
  /** fetch aggregated fields from the table: "lonely.area" */
  lonely_area_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_area_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_area_order_by[] | null
    /** filter the rows returned */
    where?: lonely_area_bool_exp | null
  }) => lonely_area_aggregateObservableChain & {
    execute: (
      request: lonely_area_aggregateRequest,
      defaultValue?: lonely_area_aggregate,
    ) => Observable<lonely_area_aggregate>
  }) &
    (lonely_area_aggregateObservableChain & {
      execute: (
        request: lonely_area_aggregateRequest,
        defaultValue?: lonely_area_aggregate,
      ) => Observable<lonely_area_aggregate>
    })
  /** fetch data from the table: "lonely.area" using primary key columns */
  lonely_area_by_pk: (args: {
    id: String
  }) => lonely_areaObservableChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Observable<lonely_area | null>
  }
  /** fetch data from the table: "lonely.device" */
  lonely_device: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_device_order_by[] | null
    /** filter the rows returned */
    where?: lonely_device_bool_exp | null
  }) => { execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Observable<lonely_device[]> }) & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Observable<lonely_device[]>
  }
  /** fetch aggregated fields from the table: "lonely.device" */
  lonely_device_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_device_order_by[] | null
    /** filter the rows returned */
    where?: lonely_device_bool_exp | null
  }) => lonely_device_aggregateObservableChain & {
    execute: (
      request: lonely_device_aggregateRequest,
      defaultValue?: lonely_device_aggregate,
    ) => Observable<lonely_device_aggregate>
  }) &
    (lonely_device_aggregateObservableChain & {
      execute: (
        request: lonely_device_aggregateRequest,
        defaultValue?: lonely_device_aggregate,
      ) => Observable<lonely_device_aggregate>
    })
  /** fetch data from the table: "lonely.device" using primary key columns */
  lonely_device_by_pk: (args: {
    id: String
  }) => lonely_deviceObservableChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Observable<lonely_device | null>
  }
  /** fetch data from the table: "lonely.status" */
  lonely_status: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_status_order_by[] | null
    /** filter the rows returned */
    where?: lonely_status_bool_exp | null
  }) => { execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Observable<lonely_status[]> }) & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Observable<lonely_status[]>
  }
  /** fetch aggregated fields from the table: "lonely.status" */
  lonely_status_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_status_order_by[] | null
    /** filter the rows returned */
    where?: lonely_status_bool_exp | null
  }) => lonely_status_aggregateObservableChain & {
    execute: (
      request: lonely_status_aggregateRequest,
      defaultValue?: lonely_status_aggregate,
    ) => Observable<lonely_status_aggregate>
  }) &
    (lonely_status_aggregateObservableChain & {
      execute: (
        request: lonely_status_aggregateRequest,
        defaultValue?: lonely_status_aggregate,
      ) => Observable<lonely_status_aggregate>
    })
  /** fetch data from the table: "lonely.status" using primary key columns */
  lonely_status_by_pk: (args: {
    id: Int
  }) => lonely_statusObservableChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Observable<lonely_status | null>
  }
  /** fetch data from the table: "pebble.account" */
  pebble_account: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_account_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_account_order_by[] | null
    /** filter the rows returned */
    where?: pebble_account_bool_exp | null
  }) => { execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Observable<pebble_account[]> }) & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Observable<pebble_account[]>
  }
  /** fetch aggregated fields from the table: "pebble.account" */
  pebble_account_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_account_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_account_order_by[] | null
    /** filter the rows returned */
    where?: pebble_account_bool_exp | null
  }) => pebble_account_aggregateObservableChain & {
    execute: (
      request: pebble_account_aggregateRequest,
      defaultValue?: pebble_account_aggregate,
    ) => Observable<pebble_account_aggregate>
  }) &
    (pebble_account_aggregateObservableChain & {
      execute: (
        request: pebble_account_aggregateRequest,
        defaultValue?: pebble_account_aggregate,
      ) => Observable<pebble_account_aggregate>
    })
  /** fetch data from the table: "pebble.account" using primary key columns */
  pebble_account_by_pk: (args: {
    id: String
  }) => pebble_accountObservableChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Observable<pebble_account | null>
  }
  /** fetch data from the table: "pebble.app" */
  pebble_app: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_bool_exp | null
  }) => { execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Observable<pebble_app[]> }) & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Observable<pebble_app[]>
  }
  /** fetch aggregated fields from the table: "pebble.app" */
  pebble_app_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_bool_exp | null
  }) => pebble_app_aggregateObservableChain & {
    execute: (request: pebble_app_aggregateRequest, defaultValue?: pebble_app_aggregate) => Observable<pebble_app_aggregate>
  }) &
    (pebble_app_aggregateObservableChain & {
      execute: (
        request: pebble_app_aggregateRequest,
        defaultValue?: pebble_app_aggregate,
      ) => Observable<pebble_app_aggregate>
    })
  /** fetch data from the table: "pebble.app" using primary key columns */
  pebble_app_by_pk: (args: {
    id: String
  }) => pebble_appObservableChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Observable<pebble_app | null>
  }
  /** fetch data from the table: "pebble.app_v2" */
  pebble_app_v2: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_v2_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_v2_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_v2_bool_exp | null
  }) => { execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Observable<pebble_app_v2[]> }) & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Observable<pebble_app_v2[]>
  }
  /** fetch aggregated fields from the table: "pebble.app_v2" */
  pebble_app_v2_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_v2_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_v2_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_v2_bool_exp | null
  }) => pebble_app_v2_aggregateObservableChain & {
    execute: (
      request: pebble_app_v2_aggregateRequest,
      defaultValue?: pebble_app_v2_aggregate,
    ) => Observable<pebble_app_v2_aggregate>
  }) &
    (pebble_app_v2_aggregateObservableChain & {
      execute: (
        request: pebble_app_v2_aggregateRequest,
        defaultValue?: pebble_app_v2_aggregate,
      ) => Observable<pebble_app_v2_aggregate>
    })
  /** fetch data from the table: "pebble.app_v2" using primary key columns */
  pebble_app_v2_by_pk: (args: {
    id: String
  }) => pebble_app_v2ObservableChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Observable<pebble_app_v2 | null>
  }
  /** fetch data from the table: "pebble.bank" */
  pebble_bank: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_bool_exp | null
  }) => { execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Observable<pebble_bank[]> }) & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Observable<pebble_bank[]>
  }
  /** fetch aggregated fields from the table: "pebble.bank" */
  pebble_bank_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_bool_exp | null
  }) => pebble_bank_aggregateObservableChain & {
    execute: (
      request: pebble_bank_aggregateRequest,
      defaultValue?: pebble_bank_aggregate,
    ) => Observable<pebble_bank_aggregate>
  }) &
    (pebble_bank_aggregateObservableChain & {
      execute: (
        request: pebble_bank_aggregateRequest,
        defaultValue?: pebble_bank_aggregate,
      ) => Observable<pebble_bank_aggregate>
    })
  /** fetch data from the table: "pebble.bank" using primary key columns */
  pebble_bank_by_pk: (args: {
    address: String
  }) => pebble_bankObservableChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Observable<pebble_bank | null>
  }
  /** fetch data from the table: "pebble.bank_record" */
  pebble_bank_record: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_record_bool_exp | null
  }) => {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Observable<pebble_bank_record[]>
  }) & {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Observable<pebble_bank_record[]>
  }
  /** fetch aggregated fields from the table: "pebble.bank_record" */
  pebble_bank_record_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_record_bool_exp | null
  }) => pebble_bank_record_aggregateObservableChain & {
    execute: (
      request: pebble_bank_record_aggregateRequest,
      defaultValue?: pebble_bank_record_aggregate,
    ) => Observable<pebble_bank_record_aggregate>
  }) &
    (pebble_bank_record_aggregateObservableChain & {
      execute: (
        request: pebble_bank_record_aggregateRequest,
        defaultValue?: pebble_bank_record_aggregate,
      ) => Observable<pebble_bank_record_aggregate>
    })
  /** fetch data from the table: "pebble.bank_record" using primary key columns */
  pebble_bank_record_by_pk: (args: {
    id: String
  }) => pebble_bank_recordObservableChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Observable<pebble_bank_record | null>
  }
  /** fetch data from the table: "pebble.config" */
  pebble_config: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_config_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_config_order_by[] | null
    /** filter the rows returned */
    where?: pebble_config_bool_exp | null
  }) => { execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Observable<pebble_config[]> }) & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Observable<pebble_config[]>
  }
  /** fetch aggregated fields from the table: "pebble.config" */
  pebble_config_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_config_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_config_order_by[] | null
    /** filter the rows returned */
    where?: pebble_config_bool_exp | null
  }) => pebble_config_aggregateObservableChain & {
    execute: (
      request: pebble_config_aggregateRequest,
      defaultValue?: pebble_config_aggregate,
    ) => Observable<pebble_config_aggregate>
  }) &
    (pebble_config_aggregateObservableChain & {
      execute: (
        request: pebble_config_aggregateRequest,
        defaultValue?: pebble_config_aggregate,
      ) => Observable<pebble_config_aggregate>
    })
  /** fetch data from the table: "pebble.config" using primary key columns */
  pebble_config_by_pk: (args: {
    id: String
  }) => pebble_configObservableChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Observable<pebble_config | null>
  }
  /** fetch data from the table: "pebble.data_cache" */
  pebble_data_cache: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_data_cache_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_data_cache_order_by[] | null
    /** filter the rows returned */
    where?: pebble_data_cache_bool_exp | null
  }) => {
    execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Observable<pebble_data_cache[]>
  }) & {
    execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Observable<pebble_data_cache[]>
  }
  /** fetch aggregated fields from the table: "pebble.data_cache" */
  pebble_data_cache_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_data_cache_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_data_cache_order_by[] | null
    /** filter the rows returned */
    where?: pebble_data_cache_bool_exp | null
  }) => pebble_data_cache_aggregateObservableChain & {
    execute: (
      request: pebble_data_cache_aggregateRequest,
      defaultValue?: pebble_data_cache_aggregate,
    ) => Observable<pebble_data_cache_aggregate>
  }) &
    (pebble_data_cache_aggregateObservableChain & {
      execute: (
        request: pebble_data_cache_aggregateRequest,
        defaultValue?: pebble_data_cache_aggregate,
      ) => Observable<pebble_data_cache_aggregate>
    })
  /** fetch data from the table: "pebble.data_cache" using primary key columns */
  pebble_data_cache_by_pk: (args: {
    id: Int
  }) => pebble_data_cacheObservableChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Observable<pebble_data_cache | null>
  }
  /** fetch data from the table: "pebble.device" */
  pebble_device: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_bool_exp | null
  }) => { execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Observable<pebble_device[]> }) & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Observable<pebble_device[]>
  }
  /** fetch aggregated fields from the table: "pebble.device" */
  pebble_device_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_bool_exp | null
  }) => pebble_device_aggregateObservableChain & {
    execute: (
      request: pebble_device_aggregateRequest,
      defaultValue?: pebble_device_aggregate,
    ) => Observable<pebble_device_aggregate>
  }) &
    (pebble_device_aggregateObservableChain & {
      execute: (
        request: pebble_device_aggregateRequest,
        defaultValue?: pebble_device_aggregate,
      ) => Observable<pebble_device_aggregate>
    })
  /** fetch data from the table: "pebble.device" using primary key columns */
  pebble_device_by_pk: (args: {
    id: String
  }) => pebble_deviceObservableChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Observable<pebble_device | null>
  }
  /** fetch data from the table: "pebble.device_record" */
  pebble_device_record: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record[],
    ) => Observable<pebble_device_record[]>
  }) & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record[],
    ) => Observable<pebble_device_record[]>
  }
  /** fetch aggregated fields from the table: "pebble.device_record" */
  pebble_device_record_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => pebble_device_record_aggregateObservableChain & {
    execute: (
      request: pebble_device_record_aggregateRequest,
      defaultValue?: pebble_device_record_aggregate,
    ) => Observable<pebble_device_record_aggregate>
  }) &
    (pebble_device_record_aggregateObservableChain & {
      execute: (
        request: pebble_device_record_aggregateRequest,
        defaultValue?: pebble_device_record_aggregate,
      ) => Observable<pebble_device_record_aggregate>
    })
  /** fetch data from the table: "pebble.device_record" using primary key columns */
  pebble_device_record_by_pk: (args: {
    id: String
  }) => pebble_device_recordObservableChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Observable<pebble_device_record | null>
  }
  /** fetch data from the table: "pebble.status" */
  pebble_status: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_status_order_by[] | null
    /** filter the rows returned */
    where?: pebble_status_bool_exp | null
  }) => { execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Observable<pebble_status[]> }) & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Observable<pebble_status[]>
  }
  /** fetch aggregated fields from the table: "pebble.status" */
  pebble_status_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_status_order_by[] | null
    /** filter the rows returned */
    where?: pebble_status_bool_exp | null
  }) => pebble_status_aggregateObservableChain & {
    execute: (
      request: pebble_status_aggregateRequest,
      defaultValue?: pebble_status_aggregate,
    ) => Observable<pebble_status_aggregate>
  }) &
    (pebble_status_aggregateObservableChain & {
      execute: (
        request: pebble_status_aggregateRequest,
        defaultValue?: pebble_status_aggregate,
      ) => Observable<pebble_status_aggregate>
    })
  /** fetch data from the table: "pebble.status" using primary key columns */
  pebble_status_by_pk: (args: {
    id: Int
  }) => pebble_statusObservableChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Observable<pebble_status | null>
  }
}

/** columns and relationships of "lonely.area" */
export interface lonely_areaPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
}

/** columns and relationships of "lonely.area" */
export interface lonely_areaObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
}

/** aggregated selection of "lonely.area" */
export interface lonely_area_aggregatePromiseChain {
  aggregate: lonely_area_aggregate_fieldsPromiseChain & {
    execute: (
      request: lonely_area_aggregate_fieldsRequest,
      defaultValue?: lonely_area_aggregate_fields | null,
    ) => Promise<lonely_area_aggregate_fields | null>
  }
  nodes: { execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Promise<lonely_area[]> }
}

/** aggregated selection of "lonely.area" */
export interface lonely_area_aggregateObservableChain {
  aggregate: lonely_area_aggregate_fieldsObservableChain & {
    execute: (
      request: lonely_area_aggregate_fieldsRequest,
      defaultValue?: lonely_area_aggregate_fields | null,
    ) => Observable<lonely_area_aggregate_fields | null>
  }
  nodes: { execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Observable<lonely_area[]> }
}

/** aggregate fields of "lonely.area" */
export interface lonely_area_aggregate_fieldsPromiseChain {
  avg: lonely_area_avg_fieldsPromiseChain & {
    execute: (
      request: lonely_area_avg_fieldsRequest,
      defaultValue?: lonely_area_avg_fields | null,
    ) => Promise<lonely_area_avg_fields | null>
  }
  count: ((args?: {
    columns?: lonely_area_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: lonely_area_max_fieldsPromiseChain & {
    execute: (
      request: lonely_area_max_fieldsRequest,
      defaultValue?: lonely_area_max_fields | null,
    ) => Promise<lonely_area_max_fields | null>
  }
  min: lonely_area_min_fieldsPromiseChain & {
    execute: (
      request: lonely_area_min_fieldsRequest,
      defaultValue?: lonely_area_min_fields | null,
    ) => Promise<lonely_area_min_fields | null>
  }
  stddev: lonely_area_stddev_fieldsPromiseChain & {
    execute: (
      request: lonely_area_stddev_fieldsRequest,
      defaultValue?: lonely_area_stddev_fields | null,
    ) => Promise<lonely_area_stddev_fields | null>
  }
  stddev_pop: lonely_area_stddev_pop_fieldsPromiseChain & {
    execute: (
      request: lonely_area_stddev_pop_fieldsRequest,
      defaultValue?: lonely_area_stddev_pop_fields | null,
    ) => Promise<lonely_area_stddev_pop_fields | null>
  }
  stddev_samp: lonely_area_stddev_samp_fieldsPromiseChain & {
    execute: (
      request: lonely_area_stddev_samp_fieldsRequest,
      defaultValue?: lonely_area_stddev_samp_fields | null,
    ) => Promise<lonely_area_stddev_samp_fields | null>
  }
  sum: lonely_area_sum_fieldsPromiseChain & {
    execute: (
      request: lonely_area_sum_fieldsRequest,
      defaultValue?: lonely_area_sum_fields | null,
    ) => Promise<lonely_area_sum_fields | null>
  }
  var_pop: lonely_area_var_pop_fieldsPromiseChain & {
    execute: (
      request: lonely_area_var_pop_fieldsRequest,
      defaultValue?: lonely_area_var_pop_fields | null,
    ) => Promise<lonely_area_var_pop_fields | null>
  }
  var_samp: lonely_area_var_samp_fieldsPromiseChain & {
    execute: (
      request: lonely_area_var_samp_fieldsRequest,
      defaultValue?: lonely_area_var_samp_fields | null,
    ) => Promise<lonely_area_var_samp_fields | null>
  }
  variance: lonely_area_variance_fieldsPromiseChain & {
    execute: (
      request: lonely_area_variance_fieldsRequest,
      defaultValue?: lonely_area_variance_fields | null,
    ) => Promise<lonely_area_variance_fields | null>
  }
}

/** aggregate fields of "lonely.area" */
export interface lonely_area_aggregate_fieldsObservableChain {
  avg: lonely_area_avg_fieldsObservableChain & {
    execute: (
      request: lonely_area_avg_fieldsRequest,
      defaultValue?: lonely_area_avg_fields | null,
    ) => Observable<lonely_area_avg_fields | null>
  }
  count: ((args?: {
    columns?: lonely_area_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: lonely_area_max_fieldsObservableChain & {
    execute: (
      request: lonely_area_max_fieldsRequest,
      defaultValue?: lonely_area_max_fields | null,
    ) => Observable<lonely_area_max_fields | null>
  }
  min: lonely_area_min_fieldsObservableChain & {
    execute: (
      request: lonely_area_min_fieldsRequest,
      defaultValue?: lonely_area_min_fields | null,
    ) => Observable<lonely_area_min_fields | null>
  }
  stddev: lonely_area_stddev_fieldsObservableChain & {
    execute: (
      request: lonely_area_stddev_fieldsRequest,
      defaultValue?: lonely_area_stddev_fields | null,
    ) => Observable<lonely_area_stddev_fields | null>
  }
  stddev_pop: lonely_area_stddev_pop_fieldsObservableChain & {
    execute: (
      request: lonely_area_stddev_pop_fieldsRequest,
      defaultValue?: lonely_area_stddev_pop_fields | null,
    ) => Observable<lonely_area_stddev_pop_fields | null>
  }
  stddev_samp: lonely_area_stddev_samp_fieldsObservableChain & {
    execute: (
      request: lonely_area_stddev_samp_fieldsRequest,
      defaultValue?: lonely_area_stddev_samp_fields | null,
    ) => Observable<lonely_area_stddev_samp_fields | null>
  }
  sum: lonely_area_sum_fieldsObservableChain & {
    execute: (
      request: lonely_area_sum_fieldsRequest,
      defaultValue?: lonely_area_sum_fields | null,
    ) => Observable<lonely_area_sum_fields | null>
  }
  var_pop: lonely_area_var_pop_fieldsObservableChain & {
    execute: (
      request: lonely_area_var_pop_fieldsRequest,
      defaultValue?: lonely_area_var_pop_fields | null,
    ) => Observable<lonely_area_var_pop_fields | null>
  }
  var_samp: lonely_area_var_samp_fieldsObservableChain & {
    execute: (
      request: lonely_area_var_samp_fieldsRequest,
      defaultValue?: lonely_area_var_samp_fields | null,
    ) => Observable<lonely_area_var_samp_fields | null>
  }
  variance: lonely_area_variance_fieldsObservableChain & {
    execute: (
      request: lonely_area_variance_fieldsRequest,
      defaultValue?: lonely_area_variance_fields | null,
    ) => Observable<lonely_area_variance_fields | null>
  }
}

/** aggregate avg on columns */
export interface lonely_area_avg_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate avg on columns */
export interface lonely_area_avg_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate max on columns */
export interface lonely_area_max_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate max on columns */
export interface lonely_area_max_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate min on columns */
export interface lonely_area_min_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate min on columns */
export interface lonely_area_min_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate stddev on columns */
export interface lonely_area_stddev_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev on columns */
export interface lonely_area_stddev_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface lonely_area_stddev_pop_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface lonely_area_stddev_pop_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface lonely_area_stddev_samp_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface lonely_area_stddev_samp_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate sum on columns */
export interface lonely_area_sum_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
}

/** aggregate sum on columns */
export interface lonely_area_sum_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
}

/** aggregate var_pop on columns */
export interface lonely_area_var_pop_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_pop on columns */
export interface lonely_area_var_pop_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate var_samp on columns */
export interface lonely_area_var_samp_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_samp on columns */
export interface lonely_area_var_samp_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate variance on columns */
export interface lonely_area_variance_fieldsPromiseChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate variance on columns */
export interface lonely_area_variance_fieldsObservableChain {
  count: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** columns and relationships of "lonely.device" */
export interface lonely_devicePromiseChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  area: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  last_report_time: { execute: (request?: boolean | number, defaultValue?: bigint) => Promise<bigint> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  location: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
}

/** columns and relationships of "lonely.device" */
export interface lonely_deviceObservableChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  area: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  last_report_time: { execute: (request?: boolean | number, defaultValue?: bigint) => Observable<bigint> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  location: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
}

/** aggregated selection of "lonely.device" */
export interface lonely_device_aggregatePromiseChain {
  aggregate: lonely_device_aggregate_fieldsPromiseChain & {
    execute: (
      request: lonely_device_aggregate_fieldsRequest,
      defaultValue?: lonely_device_aggregate_fields | null,
    ) => Promise<lonely_device_aggregate_fields | null>
  }
  nodes: { execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Promise<lonely_device[]> }
}

/** aggregated selection of "lonely.device" */
export interface lonely_device_aggregateObservableChain {
  aggregate: lonely_device_aggregate_fieldsObservableChain & {
    execute: (
      request: lonely_device_aggregate_fieldsRequest,
      defaultValue?: lonely_device_aggregate_fields | null,
    ) => Observable<lonely_device_aggregate_fields | null>
  }
  nodes: { execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Observable<lonely_device[]> }
}

/** aggregate fields of "lonely.device" */
export interface lonely_device_aggregate_fieldsPromiseChain {
  avg: lonely_device_avg_fieldsPromiseChain & {
    execute: (
      request: lonely_device_avg_fieldsRequest,
      defaultValue?: lonely_device_avg_fields | null,
    ) => Promise<lonely_device_avg_fields | null>
  }
  count: ((args?: {
    columns?: lonely_device_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: lonely_device_max_fieldsPromiseChain & {
    execute: (
      request: lonely_device_max_fieldsRequest,
      defaultValue?: lonely_device_max_fields | null,
    ) => Promise<lonely_device_max_fields | null>
  }
  min: lonely_device_min_fieldsPromiseChain & {
    execute: (
      request: lonely_device_min_fieldsRequest,
      defaultValue?: lonely_device_min_fields | null,
    ) => Promise<lonely_device_min_fields | null>
  }
  stddev: lonely_device_stddev_fieldsPromiseChain & {
    execute: (
      request: lonely_device_stddev_fieldsRequest,
      defaultValue?: lonely_device_stddev_fields | null,
    ) => Promise<lonely_device_stddev_fields | null>
  }
  stddev_pop: lonely_device_stddev_pop_fieldsPromiseChain & {
    execute: (
      request: lonely_device_stddev_pop_fieldsRequest,
      defaultValue?: lonely_device_stddev_pop_fields | null,
    ) => Promise<lonely_device_stddev_pop_fields | null>
  }
  stddev_samp: lonely_device_stddev_samp_fieldsPromiseChain & {
    execute: (
      request: lonely_device_stddev_samp_fieldsRequest,
      defaultValue?: lonely_device_stddev_samp_fields | null,
    ) => Promise<lonely_device_stddev_samp_fields | null>
  }
  sum: lonely_device_sum_fieldsPromiseChain & {
    execute: (
      request: lonely_device_sum_fieldsRequest,
      defaultValue?: lonely_device_sum_fields | null,
    ) => Promise<lonely_device_sum_fields | null>
  }
  var_pop: lonely_device_var_pop_fieldsPromiseChain & {
    execute: (
      request: lonely_device_var_pop_fieldsRequest,
      defaultValue?: lonely_device_var_pop_fields | null,
    ) => Promise<lonely_device_var_pop_fields | null>
  }
  var_samp: lonely_device_var_samp_fieldsPromiseChain & {
    execute: (
      request: lonely_device_var_samp_fieldsRequest,
      defaultValue?: lonely_device_var_samp_fields | null,
    ) => Promise<lonely_device_var_samp_fields | null>
  }
  variance: lonely_device_variance_fieldsPromiseChain & {
    execute: (
      request: lonely_device_variance_fieldsRequest,
      defaultValue?: lonely_device_variance_fields | null,
    ) => Promise<lonely_device_variance_fields | null>
  }
}

/** aggregate fields of "lonely.device" */
export interface lonely_device_aggregate_fieldsObservableChain {
  avg: lonely_device_avg_fieldsObservableChain & {
    execute: (
      request: lonely_device_avg_fieldsRequest,
      defaultValue?: lonely_device_avg_fields | null,
    ) => Observable<lonely_device_avg_fields | null>
  }
  count: ((args?: {
    columns?: lonely_device_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: lonely_device_max_fieldsObservableChain & {
    execute: (
      request: lonely_device_max_fieldsRequest,
      defaultValue?: lonely_device_max_fields | null,
    ) => Observable<lonely_device_max_fields | null>
  }
  min: lonely_device_min_fieldsObservableChain & {
    execute: (
      request: lonely_device_min_fieldsRequest,
      defaultValue?: lonely_device_min_fields | null,
    ) => Observable<lonely_device_min_fields | null>
  }
  stddev: lonely_device_stddev_fieldsObservableChain & {
    execute: (
      request: lonely_device_stddev_fieldsRequest,
      defaultValue?: lonely_device_stddev_fields | null,
    ) => Observable<lonely_device_stddev_fields | null>
  }
  stddev_pop: lonely_device_stddev_pop_fieldsObservableChain & {
    execute: (
      request: lonely_device_stddev_pop_fieldsRequest,
      defaultValue?: lonely_device_stddev_pop_fields | null,
    ) => Observable<lonely_device_stddev_pop_fields | null>
  }
  stddev_samp: lonely_device_stddev_samp_fieldsObservableChain & {
    execute: (
      request: lonely_device_stddev_samp_fieldsRequest,
      defaultValue?: lonely_device_stddev_samp_fields | null,
    ) => Observable<lonely_device_stddev_samp_fields | null>
  }
  sum: lonely_device_sum_fieldsObservableChain & {
    execute: (
      request: lonely_device_sum_fieldsRequest,
      defaultValue?: lonely_device_sum_fields | null,
    ) => Observable<lonely_device_sum_fields | null>
  }
  var_pop: lonely_device_var_pop_fieldsObservableChain & {
    execute: (
      request: lonely_device_var_pop_fieldsRequest,
      defaultValue?: lonely_device_var_pop_fields | null,
    ) => Observable<lonely_device_var_pop_fields | null>
  }
  var_samp: lonely_device_var_samp_fieldsObservableChain & {
    execute: (
      request: lonely_device_var_samp_fieldsRequest,
      defaultValue?: lonely_device_var_samp_fields | null,
    ) => Observable<lonely_device_var_samp_fields | null>
  }
  variance: lonely_device_variance_fieldsObservableChain & {
    execute: (
      request: lonely_device_variance_fieldsRequest,
      defaultValue?: lonely_device_variance_fields | null,
    ) => Observable<lonely_device_variance_fields | null>
  }
}

/** aggregate avg on columns */
export interface lonely_device_avg_fieldsPromiseChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate avg on columns */
export interface lonely_device_avg_fieldsObservableChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate max on columns */
export interface lonely_device_max_fieldsPromiseChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  area: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  last_report_time: { execute: (request?: boolean | number, defaultValue?: bigint | null) => Promise<bigint | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  location: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate max on columns */
export interface lonely_device_max_fieldsObservableChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  area: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  last_report_time: { execute: (request?: boolean | number, defaultValue?: bigint | null) => Observable<bigint | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  location: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate min on columns */
export interface lonely_device_min_fieldsPromiseChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  area: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  last_report_time: { execute: (request?: boolean | number, defaultValue?: bigint | null) => Promise<bigint | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  location: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate min on columns */
export interface lonely_device_min_fieldsObservableChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  area: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  last_report_time: { execute: (request?: boolean | number, defaultValue?: bigint | null) => Observable<bigint | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  location: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate stddev on columns */
export interface lonely_device_stddev_fieldsPromiseChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev on columns */
export interface lonely_device_stddev_fieldsObservableChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface lonely_device_stddev_pop_fieldsPromiseChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface lonely_device_stddev_pop_fieldsObservableChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface lonely_device_stddev_samp_fieldsPromiseChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface lonely_device_stddev_samp_fieldsObservableChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate sum on columns */
export interface lonely_device_sum_fieldsPromiseChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: bigint | null) => Promise<bigint | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate sum on columns */
export interface lonely_device_sum_fieldsObservableChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: bigint | null) => Observable<bigint | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate var_pop on columns */
export interface lonely_device_var_pop_fieldsPromiseChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_pop on columns */
export interface lonely_device_var_pop_fieldsObservableChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate var_samp on columns */
export interface lonely_device_var_samp_fieldsPromiseChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_samp on columns */
export interface lonely_device_var_samp_fieldsObservableChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate variance on columns */
export interface lonely_device_variance_fieldsPromiseChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate variance on columns */
export interface lonely_device_variance_fieldsObservableChain {
  last_report_time: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  to_be_claimed: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** columns and relationships of "lonely.status" */
export interface lonely_statusPromiseChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  value: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
}

/** columns and relationships of "lonely.status" */
export interface lonely_statusObservableChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  value: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
}

/** aggregated selection of "lonely.status" */
export interface lonely_status_aggregatePromiseChain {
  aggregate: lonely_status_aggregate_fieldsPromiseChain & {
    execute: (
      request: lonely_status_aggregate_fieldsRequest,
      defaultValue?: lonely_status_aggregate_fields | null,
    ) => Promise<lonely_status_aggregate_fields | null>
  }
  nodes: { execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Promise<lonely_status[]> }
}

/** aggregated selection of "lonely.status" */
export interface lonely_status_aggregateObservableChain {
  aggregate: lonely_status_aggregate_fieldsObservableChain & {
    execute: (
      request: lonely_status_aggregate_fieldsRequest,
      defaultValue?: lonely_status_aggregate_fields | null,
    ) => Observable<lonely_status_aggregate_fields | null>
  }
  nodes: { execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Observable<lonely_status[]> }
}

/** aggregate fields of "lonely.status" */
export interface lonely_status_aggregate_fieldsPromiseChain {
  avg: lonely_status_avg_fieldsPromiseChain & {
    execute: (
      request: lonely_status_avg_fieldsRequest,
      defaultValue?: lonely_status_avg_fields | null,
    ) => Promise<lonely_status_avg_fields | null>
  }
  count: ((args?: {
    columns?: lonely_status_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: lonely_status_max_fieldsPromiseChain & {
    execute: (
      request: lonely_status_max_fieldsRequest,
      defaultValue?: lonely_status_max_fields | null,
    ) => Promise<lonely_status_max_fields | null>
  }
  min: lonely_status_min_fieldsPromiseChain & {
    execute: (
      request: lonely_status_min_fieldsRequest,
      defaultValue?: lonely_status_min_fields | null,
    ) => Promise<lonely_status_min_fields | null>
  }
  stddev: lonely_status_stddev_fieldsPromiseChain & {
    execute: (
      request: lonely_status_stddev_fieldsRequest,
      defaultValue?: lonely_status_stddev_fields | null,
    ) => Promise<lonely_status_stddev_fields | null>
  }
  stddev_pop: lonely_status_stddev_pop_fieldsPromiseChain & {
    execute: (
      request: lonely_status_stddev_pop_fieldsRequest,
      defaultValue?: lonely_status_stddev_pop_fields | null,
    ) => Promise<lonely_status_stddev_pop_fields | null>
  }
  stddev_samp: lonely_status_stddev_samp_fieldsPromiseChain & {
    execute: (
      request: lonely_status_stddev_samp_fieldsRequest,
      defaultValue?: lonely_status_stddev_samp_fields | null,
    ) => Promise<lonely_status_stddev_samp_fields | null>
  }
  sum: lonely_status_sum_fieldsPromiseChain & {
    execute: (
      request: lonely_status_sum_fieldsRequest,
      defaultValue?: lonely_status_sum_fields | null,
    ) => Promise<lonely_status_sum_fields | null>
  }
  var_pop: lonely_status_var_pop_fieldsPromiseChain & {
    execute: (
      request: lonely_status_var_pop_fieldsRequest,
      defaultValue?: lonely_status_var_pop_fields | null,
    ) => Promise<lonely_status_var_pop_fields | null>
  }
  var_samp: lonely_status_var_samp_fieldsPromiseChain & {
    execute: (
      request: lonely_status_var_samp_fieldsRequest,
      defaultValue?: lonely_status_var_samp_fields | null,
    ) => Promise<lonely_status_var_samp_fields | null>
  }
  variance: lonely_status_variance_fieldsPromiseChain & {
    execute: (
      request: lonely_status_variance_fieldsRequest,
      defaultValue?: lonely_status_variance_fields | null,
    ) => Promise<lonely_status_variance_fields | null>
  }
}

/** aggregate fields of "lonely.status" */
export interface lonely_status_aggregate_fieldsObservableChain {
  avg: lonely_status_avg_fieldsObservableChain & {
    execute: (
      request: lonely_status_avg_fieldsRequest,
      defaultValue?: lonely_status_avg_fields | null,
    ) => Observable<lonely_status_avg_fields | null>
  }
  count: ((args?: {
    columns?: lonely_status_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: lonely_status_max_fieldsObservableChain & {
    execute: (
      request: lonely_status_max_fieldsRequest,
      defaultValue?: lonely_status_max_fields | null,
    ) => Observable<lonely_status_max_fields | null>
  }
  min: lonely_status_min_fieldsObservableChain & {
    execute: (
      request: lonely_status_min_fieldsRequest,
      defaultValue?: lonely_status_min_fields | null,
    ) => Observable<lonely_status_min_fields | null>
  }
  stddev: lonely_status_stddev_fieldsObservableChain & {
    execute: (
      request: lonely_status_stddev_fieldsRequest,
      defaultValue?: lonely_status_stddev_fields | null,
    ) => Observable<lonely_status_stddev_fields | null>
  }
  stddev_pop: lonely_status_stddev_pop_fieldsObservableChain & {
    execute: (
      request: lonely_status_stddev_pop_fieldsRequest,
      defaultValue?: lonely_status_stddev_pop_fields | null,
    ) => Observable<lonely_status_stddev_pop_fields | null>
  }
  stddev_samp: lonely_status_stddev_samp_fieldsObservableChain & {
    execute: (
      request: lonely_status_stddev_samp_fieldsRequest,
      defaultValue?: lonely_status_stddev_samp_fields | null,
    ) => Observable<lonely_status_stddev_samp_fields | null>
  }
  sum: lonely_status_sum_fieldsObservableChain & {
    execute: (
      request: lonely_status_sum_fieldsRequest,
      defaultValue?: lonely_status_sum_fields | null,
    ) => Observable<lonely_status_sum_fields | null>
  }
  var_pop: lonely_status_var_pop_fieldsObservableChain & {
    execute: (
      request: lonely_status_var_pop_fieldsRequest,
      defaultValue?: lonely_status_var_pop_fields | null,
    ) => Observable<lonely_status_var_pop_fields | null>
  }
  var_samp: lonely_status_var_samp_fieldsObservableChain & {
    execute: (
      request: lonely_status_var_samp_fieldsRequest,
      defaultValue?: lonely_status_var_samp_fields | null,
    ) => Observable<lonely_status_var_samp_fields | null>
  }
  variance: lonely_status_variance_fieldsObservableChain & {
    execute: (
      request: lonely_status_variance_fieldsRequest,
      defaultValue?: lonely_status_variance_fields | null,
    ) => Observable<lonely_status_variance_fields | null>
  }
}

/** aggregate avg on columns */
export interface lonely_status_avg_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate avg on columns */
export interface lonely_status_avg_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate max on columns */
export interface lonely_status_max_fieldsPromiseChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate max on columns */
export interface lonely_status_max_fieldsObservableChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate min on columns */
export interface lonely_status_min_fieldsPromiseChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate min on columns */
export interface lonely_status_min_fieldsObservableChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate stddev on columns */
export interface lonely_status_stddev_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev on columns */
export interface lonely_status_stddev_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface lonely_status_stddev_pop_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface lonely_status_stddev_pop_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface lonely_status_stddev_samp_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface lonely_status_stddev_samp_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate sum on columns */
export interface lonely_status_sum_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate sum on columns */
export interface lonely_status_sum_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate var_pop on columns */
export interface lonely_status_var_pop_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_pop on columns */
export interface lonely_status_var_pop_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate var_samp on columns */
export interface lonely_status_var_samp_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_samp on columns */
export interface lonely_status_var_samp_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate variance on columns */
export interface lonely_status_variance_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate variance on columns */
export interface lonely_status_variance_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** columns and relationships of "pebble.account" */
export interface pebble_accountPromiseChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
}

/** columns and relationships of "pebble.account" */
export interface pebble_accountObservableChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
}

/** aggregated selection of "pebble.account" */
export interface pebble_account_aggregatePromiseChain {
  aggregate: pebble_account_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_account_aggregate_fieldsRequest,
      defaultValue?: pebble_account_aggregate_fields | null,
    ) => Promise<pebble_account_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Promise<pebble_account[]> }
}

/** aggregated selection of "pebble.account" */
export interface pebble_account_aggregateObservableChain {
  aggregate: pebble_account_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_account_aggregate_fieldsRequest,
      defaultValue?: pebble_account_aggregate_fields | null,
    ) => Observable<pebble_account_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Observable<pebble_account[]> }
}

/** aggregate fields of "pebble.account" */
export interface pebble_account_aggregate_fieldsPromiseChain {
  count: ((args?: {
    columns?: pebble_account_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_account_max_fieldsPromiseChain & {
    execute: (
      request: pebble_account_max_fieldsRequest,
      defaultValue?: pebble_account_max_fields | null,
    ) => Promise<pebble_account_max_fields | null>
  }
  min: pebble_account_min_fieldsPromiseChain & {
    execute: (
      request: pebble_account_min_fieldsRequest,
      defaultValue?: pebble_account_min_fields | null,
    ) => Promise<pebble_account_min_fields | null>
  }
}

/** aggregate fields of "pebble.account" */
export interface pebble_account_aggregate_fieldsObservableChain {
  count: ((args?: {
    columns?: pebble_account_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_account_max_fieldsObservableChain & {
    execute: (
      request: pebble_account_max_fieldsRequest,
      defaultValue?: pebble_account_max_fields | null,
    ) => Observable<pebble_account_max_fields | null>
  }
  min: pebble_account_min_fieldsObservableChain & {
    execute: (
      request: pebble_account_min_fieldsRequest,
      defaultValue?: pebble_account_min_fields | null,
    ) => Observable<pebble_account_min_fields | null>
  }
}

/** aggregate max on columns */
export interface pebble_account_max_fieldsPromiseChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate max on columns */
export interface pebble_account_max_fieldsObservableChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate min on columns */
export interface pebble_account_min_fieldsPromiseChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate min on columns */
export interface pebble_account_min_fieldsObservableChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** columns and relationships of "pebble.app" */
export interface pebble_appPromiseChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  uri: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  version: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** columns and relationships of "pebble.app" */
export interface pebble_appObservableChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  uri: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  version: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

/** aggregated selection of "pebble.app" */
export interface pebble_app_aggregatePromiseChain {
  aggregate: pebble_app_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_app_aggregate_fieldsRequest,
      defaultValue?: pebble_app_aggregate_fields | null,
    ) => Promise<pebble_app_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Promise<pebble_app[]> }
}

/** aggregated selection of "pebble.app" */
export interface pebble_app_aggregateObservableChain {
  aggregate: pebble_app_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_app_aggregate_fieldsRequest,
      defaultValue?: pebble_app_aggregate_fields | null,
    ) => Observable<pebble_app_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Observable<pebble_app[]> }
}

/** aggregate fields of "pebble.app" */
export interface pebble_app_aggregate_fieldsPromiseChain {
  count: ((args?: {
    columns?: pebble_app_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_app_max_fieldsPromiseChain & {
    execute: (
      request: pebble_app_max_fieldsRequest,
      defaultValue?: pebble_app_max_fields | null,
    ) => Promise<pebble_app_max_fields | null>
  }
  min: pebble_app_min_fieldsPromiseChain & {
    execute: (
      request: pebble_app_min_fieldsRequest,
      defaultValue?: pebble_app_min_fields | null,
    ) => Promise<pebble_app_min_fields | null>
  }
}

/** aggregate fields of "pebble.app" */
export interface pebble_app_aggregate_fieldsObservableChain {
  count: ((args?: {
    columns?: pebble_app_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_app_max_fieldsObservableChain & {
    execute: (
      request: pebble_app_max_fieldsRequest,
      defaultValue?: pebble_app_max_fields | null,
    ) => Observable<pebble_app_max_fields | null>
  }
  min: pebble_app_min_fieldsObservableChain & {
    execute: (
      request: pebble_app_min_fieldsRequest,
      defaultValue?: pebble_app_min_fields | null,
    ) => Observable<pebble_app_min_fields | null>
  }
}

/** aggregate max on columns */
export interface pebble_app_max_fieldsPromiseChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** aggregate max on columns */
export interface pebble_app_max_fieldsObservableChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

/** aggregate min on columns */
export interface pebble_app_min_fieldsPromiseChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** aggregate min on columns */
export interface pebble_app_min_fieldsObservableChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

/** columns and relationships of "pebble.app_v2" */
export interface pebble_app_v2PromiseChain {
  author: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  category: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Promise<date | null> }
  data: ((args?: {
    /** JSON select path */
    path?: String | null
  }) => { execute: (request?: boolean | number, defaultValue?: jsonb | null) => Promise<jsonb | null> }) & {
    execute: (request?: boolean | number, defaultValue?: jsonb | null) => Promise<jsonb | null>
  }
  date: { execute: (request?: boolean | number, defaultValue?: date | null) => Promise<date | null> }
  direct_link: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  logo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  previews: { execute: (request?: boolean | number, defaultValue?: _text | null) => Promise<_text | null> }
  slug: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Promise<date | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** columns and relationships of "pebble.app_v2" */
export interface pebble_app_v2ObservableChain {
  author: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  category: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Observable<date | null> }
  data: ((args?: {
    /** JSON select path */
    path?: String | null
  }) => { execute: (request?: boolean | number, defaultValue?: jsonb | null) => Observable<jsonb | null> }) & {
    execute: (request?: boolean | number, defaultValue?: jsonb | null) => Observable<jsonb | null>
  }
  date: { execute: (request?: boolean | number, defaultValue?: date | null) => Observable<date | null> }
  direct_link: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  logo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  previews: { execute: (request?: boolean | number, defaultValue?: _text | null) => Observable<_text | null> }
  slug: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Observable<date | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

/** aggregated selection of "pebble.app_v2" */
export interface pebble_app_v2_aggregatePromiseChain {
  aggregate: pebble_app_v2_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_aggregate_fieldsRequest,
      defaultValue?: pebble_app_v2_aggregate_fields | null,
    ) => Promise<pebble_app_v2_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Promise<pebble_app_v2[]> }
}

/** aggregated selection of "pebble.app_v2" */
export interface pebble_app_v2_aggregateObservableChain {
  aggregate: pebble_app_v2_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_aggregate_fieldsRequest,
      defaultValue?: pebble_app_v2_aggregate_fields | null,
    ) => Observable<pebble_app_v2_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Observable<pebble_app_v2[]> }
}

/** aggregate fields of "pebble.app_v2" */
export interface pebble_app_v2_aggregate_fieldsPromiseChain {
  avg: pebble_app_v2_avg_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_avg_fieldsRequest,
      defaultValue?: pebble_app_v2_avg_fields | null,
    ) => Promise<pebble_app_v2_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_app_v2_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_app_v2_max_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_max_fieldsRequest,
      defaultValue?: pebble_app_v2_max_fields | null,
    ) => Promise<pebble_app_v2_max_fields | null>
  }
  min: pebble_app_v2_min_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_min_fieldsRequest,
      defaultValue?: pebble_app_v2_min_fields | null,
    ) => Promise<pebble_app_v2_min_fields | null>
  }
  stddev: pebble_app_v2_stddev_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_stddev_fieldsRequest,
      defaultValue?: pebble_app_v2_stddev_fields | null,
    ) => Promise<pebble_app_v2_stddev_fields | null>
  }
  stddev_pop: pebble_app_v2_stddev_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_stddev_pop_fieldsRequest,
      defaultValue?: pebble_app_v2_stddev_pop_fields | null,
    ) => Promise<pebble_app_v2_stddev_pop_fields | null>
  }
  stddev_samp: pebble_app_v2_stddev_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_stddev_samp_fieldsRequest,
      defaultValue?: pebble_app_v2_stddev_samp_fields | null,
    ) => Promise<pebble_app_v2_stddev_samp_fields | null>
  }
  sum: pebble_app_v2_sum_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_sum_fieldsRequest,
      defaultValue?: pebble_app_v2_sum_fields | null,
    ) => Promise<pebble_app_v2_sum_fields | null>
  }
  var_pop: pebble_app_v2_var_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_var_pop_fieldsRequest,
      defaultValue?: pebble_app_v2_var_pop_fields | null,
    ) => Promise<pebble_app_v2_var_pop_fields | null>
  }
  var_samp: pebble_app_v2_var_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_var_samp_fieldsRequest,
      defaultValue?: pebble_app_v2_var_samp_fields | null,
    ) => Promise<pebble_app_v2_var_samp_fields | null>
  }
  variance: pebble_app_v2_variance_fieldsPromiseChain & {
    execute: (
      request: pebble_app_v2_variance_fieldsRequest,
      defaultValue?: pebble_app_v2_variance_fields | null,
    ) => Promise<pebble_app_v2_variance_fields | null>
  }
}

/** aggregate fields of "pebble.app_v2" */
export interface pebble_app_v2_aggregate_fieldsObservableChain {
  avg: pebble_app_v2_avg_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_avg_fieldsRequest,
      defaultValue?: pebble_app_v2_avg_fields | null,
    ) => Observable<pebble_app_v2_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_app_v2_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_app_v2_max_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_max_fieldsRequest,
      defaultValue?: pebble_app_v2_max_fields | null,
    ) => Observable<pebble_app_v2_max_fields | null>
  }
  min: pebble_app_v2_min_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_min_fieldsRequest,
      defaultValue?: pebble_app_v2_min_fields | null,
    ) => Observable<pebble_app_v2_min_fields | null>
  }
  stddev: pebble_app_v2_stddev_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_stddev_fieldsRequest,
      defaultValue?: pebble_app_v2_stddev_fields | null,
    ) => Observable<pebble_app_v2_stddev_fields | null>
  }
  stddev_pop: pebble_app_v2_stddev_pop_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_stddev_pop_fieldsRequest,
      defaultValue?: pebble_app_v2_stddev_pop_fields | null,
    ) => Observable<pebble_app_v2_stddev_pop_fields | null>
  }
  stddev_samp: pebble_app_v2_stddev_samp_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_stddev_samp_fieldsRequest,
      defaultValue?: pebble_app_v2_stddev_samp_fields | null,
    ) => Observable<pebble_app_v2_stddev_samp_fields | null>
  }
  sum: pebble_app_v2_sum_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_sum_fieldsRequest,
      defaultValue?: pebble_app_v2_sum_fields | null,
    ) => Observable<pebble_app_v2_sum_fields | null>
  }
  var_pop: pebble_app_v2_var_pop_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_var_pop_fieldsRequest,
      defaultValue?: pebble_app_v2_var_pop_fields | null,
    ) => Observable<pebble_app_v2_var_pop_fields | null>
  }
  var_samp: pebble_app_v2_var_samp_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_var_samp_fieldsRequest,
      defaultValue?: pebble_app_v2_var_samp_fields | null,
    ) => Observable<pebble_app_v2_var_samp_fields | null>
  }
  variance: pebble_app_v2_variance_fieldsObservableChain & {
    execute: (
      request: pebble_app_v2_variance_fieldsRequest,
      defaultValue?: pebble_app_v2_variance_fields | null,
    ) => Observable<pebble_app_v2_variance_fields | null>
  }
}

/** aggregate avg on columns */
export interface pebble_app_v2_avg_fieldsPromiseChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate avg on columns */
export interface pebble_app_v2_avg_fieldsObservableChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate max on columns */
export interface pebble_app_v2_max_fieldsPromiseChain {
  author: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  category: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Promise<date | null> }
  date: { execute: (request?: boolean | number, defaultValue?: date | null) => Promise<date | null> }
  direct_link: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  logo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  slug: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Promise<date | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** aggregate max on columns */
export interface pebble_app_v2_max_fieldsObservableChain {
  author: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  category: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Observable<date | null> }
  date: { execute: (request?: boolean | number, defaultValue?: date | null) => Observable<date | null> }
  direct_link: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  logo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  slug: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Observable<date | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

/** aggregate min on columns */
export interface pebble_app_v2_min_fieldsPromiseChain {
  author: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  category: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Promise<date | null> }
  date: { execute: (request?: boolean | number, defaultValue?: date | null) => Promise<date | null> }
  direct_link: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  logo: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  slug: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Promise<date | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** aggregate min on columns */
export interface pebble_app_v2_min_fieldsObservableChain {
  author: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  category: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  content: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Observable<date | null> }
  date: { execute: (request?: boolean | number, defaultValue?: date | null) => Observable<date | null> }
  direct_link: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  logo: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  slug: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  status: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: date | null) => Observable<date | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

/** aggregate stddev on columns */
export interface pebble_app_v2_stddev_fieldsPromiseChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev on columns */
export interface pebble_app_v2_stddev_fieldsObservableChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_app_v2_stddev_pop_fieldsPromiseChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_app_v2_stddev_pop_fieldsObservableChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_app_v2_stddev_samp_fieldsPromiseChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_app_v2_stddev_samp_fieldsObservableChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate sum on columns */
export interface pebble_app_v2_sum_fieldsPromiseChain {
  category: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate sum on columns */
export interface pebble_app_v2_sum_fieldsObservableChain {
  category: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate var_pop on columns */
export interface pebble_app_v2_var_pop_fieldsPromiseChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_pop on columns */
export interface pebble_app_v2_var_pop_fieldsObservableChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_app_v2_var_samp_fieldsPromiseChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_app_v2_var_samp_fieldsObservableChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_app_v2_variance_fieldsPromiseChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_app_v2_variance_fieldsObservableChain {
  category: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  order: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** columns and relationships of "pebble.bank" */
export interface pebble_bankPromiseChain {
  address: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  balance: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
}

/** columns and relationships of "pebble.bank" */
export interface pebble_bankObservableChain {
  address: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  balance: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
}

/** aggregated selection of "pebble.bank" */
export interface pebble_bank_aggregatePromiseChain {
  aggregate: pebble_bank_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_aggregate_fieldsRequest,
      defaultValue?: pebble_bank_aggregate_fields | null,
    ) => Promise<pebble_bank_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Promise<pebble_bank[]> }
}

/** aggregated selection of "pebble.bank" */
export interface pebble_bank_aggregateObservableChain {
  aggregate: pebble_bank_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_bank_aggregate_fieldsRequest,
      defaultValue?: pebble_bank_aggregate_fields | null,
    ) => Observable<pebble_bank_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Observable<pebble_bank[]> }
}

/** aggregate fields of "pebble.bank" */
export interface pebble_bank_aggregate_fieldsPromiseChain {
  count: ((args?: {
    columns?: pebble_bank_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_bank_max_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_max_fieldsRequest,
      defaultValue?: pebble_bank_max_fields | null,
    ) => Promise<pebble_bank_max_fields | null>
  }
  min: pebble_bank_min_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_min_fieldsRequest,
      defaultValue?: pebble_bank_min_fields | null,
    ) => Promise<pebble_bank_min_fields | null>
  }
}

/** aggregate fields of "pebble.bank" */
export interface pebble_bank_aggregate_fieldsObservableChain {
  count: ((args?: {
    columns?: pebble_bank_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_bank_max_fieldsObservableChain & {
    execute: (
      request: pebble_bank_max_fieldsRequest,
      defaultValue?: pebble_bank_max_fields | null,
    ) => Observable<pebble_bank_max_fields | null>
  }
  min: pebble_bank_min_fieldsObservableChain & {
    execute: (
      request: pebble_bank_min_fieldsRequest,
      defaultValue?: pebble_bank_min_fields | null,
    ) => Observable<pebble_bank_min_fields | null>
  }
}

/** aggregate max on columns */
export interface pebble_bank_max_fieldsPromiseChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  balance: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate max on columns */
export interface pebble_bank_max_fieldsObservableChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  balance: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate min on columns */
export interface pebble_bank_min_fieldsPromiseChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  balance: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate min on columns */
export interface pebble_bank_min_fieldsObservableChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  balance: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** columns and relationships of "pebble.bank_record" */
export interface pebble_bank_recordPromiseChain {
  amount: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  from: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  to: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  type: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
}

/** columns and relationships of "pebble.bank_record" */
export interface pebble_bank_recordObservableChain {
  amount: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  from: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  to: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  type: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
}

/** aggregated selection of "pebble.bank_record" */
export interface pebble_bank_record_aggregatePromiseChain {
  aggregate: pebble_bank_record_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_aggregate_fieldsRequest,
      defaultValue?: pebble_bank_record_aggregate_fields | null,
    ) => Promise<pebble_bank_record_aggregate_fields | null>
  }
  nodes: {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Promise<pebble_bank_record[]>
  }
}

/** aggregated selection of "pebble.bank_record" */
export interface pebble_bank_record_aggregateObservableChain {
  aggregate: pebble_bank_record_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_aggregate_fieldsRequest,
      defaultValue?: pebble_bank_record_aggregate_fields | null,
    ) => Observable<pebble_bank_record_aggregate_fields | null>
  }
  nodes: {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Observable<pebble_bank_record[]>
  }
}

/** aggregate fields of "pebble.bank_record" */
export interface pebble_bank_record_aggregate_fieldsPromiseChain {
  avg: pebble_bank_record_avg_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_avg_fieldsRequest,
      defaultValue?: pebble_bank_record_avg_fields | null,
    ) => Promise<pebble_bank_record_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_bank_record_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_bank_record_max_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_max_fieldsRequest,
      defaultValue?: pebble_bank_record_max_fields | null,
    ) => Promise<pebble_bank_record_max_fields | null>
  }
  min: pebble_bank_record_min_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_min_fieldsRequest,
      defaultValue?: pebble_bank_record_min_fields | null,
    ) => Promise<pebble_bank_record_min_fields | null>
  }
  stddev: pebble_bank_record_stddev_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_stddev_fieldsRequest,
      defaultValue?: pebble_bank_record_stddev_fields | null,
    ) => Promise<pebble_bank_record_stddev_fields | null>
  }
  stddev_pop: pebble_bank_record_stddev_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_stddev_pop_fieldsRequest,
      defaultValue?: pebble_bank_record_stddev_pop_fields | null,
    ) => Promise<pebble_bank_record_stddev_pop_fields | null>
  }
  stddev_samp: pebble_bank_record_stddev_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_stddev_samp_fieldsRequest,
      defaultValue?: pebble_bank_record_stddev_samp_fields | null,
    ) => Promise<pebble_bank_record_stddev_samp_fields | null>
  }
  sum: pebble_bank_record_sum_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_sum_fieldsRequest,
      defaultValue?: pebble_bank_record_sum_fields | null,
    ) => Promise<pebble_bank_record_sum_fields | null>
  }
  var_pop: pebble_bank_record_var_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_var_pop_fieldsRequest,
      defaultValue?: pebble_bank_record_var_pop_fields | null,
    ) => Promise<pebble_bank_record_var_pop_fields | null>
  }
  var_samp: pebble_bank_record_var_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_var_samp_fieldsRequest,
      defaultValue?: pebble_bank_record_var_samp_fields | null,
    ) => Promise<pebble_bank_record_var_samp_fields | null>
  }
  variance: pebble_bank_record_variance_fieldsPromiseChain & {
    execute: (
      request: pebble_bank_record_variance_fieldsRequest,
      defaultValue?: pebble_bank_record_variance_fields | null,
    ) => Promise<pebble_bank_record_variance_fields | null>
  }
}

/** aggregate fields of "pebble.bank_record" */
export interface pebble_bank_record_aggregate_fieldsObservableChain {
  avg: pebble_bank_record_avg_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_avg_fieldsRequest,
      defaultValue?: pebble_bank_record_avg_fields | null,
    ) => Observable<pebble_bank_record_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_bank_record_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_bank_record_max_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_max_fieldsRequest,
      defaultValue?: pebble_bank_record_max_fields | null,
    ) => Observable<pebble_bank_record_max_fields | null>
  }
  min: pebble_bank_record_min_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_min_fieldsRequest,
      defaultValue?: pebble_bank_record_min_fields | null,
    ) => Observable<pebble_bank_record_min_fields | null>
  }
  stddev: pebble_bank_record_stddev_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_stddev_fieldsRequest,
      defaultValue?: pebble_bank_record_stddev_fields | null,
    ) => Observable<pebble_bank_record_stddev_fields | null>
  }
  stddev_pop: pebble_bank_record_stddev_pop_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_stddev_pop_fieldsRequest,
      defaultValue?: pebble_bank_record_stddev_pop_fields | null,
    ) => Observable<pebble_bank_record_stddev_pop_fields | null>
  }
  stddev_samp: pebble_bank_record_stddev_samp_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_stddev_samp_fieldsRequest,
      defaultValue?: pebble_bank_record_stddev_samp_fields | null,
    ) => Observable<pebble_bank_record_stddev_samp_fields | null>
  }
  sum: pebble_bank_record_sum_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_sum_fieldsRequest,
      defaultValue?: pebble_bank_record_sum_fields | null,
    ) => Observable<pebble_bank_record_sum_fields | null>
  }
  var_pop: pebble_bank_record_var_pop_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_var_pop_fieldsRequest,
      defaultValue?: pebble_bank_record_var_pop_fields | null,
    ) => Observable<pebble_bank_record_var_pop_fields | null>
  }
  var_samp: pebble_bank_record_var_samp_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_var_samp_fieldsRequest,
      defaultValue?: pebble_bank_record_var_samp_fields | null,
    ) => Observable<pebble_bank_record_var_samp_fields | null>
  }
  variance: pebble_bank_record_variance_fieldsObservableChain & {
    execute: (
      request: pebble_bank_record_variance_fieldsRequest,
      defaultValue?: pebble_bank_record_variance_fields | null,
    ) => Observable<pebble_bank_record_variance_fields | null>
  }
}

/** aggregate avg on columns */
export interface pebble_bank_record_avg_fieldsPromiseChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate avg on columns */
export interface pebble_bank_record_avg_fieldsObservableChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate max on columns */
export interface pebble_bank_record_max_fieldsPromiseChain {
  amount: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  from: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  to: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate max on columns */
export interface pebble_bank_record_max_fieldsObservableChain {
  amount: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  from: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  to: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate min on columns */
export interface pebble_bank_record_min_fieldsPromiseChain {
  amount: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  from: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  to: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate min on columns */
export interface pebble_bank_record_min_fieldsObservableChain {
  amount: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  from: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  to: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate stddev on columns */
export interface pebble_bank_record_stddev_fieldsPromiseChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev on columns */
export interface pebble_bank_record_stddev_fieldsObservableChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_bank_record_stddev_pop_fieldsPromiseChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_bank_record_stddev_pop_fieldsObservableChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_bank_record_stddev_samp_fieldsPromiseChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_bank_record_stddev_samp_fieldsObservableChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate sum on columns */
export interface pebble_bank_record_sum_fieldsPromiseChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate sum on columns */
export interface pebble_bank_record_sum_fieldsObservableChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate var_pop on columns */
export interface pebble_bank_record_var_pop_fieldsPromiseChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_pop on columns */
export interface pebble_bank_record_var_pop_fieldsObservableChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_bank_record_var_samp_fieldsPromiseChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_bank_record_var_samp_fieldsObservableChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_bank_record_variance_fieldsPromiseChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_bank_record_variance_fieldsObservableChain {
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** columns and relationships of "pebble.config" */
export interface pebble_configPromiseChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  data: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  uri: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  version: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
}

/** columns and relationships of "pebble.config" */
export interface pebble_configObservableChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  data: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  uri: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  version: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
}

/** aggregated selection of "pebble.config" */
export interface pebble_config_aggregatePromiseChain {
  aggregate: pebble_config_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_config_aggregate_fieldsRequest,
      defaultValue?: pebble_config_aggregate_fields | null,
    ) => Promise<pebble_config_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Promise<pebble_config[]> }
}

/** aggregated selection of "pebble.config" */
export interface pebble_config_aggregateObservableChain {
  aggregate: pebble_config_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_config_aggregate_fieldsRequest,
      defaultValue?: pebble_config_aggregate_fields | null,
    ) => Observable<pebble_config_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Observable<pebble_config[]> }
}

/** aggregate fields of "pebble.config" */
export interface pebble_config_aggregate_fieldsPromiseChain {
  count: ((args?: {
    columns?: pebble_config_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_config_max_fieldsPromiseChain & {
    execute: (
      request: pebble_config_max_fieldsRequest,
      defaultValue?: pebble_config_max_fields | null,
    ) => Promise<pebble_config_max_fields | null>
  }
  min: pebble_config_min_fieldsPromiseChain & {
    execute: (
      request: pebble_config_min_fieldsRequest,
      defaultValue?: pebble_config_min_fields | null,
    ) => Promise<pebble_config_min_fields | null>
  }
}

/** aggregate fields of "pebble.config" */
export interface pebble_config_aggregate_fieldsObservableChain {
  count: ((args?: {
    columns?: pebble_config_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_config_max_fieldsObservableChain & {
    execute: (
      request: pebble_config_max_fieldsRequest,
      defaultValue?: pebble_config_max_fields | null,
    ) => Observable<pebble_config_max_fields | null>
  }
  min: pebble_config_min_fieldsObservableChain & {
    execute: (
      request: pebble_config_min_fieldsRequest,
      defaultValue?: pebble_config_min_fields | null,
    ) => Observable<pebble_config_min_fields | null>
  }
}

/** aggregate max on columns */
export interface pebble_config_max_fieldsPromiseChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  data: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** aggregate max on columns */
export interface pebble_config_max_fieldsObservableChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  data: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

/** aggregate min on columns */
export interface pebble_config_min_fieldsPromiseChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  data: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
}

/** aggregate min on columns */
export interface pebble_config_min_fieldsObservableChain {
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  data: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  uri: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  version: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
}

/** columns and relationships of "pebble.data_cache" */
export interface pebble_data_cachePromiseChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  data: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  id: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  imei: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  signature: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  type: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
}

/** columns and relationships of "pebble.data_cache" */
export interface pebble_data_cacheObservableChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  data: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  id: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  imei: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  signature: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  type: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
}

/** aggregated selection of "pebble.data_cache" */
export interface pebble_data_cache_aggregatePromiseChain {
  aggregate: pebble_data_cache_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_aggregate_fieldsRequest,
      defaultValue?: pebble_data_cache_aggregate_fields | null,
    ) => Promise<pebble_data_cache_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Promise<pebble_data_cache[]> }
}

/** aggregated selection of "pebble.data_cache" */
export interface pebble_data_cache_aggregateObservableChain {
  aggregate: pebble_data_cache_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_aggregate_fieldsRequest,
      defaultValue?: pebble_data_cache_aggregate_fields | null,
    ) => Observable<pebble_data_cache_aggregate_fields | null>
  }
  nodes: {
    execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Observable<pebble_data_cache[]>
  }
}

/** aggregate fields of "pebble.data_cache" */
export interface pebble_data_cache_aggregate_fieldsPromiseChain {
  avg: pebble_data_cache_avg_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_avg_fieldsRequest,
      defaultValue?: pebble_data_cache_avg_fields | null,
    ) => Promise<pebble_data_cache_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_data_cache_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_data_cache_max_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_max_fieldsRequest,
      defaultValue?: pebble_data_cache_max_fields | null,
    ) => Promise<pebble_data_cache_max_fields | null>
  }
  min: pebble_data_cache_min_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_min_fieldsRequest,
      defaultValue?: pebble_data_cache_min_fields | null,
    ) => Promise<pebble_data_cache_min_fields | null>
  }
  stddev: pebble_data_cache_stddev_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_stddev_fieldsRequest,
      defaultValue?: pebble_data_cache_stddev_fields | null,
    ) => Promise<pebble_data_cache_stddev_fields | null>
  }
  stddev_pop: pebble_data_cache_stddev_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_stddev_pop_fieldsRequest,
      defaultValue?: pebble_data_cache_stddev_pop_fields | null,
    ) => Promise<pebble_data_cache_stddev_pop_fields | null>
  }
  stddev_samp: pebble_data_cache_stddev_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_stddev_samp_fieldsRequest,
      defaultValue?: pebble_data_cache_stddev_samp_fields | null,
    ) => Promise<pebble_data_cache_stddev_samp_fields | null>
  }
  sum: pebble_data_cache_sum_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_sum_fieldsRequest,
      defaultValue?: pebble_data_cache_sum_fields | null,
    ) => Promise<pebble_data_cache_sum_fields | null>
  }
  var_pop: pebble_data_cache_var_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_var_pop_fieldsRequest,
      defaultValue?: pebble_data_cache_var_pop_fields | null,
    ) => Promise<pebble_data_cache_var_pop_fields | null>
  }
  var_samp: pebble_data_cache_var_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_var_samp_fieldsRequest,
      defaultValue?: pebble_data_cache_var_samp_fields | null,
    ) => Promise<pebble_data_cache_var_samp_fields | null>
  }
  variance: pebble_data_cache_variance_fieldsPromiseChain & {
    execute: (
      request: pebble_data_cache_variance_fieldsRequest,
      defaultValue?: pebble_data_cache_variance_fields | null,
    ) => Promise<pebble_data_cache_variance_fields | null>
  }
}

/** aggregate fields of "pebble.data_cache" */
export interface pebble_data_cache_aggregate_fieldsObservableChain {
  avg: pebble_data_cache_avg_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_avg_fieldsRequest,
      defaultValue?: pebble_data_cache_avg_fields | null,
    ) => Observable<pebble_data_cache_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_data_cache_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_data_cache_max_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_max_fieldsRequest,
      defaultValue?: pebble_data_cache_max_fields | null,
    ) => Observable<pebble_data_cache_max_fields | null>
  }
  min: pebble_data_cache_min_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_min_fieldsRequest,
      defaultValue?: pebble_data_cache_min_fields | null,
    ) => Observable<pebble_data_cache_min_fields | null>
  }
  stddev: pebble_data_cache_stddev_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_stddev_fieldsRequest,
      defaultValue?: pebble_data_cache_stddev_fields | null,
    ) => Observable<pebble_data_cache_stddev_fields | null>
  }
  stddev_pop: pebble_data_cache_stddev_pop_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_stddev_pop_fieldsRequest,
      defaultValue?: pebble_data_cache_stddev_pop_fields | null,
    ) => Observable<pebble_data_cache_stddev_pop_fields | null>
  }
  stddev_samp: pebble_data_cache_stddev_samp_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_stddev_samp_fieldsRequest,
      defaultValue?: pebble_data_cache_stddev_samp_fields | null,
    ) => Observable<pebble_data_cache_stddev_samp_fields | null>
  }
  sum: pebble_data_cache_sum_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_sum_fieldsRequest,
      defaultValue?: pebble_data_cache_sum_fields | null,
    ) => Observable<pebble_data_cache_sum_fields | null>
  }
  var_pop: pebble_data_cache_var_pop_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_var_pop_fieldsRequest,
      defaultValue?: pebble_data_cache_var_pop_fields | null,
    ) => Observable<pebble_data_cache_var_pop_fields | null>
  }
  var_samp: pebble_data_cache_var_samp_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_var_samp_fieldsRequest,
      defaultValue?: pebble_data_cache_var_samp_fields | null,
    ) => Observable<pebble_data_cache_var_samp_fields | null>
  }
  variance: pebble_data_cache_variance_fieldsObservableChain & {
    execute: (
      request: pebble_data_cache_variance_fieldsRequest,
      defaultValue?: pebble_data_cache_variance_fields | null,
    ) => Observable<pebble_data_cache_variance_fields | null>
  }
}

/** aggregate avg on columns */
export interface pebble_data_cache_avg_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate avg on columns */
export interface pebble_data_cache_avg_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate max on columns */
export interface pebble_data_cache_max_fieldsPromiseChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  data: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  imei: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  signature: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate max on columns */
export interface pebble_data_cache_max_fieldsObservableChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  data: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  imei: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  signature: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate min on columns */
export interface pebble_data_cache_min_fieldsPromiseChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  data: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  imei: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  signature: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
}

/** aggregate min on columns */
export interface pebble_data_cache_min_fieldsObservableChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  data: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  imei: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  signature: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
}

/** aggregate stddev on columns */
export interface pebble_data_cache_stddev_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev on columns */
export interface pebble_data_cache_stddev_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_data_cache_stddev_pop_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_data_cache_stddev_pop_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_data_cache_stddev_samp_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_data_cache_stddev_samp_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate sum on columns */
export interface pebble_data_cache_sum_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate sum on columns */
export interface pebble_data_cache_sum_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate var_pop on columns */
export interface pebble_data_cache_var_pop_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_pop on columns */
export interface pebble_data_cache_var_pop_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_data_cache_var_samp_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_data_cache_var_samp_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_data_cache_variance_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_data_cache_variance_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** columns and relationships of "pebble.device" */
export interface pebble_devicePromiseChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  beep: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  config: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** An array relationship */
  deviceRecord: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => {
    execute: (request: pebble_device_recordRequest, defaultValue?: pebble_device_record[]) => Promise<pebble_device_record[]>
  }) & {
    execute: (request: pebble_device_recordRequest, defaultValue?: pebble_device_record[]) => Promise<pebble_device_record[]>
  }
  /** An aggregate relationship */
  deviceRecord_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => pebble_device_record_aggregatePromiseChain & {
    execute: (
      request: pebble_device_record_aggregateRequest,
      defaultValue?: pebble_device_record_aggregate,
    ) => Promise<pebble_device_record_aggregate>
  }) &
    (pebble_device_record_aggregatePromiseChain & {
      execute: (
        request: pebble_device_record_aggregateRequest,
        defaultValue?: pebble_device_record_aggregate,
      ) => Promise<pebble_device_record_aggregate>
    })
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  proposer: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  real_firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  status: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  type: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
}

/** columns and relationships of "pebble.device" */
export interface pebble_deviceObservableChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  beep: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  config: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** An array relationship */
  deviceRecord: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record[],
    ) => Observable<pebble_device_record[]>
  }) & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record[],
    ) => Observable<pebble_device_record[]>
  }
  /** An aggregate relationship */
  deviceRecord_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => pebble_device_record_aggregateObservableChain & {
    execute: (
      request: pebble_device_record_aggregateRequest,
      defaultValue?: pebble_device_record_aggregate,
    ) => Observable<pebble_device_record_aggregate>
  }) &
    (pebble_device_record_aggregateObservableChain & {
      execute: (
        request: pebble_device_record_aggregateRequest,
        defaultValue?: pebble_device_record_aggregate,
      ) => Observable<pebble_device_record_aggregate>
    })
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  proposer: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  real_firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  status: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  type: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
}

/** columns and relationships of "pebble.device_record" */
export interface pebble_device_recordPromiseChain {
  accelerometer: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  gyroscope: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  hash: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  imei: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  light: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  operator: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  pressure: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  signature: { execute: (request?: boolean | number, defaultValue?: String) => Promise<String> }
  snr: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  temperature: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  vbat: { execute: (request?: boolean | number, defaultValue?: numeric) => Promise<numeric> }
}

/** columns and relationships of "pebble.device_record" */
export interface pebble_device_recordObservableChain {
  accelerometer: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  gyroscope: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  hash: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  id: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  imei: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  light: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  operator: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  pressure: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  signature: { execute: (request?: boolean | number, defaultValue?: String) => Observable<String> }
  snr: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  temperature: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  vbat: { execute: (request?: boolean | number, defaultValue?: numeric) => Observable<numeric> }
}

/** aggregated selection of "pebble.device_record" */
export interface pebble_device_record_aggregatePromiseChain {
  aggregate: pebble_device_record_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_aggregate_fieldsRequest,
      defaultValue?: pebble_device_record_aggregate_fields | null,
    ) => Promise<pebble_device_record_aggregate_fields | null>
  }
  nodes: {
    execute: (request: pebble_device_recordRequest, defaultValue?: pebble_device_record[]) => Promise<pebble_device_record[]>
  }
}

/** aggregated selection of "pebble.device_record" */
export interface pebble_device_record_aggregateObservableChain {
  aggregate: pebble_device_record_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_aggregate_fieldsRequest,
      defaultValue?: pebble_device_record_aggregate_fields | null,
    ) => Observable<pebble_device_record_aggregate_fields | null>
  }
  nodes: {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record[],
    ) => Observable<pebble_device_record[]>
  }
}

/** aggregate fields of "pebble.device_record" */
export interface pebble_device_record_aggregate_fieldsPromiseChain {
  avg: pebble_device_record_avg_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_avg_fieldsRequest,
      defaultValue?: pebble_device_record_avg_fields | null,
    ) => Promise<pebble_device_record_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_device_record_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_device_record_max_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_max_fieldsRequest,
      defaultValue?: pebble_device_record_max_fields | null,
    ) => Promise<pebble_device_record_max_fields | null>
  }
  min: pebble_device_record_min_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_min_fieldsRequest,
      defaultValue?: pebble_device_record_min_fields | null,
    ) => Promise<pebble_device_record_min_fields | null>
  }
  stddev: pebble_device_record_stddev_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_stddev_fieldsRequest,
      defaultValue?: pebble_device_record_stddev_fields | null,
    ) => Promise<pebble_device_record_stddev_fields | null>
  }
  stddev_pop: pebble_device_record_stddev_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_stddev_pop_fieldsRequest,
      defaultValue?: pebble_device_record_stddev_pop_fields | null,
    ) => Promise<pebble_device_record_stddev_pop_fields | null>
  }
  stddev_samp: pebble_device_record_stddev_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_stddev_samp_fieldsRequest,
      defaultValue?: pebble_device_record_stddev_samp_fields | null,
    ) => Promise<pebble_device_record_stddev_samp_fields | null>
  }
  sum: pebble_device_record_sum_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_sum_fieldsRequest,
      defaultValue?: pebble_device_record_sum_fields | null,
    ) => Promise<pebble_device_record_sum_fields | null>
  }
  var_pop: pebble_device_record_var_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_var_pop_fieldsRequest,
      defaultValue?: pebble_device_record_var_pop_fields | null,
    ) => Promise<pebble_device_record_var_pop_fields | null>
  }
  var_samp: pebble_device_record_var_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_var_samp_fieldsRequest,
      defaultValue?: pebble_device_record_var_samp_fields | null,
    ) => Promise<pebble_device_record_var_samp_fields | null>
  }
  variance: pebble_device_record_variance_fieldsPromiseChain & {
    execute: (
      request: pebble_device_record_variance_fieldsRequest,
      defaultValue?: pebble_device_record_variance_fields | null,
    ) => Promise<pebble_device_record_variance_fields | null>
  }
}

/** aggregate fields of "pebble.device_record" */
export interface pebble_device_record_aggregate_fieldsObservableChain {
  avg: pebble_device_record_avg_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_avg_fieldsRequest,
      defaultValue?: pebble_device_record_avg_fields | null,
    ) => Observable<pebble_device_record_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_device_record_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_device_record_max_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_max_fieldsRequest,
      defaultValue?: pebble_device_record_max_fields | null,
    ) => Observable<pebble_device_record_max_fields | null>
  }
  min: pebble_device_record_min_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_min_fieldsRequest,
      defaultValue?: pebble_device_record_min_fields | null,
    ) => Observable<pebble_device_record_min_fields | null>
  }
  stddev: pebble_device_record_stddev_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_stddev_fieldsRequest,
      defaultValue?: pebble_device_record_stddev_fields | null,
    ) => Observable<pebble_device_record_stddev_fields | null>
  }
  stddev_pop: pebble_device_record_stddev_pop_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_stddev_pop_fieldsRequest,
      defaultValue?: pebble_device_record_stddev_pop_fields | null,
    ) => Observable<pebble_device_record_stddev_pop_fields | null>
  }
  stddev_samp: pebble_device_record_stddev_samp_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_stddev_samp_fieldsRequest,
      defaultValue?: pebble_device_record_stddev_samp_fields | null,
    ) => Observable<pebble_device_record_stddev_samp_fields | null>
  }
  sum: pebble_device_record_sum_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_sum_fieldsRequest,
      defaultValue?: pebble_device_record_sum_fields | null,
    ) => Observable<pebble_device_record_sum_fields | null>
  }
  var_pop: pebble_device_record_var_pop_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_var_pop_fieldsRequest,
      defaultValue?: pebble_device_record_var_pop_fields | null,
    ) => Observable<pebble_device_record_var_pop_fields | null>
  }
  var_samp: pebble_device_record_var_samp_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_var_samp_fieldsRequest,
      defaultValue?: pebble_device_record_var_samp_fields | null,
    ) => Observable<pebble_device_record_var_samp_fields | null>
  }
  variance: pebble_device_record_variance_fieldsObservableChain & {
    execute: (
      request: pebble_device_record_variance_fieldsRequest,
      defaultValue?: pebble_device_record_variance_fields | null,
    ) => Observable<pebble_device_record_variance_fields | null>
  }
}

/** aggregate avg on columns */
export interface pebble_device_record_avg_fieldsPromiseChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate avg on columns */
export interface pebble_device_record_avg_fieldsObservableChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate max on columns */
export interface pebble_device_record_max_fieldsPromiseChain {
  accelerometer: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  gyroscope: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  hash: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  imei: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  light: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  operator: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  signature: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
}

/** aggregate max on columns */
export interface pebble_device_record_max_fieldsObservableChain {
  accelerometer: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  gyroscope: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  hash: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  imei: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  light: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  operator: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  signature: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
}

/** aggregate min on columns */
export interface pebble_device_record_min_fieldsPromiseChain {
  accelerometer: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  gyroscope: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  hash: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  imei: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  light: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  operator: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  signature: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
}

/** aggregate min on columns */
export interface pebble_device_record_min_fieldsObservableChain {
  accelerometer: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  gyroscope: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  hash: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  imei: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  light: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  operator: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  signature: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
}

/** aggregate stddev on columns */
export interface pebble_device_record_stddev_fieldsPromiseChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev on columns */
export interface pebble_device_record_stddev_fieldsObservableChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_device_record_stddev_pop_fieldsPromiseChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_device_record_stddev_pop_fieldsObservableChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_device_record_stddev_samp_fieldsPromiseChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_device_record_stddev_samp_fieldsObservableChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate sum on columns */
export interface pebble_device_record_sum_fieldsPromiseChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  light: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
}

/** aggregate sum on columns */
export interface pebble_device_record_sum_fieldsObservableChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  light: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
}

/** aggregate var_pop on columns */
export interface pebble_device_record_var_pop_fieldsPromiseChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_pop on columns */
export interface pebble_device_record_var_pop_fieldsObservableChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_device_record_var_samp_fieldsPromiseChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_device_record_var_samp_fieldsObservableChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_device_record_variance_fieldsPromiseChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_device_record_variance_fieldsObservableChain {
  gas_resistance: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  humidity: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  latitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  light: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  longitude: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  pressure: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  snr: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  temperature2: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  timestamp: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  vbat: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregated selection of "pebble.device" */
export interface pebble_device_aggregatePromiseChain {
  aggregate: pebble_device_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_device_aggregate_fieldsRequest,
      defaultValue?: pebble_device_aggregate_fields | null,
    ) => Promise<pebble_device_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Promise<pebble_device[]> }
}

/** aggregated selection of "pebble.device" */
export interface pebble_device_aggregateObservableChain {
  aggregate: pebble_device_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_device_aggregate_fieldsRequest,
      defaultValue?: pebble_device_aggregate_fields | null,
    ) => Observable<pebble_device_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Observable<pebble_device[]> }
}

/** aggregate fields of "pebble.device" */
export interface pebble_device_aggregate_fieldsPromiseChain {
  avg: pebble_device_avg_fieldsPromiseChain & {
    execute: (
      request: pebble_device_avg_fieldsRequest,
      defaultValue?: pebble_device_avg_fields | null,
    ) => Promise<pebble_device_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_device_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_device_max_fieldsPromiseChain & {
    execute: (
      request: pebble_device_max_fieldsRequest,
      defaultValue?: pebble_device_max_fields | null,
    ) => Promise<pebble_device_max_fields | null>
  }
  min: pebble_device_min_fieldsPromiseChain & {
    execute: (
      request: pebble_device_min_fieldsRequest,
      defaultValue?: pebble_device_min_fields | null,
    ) => Promise<pebble_device_min_fields | null>
  }
  stddev: pebble_device_stddev_fieldsPromiseChain & {
    execute: (
      request: pebble_device_stddev_fieldsRequest,
      defaultValue?: pebble_device_stddev_fields | null,
    ) => Promise<pebble_device_stddev_fields | null>
  }
  stddev_pop: pebble_device_stddev_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_device_stddev_pop_fieldsRequest,
      defaultValue?: pebble_device_stddev_pop_fields | null,
    ) => Promise<pebble_device_stddev_pop_fields | null>
  }
  stddev_samp: pebble_device_stddev_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_device_stddev_samp_fieldsRequest,
      defaultValue?: pebble_device_stddev_samp_fields | null,
    ) => Promise<pebble_device_stddev_samp_fields | null>
  }
  sum: pebble_device_sum_fieldsPromiseChain & {
    execute: (
      request: pebble_device_sum_fieldsRequest,
      defaultValue?: pebble_device_sum_fields | null,
    ) => Promise<pebble_device_sum_fields | null>
  }
  var_pop: pebble_device_var_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_device_var_pop_fieldsRequest,
      defaultValue?: pebble_device_var_pop_fields | null,
    ) => Promise<pebble_device_var_pop_fields | null>
  }
  var_samp: pebble_device_var_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_device_var_samp_fieldsRequest,
      defaultValue?: pebble_device_var_samp_fields | null,
    ) => Promise<pebble_device_var_samp_fields | null>
  }
  variance: pebble_device_variance_fieldsPromiseChain & {
    execute: (
      request: pebble_device_variance_fieldsRequest,
      defaultValue?: pebble_device_variance_fields | null,
    ) => Promise<pebble_device_variance_fields | null>
  }
}

/** aggregate fields of "pebble.device" */
export interface pebble_device_aggregate_fieldsObservableChain {
  avg: pebble_device_avg_fieldsObservableChain & {
    execute: (
      request: pebble_device_avg_fieldsRequest,
      defaultValue?: pebble_device_avg_fields | null,
    ) => Observable<pebble_device_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_device_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_device_max_fieldsObservableChain & {
    execute: (
      request: pebble_device_max_fieldsRequest,
      defaultValue?: pebble_device_max_fields | null,
    ) => Observable<pebble_device_max_fields | null>
  }
  min: pebble_device_min_fieldsObservableChain & {
    execute: (
      request: pebble_device_min_fieldsRequest,
      defaultValue?: pebble_device_min_fields | null,
    ) => Observable<pebble_device_min_fields | null>
  }
  stddev: pebble_device_stddev_fieldsObservableChain & {
    execute: (
      request: pebble_device_stddev_fieldsRequest,
      defaultValue?: pebble_device_stddev_fields | null,
    ) => Observable<pebble_device_stddev_fields | null>
  }
  stddev_pop: pebble_device_stddev_pop_fieldsObservableChain & {
    execute: (
      request: pebble_device_stddev_pop_fieldsRequest,
      defaultValue?: pebble_device_stddev_pop_fields | null,
    ) => Observable<pebble_device_stddev_pop_fields | null>
  }
  stddev_samp: pebble_device_stddev_samp_fieldsObservableChain & {
    execute: (
      request: pebble_device_stddev_samp_fieldsRequest,
      defaultValue?: pebble_device_stddev_samp_fields | null,
    ) => Observable<pebble_device_stddev_samp_fields | null>
  }
  sum: pebble_device_sum_fieldsObservableChain & {
    execute: (
      request: pebble_device_sum_fieldsRequest,
      defaultValue?: pebble_device_sum_fields | null,
    ) => Observable<pebble_device_sum_fields | null>
  }
  var_pop: pebble_device_var_pop_fieldsObservableChain & {
    execute: (
      request: pebble_device_var_pop_fieldsRequest,
      defaultValue?: pebble_device_var_pop_fields | null,
    ) => Observable<pebble_device_var_pop_fields | null>
  }
  var_samp: pebble_device_var_samp_fieldsObservableChain & {
    execute: (
      request: pebble_device_var_samp_fieldsRequest,
      defaultValue?: pebble_device_var_samp_fields | null,
    ) => Observable<pebble_device_var_samp_fields | null>
  }
  variance: pebble_device_variance_fieldsObservableChain & {
    execute: (
      request: pebble_device_variance_fieldsRequest,
      defaultValue?: pebble_device_variance_fields | null,
    ) => Observable<pebble_device_variance_fields | null>
  }
}

/** aggregate avg on columns */
export interface pebble_device_avg_fieldsPromiseChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate avg on columns */
export interface pebble_device_avg_fieldsObservableChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload_sampling_cnt: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  bulk_upload_sampling_freq: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate max on columns */
export interface pebble_device_max_fieldsPromiseChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  beep: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  config: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  proposer: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  real_firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate max on columns */
export interface pebble_device_max_fieldsObservableChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  beep: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  config: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  proposer: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  real_firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate min on columns */
export interface pebble_device_min_fieldsPromiseChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  beep: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  config: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  proposer: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  real_firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Promise<String | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate min on columns */
export interface pebble_device_min_fieldsObservableChain {
  address: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  avatar: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  beep: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  config: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  id: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  name: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  owner: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  proposer: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  real_firmware: { execute: (request?: boolean | number, defaultValue?: String | null) => Observable<String | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate stddev on columns */
export interface pebble_device_stddev_fieldsPromiseChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev on columns */
export interface pebble_device_stddev_fieldsObservableChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload_sampling_cnt: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  bulk_upload_sampling_freq: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_device_stddev_pop_fieldsPromiseChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_device_stddev_pop_fieldsObservableChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload_sampling_cnt: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  bulk_upload_sampling_freq: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_device_stddev_samp_fieldsPromiseChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_device_stddev_samp_fieldsObservableChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload_sampling_cnt: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  bulk_upload_sampling_freq: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate sum on columns */
export interface pebble_device_sum_fieldsPromiseChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Promise<numeric | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate sum on columns */
export interface pebble_device_sum_fieldsObservableChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: numeric | null) => Observable<numeric | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate var_pop on columns */
export interface pebble_device_var_pop_fieldsPromiseChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_pop on columns */
export interface pebble_device_var_pop_fieldsObservableChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload_sampling_cnt: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  bulk_upload_sampling_freq: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_device_var_samp_fieldsPromiseChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_device_var_samp_fieldsObservableChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload_sampling_cnt: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  bulk_upload_sampling_freq: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_device_variance_fieldsPromiseChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_cnt: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  bulk_upload_sampling_freq: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_device_variance_fieldsObservableChain {
  beep: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  bulk_upload_sampling_cnt: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  bulk_upload_sampling_freq: {
    execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null>
  }
  data_channel: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  state: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  status: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  total_gas: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  type: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  upload_period: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** columns and relationships of "pebble.status" */
export interface pebble_statusPromiseChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Promise<timestamptz> }
  value: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
}

/** columns and relationships of "pebble.status" */
export interface pebble_statusObservableChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  id: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz) => Observable<timestamptz> }
  value: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
}

/** aggregated selection of "pebble.status" */
export interface pebble_status_aggregatePromiseChain {
  aggregate: pebble_status_aggregate_fieldsPromiseChain & {
    execute: (
      request: pebble_status_aggregate_fieldsRequest,
      defaultValue?: pebble_status_aggregate_fields | null,
    ) => Promise<pebble_status_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Promise<pebble_status[]> }
}

/** aggregated selection of "pebble.status" */
export interface pebble_status_aggregateObservableChain {
  aggregate: pebble_status_aggregate_fieldsObservableChain & {
    execute: (
      request: pebble_status_aggregate_fieldsRequest,
      defaultValue?: pebble_status_aggregate_fields | null,
    ) => Observable<pebble_status_aggregate_fields | null>
  }
  nodes: { execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Observable<pebble_status[]> }
}

/** aggregate fields of "pebble.status" */
export interface pebble_status_aggregate_fieldsPromiseChain {
  avg: pebble_status_avg_fieldsPromiseChain & {
    execute: (
      request: pebble_status_avg_fieldsRequest,
      defaultValue?: pebble_status_avg_fields | null,
    ) => Promise<pebble_status_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_status_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int>
  }
  max: pebble_status_max_fieldsPromiseChain & {
    execute: (
      request: pebble_status_max_fieldsRequest,
      defaultValue?: pebble_status_max_fields | null,
    ) => Promise<pebble_status_max_fields | null>
  }
  min: pebble_status_min_fieldsPromiseChain & {
    execute: (
      request: pebble_status_min_fieldsRequest,
      defaultValue?: pebble_status_min_fields | null,
    ) => Promise<pebble_status_min_fields | null>
  }
  stddev: pebble_status_stddev_fieldsPromiseChain & {
    execute: (
      request: pebble_status_stddev_fieldsRequest,
      defaultValue?: pebble_status_stddev_fields | null,
    ) => Promise<pebble_status_stddev_fields | null>
  }
  stddev_pop: pebble_status_stddev_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_status_stddev_pop_fieldsRequest,
      defaultValue?: pebble_status_stddev_pop_fields | null,
    ) => Promise<pebble_status_stddev_pop_fields | null>
  }
  stddev_samp: pebble_status_stddev_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_status_stddev_samp_fieldsRequest,
      defaultValue?: pebble_status_stddev_samp_fields | null,
    ) => Promise<pebble_status_stddev_samp_fields | null>
  }
  sum: pebble_status_sum_fieldsPromiseChain & {
    execute: (
      request: pebble_status_sum_fieldsRequest,
      defaultValue?: pebble_status_sum_fields | null,
    ) => Promise<pebble_status_sum_fields | null>
  }
  var_pop: pebble_status_var_pop_fieldsPromiseChain & {
    execute: (
      request: pebble_status_var_pop_fieldsRequest,
      defaultValue?: pebble_status_var_pop_fields | null,
    ) => Promise<pebble_status_var_pop_fields | null>
  }
  var_samp: pebble_status_var_samp_fieldsPromiseChain & {
    execute: (
      request: pebble_status_var_samp_fieldsRequest,
      defaultValue?: pebble_status_var_samp_fields | null,
    ) => Promise<pebble_status_var_samp_fields | null>
  }
  variance: pebble_status_variance_fieldsPromiseChain & {
    execute: (
      request: pebble_status_variance_fieldsRequest,
      defaultValue?: pebble_status_variance_fields | null,
    ) => Promise<pebble_status_variance_fields | null>
  }
}

/** aggregate fields of "pebble.status" */
export interface pebble_status_aggregate_fieldsObservableChain {
  avg: pebble_status_avg_fieldsObservableChain & {
    execute: (
      request: pebble_status_avg_fieldsRequest,
      defaultValue?: pebble_status_avg_fields | null,
    ) => Observable<pebble_status_avg_fields | null>
  }
  count: ((args?: {
    columns?: pebble_status_select_column[] | null
    distinct?: Boolean | null
  }) => { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }) & {
    execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int>
  }
  max: pebble_status_max_fieldsObservableChain & {
    execute: (
      request: pebble_status_max_fieldsRequest,
      defaultValue?: pebble_status_max_fields | null,
    ) => Observable<pebble_status_max_fields | null>
  }
  min: pebble_status_min_fieldsObservableChain & {
    execute: (
      request: pebble_status_min_fieldsRequest,
      defaultValue?: pebble_status_min_fields | null,
    ) => Observable<pebble_status_min_fields | null>
  }
  stddev: pebble_status_stddev_fieldsObservableChain & {
    execute: (
      request: pebble_status_stddev_fieldsRequest,
      defaultValue?: pebble_status_stddev_fields | null,
    ) => Observable<pebble_status_stddev_fields | null>
  }
  stddev_pop: pebble_status_stddev_pop_fieldsObservableChain & {
    execute: (
      request: pebble_status_stddev_pop_fieldsRequest,
      defaultValue?: pebble_status_stddev_pop_fields | null,
    ) => Observable<pebble_status_stddev_pop_fields | null>
  }
  stddev_samp: pebble_status_stddev_samp_fieldsObservableChain & {
    execute: (
      request: pebble_status_stddev_samp_fieldsRequest,
      defaultValue?: pebble_status_stddev_samp_fields | null,
    ) => Observable<pebble_status_stddev_samp_fields | null>
  }
  sum: pebble_status_sum_fieldsObservableChain & {
    execute: (
      request: pebble_status_sum_fieldsRequest,
      defaultValue?: pebble_status_sum_fields | null,
    ) => Observable<pebble_status_sum_fields | null>
  }
  var_pop: pebble_status_var_pop_fieldsObservableChain & {
    execute: (
      request: pebble_status_var_pop_fieldsRequest,
      defaultValue?: pebble_status_var_pop_fields | null,
    ) => Observable<pebble_status_var_pop_fields | null>
  }
  var_samp: pebble_status_var_samp_fieldsObservableChain & {
    execute: (
      request: pebble_status_var_samp_fieldsRequest,
      defaultValue?: pebble_status_var_samp_fields | null,
    ) => Observable<pebble_status_var_samp_fields | null>
  }
  variance: pebble_status_variance_fieldsObservableChain & {
    execute: (
      request: pebble_status_variance_fieldsRequest,
      defaultValue?: pebble_status_variance_fields | null,
    ) => Observable<pebble_status_variance_fields | null>
  }
}

/** aggregate avg on columns */
export interface pebble_status_avg_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate avg on columns */
export interface pebble_status_avg_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate max on columns */
export interface pebble_status_max_fieldsPromiseChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate max on columns */
export interface pebble_status_max_fieldsObservableChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate min on columns */
export interface pebble_status_min_fieldsPromiseChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Promise<timestamptz | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate min on columns */
export interface pebble_status_min_fieldsObservableChain {
  created_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  updated_at: { execute: (request?: boolean | number, defaultValue?: timestamptz | null) => Observable<timestamptz | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate stddev on columns */
export interface pebble_status_stddev_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev on columns */
export interface pebble_status_stddev_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_status_stddev_pop_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_pop on columns */
export interface pebble_status_stddev_pop_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_status_stddev_samp_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate stddev_samp on columns */
export interface pebble_status_stddev_samp_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate sum on columns */
export interface pebble_status_sum_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Promise<Int | null> }
}

/** aggregate sum on columns */
export interface pebble_status_sum_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Int | null) => Observable<Int | null> }
}

/** aggregate var_pop on columns */
export interface pebble_status_var_pop_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_pop on columns */
export interface pebble_status_var_pop_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_status_var_samp_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate var_samp on columns */
export interface pebble_status_var_samp_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_status_variance_fieldsPromiseChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Promise<Float | null> }
}

/** aggregate variance on columns */
export interface pebble_status_variance_fieldsObservableChain {
  id: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
  value: { execute: (request?: boolean | number, defaultValue?: Float | null) => Observable<Float | null> }
}

/** mutation root */
export interface mutation_rootPromiseChain {
  /** delete data from the table: "lonely.area" */
  delete_lonely_area: (args: {
    /** filter the rows which have to be deleted */
    where: lonely_area_bool_exp
  }) => lonely_area_mutation_responsePromiseChain & {
    execute: (
      request: lonely_area_mutation_responseRequest,
      defaultValue?: lonely_area_mutation_response | null,
    ) => Promise<lonely_area_mutation_response | null>
  }
  /** delete single row from the table: "lonely.area" */
  delete_lonely_area_by_pk: (args: {
    id: String
  }) => lonely_areaPromiseChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Promise<lonely_area | null>
  }
  /** delete data from the table: "lonely.device" */
  delete_lonely_device: (args: {
    /** filter the rows which have to be deleted */
    where: lonely_device_bool_exp
  }) => lonely_device_mutation_responsePromiseChain & {
    execute: (
      request: lonely_device_mutation_responseRequest,
      defaultValue?: lonely_device_mutation_response | null,
    ) => Promise<lonely_device_mutation_response | null>
  }
  /** delete single row from the table: "lonely.device" */
  delete_lonely_device_by_pk: (args: {
    id: String
  }) => lonely_devicePromiseChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Promise<lonely_device | null>
  }
  /** delete data from the table: "lonely.status" */
  delete_lonely_status: (args: {
    /** filter the rows which have to be deleted */
    where: lonely_status_bool_exp
  }) => lonely_status_mutation_responsePromiseChain & {
    execute: (
      request: lonely_status_mutation_responseRequest,
      defaultValue?: lonely_status_mutation_response | null,
    ) => Promise<lonely_status_mutation_response | null>
  }
  /** delete single row from the table: "lonely.status" */
  delete_lonely_status_by_pk: (args: {
    id: Int
  }) => lonely_statusPromiseChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Promise<lonely_status | null>
  }
  /** delete data from the table: "pebble.account" */
  delete_pebble_account: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_account_bool_exp
  }) => pebble_account_mutation_responsePromiseChain & {
    execute: (
      request: pebble_account_mutation_responseRequest,
      defaultValue?: pebble_account_mutation_response | null,
    ) => Promise<pebble_account_mutation_response | null>
  }
  /** delete single row from the table: "pebble.account" */
  delete_pebble_account_by_pk: (args: {
    id: String
  }) => pebble_accountPromiseChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Promise<pebble_account | null>
  }
  /** delete data from the table: "pebble.app" */
  delete_pebble_app: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_app_bool_exp
  }) => pebble_app_mutation_responsePromiseChain & {
    execute: (
      request: pebble_app_mutation_responseRequest,
      defaultValue?: pebble_app_mutation_response | null,
    ) => Promise<pebble_app_mutation_response | null>
  }
  /** delete single row from the table: "pebble.app" */
  delete_pebble_app_by_pk: (args: {
    id: String
  }) => pebble_appPromiseChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Promise<pebble_app | null>
  }
  /** delete data from the table: "pebble.app_v2" */
  delete_pebble_app_v2: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_app_v2_bool_exp
  }) => pebble_app_v2_mutation_responsePromiseChain & {
    execute: (
      request: pebble_app_v2_mutation_responseRequest,
      defaultValue?: pebble_app_v2_mutation_response | null,
    ) => Promise<pebble_app_v2_mutation_response | null>
  }
  /** delete single row from the table: "pebble.app_v2" */
  delete_pebble_app_v2_by_pk: (args: {
    id: String
  }) => pebble_app_v2PromiseChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Promise<pebble_app_v2 | null>
  }
  /** delete data from the table: "pebble.bank" */
  delete_pebble_bank: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_bank_bool_exp
  }) => pebble_bank_mutation_responsePromiseChain & {
    execute: (
      request: pebble_bank_mutation_responseRequest,
      defaultValue?: pebble_bank_mutation_response | null,
    ) => Promise<pebble_bank_mutation_response | null>
  }
  /** delete single row from the table: "pebble.bank" */
  delete_pebble_bank_by_pk: (args: {
    address: String
  }) => pebble_bankPromiseChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Promise<pebble_bank | null>
  }
  /** delete data from the table: "pebble.bank_record" */
  delete_pebble_bank_record: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_bank_record_bool_exp
  }) => pebble_bank_record_mutation_responsePromiseChain & {
    execute: (
      request: pebble_bank_record_mutation_responseRequest,
      defaultValue?: pebble_bank_record_mutation_response | null,
    ) => Promise<pebble_bank_record_mutation_response | null>
  }
  /** delete single row from the table: "pebble.bank_record" */
  delete_pebble_bank_record_by_pk: (args: {
    id: String
  }) => pebble_bank_recordPromiseChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Promise<pebble_bank_record | null>
  }
  /** delete data from the table: "pebble.config" */
  delete_pebble_config: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_config_bool_exp
  }) => pebble_config_mutation_responsePromiseChain & {
    execute: (
      request: pebble_config_mutation_responseRequest,
      defaultValue?: pebble_config_mutation_response | null,
    ) => Promise<pebble_config_mutation_response | null>
  }
  /** delete single row from the table: "pebble.config" */
  delete_pebble_config_by_pk: (args: {
    id: String
  }) => pebble_configPromiseChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Promise<pebble_config | null>
  }
  /** delete data from the table: "pebble.data_cache" */
  delete_pebble_data_cache: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_data_cache_bool_exp
  }) => pebble_data_cache_mutation_responsePromiseChain & {
    execute: (
      request: pebble_data_cache_mutation_responseRequest,
      defaultValue?: pebble_data_cache_mutation_response | null,
    ) => Promise<pebble_data_cache_mutation_response | null>
  }
  /** delete single row from the table: "pebble.data_cache" */
  delete_pebble_data_cache_by_pk: (args: {
    id: Int
  }) => pebble_data_cachePromiseChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Promise<pebble_data_cache | null>
  }
  /** delete data from the table: "pebble.device" */
  delete_pebble_device: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_device_bool_exp
  }) => pebble_device_mutation_responsePromiseChain & {
    execute: (
      request: pebble_device_mutation_responseRequest,
      defaultValue?: pebble_device_mutation_response | null,
    ) => Promise<pebble_device_mutation_response | null>
  }
  /** delete single row from the table: "pebble.device" */
  delete_pebble_device_by_pk: (args: {
    id: String
  }) => pebble_devicePromiseChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Promise<pebble_device | null>
  }
  /** delete data from the table: "pebble.device_record" */
  delete_pebble_device_record: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_device_record_bool_exp
  }) => pebble_device_record_mutation_responsePromiseChain & {
    execute: (
      request: pebble_device_record_mutation_responseRequest,
      defaultValue?: pebble_device_record_mutation_response | null,
    ) => Promise<pebble_device_record_mutation_response | null>
  }
  /** delete single row from the table: "pebble.device_record" */
  delete_pebble_device_record_by_pk: (args: {
    id: String
  }) => pebble_device_recordPromiseChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Promise<pebble_device_record | null>
  }
  /** delete data from the table: "pebble.status" */
  delete_pebble_status: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_status_bool_exp
  }) => pebble_status_mutation_responsePromiseChain & {
    execute: (
      request: pebble_status_mutation_responseRequest,
      defaultValue?: pebble_status_mutation_response | null,
    ) => Promise<pebble_status_mutation_response | null>
  }
  /** delete single row from the table: "pebble.status" */
  delete_pebble_status_by_pk: (args: {
    id: Int
  }) => pebble_statusPromiseChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Promise<pebble_status | null>
  }
  /** insert data into the table: "lonely.area" */
  insert_lonely_area: (args: {
    /** the rows to be inserted */
    objects: lonely_area_insert_input[]
    /** on conflict condition */
    on_conflict?: lonely_area_on_conflict | null
  }) => lonely_area_mutation_responsePromiseChain & {
    execute: (
      request: lonely_area_mutation_responseRequest,
      defaultValue?: lonely_area_mutation_response | null,
    ) => Promise<lonely_area_mutation_response | null>
  }
  /** insert a single row into the table: "lonely.area" */
  insert_lonely_area_one: (args: {
    /** the row to be inserted */
    object: lonely_area_insert_input
    /** on conflict condition */
    on_conflict?: lonely_area_on_conflict | null
  }) => lonely_areaPromiseChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Promise<lonely_area | null>
  }
  /** insert data into the table: "lonely.device" */
  insert_lonely_device: (args: {
    /** the rows to be inserted */
    objects: lonely_device_insert_input[]
    /** on conflict condition */
    on_conflict?: lonely_device_on_conflict | null
  }) => lonely_device_mutation_responsePromiseChain & {
    execute: (
      request: lonely_device_mutation_responseRequest,
      defaultValue?: lonely_device_mutation_response | null,
    ) => Promise<lonely_device_mutation_response | null>
  }
  /** insert a single row into the table: "lonely.device" */
  insert_lonely_device_one: (args: {
    /** the row to be inserted */
    object: lonely_device_insert_input
    /** on conflict condition */
    on_conflict?: lonely_device_on_conflict | null
  }) => lonely_devicePromiseChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Promise<lonely_device | null>
  }
  /** insert data into the table: "lonely.status" */
  insert_lonely_status: (args: {
    /** the rows to be inserted */
    objects: lonely_status_insert_input[]
    /** on conflict condition */
    on_conflict?: lonely_status_on_conflict | null
  }) => lonely_status_mutation_responsePromiseChain & {
    execute: (
      request: lonely_status_mutation_responseRequest,
      defaultValue?: lonely_status_mutation_response | null,
    ) => Promise<lonely_status_mutation_response | null>
  }
  /** insert a single row into the table: "lonely.status" */
  insert_lonely_status_one: (args: {
    /** the row to be inserted */
    object: lonely_status_insert_input
    /** on conflict condition */
    on_conflict?: lonely_status_on_conflict | null
  }) => lonely_statusPromiseChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Promise<lonely_status | null>
  }
  /** insert data into the table: "pebble.account" */
  insert_pebble_account: (args: {
    /** the rows to be inserted */
    objects: pebble_account_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_account_on_conflict | null
  }) => pebble_account_mutation_responsePromiseChain & {
    execute: (
      request: pebble_account_mutation_responseRequest,
      defaultValue?: pebble_account_mutation_response | null,
    ) => Promise<pebble_account_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.account" */
  insert_pebble_account_one: (args: {
    /** the row to be inserted */
    object: pebble_account_insert_input
    /** on conflict condition */
    on_conflict?: pebble_account_on_conflict | null
  }) => pebble_accountPromiseChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Promise<pebble_account | null>
  }
  /** insert data into the table: "pebble.app" */
  insert_pebble_app: (args: {
    /** the rows to be inserted */
    objects: pebble_app_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_app_on_conflict | null
  }) => pebble_app_mutation_responsePromiseChain & {
    execute: (
      request: pebble_app_mutation_responseRequest,
      defaultValue?: pebble_app_mutation_response | null,
    ) => Promise<pebble_app_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.app" */
  insert_pebble_app_one: (args: {
    /** the row to be inserted */
    object: pebble_app_insert_input
    /** on conflict condition */
    on_conflict?: pebble_app_on_conflict | null
  }) => pebble_appPromiseChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Promise<pebble_app | null>
  }
  /** insert data into the table: "pebble.app_v2" */
  insert_pebble_app_v2: (args: {
    /** the rows to be inserted */
    objects: pebble_app_v2_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_app_v2_on_conflict | null
  }) => pebble_app_v2_mutation_responsePromiseChain & {
    execute: (
      request: pebble_app_v2_mutation_responseRequest,
      defaultValue?: pebble_app_v2_mutation_response | null,
    ) => Promise<pebble_app_v2_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.app_v2" */
  insert_pebble_app_v2_one: (args: {
    /** the row to be inserted */
    object: pebble_app_v2_insert_input
    /** on conflict condition */
    on_conflict?: pebble_app_v2_on_conflict | null
  }) => pebble_app_v2PromiseChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Promise<pebble_app_v2 | null>
  }
  /** insert data into the table: "pebble.bank" */
  insert_pebble_bank: (args: {
    /** the rows to be inserted */
    objects: pebble_bank_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_bank_on_conflict | null
  }) => pebble_bank_mutation_responsePromiseChain & {
    execute: (
      request: pebble_bank_mutation_responseRequest,
      defaultValue?: pebble_bank_mutation_response | null,
    ) => Promise<pebble_bank_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.bank" */
  insert_pebble_bank_one: (args: {
    /** the row to be inserted */
    object: pebble_bank_insert_input
    /** on conflict condition */
    on_conflict?: pebble_bank_on_conflict | null
  }) => pebble_bankPromiseChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Promise<pebble_bank | null>
  }
  /** insert data into the table: "pebble.bank_record" */
  insert_pebble_bank_record: (args: {
    /** the rows to be inserted */
    objects: pebble_bank_record_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_bank_record_on_conflict | null
  }) => pebble_bank_record_mutation_responsePromiseChain & {
    execute: (
      request: pebble_bank_record_mutation_responseRequest,
      defaultValue?: pebble_bank_record_mutation_response | null,
    ) => Promise<pebble_bank_record_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.bank_record" */
  insert_pebble_bank_record_one: (args: {
    /** the row to be inserted */
    object: pebble_bank_record_insert_input
    /** on conflict condition */
    on_conflict?: pebble_bank_record_on_conflict | null
  }) => pebble_bank_recordPromiseChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Promise<pebble_bank_record | null>
  }
  /** insert data into the table: "pebble.config" */
  insert_pebble_config: (args: {
    /** the rows to be inserted */
    objects: pebble_config_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_config_on_conflict | null
  }) => pebble_config_mutation_responsePromiseChain & {
    execute: (
      request: pebble_config_mutation_responseRequest,
      defaultValue?: pebble_config_mutation_response | null,
    ) => Promise<pebble_config_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.config" */
  insert_pebble_config_one: (args: {
    /** the row to be inserted */
    object: pebble_config_insert_input
    /** on conflict condition */
    on_conflict?: pebble_config_on_conflict | null
  }) => pebble_configPromiseChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Promise<pebble_config | null>
  }
  /** insert data into the table: "pebble.data_cache" */
  insert_pebble_data_cache: (args: {
    /** the rows to be inserted */
    objects: pebble_data_cache_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_data_cache_on_conflict | null
  }) => pebble_data_cache_mutation_responsePromiseChain & {
    execute: (
      request: pebble_data_cache_mutation_responseRequest,
      defaultValue?: pebble_data_cache_mutation_response | null,
    ) => Promise<pebble_data_cache_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.data_cache" */
  insert_pebble_data_cache_one: (args: {
    /** the row to be inserted */
    object: pebble_data_cache_insert_input
    /** on conflict condition */
    on_conflict?: pebble_data_cache_on_conflict | null
  }) => pebble_data_cachePromiseChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Promise<pebble_data_cache | null>
  }
  /** insert data into the table: "pebble.device" */
  insert_pebble_device: (args: {
    /** the rows to be inserted */
    objects: pebble_device_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_device_on_conflict | null
  }) => pebble_device_mutation_responsePromiseChain & {
    execute: (
      request: pebble_device_mutation_responseRequest,
      defaultValue?: pebble_device_mutation_response | null,
    ) => Promise<pebble_device_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.device" */
  insert_pebble_device_one: (args: {
    /** the row to be inserted */
    object: pebble_device_insert_input
    /** on conflict condition */
    on_conflict?: pebble_device_on_conflict | null
  }) => pebble_devicePromiseChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Promise<pebble_device | null>
  }
  /** insert data into the table: "pebble.device_record" */
  insert_pebble_device_record: (args: {
    /** the rows to be inserted */
    objects: pebble_device_record_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_device_record_on_conflict | null
  }) => pebble_device_record_mutation_responsePromiseChain & {
    execute: (
      request: pebble_device_record_mutation_responseRequest,
      defaultValue?: pebble_device_record_mutation_response | null,
    ) => Promise<pebble_device_record_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.device_record" */
  insert_pebble_device_record_one: (args: {
    /** the row to be inserted */
    object: pebble_device_record_insert_input
    /** on conflict condition */
    on_conflict?: pebble_device_record_on_conflict | null
  }) => pebble_device_recordPromiseChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Promise<pebble_device_record | null>
  }
  /** insert data into the table: "pebble.status" */
  insert_pebble_status: (args: {
    /** the rows to be inserted */
    objects: pebble_status_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_status_on_conflict | null
  }) => pebble_status_mutation_responsePromiseChain & {
    execute: (
      request: pebble_status_mutation_responseRequest,
      defaultValue?: pebble_status_mutation_response | null,
    ) => Promise<pebble_status_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.status" */
  insert_pebble_status_one: (args: {
    /** the row to be inserted */
    object: pebble_status_insert_input
    /** on conflict condition */
    on_conflict?: pebble_status_on_conflict | null
  }) => pebble_statusPromiseChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Promise<pebble_status | null>
  }
  /** update data of the table: "lonely.area" */
  update_lonely_area: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_area_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_area_set_input | null
    /** filter the rows which have to be updated */
    where: lonely_area_bool_exp
  }) => lonely_area_mutation_responsePromiseChain & {
    execute: (
      request: lonely_area_mutation_responseRequest,
      defaultValue?: lonely_area_mutation_response | null,
    ) => Promise<lonely_area_mutation_response | null>
  }
  /** update single row of the table: "lonely.area" */
  update_lonely_area_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_area_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_area_set_input | null
    pk_columns: lonely_area_pk_columns_input
  }) => lonely_areaPromiseChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Promise<lonely_area | null>
  }
  /** update data of the table: "lonely.device" */
  update_lonely_device: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_device_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_device_set_input | null
    /** filter the rows which have to be updated */
    where: lonely_device_bool_exp
  }) => lonely_device_mutation_responsePromiseChain & {
    execute: (
      request: lonely_device_mutation_responseRequest,
      defaultValue?: lonely_device_mutation_response | null,
    ) => Promise<lonely_device_mutation_response | null>
  }
  /** update single row of the table: "lonely.device" */
  update_lonely_device_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_device_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_device_set_input | null
    pk_columns: lonely_device_pk_columns_input
  }) => lonely_devicePromiseChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Promise<lonely_device | null>
  }
  /** update data of the table: "lonely.status" */
  update_lonely_status: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_status_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_status_set_input | null
    /** filter the rows which have to be updated */
    where: lonely_status_bool_exp
  }) => lonely_status_mutation_responsePromiseChain & {
    execute: (
      request: lonely_status_mutation_responseRequest,
      defaultValue?: lonely_status_mutation_response | null,
    ) => Promise<lonely_status_mutation_response | null>
  }
  /** update single row of the table: "lonely.status" */
  update_lonely_status_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_status_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_status_set_input | null
    pk_columns: lonely_status_pk_columns_input
  }) => lonely_statusPromiseChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Promise<lonely_status | null>
  }
  /** update data of the table: "pebble.account" */
  update_pebble_account: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_account_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_account_bool_exp
  }) => pebble_account_mutation_responsePromiseChain & {
    execute: (
      request: pebble_account_mutation_responseRequest,
      defaultValue?: pebble_account_mutation_response | null,
    ) => Promise<pebble_account_mutation_response | null>
  }
  /** update single row of the table: "pebble.account" */
  update_pebble_account_by_pk: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_account_set_input | null
    pk_columns: pebble_account_pk_columns_input
  }) => pebble_accountPromiseChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Promise<pebble_account | null>
  }
  /** update data of the table: "pebble.app" */
  update_pebble_app: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_app_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_app_bool_exp
  }) => pebble_app_mutation_responsePromiseChain & {
    execute: (
      request: pebble_app_mutation_responseRequest,
      defaultValue?: pebble_app_mutation_response | null,
    ) => Promise<pebble_app_mutation_response | null>
  }
  /** update single row of the table: "pebble.app" */
  update_pebble_app_by_pk: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_app_set_input | null
    pk_columns: pebble_app_pk_columns_input
  }) => pebble_appPromiseChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Promise<pebble_app | null>
  }
  /** update data of the table: "pebble.app_v2" */
  update_pebble_app_v2: (args: {
    /** append existing jsonb value of filtered columns with new jsonb value */
    _append?: pebble_app_v2_append_input | null
    /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
    _delete_at_path?: pebble_app_v2_delete_at_path_input | null
    /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
    _delete_elem?: pebble_app_v2_delete_elem_input | null
    /** delete key/value pair or string element. key/value pairs are matched based on their key value */
    _delete_key?: pebble_app_v2_delete_key_input | null
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_app_v2_inc_input | null
    /** prepend existing jsonb value of filtered columns with new jsonb value */
    _prepend?: pebble_app_v2_prepend_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_app_v2_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_app_v2_bool_exp
  }) => pebble_app_v2_mutation_responsePromiseChain & {
    execute: (
      request: pebble_app_v2_mutation_responseRequest,
      defaultValue?: pebble_app_v2_mutation_response | null,
    ) => Promise<pebble_app_v2_mutation_response | null>
  }
  /** update single row of the table: "pebble.app_v2" */
  update_pebble_app_v2_by_pk: (args: {
    /** append existing jsonb value of filtered columns with new jsonb value */
    _append?: pebble_app_v2_append_input | null
    /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
    _delete_at_path?: pebble_app_v2_delete_at_path_input | null
    /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
    _delete_elem?: pebble_app_v2_delete_elem_input | null
    /** delete key/value pair or string element. key/value pairs are matched based on their key value */
    _delete_key?: pebble_app_v2_delete_key_input | null
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_app_v2_inc_input | null
    /** prepend existing jsonb value of filtered columns with new jsonb value */
    _prepend?: pebble_app_v2_prepend_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_app_v2_set_input | null
    pk_columns: pebble_app_v2_pk_columns_input
  }) => pebble_app_v2PromiseChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Promise<pebble_app_v2 | null>
  }
  /** update data of the table: "pebble.bank" */
  update_pebble_bank: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_bank_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_bank_bool_exp
  }) => pebble_bank_mutation_responsePromiseChain & {
    execute: (
      request: pebble_bank_mutation_responseRequest,
      defaultValue?: pebble_bank_mutation_response | null,
    ) => Promise<pebble_bank_mutation_response | null>
  }
  /** update single row of the table: "pebble.bank" */
  update_pebble_bank_by_pk: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_bank_set_input | null
    pk_columns: pebble_bank_pk_columns_input
  }) => pebble_bankPromiseChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Promise<pebble_bank | null>
  }
  /** update data of the table: "pebble.bank_record" */
  update_pebble_bank_record: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_bank_record_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_bank_record_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_bank_record_bool_exp
  }) => pebble_bank_record_mutation_responsePromiseChain & {
    execute: (
      request: pebble_bank_record_mutation_responseRequest,
      defaultValue?: pebble_bank_record_mutation_response | null,
    ) => Promise<pebble_bank_record_mutation_response | null>
  }
  /** update single row of the table: "pebble.bank_record" */
  update_pebble_bank_record_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_bank_record_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_bank_record_set_input | null
    pk_columns: pebble_bank_record_pk_columns_input
  }) => pebble_bank_recordPromiseChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Promise<pebble_bank_record | null>
  }
  /** update data of the table: "pebble.config" */
  update_pebble_config: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_config_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_config_bool_exp
  }) => pebble_config_mutation_responsePromiseChain & {
    execute: (
      request: pebble_config_mutation_responseRequest,
      defaultValue?: pebble_config_mutation_response | null,
    ) => Promise<pebble_config_mutation_response | null>
  }
  /** update single row of the table: "pebble.config" */
  update_pebble_config_by_pk: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_config_set_input | null
    pk_columns: pebble_config_pk_columns_input
  }) => pebble_configPromiseChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Promise<pebble_config | null>
  }
  /** update data of the table: "pebble.data_cache" */
  update_pebble_data_cache: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_data_cache_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_data_cache_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_data_cache_bool_exp
  }) => pebble_data_cache_mutation_responsePromiseChain & {
    execute: (
      request: pebble_data_cache_mutation_responseRequest,
      defaultValue?: pebble_data_cache_mutation_response | null,
    ) => Promise<pebble_data_cache_mutation_response | null>
  }
  /** update single row of the table: "pebble.data_cache" */
  update_pebble_data_cache_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_data_cache_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_data_cache_set_input | null
    pk_columns: pebble_data_cache_pk_columns_input
  }) => pebble_data_cachePromiseChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Promise<pebble_data_cache | null>
  }
  /** update data of the table: "pebble.device" */
  update_pebble_device: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_device_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_device_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_device_bool_exp
  }) => pebble_device_mutation_responsePromiseChain & {
    execute: (
      request: pebble_device_mutation_responseRequest,
      defaultValue?: pebble_device_mutation_response | null,
    ) => Promise<pebble_device_mutation_response | null>
  }
  /** update single row of the table: "pebble.device" */
  update_pebble_device_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_device_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_device_set_input | null
    pk_columns: pebble_device_pk_columns_input
  }) => pebble_devicePromiseChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Promise<pebble_device | null>
  }
  /** update data of the table: "pebble.device_record" */
  update_pebble_device_record: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_device_record_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_device_record_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_device_record_bool_exp
  }) => pebble_device_record_mutation_responsePromiseChain & {
    execute: (
      request: pebble_device_record_mutation_responseRequest,
      defaultValue?: pebble_device_record_mutation_response | null,
    ) => Promise<pebble_device_record_mutation_response | null>
  }
  /** update single row of the table: "pebble.device_record" */
  update_pebble_device_record_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_device_record_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_device_record_set_input | null
    pk_columns: pebble_device_record_pk_columns_input
  }) => pebble_device_recordPromiseChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Promise<pebble_device_record | null>
  }
  /** update data of the table: "pebble.status" */
  update_pebble_status: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_status_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_status_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_status_bool_exp
  }) => pebble_status_mutation_responsePromiseChain & {
    execute: (
      request: pebble_status_mutation_responseRequest,
      defaultValue?: pebble_status_mutation_response | null,
    ) => Promise<pebble_status_mutation_response | null>
  }
  /** update single row of the table: "pebble.status" */
  update_pebble_status_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_status_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_status_set_input | null
    pk_columns: pebble_status_pk_columns_input
  }) => pebble_statusPromiseChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Promise<pebble_status | null>
  }
}

/** mutation root */
export interface mutation_rootObservableChain {
  /** delete data from the table: "lonely.area" */
  delete_lonely_area: (args: {
    /** filter the rows which have to be deleted */
    where: lonely_area_bool_exp
  }) => lonely_area_mutation_responseObservableChain & {
    execute: (
      request: lonely_area_mutation_responseRequest,
      defaultValue?: lonely_area_mutation_response | null,
    ) => Observable<lonely_area_mutation_response | null>
  }
  /** delete single row from the table: "lonely.area" */
  delete_lonely_area_by_pk: (args: {
    id: String
  }) => lonely_areaObservableChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Observable<lonely_area | null>
  }
  /** delete data from the table: "lonely.device" */
  delete_lonely_device: (args: {
    /** filter the rows which have to be deleted */
    where: lonely_device_bool_exp
  }) => lonely_device_mutation_responseObservableChain & {
    execute: (
      request: lonely_device_mutation_responseRequest,
      defaultValue?: lonely_device_mutation_response | null,
    ) => Observable<lonely_device_mutation_response | null>
  }
  /** delete single row from the table: "lonely.device" */
  delete_lonely_device_by_pk: (args: {
    id: String
  }) => lonely_deviceObservableChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Observable<lonely_device | null>
  }
  /** delete data from the table: "lonely.status" */
  delete_lonely_status: (args: {
    /** filter the rows which have to be deleted */
    where: lonely_status_bool_exp
  }) => lonely_status_mutation_responseObservableChain & {
    execute: (
      request: lonely_status_mutation_responseRequest,
      defaultValue?: lonely_status_mutation_response | null,
    ) => Observable<lonely_status_mutation_response | null>
  }
  /** delete single row from the table: "lonely.status" */
  delete_lonely_status_by_pk: (args: {
    id: Int
  }) => lonely_statusObservableChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Observable<lonely_status | null>
  }
  /** delete data from the table: "pebble.account" */
  delete_pebble_account: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_account_bool_exp
  }) => pebble_account_mutation_responseObservableChain & {
    execute: (
      request: pebble_account_mutation_responseRequest,
      defaultValue?: pebble_account_mutation_response | null,
    ) => Observable<pebble_account_mutation_response | null>
  }
  /** delete single row from the table: "pebble.account" */
  delete_pebble_account_by_pk: (args: {
    id: String
  }) => pebble_accountObservableChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Observable<pebble_account | null>
  }
  /** delete data from the table: "pebble.app" */
  delete_pebble_app: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_app_bool_exp
  }) => pebble_app_mutation_responseObservableChain & {
    execute: (
      request: pebble_app_mutation_responseRequest,
      defaultValue?: pebble_app_mutation_response | null,
    ) => Observable<pebble_app_mutation_response | null>
  }
  /** delete single row from the table: "pebble.app" */
  delete_pebble_app_by_pk: (args: {
    id: String
  }) => pebble_appObservableChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Observable<pebble_app | null>
  }
  /** delete data from the table: "pebble.app_v2" */
  delete_pebble_app_v2: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_app_v2_bool_exp
  }) => pebble_app_v2_mutation_responseObservableChain & {
    execute: (
      request: pebble_app_v2_mutation_responseRequest,
      defaultValue?: pebble_app_v2_mutation_response | null,
    ) => Observable<pebble_app_v2_mutation_response | null>
  }
  /** delete single row from the table: "pebble.app_v2" */
  delete_pebble_app_v2_by_pk: (args: {
    id: String
  }) => pebble_app_v2ObservableChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Observable<pebble_app_v2 | null>
  }
  /** delete data from the table: "pebble.bank" */
  delete_pebble_bank: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_bank_bool_exp
  }) => pebble_bank_mutation_responseObservableChain & {
    execute: (
      request: pebble_bank_mutation_responseRequest,
      defaultValue?: pebble_bank_mutation_response | null,
    ) => Observable<pebble_bank_mutation_response | null>
  }
  /** delete single row from the table: "pebble.bank" */
  delete_pebble_bank_by_pk: (args: {
    address: String
  }) => pebble_bankObservableChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Observable<pebble_bank | null>
  }
  /** delete data from the table: "pebble.bank_record" */
  delete_pebble_bank_record: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_bank_record_bool_exp
  }) => pebble_bank_record_mutation_responseObservableChain & {
    execute: (
      request: pebble_bank_record_mutation_responseRequest,
      defaultValue?: pebble_bank_record_mutation_response | null,
    ) => Observable<pebble_bank_record_mutation_response | null>
  }
  /** delete single row from the table: "pebble.bank_record" */
  delete_pebble_bank_record_by_pk: (args: {
    id: String
  }) => pebble_bank_recordObservableChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Observable<pebble_bank_record | null>
  }
  /** delete data from the table: "pebble.config" */
  delete_pebble_config: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_config_bool_exp
  }) => pebble_config_mutation_responseObservableChain & {
    execute: (
      request: pebble_config_mutation_responseRequest,
      defaultValue?: pebble_config_mutation_response | null,
    ) => Observable<pebble_config_mutation_response | null>
  }
  /** delete single row from the table: "pebble.config" */
  delete_pebble_config_by_pk: (args: {
    id: String
  }) => pebble_configObservableChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Observable<pebble_config | null>
  }
  /** delete data from the table: "pebble.data_cache" */
  delete_pebble_data_cache: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_data_cache_bool_exp
  }) => pebble_data_cache_mutation_responseObservableChain & {
    execute: (
      request: pebble_data_cache_mutation_responseRequest,
      defaultValue?: pebble_data_cache_mutation_response | null,
    ) => Observable<pebble_data_cache_mutation_response | null>
  }
  /** delete single row from the table: "pebble.data_cache" */
  delete_pebble_data_cache_by_pk: (args: {
    id: Int
  }) => pebble_data_cacheObservableChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Observable<pebble_data_cache | null>
  }
  /** delete data from the table: "pebble.device" */
  delete_pebble_device: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_device_bool_exp
  }) => pebble_device_mutation_responseObservableChain & {
    execute: (
      request: pebble_device_mutation_responseRequest,
      defaultValue?: pebble_device_mutation_response | null,
    ) => Observable<pebble_device_mutation_response | null>
  }
  /** delete single row from the table: "pebble.device" */
  delete_pebble_device_by_pk: (args: {
    id: String
  }) => pebble_deviceObservableChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Observable<pebble_device | null>
  }
  /** delete data from the table: "pebble.device_record" */
  delete_pebble_device_record: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_device_record_bool_exp
  }) => pebble_device_record_mutation_responseObservableChain & {
    execute: (
      request: pebble_device_record_mutation_responseRequest,
      defaultValue?: pebble_device_record_mutation_response | null,
    ) => Observable<pebble_device_record_mutation_response | null>
  }
  /** delete single row from the table: "pebble.device_record" */
  delete_pebble_device_record_by_pk: (args: {
    id: String
  }) => pebble_device_recordObservableChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Observable<pebble_device_record | null>
  }
  /** delete data from the table: "pebble.status" */
  delete_pebble_status: (args: {
    /** filter the rows which have to be deleted */
    where: pebble_status_bool_exp
  }) => pebble_status_mutation_responseObservableChain & {
    execute: (
      request: pebble_status_mutation_responseRequest,
      defaultValue?: pebble_status_mutation_response | null,
    ) => Observable<pebble_status_mutation_response | null>
  }
  /** delete single row from the table: "pebble.status" */
  delete_pebble_status_by_pk: (args: {
    id: Int
  }) => pebble_statusObservableChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Observable<pebble_status | null>
  }
  /** insert data into the table: "lonely.area" */
  insert_lonely_area: (args: {
    /** the rows to be inserted */
    objects: lonely_area_insert_input[]
    /** on conflict condition */
    on_conflict?: lonely_area_on_conflict | null
  }) => lonely_area_mutation_responseObservableChain & {
    execute: (
      request: lonely_area_mutation_responseRequest,
      defaultValue?: lonely_area_mutation_response | null,
    ) => Observable<lonely_area_mutation_response | null>
  }
  /** insert a single row into the table: "lonely.area" */
  insert_lonely_area_one: (args: {
    /** the row to be inserted */
    object: lonely_area_insert_input
    /** on conflict condition */
    on_conflict?: lonely_area_on_conflict | null
  }) => lonely_areaObservableChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Observable<lonely_area | null>
  }
  /** insert data into the table: "lonely.device" */
  insert_lonely_device: (args: {
    /** the rows to be inserted */
    objects: lonely_device_insert_input[]
    /** on conflict condition */
    on_conflict?: lonely_device_on_conflict | null
  }) => lonely_device_mutation_responseObservableChain & {
    execute: (
      request: lonely_device_mutation_responseRequest,
      defaultValue?: lonely_device_mutation_response | null,
    ) => Observable<lonely_device_mutation_response | null>
  }
  /** insert a single row into the table: "lonely.device" */
  insert_lonely_device_one: (args: {
    /** the row to be inserted */
    object: lonely_device_insert_input
    /** on conflict condition */
    on_conflict?: lonely_device_on_conflict | null
  }) => lonely_deviceObservableChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Observable<lonely_device | null>
  }
  /** insert data into the table: "lonely.status" */
  insert_lonely_status: (args: {
    /** the rows to be inserted */
    objects: lonely_status_insert_input[]
    /** on conflict condition */
    on_conflict?: lonely_status_on_conflict | null
  }) => lonely_status_mutation_responseObservableChain & {
    execute: (
      request: lonely_status_mutation_responseRequest,
      defaultValue?: lonely_status_mutation_response | null,
    ) => Observable<lonely_status_mutation_response | null>
  }
  /** insert a single row into the table: "lonely.status" */
  insert_lonely_status_one: (args: {
    /** the row to be inserted */
    object: lonely_status_insert_input
    /** on conflict condition */
    on_conflict?: lonely_status_on_conflict | null
  }) => lonely_statusObservableChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Observable<lonely_status | null>
  }
  /** insert data into the table: "pebble.account" */
  insert_pebble_account: (args: {
    /** the rows to be inserted */
    objects: pebble_account_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_account_on_conflict | null
  }) => pebble_account_mutation_responseObservableChain & {
    execute: (
      request: pebble_account_mutation_responseRequest,
      defaultValue?: pebble_account_mutation_response | null,
    ) => Observable<pebble_account_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.account" */
  insert_pebble_account_one: (args: {
    /** the row to be inserted */
    object: pebble_account_insert_input
    /** on conflict condition */
    on_conflict?: pebble_account_on_conflict | null
  }) => pebble_accountObservableChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Observable<pebble_account | null>
  }
  /** insert data into the table: "pebble.app" */
  insert_pebble_app: (args: {
    /** the rows to be inserted */
    objects: pebble_app_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_app_on_conflict | null
  }) => pebble_app_mutation_responseObservableChain & {
    execute: (
      request: pebble_app_mutation_responseRequest,
      defaultValue?: pebble_app_mutation_response | null,
    ) => Observable<pebble_app_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.app" */
  insert_pebble_app_one: (args: {
    /** the row to be inserted */
    object: pebble_app_insert_input
    /** on conflict condition */
    on_conflict?: pebble_app_on_conflict | null
  }) => pebble_appObservableChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Observable<pebble_app | null>
  }
  /** insert data into the table: "pebble.app_v2" */
  insert_pebble_app_v2: (args: {
    /** the rows to be inserted */
    objects: pebble_app_v2_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_app_v2_on_conflict | null
  }) => pebble_app_v2_mutation_responseObservableChain & {
    execute: (
      request: pebble_app_v2_mutation_responseRequest,
      defaultValue?: pebble_app_v2_mutation_response | null,
    ) => Observable<pebble_app_v2_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.app_v2" */
  insert_pebble_app_v2_one: (args: {
    /** the row to be inserted */
    object: pebble_app_v2_insert_input
    /** on conflict condition */
    on_conflict?: pebble_app_v2_on_conflict | null
  }) => pebble_app_v2ObservableChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Observable<pebble_app_v2 | null>
  }
  /** insert data into the table: "pebble.bank" */
  insert_pebble_bank: (args: {
    /** the rows to be inserted */
    objects: pebble_bank_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_bank_on_conflict | null
  }) => pebble_bank_mutation_responseObservableChain & {
    execute: (
      request: pebble_bank_mutation_responseRequest,
      defaultValue?: pebble_bank_mutation_response | null,
    ) => Observable<pebble_bank_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.bank" */
  insert_pebble_bank_one: (args: {
    /** the row to be inserted */
    object: pebble_bank_insert_input
    /** on conflict condition */
    on_conflict?: pebble_bank_on_conflict | null
  }) => pebble_bankObservableChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Observable<pebble_bank | null>
  }
  /** insert data into the table: "pebble.bank_record" */
  insert_pebble_bank_record: (args: {
    /** the rows to be inserted */
    objects: pebble_bank_record_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_bank_record_on_conflict | null
  }) => pebble_bank_record_mutation_responseObservableChain & {
    execute: (
      request: pebble_bank_record_mutation_responseRequest,
      defaultValue?: pebble_bank_record_mutation_response | null,
    ) => Observable<pebble_bank_record_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.bank_record" */
  insert_pebble_bank_record_one: (args: {
    /** the row to be inserted */
    object: pebble_bank_record_insert_input
    /** on conflict condition */
    on_conflict?: pebble_bank_record_on_conflict | null
  }) => pebble_bank_recordObservableChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Observable<pebble_bank_record | null>
  }
  /** insert data into the table: "pebble.config" */
  insert_pebble_config: (args: {
    /** the rows to be inserted */
    objects: pebble_config_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_config_on_conflict | null
  }) => pebble_config_mutation_responseObservableChain & {
    execute: (
      request: pebble_config_mutation_responseRequest,
      defaultValue?: pebble_config_mutation_response | null,
    ) => Observable<pebble_config_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.config" */
  insert_pebble_config_one: (args: {
    /** the row to be inserted */
    object: pebble_config_insert_input
    /** on conflict condition */
    on_conflict?: pebble_config_on_conflict | null
  }) => pebble_configObservableChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Observable<pebble_config | null>
  }
  /** insert data into the table: "pebble.data_cache" */
  insert_pebble_data_cache: (args: {
    /** the rows to be inserted */
    objects: pebble_data_cache_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_data_cache_on_conflict | null
  }) => pebble_data_cache_mutation_responseObservableChain & {
    execute: (
      request: pebble_data_cache_mutation_responseRequest,
      defaultValue?: pebble_data_cache_mutation_response | null,
    ) => Observable<pebble_data_cache_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.data_cache" */
  insert_pebble_data_cache_one: (args: {
    /** the row to be inserted */
    object: pebble_data_cache_insert_input
    /** on conflict condition */
    on_conflict?: pebble_data_cache_on_conflict | null
  }) => pebble_data_cacheObservableChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Observable<pebble_data_cache | null>
  }
  /** insert data into the table: "pebble.device" */
  insert_pebble_device: (args: {
    /** the rows to be inserted */
    objects: pebble_device_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_device_on_conflict | null
  }) => pebble_device_mutation_responseObservableChain & {
    execute: (
      request: pebble_device_mutation_responseRequest,
      defaultValue?: pebble_device_mutation_response | null,
    ) => Observable<pebble_device_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.device" */
  insert_pebble_device_one: (args: {
    /** the row to be inserted */
    object: pebble_device_insert_input
    /** on conflict condition */
    on_conflict?: pebble_device_on_conflict | null
  }) => pebble_deviceObservableChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Observable<pebble_device | null>
  }
  /** insert data into the table: "pebble.device_record" */
  insert_pebble_device_record: (args: {
    /** the rows to be inserted */
    objects: pebble_device_record_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_device_record_on_conflict | null
  }) => pebble_device_record_mutation_responseObservableChain & {
    execute: (
      request: pebble_device_record_mutation_responseRequest,
      defaultValue?: pebble_device_record_mutation_response | null,
    ) => Observable<pebble_device_record_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.device_record" */
  insert_pebble_device_record_one: (args: {
    /** the row to be inserted */
    object: pebble_device_record_insert_input
    /** on conflict condition */
    on_conflict?: pebble_device_record_on_conflict | null
  }) => pebble_device_recordObservableChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Observable<pebble_device_record | null>
  }
  /** insert data into the table: "pebble.status" */
  insert_pebble_status: (args: {
    /** the rows to be inserted */
    objects: pebble_status_insert_input[]
    /** on conflict condition */
    on_conflict?: pebble_status_on_conflict | null
  }) => pebble_status_mutation_responseObservableChain & {
    execute: (
      request: pebble_status_mutation_responseRequest,
      defaultValue?: pebble_status_mutation_response | null,
    ) => Observable<pebble_status_mutation_response | null>
  }
  /** insert a single row into the table: "pebble.status" */
  insert_pebble_status_one: (args: {
    /** the row to be inserted */
    object: pebble_status_insert_input
    /** on conflict condition */
    on_conflict?: pebble_status_on_conflict | null
  }) => pebble_statusObservableChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Observable<pebble_status | null>
  }
  /** update data of the table: "lonely.area" */
  update_lonely_area: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_area_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_area_set_input | null
    /** filter the rows which have to be updated */
    where: lonely_area_bool_exp
  }) => lonely_area_mutation_responseObservableChain & {
    execute: (
      request: lonely_area_mutation_responseRequest,
      defaultValue?: lonely_area_mutation_response | null,
    ) => Observable<lonely_area_mutation_response | null>
  }
  /** update single row of the table: "lonely.area" */
  update_lonely_area_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_area_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_area_set_input | null
    pk_columns: lonely_area_pk_columns_input
  }) => lonely_areaObservableChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Observable<lonely_area | null>
  }
  /** update data of the table: "lonely.device" */
  update_lonely_device: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_device_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_device_set_input | null
    /** filter the rows which have to be updated */
    where: lonely_device_bool_exp
  }) => lonely_device_mutation_responseObservableChain & {
    execute: (
      request: lonely_device_mutation_responseRequest,
      defaultValue?: lonely_device_mutation_response | null,
    ) => Observable<lonely_device_mutation_response | null>
  }
  /** update single row of the table: "lonely.device" */
  update_lonely_device_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_device_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_device_set_input | null
    pk_columns: lonely_device_pk_columns_input
  }) => lonely_deviceObservableChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Observable<lonely_device | null>
  }
  /** update data of the table: "lonely.status" */
  update_lonely_status: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_status_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_status_set_input | null
    /** filter the rows which have to be updated */
    where: lonely_status_bool_exp
  }) => lonely_status_mutation_responseObservableChain & {
    execute: (
      request: lonely_status_mutation_responseRequest,
      defaultValue?: lonely_status_mutation_response | null,
    ) => Observable<lonely_status_mutation_response | null>
  }
  /** update single row of the table: "lonely.status" */
  update_lonely_status_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: lonely_status_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: lonely_status_set_input | null
    pk_columns: lonely_status_pk_columns_input
  }) => lonely_statusObservableChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Observable<lonely_status | null>
  }
  /** update data of the table: "pebble.account" */
  update_pebble_account: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_account_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_account_bool_exp
  }) => pebble_account_mutation_responseObservableChain & {
    execute: (
      request: pebble_account_mutation_responseRequest,
      defaultValue?: pebble_account_mutation_response | null,
    ) => Observable<pebble_account_mutation_response | null>
  }
  /** update single row of the table: "pebble.account" */
  update_pebble_account_by_pk: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_account_set_input | null
    pk_columns: pebble_account_pk_columns_input
  }) => pebble_accountObservableChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Observable<pebble_account | null>
  }
  /** update data of the table: "pebble.app" */
  update_pebble_app: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_app_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_app_bool_exp
  }) => pebble_app_mutation_responseObservableChain & {
    execute: (
      request: pebble_app_mutation_responseRequest,
      defaultValue?: pebble_app_mutation_response | null,
    ) => Observable<pebble_app_mutation_response | null>
  }
  /** update single row of the table: "pebble.app" */
  update_pebble_app_by_pk: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_app_set_input | null
    pk_columns: pebble_app_pk_columns_input
  }) => pebble_appObservableChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Observable<pebble_app | null>
  }
  /** update data of the table: "pebble.app_v2" */
  update_pebble_app_v2: (args: {
    /** append existing jsonb value of filtered columns with new jsonb value */
    _append?: pebble_app_v2_append_input | null
    /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
    _delete_at_path?: pebble_app_v2_delete_at_path_input | null
    /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
    _delete_elem?: pebble_app_v2_delete_elem_input | null
    /** delete key/value pair or string element. key/value pairs are matched based on their key value */
    _delete_key?: pebble_app_v2_delete_key_input | null
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_app_v2_inc_input | null
    /** prepend existing jsonb value of filtered columns with new jsonb value */
    _prepend?: pebble_app_v2_prepend_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_app_v2_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_app_v2_bool_exp
  }) => pebble_app_v2_mutation_responseObservableChain & {
    execute: (
      request: pebble_app_v2_mutation_responseRequest,
      defaultValue?: pebble_app_v2_mutation_response | null,
    ) => Observable<pebble_app_v2_mutation_response | null>
  }
  /** update single row of the table: "pebble.app_v2" */
  update_pebble_app_v2_by_pk: (args: {
    /** append existing jsonb value of filtered columns with new jsonb value */
    _append?: pebble_app_v2_append_input | null
    /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
    _delete_at_path?: pebble_app_v2_delete_at_path_input | null
    /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
    _delete_elem?: pebble_app_v2_delete_elem_input | null
    /** delete key/value pair or string element. key/value pairs are matched based on their key value */
    _delete_key?: pebble_app_v2_delete_key_input | null
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_app_v2_inc_input | null
    /** prepend existing jsonb value of filtered columns with new jsonb value */
    _prepend?: pebble_app_v2_prepend_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_app_v2_set_input | null
    pk_columns: pebble_app_v2_pk_columns_input
  }) => pebble_app_v2ObservableChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Observable<pebble_app_v2 | null>
  }
  /** update data of the table: "pebble.bank" */
  update_pebble_bank: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_bank_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_bank_bool_exp
  }) => pebble_bank_mutation_responseObservableChain & {
    execute: (
      request: pebble_bank_mutation_responseRequest,
      defaultValue?: pebble_bank_mutation_response | null,
    ) => Observable<pebble_bank_mutation_response | null>
  }
  /** update single row of the table: "pebble.bank" */
  update_pebble_bank_by_pk: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_bank_set_input | null
    pk_columns: pebble_bank_pk_columns_input
  }) => pebble_bankObservableChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Observable<pebble_bank | null>
  }
  /** update data of the table: "pebble.bank_record" */
  update_pebble_bank_record: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_bank_record_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_bank_record_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_bank_record_bool_exp
  }) => pebble_bank_record_mutation_responseObservableChain & {
    execute: (
      request: pebble_bank_record_mutation_responseRequest,
      defaultValue?: pebble_bank_record_mutation_response | null,
    ) => Observable<pebble_bank_record_mutation_response | null>
  }
  /** update single row of the table: "pebble.bank_record" */
  update_pebble_bank_record_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_bank_record_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_bank_record_set_input | null
    pk_columns: pebble_bank_record_pk_columns_input
  }) => pebble_bank_recordObservableChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Observable<pebble_bank_record | null>
  }
  /** update data of the table: "pebble.config" */
  update_pebble_config: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_config_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_config_bool_exp
  }) => pebble_config_mutation_responseObservableChain & {
    execute: (
      request: pebble_config_mutation_responseRequest,
      defaultValue?: pebble_config_mutation_response | null,
    ) => Observable<pebble_config_mutation_response | null>
  }
  /** update single row of the table: "pebble.config" */
  update_pebble_config_by_pk: (args: {
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_config_set_input | null
    pk_columns: pebble_config_pk_columns_input
  }) => pebble_configObservableChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Observable<pebble_config | null>
  }
  /** update data of the table: "pebble.data_cache" */
  update_pebble_data_cache: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_data_cache_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_data_cache_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_data_cache_bool_exp
  }) => pebble_data_cache_mutation_responseObservableChain & {
    execute: (
      request: pebble_data_cache_mutation_responseRequest,
      defaultValue?: pebble_data_cache_mutation_response | null,
    ) => Observable<pebble_data_cache_mutation_response | null>
  }
  /** update single row of the table: "pebble.data_cache" */
  update_pebble_data_cache_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_data_cache_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_data_cache_set_input | null
    pk_columns: pebble_data_cache_pk_columns_input
  }) => pebble_data_cacheObservableChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Observable<pebble_data_cache | null>
  }
  /** update data of the table: "pebble.device" */
  update_pebble_device: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_device_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_device_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_device_bool_exp
  }) => pebble_device_mutation_responseObservableChain & {
    execute: (
      request: pebble_device_mutation_responseRequest,
      defaultValue?: pebble_device_mutation_response | null,
    ) => Observable<pebble_device_mutation_response | null>
  }
  /** update single row of the table: "pebble.device" */
  update_pebble_device_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_device_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_device_set_input | null
    pk_columns: pebble_device_pk_columns_input
  }) => pebble_deviceObservableChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Observable<pebble_device | null>
  }
  /** update data of the table: "pebble.device_record" */
  update_pebble_device_record: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_device_record_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_device_record_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_device_record_bool_exp
  }) => pebble_device_record_mutation_responseObservableChain & {
    execute: (
      request: pebble_device_record_mutation_responseRequest,
      defaultValue?: pebble_device_record_mutation_response | null,
    ) => Observable<pebble_device_record_mutation_response | null>
  }
  /** update single row of the table: "pebble.device_record" */
  update_pebble_device_record_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_device_record_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_device_record_set_input | null
    pk_columns: pebble_device_record_pk_columns_input
  }) => pebble_device_recordObservableChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Observable<pebble_device_record | null>
  }
  /** update data of the table: "pebble.status" */
  update_pebble_status: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_status_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_status_set_input | null
    /** filter the rows which have to be updated */
    where: pebble_status_bool_exp
  }) => pebble_status_mutation_responseObservableChain & {
    execute: (
      request: pebble_status_mutation_responseRequest,
      defaultValue?: pebble_status_mutation_response | null,
    ) => Observable<pebble_status_mutation_response | null>
  }
  /** update single row of the table: "pebble.status" */
  update_pebble_status_by_pk: (args: {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: pebble_status_inc_input | null
    /** sets the columns of the filtered rows to the given values */
    _set?: pebble_status_set_input | null
    pk_columns: pebble_status_pk_columns_input
  }) => pebble_statusObservableChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Observable<pebble_status | null>
  }
}

/** response of any mutation on the table "lonely.area" */
export interface lonely_area_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Promise<lonely_area[]> }
}

/** response of any mutation on the table "lonely.area" */
export interface lonely_area_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Observable<lonely_area[]> }
}

/** response of any mutation on the table "lonely.device" */
export interface lonely_device_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Promise<lonely_device[]> }
}

/** response of any mutation on the table "lonely.device" */
export interface lonely_device_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Observable<lonely_device[]> }
}

/** response of any mutation on the table "lonely.status" */
export interface lonely_status_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Promise<lonely_status[]> }
}

/** response of any mutation on the table "lonely.status" */
export interface lonely_status_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Observable<lonely_status[]> }
}

/** response of any mutation on the table "pebble.account" */
export interface pebble_account_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Promise<pebble_account[]> }
}

/** response of any mutation on the table "pebble.account" */
export interface pebble_account_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Observable<pebble_account[]> }
}

/** response of any mutation on the table "pebble.app" */
export interface pebble_app_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Promise<pebble_app[]> }
}

/** response of any mutation on the table "pebble.app" */
export interface pebble_app_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Observable<pebble_app[]> }
}

/** response of any mutation on the table "pebble.app_v2" */
export interface pebble_app_v2_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Promise<pebble_app_v2[]> }
}

/** response of any mutation on the table "pebble.app_v2" */
export interface pebble_app_v2_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Observable<pebble_app_v2[]> }
}

/** response of any mutation on the table "pebble.bank" */
export interface pebble_bank_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Promise<pebble_bank[]> }
}

/** response of any mutation on the table "pebble.bank" */
export interface pebble_bank_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Observable<pebble_bank[]> }
}

/** response of any mutation on the table "pebble.bank_record" */
export interface pebble_bank_record_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Promise<pebble_bank_record[]>
  }
}

/** response of any mutation on the table "pebble.bank_record" */
export interface pebble_bank_record_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Observable<pebble_bank_record[]>
  }
}

/** response of any mutation on the table "pebble.config" */
export interface pebble_config_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Promise<pebble_config[]> }
}

/** response of any mutation on the table "pebble.config" */
export interface pebble_config_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Observable<pebble_config[]> }
}

/** response of any mutation on the table "pebble.data_cache" */
export interface pebble_data_cache_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: {
    execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Promise<pebble_data_cache[]>
  }
}

/** response of any mutation on the table "pebble.data_cache" */
export interface pebble_data_cache_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: {
    execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Observable<pebble_data_cache[]>
  }
}

/** response of any mutation on the table "pebble.device" */
export interface pebble_device_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Promise<pebble_device[]> }
}

/** response of any mutation on the table "pebble.device" */
export interface pebble_device_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Observable<pebble_device[]> }
}

/** response of any mutation on the table "pebble.device_record" */
export interface pebble_device_record_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: {
    execute: (request: pebble_device_recordRequest, defaultValue?: pebble_device_record[]) => Promise<pebble_device_record[]>
  }
}

/** response of any mutation on the table "pebble.device_record" */
export interface pebble_device_record_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record[],
    ) => Observable<pebble_device_record[]>
  }
}

/** response of any mutation on the table "pebble.status" */
export interface pebble_status_mutation_responsePromiseChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Promise<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Promise<pebble_status[]> }
}

/** response of any mutation on the table "pebble.status" */
export interface pebble_status_mutation_responseObservableChain {
  /** number of rows affected by the mutation */
  affected_rows: { execute: (request?: boolean | number, defaultValue?: Int) => Observable<Int> }
  /** data from the rows affected by the mutation */
  returning: { execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Observable<pebble_status[]> }
}

export interface subscription_rootPromiseChain {
  /** fetch data from the table: "lonely.area" */
  lonely_area: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_area_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_area_order_by[] | null
    /** filter the rows returned */
    where?: lonely_area_bool_exp | null
  }) => { execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Promise<lonely_area[]> }) & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Promise<lonely_area[]>
  }
  /** fetch aggregated fields from the table: "lonely.area" */
  lonely_area_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_area_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_area_order_by[] | null
    /** filter the rows returned */
    where?: lonely_area_bool_exp | null
  }) => lonely_area_aggregatePromiseChain & {
    execute: (request: lonely_area_aggregateRequest, defaultValue?: lonely_area_aggregate) => Promise<lonely_area_aggregate>
  }) &
    (lonely_area_aggregatePromiseChain & {
      execute: (
        request: lonely_area_aggregateRequest,
        defaultValue?: lonely_area_aggregate,
      ) => Promise<lonely_area_aggregate>
    })
  /** fetch data from the table: "lonely.area" using primary key columns */
  lonely_area_by_pk: (args: {
    id: String
  }) => lonely_areaPromiseChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Promise<lonely_area | null>
  }
  /** fetch data from the table: "lonely.device" */
  lonely_device: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_device_order_by[] | null
    /** filter the rows returned */
    where?: lonely_device_bool_exp | null
  }) => { execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Promise<lonely_device[]> }) & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Promise<lonely_device[]>
  }
  /** fetch aggregated fields from the table: "lonely.device" */
  lonely_device_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_device_order_by[] | null
    /** filter the rows returned */
    where?: lonely_device_bool_exp | null
  }) => lonely_device_aggregatePromiseChain & {
    execute: (
      request: lonely_device_aggregateRequest,
      defaultValue?: lonely_device_aggregate,
    ) => Promise<lonely_device_aggregate>
  }) &
    (lonely_device_aggregatePromiseChain & {
      execute: (
        request: lonely_device_aggregateRequest,
        defaultValue?: lonely_device_aggregate,
      ) => Promise<lonely_device_aggregate>
    })
  /** fetch data from the table: "lonely.device" using primary key columns */
  lonely_device_by_pk: (args: {
    id: String
  }) => lonely_devicePromiseChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Promise<lonely_device | null>
  }
  /** fetch data from the table: "lonely.status" */
  lonely_status: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_status_order_by[] | null
    /** filter the rows returned */
    where?: lonely_status_bool_exp | null
  }) => { execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Promise<lonely_status[]> }) & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Promise<lonely_status[]>
  }
  /** fetch aggregated fields from the table: "lonely.status" */
  lonely_status_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_status_order_by[] | null
    /** filter the rows returned */
    where?: lonely_status_bool_exp | null
  }) => lonely_status_aggregatePromiseChain & {
    execute: (
      request: lonely_status_aggregateRequest,
      defaultValue?: lonely_status_aggregate,
    ) => Promise<lonely_status_aggregate>
  }) &
    (lonely_status_aggregatePromiseChain & {
      execute: (
        request: lonely_status_aggregateRequest,
        defaultValue?: lonely_status_aggregate,
      ) => Promise<lonely_status_aggregate>
    })
  /** fetch data from the table: "lonely.status" using primary key columns */
  lonely_status_by_pk: (args: {
    id: Int
  }) => lonely_statusPromiseChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Promise<lonely_status | null>
  }
  /** fetch data from the table: "pebble.account" */
  pebble_account: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_account_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_account_order_by[] | null
    /** filter the rows returned */
    where?: pebble_account_bool_exp | null
  }) => { execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Promise<pebble_account[]> }) & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Promise<pebble_account[]>
  }
  /** fetch aggregated fields from the table: "pebble.account" */
  pebble_account_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_account_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_account_order_by[] | null
    /** filter the rows returned */
    where?: pebble_account_bool_exp | null
  }) => pebble_account_aggregatePromiseChain & {
    execute: (
      request: pebble_account_aggregateRequest,
      defaultValue?: pebble_account_aggregate,
    ) => Promise<pebble_account_aggregate>
  }) &
    (pebble_account_aggregatePromiseChain & {
      execute: (
        request: pebble_account_aggregateRequest,
        defaultValue?: pebble_account_aggregate,
      ) => Promise<pebble_account_aggregate>
    })
  /** fetch data from the table: "pebble.account" using primary key columns */
  pebble_account_by_pk: (args: {
    id: String
  }) => pebble_accountPromiseChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Promise<pebble_account | null>
  }
  /** fetch data from the table: "pebble.app" */
  pebble_app: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_bool_exp | null
  }) => { execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Promise<pebble_app[]> }) & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Promise<pebble_app[]>
  }
  /** fetch aggregated fields from the table: "pebble.app" */
  pebble_app_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_bool_exp | null
  }) => pebble_app_aggregatePromiseChain & {
    execute: (request: pebble_app_aggregateRequest, defaultValue?: pebble_app_aggregate) => Promise<pebble_app_aggregate>
  }) &
    (pebble_app_aggregatePromiseChain & {
      execute: (request: pebble_app_aggregateRequest, defaultValue?: pebble_app_aggregate) => Promise<pebble_app_aggregate>
    })
  /** fetch data from the table: "pebble.app" using primary key columns */
  pebble_app_by_pk: (args: {
    id: String
  }) => pebble_appPromiseChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Promise<pebble_app | null>
  }
  /** fetch data from the table: "pebble.app_v2" */
  pebble_app_v2: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_v2_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_v2_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_v2_bool_exp | null
  }) => { execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Promise<pebble_app_v2[]> }) & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Promise<pebble_app_v2[]>
  }
  /** fetch aggregated fields from the table: "pebble.app_v2" */
  pebble_app_v2_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_v2_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_v2_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_v2_bool_exp | null
  }) => pebble_app_v2_aggregatePromiseChain & {
    execute: (
      request: pebble_app_v2_aggregateRequest,
      defaultValue?: pebble_app_v2_aggregate,
    ) => Promise<pebble_app_v2_aggregate>
  }) &
    (pebble_app_v2_aggregatePromiseChain & {
      execute: (
        request: pebble_app_v2_aggregateRequest,
        defaultValue?: pebble_app_v2_aggregate,
      ) => Promise<pebble_app_v2_aggregate>
    })
  /** fetch data from the table: "pebble.app_v2" using primary key columns */
  pebble_app_v2_by_pk: (args: {
    id: String
  }) => pebble_app_v2PromiseChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Promise<pebble_app_v2 | null>
  }
  /** fetch data from the table: "pebble.bank" */
  pebble_bank: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_bool_exp | null
  }) => { execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Promise<pebble_bank[]> }) & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Promise<pebble_bank[]>
  }
  /** fetch aggregated fields from the table: "pebble.bank" */
  pebble_bank_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_bool_exp | null
  }) => pebble_bank_aggregatePromiseChain & {
    execute: (request: pebble_bank_aggregateRequest, defaultValue?: pebble_bank_aggregate) => Promise<pebble_bank_aggregate>
  }) &
    (pebble_bank_aggregatePromiseChain & {
      execute: (
        request: pebble_bank_aggregateRequest,
        defaultValue?: pebble_bank_aggregate,
      ) => Promise<pebble_bank_aggregate>
    })
  /** fetch data from the table: "pebble.bank" using primary key columns */
  pebble_bank_by_pk: (args: {
    address: String
  }) => pebble_bankPromiseChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Promise<pebble_bank | null>
  }
  /** fetch data from the table: "pebble.bank_record" */
  pebble_bank_record: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_record_bool_exp | null
  }) => {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Promise<pebble_bank_record[]>
  }) & {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Promise<pebble_bank_record[]>
  }
  /** fetch aggregated fields from the table: "pebble.bank_record" */
  pebble_bank_record_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_record_bool_exp | null
  }) => pebble_bank_record_aggregatePromiseChain & {
    execute: (
      request: pebble_bank_record_aggregateRequest,
      defaultValue?: pebble_bank_record_aggregate,
    ) => Promise<pebble_bank_record_aggregate>
  }) &
    (pebble_bank_record_aggregatePromiseChain & {
      execute: (
        request: pebble_bank_record_aggregateRequest,
        defaultValue?: pebble_bank_record_aggregate,
      ) => Promise<pebble_bank_record_aggregate>
    })
  /** fetch data from the table: "pebble.bank_record" using primary key columns */
  pebble_bank_record_by_pk: (args: {
    id: String
  }) => pebble_bank_recordPromiseChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Promise<pebble_bank_record | null>
  }
  /** fetch data from the table: "pebble.config" */
  pebble_config: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_config_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_config_order_by[] | null
    /** filter the rows returned */
    where?: pebble_config_bool_exp | null
  }) => { execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Promise<pebble_config[]> }) & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Promise<pebble_config[]>
  }
  /** fetch aggregated fields from the table: "pebble.config" */
  pebble_config_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_config_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_config_order_by[] | null
    /** filter the rows returned */
    where?: pebble_config_bool_exp | null
  }) => pebble_config_aggregatePromiseChain & {
    execute: (
      request: pebble_config_aggregateRequest,
      defaultValue?: pebble_config_aggregate,
    ) => Promise<pebble_config_aggregate>
  }) &
    (pebble_config_aggregatePromiseChain & {
      execute: (
        request: pebble_config_aggregateRequest,
        defaultValue?: pebble_config_aggregate,
      ) => Promise<pebble_config_aggregate>
    })
  /** fetch data from the table: "pebble.config" using primary key columns */
  pebble_config_by_pk: (args: {
    id: String
  }) => pebble_configPromiseChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Promise<pebble_config | null>
  }
  /** fetch data from the table: "pebble.data_cache" */
  pebble_data_cache: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_data_cache_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_data_cache_order_by[] | null
    /** filter the rows returned */
    where?: pebble_data_cache_bool_exp | null
  }) => {
    execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Promise<pebble_data_cache[]>
  }) & { execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Promise<pebble_data_cache[]> }
  /** fetch aggregated fields from the table: "pebble.data_cache" */
  pebble_data_cache_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_data_cache_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_data_cache_order_by[] | null
    /** filter the rows returned */
    where?: pebble_data_cache_bool_exp | null
  }) => pebble_data_cache_aggregatePromiseChain & {
    execute: (
      request: pebble_data_cache_aggregateRequest,
      defaultValue?: pebble_data_cache_aggregate,
    ) => Promise<pebble_data_cache_aggregate>
  }) &
    (pebble_data_cache_aggregatePromiseChain & {
      execute: (
        request: pebble_data_cache_aggregateRequest,
        defaultValue?: pebble_data_cache_aggregate,
      ) => Promise<pebble_data_cache_aggregate>
    })
  /** fetch data from the table: "pebble.data_cache" using primary key columns */
  pebble_data_cache_by_pk: (args: {
    id: Int
  }) => pebble_data_cachePromiseChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Promise<pebble_data_cache | null>
  }
  /** fetch data from the table: "pebble.device" */
  pebble_device: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_bool_exp | null
  }) => { execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Promise<pebble_device[]> }) & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Promise<pebble_device[]>
  }
  /** fetch aggregated fields from the table: "pebble.device" */
  pebble_device_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_bool_exp | null
  }) => pebble_device_aggregatePromiseChain & {
    execute: (
      request: pebble_device_aggregateRequest,
      defaultValue?: pebble_device_aggregate,
    ) => Promise<pebble_device_aggregate>
  }) &
    (pebble_device_aggregatePromiseChain & {
      execute: (
        request: pebble_device_aggregateRequest,
        defaultValue?: pebble_device_aggregate,
      ) => Promise<pebble_device_aggregate>
    })
  /** fetch data from the table: "pebble.device" using primary key columns */
  pebble_device_by_pk: (args: {
    id: String
  }) => pebble_devicePromiseChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Promise<pebble_device | null>
  }
  /** fetch data from the table: "pebble.device_record" */
  pebble_device_record: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => {
    execute: (request: pebble_device_recordRequest, defaultValue?: pebble_device_record[]) => Promise<pebble_device_record[]>
  }) & {
    execute: (request: pebble_device_recordRequest, defaultValue?: pebble_device_record[]) => Promise<pebble_device_record[]>
  }
  /** fetch aggregated fields from the table: "pebble.device_record" */
  pebble_device_record_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => pebble_device_record_aggregatePromiseChain & {
    execute: (
      request: pebble_device_record_aggregateRequest,
      defaultValue?: pebble_device_record_aggregate,
    ) => Promise<pebble_device_record_aggregate>
  }) &
    (pebble_device_record_aggregatePromiseChain & {
      execute: (
        request: pebble_device_record_aggregateRequest,
        defaultValue?: pebble_device_record_aggregate,
      ) => Promise<pebble_device_record_aggregate>
    })
  /** fetch data from the table: "pebble.device_record" using primary key columns */
  pebble_device_record_by_pk: (args: {
    id: String
  }) => pebble_device_recordPromiseChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Promise<pebble_device_record | null>
  }
  /** fetch data from the table: "pebble.status" */
  pebble_status: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_status_order_by[] | null
    /** filter the rows returned */
    where?: pebble_status_bool_exp | null
  }) => { execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Promise<pebble_status[]> }) & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Promise<pebble_status[]>
  }
  /** fetch aggregated fields from the table: "pebble.status" */
  pebble_status_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_status_order_by[] | null
    /** filter the rows returned */
    where?: pebble_status_bool_exp | null
  }) => pebble_status_aggregatePromiseChain & {
    execute: (
      request: pebble_status_aggregateRequest,
      defaultValue?: pebble_status_aggregate,
    ) => Promise<pebble_status_aggregate>
  }) &
    (pebble_status_aggregatePromiseChain & {
      execute: (
        request: pebble_status_aggregateRequest,
        defaultValue?: pebble_status_aggregate,
      ) => Promise<pebble_status_aggregate>
    })
  /** fetch data from the table: "pebble.status" using primary key columns */
  pebble_status_by_pk: (args: {
    id: Int
  }) => pebble_statusPromiseChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Promise<pebble_status | null>
  }
}

export interface subscription_rootObservableChain {
  /** fetch data from the table: "lonely.area" */
  lonely_area: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_area_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_area_order_by[] | null
    /** filter the rows returned */
    where?: lonely_area_bool_exp | null
  }) => { execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Observable<lonely_area[]> }) & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area[]) => Observable<lonely_area[]>
  }
  /** fetch aggregated fields from the table: "lonely.area" */
  lonely_area_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_area_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_area_order_by[] | null
    /** filter the rows returned */
    where?: lonely_area_bool_exp | null
  }) => lonely_area_aggregateObservableChain & {
    execute: (
      request: lonely_area_aggregateRequest,
      defaultValue?: lonely_area_aggregate,
    ) => Observable<lonely_area_aggregate>
  }) &
    (lonely_area_aggregateObservableChain & {
      execute: (
        request: lonely_area_aggregateRequest,
        defaultValue?: lonely_area_aggregate,
      ) => Observable<lonely_area_aggregate>
    })
  /** fetch data from the table: "lonely.area" using primary key columns */
  lonely_area_by_pk: (args: {
    id: String
  }) => lonely_areaObservableChain & {
    execute: (request: lonely_areaRequest, defaultValue?: lonely_area | null) => Observable<lonely_area | null>
  }
  /** fetch data from the table: "lonely.device" */
  lonely_device: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_device_order_by[] | null
    /** filter the rows returned */
    where?: lonely_device_bool_exp | null
  }) => { execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Observable<lonely_device[]> }) & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device[]) => Observable<lonely_device[]>
  }
  /** fetch aggregated fields from the table: "lonely.device" */
  lonely_device_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_device_order_by[] | null
    /** filter the rows returned */
    where?: lonely_device_bool_exp | null
  }) => lonely_device_aggregateObservableChain & {
    execute: (
      request: lonely_device_aggregateRequest,
      defaultValue?: lonely_device_aggregate,
    ) => Observable<lonely_device_aggregate>
  }) &
    (lonely_device_aggregateObservableChain & {
      execute: (
        request: lonely_device_aggregateRequest,
        defaultValue?: lonely_device_aggregate,
      ) => Observable<lonely_device_aggregate>
    })
  /** fetch data from the table: "lonely.device" using primary key columns */
  lonely_device_by_pk: (args: {
    id: String
  }) => lonely_deviceObservableChain & {
    execute: (request: lonely_deviceRequest, defaultValue?: lonely_device | null) => Observable<lonely_device | null>
  }
  /** fetch data from the table: "lonely.status" */
  lonely_status: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_status_order_by[] | null
    /** filter the rows returned */
    where?: lonely_status_bool_exp | null
  }) => { execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Observable<lonely_status[]> }) & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status[]) => Observable<lonely_status[]>
  }
  /** fetch aggregated fields from the table: "lonely.status" */
  lonely_status_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: lonely_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: lonely_status_order_by[] | null
    /** filter the rows returned */
    where?: lonely_status_bool_exp | null
  }) => lonely_status_aggregateObservableChain & {
    execute: (
      request: lonely_status_aggregateRequest,
      defaultValue?: lonely_status_aggregate,
    ) => Observable<lonely_status_aggregate>
  }) &
    (lonely_status_aggregateObservableChain & {
      execute: (
        request: lonely_status_aggregateRequest,
        defaultValue?: lonely_status_aggregate,
      ) => Observable<lonely_status_aggregate>
    })
  /** fetch data from the table: "lonely.status" using primary key columns */
  lonely_status_by_pk: (args: {
    id: Int
  }) => lonely_statusObservableChain & {
    execute: (request: lonely_statusRequest, defaultValue?: lonely_status | null) => Observable<lonely_status | null>
  }
  /** fetch data from the table: "pebble.account" */
  pebble_account: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_account_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_account_order_by[] | null
    /** filter the rows returned */
    where?: pebble_account_bool_exp | null
  }) => { execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Observable<pebble_account[]> }) & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account[]) => Observable<pebble_account[]>
  }
  /** fetch aggregated fields from the table: "pebble.account" */
  pebble_account_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_account_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_account_order_by[] | null
    /** filter the rows returned */
    where?: pebble_account_bool_exp | null
  }) => pebble_account_aggregateObservableChain & {
    execute: (
      request: pebble_account_aggregateRequest,
      defaultValue?: pebble_account_aggregate,
    ) => Observable<pebble_account_aggregate>
  }) &
    (pebble_account_aggregateObservableChain & {
      execute: (
        request: pebble_account_aggregateRequest,
        defaultValue?: pebble_account_aggregate,
      ) => Observable<pebble_account_aggregate>
    })
  /** fetch data from the table: "pebble.account" using primary key columns */
  pebble_account_by_pk: (args: {
    id: String
  }) => pebble_accountObservableChain & {
    execute: (request: pebble_accountRequest, defaultValue?: pebble_account | null) => Observable<pebble_account | null>
  }
  /** fetch data from the table: "pebble.app" */
  pebble_app: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_bool_exp | null
  }) => { execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Observable<pebble_app[]> }) & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app[]) => Observable<pebble_app[]>
  }
  /** fetch aggregated fields from the table: "pebble.app" */
  pebble_app_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_bool_exp | null
  }) => pebble_app_aggregateObservableChain & {
    execute: (request: pebble_app_aggregateRequest, defaultValue?: pebble_app_aggregate) => Observable<pebble_app_aggregate>
  }) &
    (pebble_app_aggregateObservableChain & {
      execute: (
        request: pebble_app_aggregateRequest,
        defaultValue?: pebble_app_aggregate,
      ) => Observable<pebble_app_aggregate>
    })
  /** fetch data from the table: "pebble.app" using primary key columns */
  pebble_app_by_pk: (args: {
    id: String
  }) => pebble_appObservableChain & {
    execute: (request: pebble_appRequest, defaultValue?: pebble_app | null) => Observable<pebble_app | null>
  }
  /** fetch data from the table: "pebble.app_v2" */
  pebble_app_v2: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_v2_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_v2_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_v2_bool_exp | null
  }) => { execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Observable<pebble_app_v2[]> }) & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2[]) => Observable<pebble_app_v2[]>
  }
  /** fetch aggregated fields from the table: "pebble.app_v2" */
  pebble_app_v2_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_app_v2_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_app_v2_order_by[] | null
    /** filter the rows returned */
    where?: pebble_app_v2_bool_exp | null
  }) => pebble_app_v2_aggregateObservableChain & {
    execute: (
      request: pebble_app_v2_aggregateRequest,
      defaultValue?: pebble_app_v2_aggregate,
    ) => Observable<pebble_app_v2_aggregate>
  }) &
    (pebble_app_v2_aggregateObservableChain & {
      execute: (
        request: pebble_app_v2_aggregateRequest,
        defaultValue?: pebble_app_v2_aggregate,
      ) => Observable<pebble_app_v2_aggregate>
    })
  /** fetch data from the table: "pebble.app_v2" using primary key columns */
  pebble_app_v2_by_pk: (args: {
    id: String
  }) => pebble_app_v2ObservableChain & {
    execute: (request: pebble_app_v2Request, defaultValue?: pebble_app_v2 | null) => Observable<pebble_app_v2 | null>
  }
  /** fetch data from the table: "pebble.bank" */
  pebble_bank: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_bool_exp | null
  }) => { execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Observable<pebble_bank[]> }) & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank[]) => Observable<pebble_bank[]>
  }
  /** fetch aggregated fields from the table: "pebble.bank" */
  pebble_bank_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_bool_exp | null
  }) => pebble_bank_aggregateObservableChain & {
    execute: (
      request: pebble_bank_aggregateRequest,
      defaultValue?: pebble_bank_aggregate,
    ) => Observable<pebble_bank_aggregate>
  }) &
    (pebble_bank_aggregateObservableChain & {
      execute: (
        request: pebble_bank_aggregateRequest,
        defaultValue?: pebble_bank_aggregate,
      ) => Observable<pebble_bank_aggregate>
    })
  /** fetch data from the table: "pebble.bank" using primary key columns */
  pebble_bank_by_pk: (args: {
    address: String
  }) => pebble_bankObservableChain & {
    execute: (request: pebble_bankRequest, defaultValue?: pebble_bank | null) => Observable<pebble_bank | null>
  }
  /** fetch data from the table: "pebble.bank_record" */
  pebble_bank_record: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_record_bool_exp | null
  }) => {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Observable<pebble_bank_record[]>
  }) & {
    execute: (request: pebble_bank_recordRequest, defaultValue?: pebble_bank_record[]) => Observable<pebble_bank_record[]>
  }
  /** fetch aggregated fields from the table: "pebble.bank_record" */
  pebble_bank_record_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_bank_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_bank_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_bank_record_bool_exp | null
  }) => pebble_bank_record_aggregateObservableChain & {
    execute: (
      request: pebble_bank_record_aggregateRequest,
      defaultValue?: pebble_bank_record_aggregate,
    ) => Observable<pebble_bank_record_aggregate>
  }) &
    (pebble_bank_record_aggregateObservableChain & {
      execute: (
        request: pebble_bank_record_aggregateRequest,
        defaultValue?: pebble_bank_record_aggregate,
      ) => Observable<pebble_bank_record_aggregate>
    })
  /** fetch data from the table: "pebble.bank_record" using primary key columns */
  pebble_bank_record_by_pk: (args: {
    id: String
  }) => pebble_bank_recordObservableChain & {
    execute: (
      request: pebble_bank_recordRequest,
      defaultValue?: pebble_bank_record | null,
    ) => Observable<pebble_bank_record | null>
  }
  /** fetch data from the table: "pebble.config" */
  pebble_config: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_config_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_config_order_by[] | null
    /** filter the rows returned */
    where?: pebble_config_bool_exp | null
  }) => { execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Observable<pebble_config[]> }) & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config[]) => Observable<pebble_config[]>
  }
  /** fetch aggregated fields from the table: "pebble.config" */
  pebble_config_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_config_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_config_order_by[] | null
    /** filter the rows returned */
    where?: pebble_config_bool_exp | null
  }) => pebble_config_aggregateObservableChain & {
    execute: (
      request: pebble_config_aggregateRequest,
      defaultValue?: pebble_config_aggregate,
    ) => Observable<pebble_config_aggregate>
  }) &
    (pebble_config_aggregateObservableChain & {
      execute: (
        request: pebble_config_aggregateRequest,
        defaultValue?: pebble_config_aggregate,
      ) => Observable<pebble_config_aggregate>
    })
  /** fetch data from the table: "pebble.config" using primary key columns */
  pebble_config_by_pk: (args: {
    id: String
  }) => pebble_configObservableChain & {
    execute: (request: pebble_configRequest, defaultValue?: pebble_config | null) => Observable<pebble_config | null>
  }
  /** fetch data from the table: "pebble.data_cache" */
  pebble_data_cache: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_data_cache_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_data_cache_order_by[] | null
    /** filter the rows returned */
    where?: pebble_data_cache_bool_exp | null
  }) => {
    execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Observable<pebble_data_cache[]>
  }) & {
    execute: (request: pebble_data_cacheRequest, defaultValue?: pebble_data_cache[]) => Observable<pebble_data_cache[]>
  }
  /** fetch aggregated fields from the table: "pebble.data_cache" */
  pebble_data_cache_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_data_cache_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_data_cache_order_by[] | null
    /** filter the rows returned */
    where?: pebble_data_cache_bool_exp | null
  }) => pebble_data_cache_aggregateObservableChain & {
    execute: (
      request: pebble_data_cache_aggregateRequest,
      defaultValue?: pebble_data_cache_aggregate,
    ) => Observable<pebble_data_cache_aggregate>
  }) &
    (pebble_data_cache_aggregateObservableChain & {
      execute: (
        request: pebble_data_cache_aggregateRequest,
        defaultValue?: pebble_data_cache_aggregate,
      ) => Observable<pebble_data_cache_aggregate>
    })
  /** fetch data from the table: "pebble.data_cache" using primary key columns */
  pebble_data_cache_by_pk: (args: {
    id: Int
  }) => pebble_data_cacheObservableChain & {
    execute: (
      request: pebble_data_cacheRequest,
      defaultValue?: pebble_data_cache | null,
    ) => Observable<pebble_data_cache | null>
  }
  /** fetch data from the table: "pebble.device" */
  pebble_device: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_bool_exp | null
  }) => { execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Observable<pebble_device[]> }) & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device[]) => Observable<pebble_device[]>
  }
  /** fetch aggregated fields from the table: "pebble.device" */
  pebble_device_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_bool_exp | null
  }) => pebble_device_aggregateObservableChain & {
    execute: (
      request: pebble_device_aggregateRequest,
      defaultValue?: pebble_device_aggregate,
    ) => Observable<pebble_device_aggregate>
  }) &
    (pebble_device_aggregateObservableChain & {
      execute: (
        request: pebble_device_aggregateRequest,
        defaultValue?: pebble_device_aggregate,
      ) => Observable<pebble_device_aggregate>
    })
  /** fetch data from the table: "pebble.device" using primary key columns */
  pebble_device_by_pk: (args: {
    id: String
  }) => pebble_deviceObservableChain & {
    execute: (request: pebble_deviceRequest, defaultValue?: pebble_device | null) => Observable<pebble_device | null>
  }
  /** fetch data from the table: "pebble.device_record" */
  pebble_device_record: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record[],
    ) => Observable<pebble_device_record[]>
  }) & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record[],
    ) => Observable<pebble_device_record[]>
  }
  /** fetch aggregated fields from the table: "pebble.device_record" */
  pebble_device_record_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_device_record_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_device_record_order_by[] | null
    /** filter the rows returned */
    where?: pebble_device_record_bool_exp | null
  }) => pebble_device_record_aggregateObservableChain & {
    execute: (
      request: pebble_device_record_aggregateRequest,
      defaultValue?: pebble_device_record_aggregate,
    ) => Observable<pebble_device_record_aggregate>
  }) &
    (pebble_device_record_aggregateObservableChain & {
      execute: (
        request: pebble_device_record_aggregateRequest,
        defaultValue?: pebble_device_record_aggregate,
      ) => Observable<pebble_device_record_aggregate>
    })
  /** fetch data from the table: "pebble.device_record" using primary key columns */
  pebble_device_record_by_pk: (args: {
    id: String
  }) => pebble_device_recordObservableChain & {
    execute: (
      request: pebble_device_recordRequest,
      defaultValue?: pebble_device_record | null,
    ) => Observable<pebble_device_record | null>
  }
  /** fetch data from the table: "pebble.status" */
  pebble_status: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_status_order_by[] | null
    /** filter the rows returned */
    where?: pebble_status_bool_exp | null
  }) => { execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Observable<pebble_status[]> }) & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status[]) => Observable<pebble_status[]>
  }
  /** fetch aggregated fields from the table: "pebble.status" */
  pebble_status_aggregate: ((args?: {
    /** distinct select on columns */
    distinct_on?: pebble_status_select_column[] | null
    /** limit the number of rows returned */
    limit?: Int | null
    /** skip the first n rows. Use only with order_by */
    offset?: Int | null
    /** sort the rows by one or more columns */
    order_by?: pebble_status_order_by[] | null
    /** filter the rows returned */
    where?: pebble_status_bool_exp | null
  }) => pebble_status_aggregateObservableChain & {
    execute: (
      request: pebble_status_aggregateRequest,
      defaultValue?: pebble_status_aggregate,
    ) => Observable<pebble_status_aggregate>
  }) &
    (pebble_status_aggregateObservableChain & {
      execute: (
        request: pebble_status_aggregateRequest,
        defaultValue?: pebble_status_aggregate,
      ) => Observable<pebble_status_aggregate>
    })
  /** fetch data from the table: "pebble.status" using primary key columns */
  pebble_status_by_pk: (args: {
    id: Int
  }) => pebble_statusObservableChain & {
    execute: (request: pebble_statusRequest, defaultValue?: pebble_status | null) => Observable<pebble_status | null>
  }
}
