import { request } from 'graphql-request';
import { createClient, linkTypeMap, Client } from '@iotexproject/graphql-typed-client';
import {
  query_rootRequest,
  query_rootPromiseChain,
  query_root,
  mutation_rootRequest,
  mutation_rootPromiseChain,
  mutation_root,
  subscription_rootRequest,
  subscription_rootObservableChain,
  subscription_root
} from "../generated/gql/schema"
import typeJSON from '../generated/gql/typeMap.json';
import { publicConfig } from '../config/public';
import { makeAutoObservable } from 'mobx';
import { RootStore } from '@/store/root';

//@ts-ignore
const typeMap = linkTypeMap(typeJSON);

export class PebbleAPIStore{
  client:Client<query_rootRequest,
    query_rootPromiseChain,
    query_root,
    never,
    never,
    never,
    subscription_rootRequest,
    subscription_rootObservableChain,
    subscription_root>
  private rootStore: RootStore;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get graphAPIURL(){
    return this.rootStore.god.currentChain.graphAPIURL
  }

  get subgraphAPI(){
    return createClient({
      queryRoot: typeMap.query_root,
      subscriptionRoot: typeMap.subscription_root,
      subscriptionCreatorOptions:{
        uri:this.graphAPIURL.replace('https','wss'),
        options:{
          reconnect:true
        }
      },
      fetcher: ({ query, variables }) => request(this.graphAPIURL, query, variables).then(data => ({ data }))
    })
  }
}
