import React from 'react';
import { useRef } from 'react';
import { Button, Flex, Text, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useColorMode, Img } from '@chakra-ui/react';
import {} from '@chakra-ui/icons';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '../../store';
// import { ExclamationCircleOutlined } from '@ant-design/icons';

interface IComponentProps {
  onTryAgain: any;
  isCollectFail: boolean;
  onClose: any;
}

export const ConnectFailDialog = observer((props: IComponentProps) => {
  const { lang } = useStore();
  const { onTryAgain, isCollectFail, onClose } = props;

  const { colorMode } = useColorMode();

  const store = useLocalObservable(() => ({
    close() {
      console.log('关闭');

      onClose();
    },
    tryAgain() {
      console.log('重试');
      onTryAgain();
    }
  }));

  return (
    <Modal
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      onClose={() => {
        store.close();
      }}
      isOpen={isCollectFail}
      size="2xl"
      isCentered
    >
      <ModalOverlay />

      <ModalContent w={{ base: '90%', md: '100%' }}>
        <ModalHeader borderBottom="1px" borderColor="borderLight">
          {lang.t('connect.wallet')}
        </ModalHeader>
        <ModalCloseButton _focus={{}} _hover={{ color: 'brandColor' }} />
        <ModalBody py="1.25rem" color="textPrimary" fontFamily="Roboto">
          <Box>
            <Flex py={2} align="baseline">
              {/* <ExclamationCircleOutlined style={{ color: 'red' }} /> */}

              <Text as="span" pl={2} fontWeight="500" fontFamily="Roboto" fontSize="16px" color={colorMode === 'light' ? 'blackColor' : 'lightColor'}>
                {lang.t('tips.connect_fail')}
              </Text>
            </Flex>
            <Box mb="4" pl={4} fontWeight="400" fontFamily="Roboto" fontSize="14px" color={colorMode === 'light' ? 'text45' : 'lightColor'}>
              {lang.t('tips.connect_fail_tip')}
            </Box>

            {/* <Box pl={4} fontWeight="400" fontFamily="Roboto" fontSize="14px" color={colorMode === 'light' ? 'text45' : 'lightColor'}>
              {lang.t('tips.connect_fail_tip2')}
              <Box
                as="span"
                pl={4}
                style={{ textDecoration: 'underline' }}
                fontWeight="400"
                fontFamily="Roboto"
                fontSize="14px"
                onClick={() => {
                  window.open('https://iopay-wallet.iotex.io', '__blank');
                }}
                color={colorMode === 'light' ? 'brandColor' : 'brandColor'}
              >
                {lang.t('tips.connect_fail_tip3')}
              </Box>
            </Box> */}
          </Box>
        </ModalBody>
        <ModalFooter borderTop="1px" borderColor="borderLight">
          <Button
            size="sm"
            onClick={() => {
              store.close();
            }}
            mr="0.5rem"
            bg="transparent"
            border="1px"
            borderColor="borderBtn"
          >
            {lang.t('cancel')}
          </Button>
          <Button size="sm" onClick={() => store.tryAgain()} color="lightColor" bg="brandColor">
            {lang.t('tryagain')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
