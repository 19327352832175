import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Image, Text, Center, Flex } from '@chakra-ui/react';

export interface IDeviceItem {
  title: string;
  desc: string;
  img: string;
  link: string;
  bg: string;
}

export const DeviceItem = observer(({ title, desc, img, link, bg }: IDeviceItem) => {
  // @ts-ignore
  return (
    <>
      <Box
        bg='linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)'
        maxW='330px'
        borderRadius='20px' pb={5}
        position='relative'
        _hover={{
          color: 'brandColor',
          cursor: "pointer"
        }}
        onClick={() => {window.open(`${link?link:'/'}`, '_blank')}}
      >
        <Box
          w='100%'
          h='100%'
          bg='transparent'
          _hover={{
            boxShadow: 'inset 0px 0px 10px #43C9BA'
          }}
          position='absolute'
          borderRadius='20px' pb={5}
        >
        </Box>
        <Flex
          alignContent='center' justifyContent='center' bgGradient={bg} borderRadius='20px 20px 0px 0'>
          <Image my={6} boxSize={32} borderRadius={'20px'} src={`images/${img}`} objectFit='scale-down' />
        </Flex>
        <Center>
          <Box w='90%'>
            <Text textAlign='center' fontSize={'1.25rem'} fontWeight={500} mt={5}>{title}</Text>
            <Text textAlign='center' fontSize={'0.75rem'} mt={2}>{desc}</Text>
          </Box>
        </Center>
      </Box>
    </>
  )
    ;
});
