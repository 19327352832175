import { makeAutoObservable } from 'mobx';
import  {EthNetworkConfig} from '../../config/NetworkConfig';
import { CallParams } from '../../../type';
import accountAbi from '@/constants/abi/Account.json';
import { RootStore } from '../root';
import BigNumber from 'bignumber.js';

export class AccountState {
  abi = accountAbi;
  name: string = "";
  avatar: string = "";
  owner: string = "";
  etherValue = new BigNumber(1).multipliedBy(1e18).toFixed()

  rootStore: RootStore;
  network = EthNetworkConfig;


  constructor(args: Partial<AccountState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }

  get address(){
    return this.rootStore.god.currentChain.pebbleContracts.ACCOUNT_CONTRACT
  }

  updateProfile(args: Partial<CallParams<[string]>>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: 'updateProfile' }, args));
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }
}
