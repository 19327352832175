import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Table } from '@chakra-ui/table';
import { ITbody } from '@/components/Table/ITbody';
import { IHeader } from '@/components/Table/IHeader';
import { Header } from '@/components/Table/type';
import { CPagination } from '@/components/pagnation';
import { useStore } from '@/store/index';
import { Box } from '@chakra-ui/react';

interface TableComponentProps {
  headers: Header[],
  items?: any[],
  fetch: (pagination) => Promise<{ items: any[], totalItems: number }>,
  timeInterval?:number
  children?:React.ReactNode
}

const ITable = observer(({ headers, items, fetch,timeInterval, children,...props }: TableComponentProps) => {
  const { god } = useStore();
  const intervalRef = useRef(null);

  const store = useLocalObservable(() => ({
    isLoading: false,
    pagination: {
      skip: 0,
      limit: 10,
      page: 1
    },
    items: [],
    totalItems: 0,
    prePage() {
      store.pagination.page = store.pagination.page - 1;
    },
    afterPage() {
      store.pagination.page = store.pagination.page + 1;
    },
    setPage(page) {
      store.pagination.page = page;
    },
    async fetchData(pagination) {
      const { items, totalItems } = await fetch(pagination);
      store.items = items;
      store.totalItems = totalItems;
    }
  }));

  useEffect(() => {

    async function fetchData(){
      store.pagination.skip = (store.pagination.page - 1) * store.pagination.limit;
      store.isLoading = true;
      try {
        await store.fetchData(store.pagination)
        store.isLoading = false
      }catch (e) {
        console.log(e);
        store.isLoading = false
      }
    }
    fetchData();

    if (timeInterval){
      intervalRef.current = setInterval(() => {
        fetchData()
      }, timeInterval);
    }
    return () => clearInterval(intervalRef.current);

  }, [store.pagination.page, god.isConnect, god.currentNetwork.account]);

  return (
    <Box overflowX={{base:'scroll',md:'auto'}}>
      <Table {...props}>
        <IHeader headers={headers} />
        {children}
        <ITbody headers={headers} items={store.items} isLoading={store.isLoading}/>
      </Table>
      <CPagination total={store.totalItems} pagesize={store.pagination.limit} prePage={store.prePage}
                   afterPage={store.afterPage} setPage={store.setPage} currentPage={store.pagination.page} />
    </Box>
  );
});

ITable.displayName = 'ITable';

export default ITable;
