import { makeAutoObservable } from 'mobx';
import  {EthNetworkConfig} from '../../config/NetworkConfig';
import { CallParams } from '../../../type';
import pebbleAbi from '@/constants/abi/pebbleAbi.json';
import PebbleImplAbi from '@/constants/abi/PebbleImpl.json';
import { RootStore } from '../root';
import BigNumber from 'bignumber.js';

export class PebbleState {
  abi = pebbleAbi;
  etherValue = new BigNumber(1).multipliedBy(1e18).toFixed()
  pebbleImplAbi = PebbleImplAbi
  rootStore: RootStore;
  network = EthNetworkConfig;

  constructor(args: Partial<PebbleState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }

  get address(){
    return this.rootStore.god.currentChain.pebbleContracts.PEBBLE_CONTRACT
  }

  setFirmware(args: Partial<CallParams>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: 'setFirmware' }, args));
  }

  setConfig(args:Partial<CallParams>){
    return this.network.execContract(Object.assign({ address: this.address, abi: this.pebbleImplAbi, method: 'setConfig' }, args));
  }

  addDevice(args: Partial<CallParams<[string]>>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.pebbleImplAbi, method: 'proposal', options: {value: this.etherValue} }, args));
  }

  removeDevice(args: Partial<CallParams<[string]>>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: 'remove' }, args));
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }
}
