import React from 'react';
import { publicConfig } from '../../config/public';

import {
  Flex,
  Text,
  Box,
  Image,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Avatar,
  Checkbox,
  useColorMode,
  useColorModeValue,
  Divider,
  Tooltip
} from '@chakra-ui/react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '@/store/index';
import { Upload } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { helper } from '@/lib/helper';
import { AddIcon, CopyIcon } from '@chakra-ui/icons';
import copy from 'copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { DEFAULT_AVATARS } from '@/constants/index';
import { PrimaryButton, PrimaryLineButton } from '@/components/Button';

export const WalletSetupDialog = observer(() => {
  const { lang, deviceStore, god, accountStore } = useStore();
  const color = useColorModeValue('#000000D9', 'white');
  const { colorMode } = useColorMode();
  const avatars = DEFAULT_AVATARS;

  const store = useLocalObservable(() => ({
    buttonDisable: true,
    avatarIndex: 0,
    accountName: '',
    fileName: '',
    avatar: avatars[0].url,
    applicationId: '',
    uri: '',
    version: '',
    isButtonEnable: false,
    loading: false,
    isLoading: false,
    colseModal() {
      store.reset();
      accountStore.closeAccountDialog();
    },
    reset() {
      store.avatarIndex = 0;
      store.uri = '';
      store.applicationId = '';
      (store.version = ''), (store.accountName = '');
      store.applicationId = '';
      // store.devicePublicKey = '';
    },
    onInputChange(e: any, inputType: number) {
      if (inputType === 1 || e.target.value === '') {
        store.accountName = e.target.value;
      }
      if (inputType === 2) {
        store.applicationId = e.target.value;
      }

      if (inputType === 3) {
        store.uri = e.target.value;
      }

      if (inputType === 4) {
        store.version = e.target.value;
      }

      store.buttonEnable();
    },

    buttonEnable() {
      store.buttonDisable = !(store.accountName.length > 0 && store.avatar.length > 0);
    },

    beforeUpload: async (option: any) => {
      // try {
      // 	store.loading = true
      const time = new Date().getTime();
      // 	const uploadedFile = await fleekStorage.upload({
      // 		apiKey: publicConfig.FLEEK_API_KEY as string,
      // 		apiSecret: publicConfig.FLEEK_API_SEC as string,
      // 		key: `${time}-${option.file.name}`,
      // 		data: option.file,
      // 		});
      // 		store.fileName = option.file.name
      // 		store.avatar = uploadedFile.publicUrl
      // 		store.loading = false
      // 		store.buttonEnable()
      // } catch (error) {
      // 	console.log(error);
      // 	store.loading = false
      // }
      const name = `${time}-${option.file.name}`;
      const unordered = {
        description: '',
        mimeType: option.file.type,
        name: name,
        version: 'zora-20210101'
      };

      // alphabetize key
      const ordered: { [key: string]: {} } = {};
      Object.keys(unordered)
        .sort()
        .forEach(key => {
          ordered[key] = unordered[key];
        });

      const metadataJSON = JSON.stringify(ordered); // minify

      console.log(option);

      let formData = new FormData();
      formData.append('upload', option.file);
      formData.append('name', option.file.name);
      formData.append('metadata', metadataJSON);
      store.loading = true;
      try {
        const upload = await axios.post(publicConfig.uploadURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        // Collect fileUrl and metadataUrl from Fleek
        const { fileUrl, metadataUrl } = upload.data;
        store.fileName = option.file.name;
        store.avatar = fileUrl;
        store.loading = false;
        store.buttonEnable();
        console.log(fileUrl, metadataUrl);
      } catch (error) {
        store.loading = false;
      }
    },

    // TODO
    async setupAccount() {
      try {
        console.log('xxxxxx', store.accountName);
        store.isLoading = true;
        const res = await accountStore.details.updateProfile({ params: [store.accountName, store.avatar] });
        console.log('updateProfile', res);
        const receipt = await res.wait();
        console.log('updateProfile', receipt);
        if (receipt.status) {
          console.log('创建成功');
          accountStore.loadAccountDetails();
          god.loadBalance();
          accountStore.closeAccountDialog();
          store.isLoading = false;
        } else {
          console.log('创建失败');
          store.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }));

  return (
    <Modal motionPreset="slideInBottom" onClose={store.colseModal} isOpen={accountStore.isShowSetupAccountDialog} size="xl" isCentered closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent w={{ base: '90%', md: '100%' }} variant="primary" bg="mainColor">
        <ModalHeader>{lang.t('setup.title')}</ModalHeader>
        <ModalCloseButton _focus={{}} onClick={store.colseModal} _hover={{ color: 'brandColor' }} />
        <ModalBody py="1.25rem" color="textPrimary">
          <Box color={color}>
            <Flex fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">
              {lang.t('setup.des')}
            </Flex>
            <Box mt="15px" fontSize="14px" fontStyle="normal" fontWeight="600" lineHeight="23px">
              <Text>{lang.t('connect.wallet')}:</Text>
              <Text color="textWhite50">{god.currentNetwork.account}</Text>
            </Box>
            <Divider mt="15px" />
            <Flex mt="22px" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px" color="textLight85">
              {lang.t('setup.des2')}
            </Flex>
            <Flex mt="15px" alignItems={['flex-start', 'center']} mb="5" direction={['column', 'row']}>
              <Text w="140px" mb={[2, 0]} color="textLight85">
                {lang.t('setup.account.name')}:
              </Text>
              <Input
                ml="4px"
                onChange={e => {
                  store.onInputChange(e, 1);
                }}
                placeholder={'account name'}
                w="70%"
                borderRadius="5px"
              />
            </Flex>
            <Flex mb="5" alignItems={['flex-start', 'center']} direction={['column', 'row']}>
              <Text w="140px" mb={[2, 0]}>
                {lang.t('hotWalletAddress')}:
              </Text>
              {god.isConnect && (
                <Flex alignItems="baseline" w="180px" fontWeight={600}>
                  {helper.string.truncate(god.currentNetwork.account, 12, '...')}
                  <Box
                    cursor="pointer"
                    onClick={() => {
                      copy(god.currentNetwork.account);
                      toast.success(lang.t('address.copied'));
                    }}
                    ml="4px"
                    mt="4px"
                  >
                    <CopyIcon color="brandColor" />
                  </Box>
                </Flex>
              )}
            </Flex>
            <Flex mb="5" direction={['column', 'row']}>
              <Tooltip label={'Your initial Trustream balance'}>
                <Text w="140px">{lang.t('trustream.credit')}:</Text>
              </Tooltip>
              {god.isConnect && (
                <Flex fontWeight={600} alignItems="baseline">
                  {deviceStore.bank.balance.format} IOTX
                </Flex>
              )}
            </Flex>
            <Text color="textWhite50" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="20px">
              {lang.t('setup.note')}
            </Text>
            <Flex mt="15px">
              <Flex direction="column">
                <Flex flexWrap="wrap">
                  {avatars.map((item, index) => {
                    return (
                      <Box
                        borderRadius="50%"
                        position="relative"
                        mb="1rem"
                        onClick={() => {
                          store.fileName = '';
                          store.avatar = item.url;
                          store.avatarIndex = index;
                          store.buttonEnable();
                        }}
                        border={index === store.avatarIndex ? '4px solid #43C9BA' : ''}
                        w="64px"
                        h="64px"
                        mx="5px"
                        key={index}
                        as={Flex}
                        justifyContent="center"
                        alignItems="center"
                        overflow="hidden"
                      >
                        <Checkbox colorScheme={colorMode === 'light' ? 'primary' : 'teal'} hidden={true} isChecked={index === store.avatarIndex} css={{ position: 'absolute', top: 0, right: 0 }} />
                        <Avatar src={item.img} w="60px" h="60px" />
                      </Box>
                    );
                  })}
                  <Box
                    onClick={() => {
                      store.avatar = '';
                      store.avatarIndex = avatars.length + 1;
                    }}
                    ml="5px"
                    w="64px"
                    h="64px"
                  >
                    <Flex
                      bg="linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)"
                      borderRadius="50%"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                      w="64px"
                      h="64px"
                    >
                      <Upload
                        style={{ width: '95px', height: '95px', position: 'relative' }}
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={file => {
                          const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                          if (!isJpgOrPng) {
                            toast.success(lang.t('image.error'));
                          }
                          return isJpgOrPng;
                        }}
                        customRequest={option => {
                          store.beforeUpload(option);
                        }}
                        // action={`${publicConfig.backendServiceURL}/v1/device/avatar`}
                      >
                        <Flex justifyContent="center" flexDirection="column" alignItems="center" w="70px" h="70px">
                          {<AddIcon color="brandColor" />}
                        </Flex>
                        {/* // store.avatar && store.avatarIndex === avatars.length + 1 && */}
                        {store.avatar && store.avatarIndex === avatars.length + 1 && <Image position="absolute" left="0px" top="0px" w="48px" h="48px" src={store.avatar} />}
                      </Upload>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Divider mt="15px" />
        </ModalBody>
        <ModalFooter>
          <PrimaryLineButton onClick={store.colseModal} mr="5">
            {lang.t('cancel')}
          </PrimaryLineButton>
          <PrimaryButton isLoading={store.isLoading} onClick={() => store.setupAccount()} disabled={store.buttonDisable}>
            {lang.t('finish.setup')}
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
