import { makeAutoObservable } from 'mobx';
import  {EthNetworkConfig} from '../../config/NetworkConfig';
import { CallParams } from '../../../type';
import ShipAbi from '@/constants/abi/Ship.json';
import { RootStore } from '../root';

export class ShiperSatate {
  abi = ShipAbi;

  rootStore: RootStore;
  network = EthNetworkConfig;


  constructor(args: Partial<ShiperSatate>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }

  get address(){
    return this.rootStore.god.currentChain.pebbleContracts.SHIPPER
  }

  ship(args: Partial<CallParams>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: 'ship' }, args));
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }
}
