import { makeAutoObservable } from 'mobx';
import { RootStore } from './root';
import { NetworkState } from './lib/NetworkState';
import { ApplicationState } from './lib/ApplicationState';
import coreAbi from '@/constants/abi/Core.json';
import { order_by } from '../generated/gql/schema';
import { StorageState } from '@/store/standard/StorageState';

export class APPState {
  id: string = '';
  uri: string = '';
  content:string='';
  slug:string='';
  logo:string='';
  author:string='';
  status:string='';
  category:number=0
  previews:string[]=[];
  network: NetworkState;
  version:string=''
  avatar:string=''
  direct_link:string=''
  firmware:string = ''
  constructor(args: Partial<APPState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }
}

export class APPStore {
  rootStore: RootStore;
  appList: APPState[] = [];
  app = new ApplicationState({})
  isOperators: boolean = false
  isOpenInstallFirmWareModal:boolean = false;
  isOpenSelectDeviceModal: boolean = false;
  isOpenInstallAppModal: boolean =false
  currentInstallDeviceImei:string=''
  installingDevices =  new StorageState({ key: 'installingDevices' })

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.app.rootStore = this.rootStore
    makeAutoObservable(this);
  }

  get god() {
    return this.rootStore.god;
  }

  get currentNetwork() {
    return this.god.currentNetwork;
  }

  get subgraphAPI(){
    return this.rootStore.pebbleApi.subgraphAPI
  }

  async loadAPPS() {
    const response = await this.subgraphAPI.query({
      pebble_app_v2: [
        {
          order_by:[
            {order:order_by.asc}
          ]
        },
        {
          uri:true,
          id: true,
          slug:true,
          logo:true,
          status:true,
          category:true,
          previews:true,
          content:true,
          author:true,
          direct_link:true,
          order:true,
          firmware:true
        }
      ]
    });
    //@ts-ignore
    this.appList = response.data.pebble_app_v2.map(i => new APPState(i));
    console.log('appList', this.appList, this.appList.length);
    return true
  }

  async loadIsOperators() {
    await this.currentNetwork.multicall([
      this.app.preMulticall({address:this.rootStore.god.currentChain.pebbleContracts.CORE,abi:coreAbi, method: 'isOperator', params: [this.currentNetwork.account], handler: (v) => {
        this.isOperators = v
      }})
    ]);
  }

  closeInstallFirmwareModal(){
    this.isOpenInstallFirmWareModal = false
    this.currentInstallDeviceImei = ''
  }

  openInstallAppModal(){
   this.isOpenInstallAppModal = true
  }

  closeInstallAppModal(){
    this.isOpenInstallAppModal =false
  }

  openSelectDeviceModal(){
    this.isOpenSelectDeviceModal = true
  }

  closeSelectDeviceModal(){
    this.isOpenSelectDeviceModal = false
  }
}
