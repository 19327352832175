import { observer, useLocalObservable } from 'mobx-react-lite';
import { Avatar, Box, Checkbox, Divider, Flex, Image, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { Upload } from 'antd';
import toast from 'react-hot-toast';
// import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useStore } from '@/store/index';
import axios from 'axios';
import { publicConfig } from '../../../../config/public';
import { Button, PrimaryButton, PrimaryLineButton } from '@/components/Button';
import { SmallAddIcon } from '@chakra-ui/icons';
import { AddDeviceLoading } from './AddDeviceLoading';

const VerifyMetadata = observer(({ nextStep, prevStep }: { nextStep: () => void; prevStep: () => void }) => {
  const { lang, deviceStore } = useStore();
  const avatars = [
    {
      img: '/images/avatar1.png',
      url: 'https://storageapi.fleek.co/uu-z-team-bucket/b7a5acf3-c513-4279-944b-72f4dbf17e8c'
    },
    {
      img: '/images/avatar2.png',
      url: 'https://storageapi.fleek.co/raullenchai-team-bucket/1622685030010-avatar2.png'
    },
    {
      img: '/images/avatar3.png',
      url: 'https://storageapi.fleek.co/raullenchai-team-bucket/1622685058243-avatar3.png'
    },
    {
      img: '/images/avatar4.png',
      url: 'https://storageapi.fleek.co/raullenchai-team-bucket/1622685077638-avatar4.png'
    },
    { img: '/images/avatar5.png', url: 'https://storageapi.fleek.co/raullenchai-team-bucket/1622685094594-avatar5.png' }
  ];
  deviceStore.device.avatar = avatars[0].url;
  const store = useLocalObservable(() => ({
    loading: false,
    isLoading: false,
    avatarIndex: 0,
    avatar: avatars[0].url,
    fileName: '',
    buttonDisable: true,
    errorMsg: '',
    get isOpen() {
      return !!this.errorMsg;
    },
    onClose() {
      this.errorMsg = '';
    },
    errors: {
      name: null,
      imei: null,
      sn: null
    },
    get buttonEnable() {
      return !this.errors.name && !this.errors.imei && !this.errors.sn && deviceStore.device.name && deviceStore.device.imei && deviceStore.device.SN;
    },
    onInputChange(key: string, value) {
      if (key === 'SN') {
        return (deviceStore.device[key] = value.toUpperCase());
      }
      deviceStore.device[key] = value;
    },
    beforeUpload: async (option: any) => {
      const time = new Date().getTime();
      const name = `${time}-${option.file.name}`;
      const unordered = {
        description: '',
        mimeType: option.file.type,
        name: name,
        version: 'zora-20210101'
      };

      // alphabetize key
      const ordered: { [key: string]: {} } = {};
      Object.keys(unordered)
        .sort()
        .forEach(key => {
          ordered[key] = unordered[key];
        });

      const metadataJSON = JSON.stringify(ordered); // minify
      let formData = new FormData();
      formData.append('upload', option.file);
      formData.append('name', option.file.name);
      formData.append('metadata', metadataJSON);
      store.loading = true;
      try {
        const upload = await axios.post(publicConfig.uploadURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        // Collect fileUrl and metadataUrl from Fleek
        const { fileUrl, metadataUrl } = upload.data;
        store.fileName = option.file.name;
        store.avatar = fileUrl;
        store.loading = false;
        console.log(fileUrl, metadataUrl);
      } catch (error) {
        store.loading = false;
      }
    },
    async next() {
      store.isLoading = true;
      try {
        const res = await deviceStore.pebble.addDevice({ params: [deviceStore.device.imei, deviceStore.device.name, deviceStore.device.avatar, deviceStore.device.SN] });
        const receipt = await res.wait();
        console.log('addDevice', receipt);
        if (receipt.status) {
          console.log('添加设备成功');
          this.isSuccess = true;
        }
        store.isLoading = false;
        nextStep();
      } catch (error) {
        console.log(error);
        store.isLoading = false;
        switch (error.data.message) {
          case 'execution simulation is reverted due to the reason: invalid imei':
            return (store.errorMsg = lang.t('invalid.imei'));
          case 'execution simulation is reverted due to the reason: invalid sn':
            return (store.errorMsg = lang.t('invalid.sn'));
          case 'execution simulation is reverted due to the reason: device has owner':
            return (store.errorMsg = lang.t('invalid.owner'));
          default:
            return (store.errorMsg = error.data.message);
        }
      }
    }
  }));
  const rules = {
    validateName(value) {
      if (!value) {
        store.errors.name = 'Device Name is required';
      } else if (String(value).length > 64) {
        store.errors.name = 'Device Name must be less then 64 length';
      } else {
        store.errors.name = null;
      }
    },
    validateImei(value) {
      if (!value) {
        store.errors.imei = 'Device IMEI is required';
      } else if (String(value).length != 15) {
        store.errors.imei = 'Deice IMEI length must be 15';
      } else {
        store.errors.imei = null;
      }
    },
    validateSn(value) {
      if (!value) {
        store.errors.sn = 'Device SN is required';
      } else if (value.length !== 10) {
        store.errors.sn = 'Device SN length must be 10';
      } else {
        store.errors.sn = null;
      }
    }
  };
  useEffect(() => {
    deviceStore.device.name && rules.validateName(deviceStore.device.name);
    deviceStore.device.imei && rules.validateImei(deviceStore.device.imei);
    deviceStore.device.SN && rules.validateSn(deviceStore.device.SN);
  });
  return (
    <>
      {store.isLoading ? (
        <AddDeviceLoading />
      ) : (
        <>
          <Box w={{ base: '100%', md: '50%' }} mt={{ base: 10, md: 20 }} textAlign="left">
            <Flex alignItems={['flex-start', 'center']} mb="10" direction={['column', 'row']}>
              <Text fontSize="1rem" fontWeight={400} w="140px" mb={[2, 0]}>
                {lang.t('device.name')}：
              </Text>
              <Flex w="100%" direction="column" flex={1}>
                <Input
                  value={deviceStore.device.name}
                  onChange={e => {
                    rules.validateName(e.target.value);
                    store.onInputChange('name', e.target.value);
                  }}
                  borderRadius="5px"
                  placeholder={lang.t('name.example')}
                  size="sm"
                  borderColor={store.errors.name && 'red'}
                />
                {store.errors.name && (
                  <Text mt={2} color="red">
                    {store.errors.name}
                  </Text>
                )}
              </Flex>
            </Flex>
            <Flex mb="5" direction={['column']}>
              <Flex alignItems={['flex-start', 'center']} direction={['column', 'row']}>
                <Text fontSize="1rem" fontWeight={400} w="140px" mb={[2, 0]}>
                  {lang.t('device.SN')}：
                </Text>
                <Input
                  flex={{ base: 'unset', md: 1 }}
                  onChange={e => {
                    rules.validateSn(e.target.value);
                    store.onInputChange('SN', e.target.value);
                  }}
                  value={deviceStore.device.SN}
                  placeholder={lang.t('sn.example')}
                  size="sm"
                  borderRadius="5px"
                />
              </Flex>
              <Box w="100%" ml={{ base: 0, md: '140px' }} mt={2}>
                <Text mt={2} color="red">
                  {store.errors.sn}
                </Text>
                <Text mt={2} color="textWhite50">
                  A string of 10-digit uppercase characters
                </Text>
              </Box>
            </Flex>
            <Flex mb="5" direction={['column']}>
              <Flex alignItems={['flex-start', 'center']} direction={['column', 'row']}>
                <Text fontSize="1rem" fontWeight={400} w="140px" mb={[2, 0]}>
                  {lang.t('device.imei')}：
                </Text>
                <Input
                  maxLength={15}
                  onChange={e => {
                    rules.validateImei(e.target.value);
                    store.onInputChange('imei', e.target.value);
                  }}
                  type="number"
                  borderRadius="5px"
                  value={deviceStore.device.imei}
                  placeholder={lang.t('imei.example')}
                  size="sm"
                  borderColor={store.errors.imei && 'red'}
                  flex={{ base: 'unset', md: 1 }}
                />
              </Flex>
              <Box w="100%" direction="column" mt={2} ml={{ base: 0, md: '140px' }}>
                <Text mt={2} color="red">
                  {store.errors.imei}
                </Text>
                <Text mt={2} color="textWhite50">
                  A 15-digit number
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box w={{ base: '100%', md: '45%' }}>
            <Text fontSize="14px" mb="5" color="textWhite50">
              {lang.t('add.device.step2.tips')}
            </Text>
            <Flex>
              <Text size="sm" mr="6">
                {lang.t('avatar')}：
              </Text>
              <Flex flexWrap="wrap">
                {avatars.map((item, index) => {
                  return (
                    <Box
                      borderRadius="50%"
                      position="relative"
                      mb="1rem"
                      onClick={() => {
                        store.fileName = '';
                        deviceStore.device.avatar = item.url;
                        store.avatarIndex = index;
                      }}
                      border={index === store.avatarIndex ? '4px solid #43C9BA' : ''}
                      w="64px"
                      h="64px"
                      mx="5px"
                      key={index}
                      as={Flex}
                      justifyContent="center"
                      alignItems="center"
                      overflow="hidden"
                    >
                      <Checkbox hidden={true} isChecked={index === store.avatarIndex} css={{ position: 'absolute', top: 0, right: 0 }} />
                      <Avatar src={item.img} w="60px" h="60px" />
                    </Box>
                  );
                })}
                <Box
                  onClick={() => {
                    store.avatar = '';
                    store.avatarIndex = avatars.length + 1;
                  }}
                  w="70px"
                  ml="5px"
                  h="70px"
                >
                  <Flex position="relative">
                    <Upload
                      style={{ width: '70px', height: '70px', position: 'relative' }}
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={file => {
                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                        if (!isJpgOrPng) {
                          toast.success(lang.t('image.error'));
                        }
                        return isJpgOrPng;
                      }}
                      customRequest={option => {
                        store.beforeUpload(option);
                      }}
                      // action={`${publicConfig.backendServiceURL}/v1/device/avatar`}
                    >
                      <Flex
                        bg="linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)"
                        borderRadius="50%"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                        w="64px"
                        h="64px"
                      >
                        {<SmallAddIcon color="brandColor" w="18" h="18" />}
                      </Flex>
                      {/* // store.avatar && store.avatarIndex === avatars.length + 1 && */}
                      {store.avatar && store.avatarIndex === avatars.length + 1 && (
                        <Image border="1px" borderColor="#F0F0F0" position="absolute" left="0px" top="0px" w="70px" h="70px" src={store.avatar} />
                      )}
                    </Upload>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Box>
          <Flex justifyContent="flex-end" flexDirection={{ base: 'column-reverse', md: 'row' }} mt={{ base: 20, md: 40 }}>
            <Link _hover={{}} display="inherit">
              <PrimaryLineButton h="50px" w="270px" fontWeight="400" mr={10} bgSize="cover" onClick={prevStep} mt={{ base: 5, md: 0 }}>
                {lang.t('back')}
              </PrimaryLineButton>
            </Link>
            <PrimaryButton h="50px" isDisabled={!store.buttonEnable} w="270px" bg="btnPrimaryColor" color="black" fontWeight={400} border="unset" onClick={store.next}>
              {lang.t('next')}
            </PrimaryButton>
          </Flex>
        </>
      )}

      {store.errorMsg && (
        <Modal isOpen={store.isOpen} onClose={store.onClose} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent bg="#1C1B30">
            <ModalHeader>{lang.t('add.device.fail')}</ModalHeader>
            <ModalCloseButton _focus={{}} _hover={{ color: 'brandColor' }} onClick={store.onClose} />
            <ModalBody>
              <Flex alignItems="center">
                <Image src="/images/device/timeout.png" />
                <Text>{store.errorMsg}</Text>
              </Flex>
              <Divider />
              <Flex mt={5} justifyContent="flex-end" mb={2}>
                <Button
                  _hover={{}}
                  _focus={{}}
                  bg="btnPrimaryColor"
                  color="black"
                  fontWeight="normal"
                  onClick={() => {
                    store.onClose();
                    // store.addDevice();
                  }}
                >
                  {lang.t('ok')}
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
});

VerifyMetadata.displayName = 'VerifyMetadata';
export default VerifyMetadata;
