export const DATA_TYPES = [
  {
    key: "gps",
    value: 0x1,
    isChecked: false,
  },
  {
    key: "snr",
    value: 0x2,
    isChecked: false,
  },
  {
    key: "vbat",
    value: 0x4,
    isChecked: false,
  },
  {
    key: "devinfo",
    value: 0x6,
    isChecked: false,
  },
  {
    key: "gas_resistance",
    value: 0x10,
    isChecked: false,
  },
  {
    key: "temperature",
    value: 0x20,
    isChecked: false,
  },
  {
    key: "pressure",
    value: 0x40,
    isChecked: false,
  },
  {
    key: "humidity",
    value: 0x80,
    isChecked: false,
  },
  {
    key: "sensor",
    value: 0xf0,
    isChecked: false,
  },
  {
    key: "temp2",
    value: 0x100,
    isChecked: false,
  },
  {
    key: "gyroscope",
    value: 0x200,
    isChecked: false,
  },
  {
    key: "accelerometer",
    value: 0x400,
    isChecked: false,
  },
  {
    key: "motion",
    value: 0x800,
    isChecked: false,
  },
  {
    key: "action_sensor",
    value: 0xf00,
    isChecked: false,
  },
  {
    key: "light",
    value: 0x1000,
    isChecked: false,
  },
]
