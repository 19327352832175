import React, {useEffect} from 'react';
import { useRef } from 'react';
import {
  Button,
  Flex,
  Text,
  Box,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorMode,
  useToast,
  NumberInput,
  NumberInputField, InputRightElement, Stack, Link, Tooltip
} from '@chakra-ui/react';
import { AiOutlineCopy } from 'react-icons/ai'
import { ChevronDownIcon } from '@chakra-ui/icons';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '../../store';
import Antenna from 'iotex-antenna';
import { notification } from 'antd';
import { toRau } from 'iotex-antenna/lib/account/utils';
import BigNumber from 'bignumber.js';
import { BigNumberInputState } from '@/store/standard/BigNumberInputState';
import { PrimaryButton, PrimaryLineButton } from '@/components/Button';
import { HStack } from '@chakra-ui/layout';
import { helper } from '@/lib/helper';

interface IComponentProps {
  isOpen?: any;
  onClose: any;
}

export const DepositDialog = observer((props: IComponentProps) => {
  const { lang, god, deviceStore } = useStore();
  const { isOpen, onClose } = props;
  const toast = useToast();
  const { colorMode } = useColorMode();
  const types = ['IOTX'];

  const store = useLocalObservable(() => ({
    type: 'IOTX',
    isLoading: false,
    buttonDisable: true,
    amount: new BigNumberInputState({ loading: true }),
    gas: '',
    gasLimit: '2000000',
    dataHex: '1234',
    gasCosLimit: '1',
    step: 0,
    tx_hash: '',
    get coinStatus() {
      return deviceStore.bank.tokenStatus({ amount: store.amount });
    },
    async sendIopay() {
      try {
        if (!store.coinStatus.valid) return;
        store.isLoading = true;
        const res = await deviceStore.bank.deposit({ params: [god.currentNetwork.account] }, store.amount);
        const receipt = await res.wait();
        console.log('sendIopay', receipt);

        if (receipt.status) {
          toast({
            title: 'Success',
            description: receipt.blockHash,
            status: 'success',
            duration: 4000,
            isClosable: true,
            position: 'top'
          });
          deviceStore.loadBankBalanceOf()
          store.isLoading = false;
          store.tx_hash = receipt.transactionHash.replace("0x", "")
          store.setStep(1)
        }
      } catch (error) {
        store.isLoading = false;
        console.log(error);

        notification.error({
          message: `${error.message}`,
          duration: 3
        });
      }
    },
    setStep(value: number) {
      this.step = value
    },
    onAmountChage(e: any, inputType: number) {
      console.log(e.target.value);
      if (/^[0-9]+(\.?[0-9]+)?$/.test(e.target.value) || e.target.value === '') {
        if (inputType === 1) {
          store.amount.setFormat(e.target.value);
        }
        if (inputType === 2) {
          store.gas = e.target.value;
        }
        if (inputType === 3) {
          store.gasLimit = e.target.value;
        }
        if (inputType === 4) {
          store.dataHex = e.target.value;
        }
        if (inputType === 5) {
          store.gasCosLimit = e.target.value;
        }
      }
      store.buttonDisable = true;
    },
    onMenuChange(item: string) {
      store.type = item;
    },
    closeDeposit() {
      store.resetForm();
      store.isLoading = false;
      onClose();
    },
    resetForm() {
      store.amount.setFormat(new BigNumber(1))
      store.gas = '';
      store.gasLimit = '2000000';
      store.gasCosLimit = '1';
      store.dataHex = '1234';
      store.setStep(0);
    },
    onDepositMaxClick() {
      const valueMinusEstimatedGas = new BigNumberInputState({})
      valueMinusEstimatedGas.setValue(deviceStore.bank.tokenBalance.value.minus(10**17))
      store.amount.setFormat(valueMinusEstimatedGas.format)
    }
  }));

  useEffect(() => {
   store.amount.setFormat(1)
  }, [])

  return (
    <Modal
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      onClose={() => {
        store.closeDeposit();
      }}
      isOpen={isOpen}
      size="lg"
      isCentered
    >
      <ModalOverlay />

      <ModalContent w={{ base: '90%', md: '100%' }} bg='mainColor'>
        <ModalHeader color='textLight85'>
          {store.step === 0 && (<>{lang.t('deposit.title')}</>)}
          {store.step === 1 && (<>{lang.t('deposit.success')}!</>)}
        </ModalHeader>
        <ModalCloseButton _focus={{}} onClick={onClose} _hover={{ color: 'brandColor' }} />
        <ModalBody pt={8} pb={16} color='textLight85' >
          {store.step === 0 && (
            <Stack spacing={4}>
              <Flex color='textWhite50' alignItems={{base: "flex-start", md: "center"}} flexDirection={{base: "column", md: "row"}} mb="3">
                {lang.t('deposit.tips')}
              </Flex>
              <Flex alignItems={{base: "flex-start", md: "center"}} flexDirection={{base: "column", md: "row"}}>
                <Text flex="none" w={{base: "max-content", md: "140px"}}>
                  {lang.t('deposit.amount')}：
                </Text>
                <InputGroup>
                  <NumberInput _hover={{}} step={1} flex="1" value={store.amount.format} min={1} onChange={value => {
                    store.amount.setFormat(value)
                  }}>
                    <NumberInputField />
                  </NumberInput>

                  <InputRightElement width='4.5rem' mr={"100px"}>
                    <Button h='1.5rem' size='sm' variant={'ghost'} onClick={() => {
                      store.onDepositMaxClick()
                    }}>
                      Max
                    </Button>
                  </InputRightElement>

                  <InputRightElement borderRightRadius='md' bg='rgba(217, 217, 217, 0.2)' w="100px">
                    <Menu>
                      <MenuButton w="100%" textAlign="center" as={Box} bgColor="clear" size="sm">
                        {store.type}
                      </MenuButton>
                    </Menu>
                  </InputRightElement>
                </InputGroup>
              </Flex>
              {/* <Flex alignItems="center" mb="3">
              <Text size="sm" w="220px">
                {lang.t("deposit.gasPrice")}：
              </Text>
              <InputGroup size="sm">
                <Input
                  placeholder="0"
                  value={store.gas}
                  size="sm"
                  onChange={(e) => {
                    store.onAmountChage(e, 2);
                  }}
                />
                <InputRightAddon w="100px" children="Qev" width="20%" style={{ justifyContent: 'center' }} />
              </InputGroup>
            </Flex>
            <Flex alignItems="center" mb="3">
              <Text size="sm" w="220px">
                {lang.t("deposit.gasLimit")}：
              </Text>
              <Input
                placeholder="0"
                value={store.gasLimit}
                size="sm"
                onChange={(e) => {
                  store.onAmountChage(e, 3);
                }}
              />
            </Flex>
            <Flex alignItems="center" mb="3">
              <Text size="sm" w="220px">
                {lang.t("deposit.dataHex")}：
              </Text>
              <Input
                value={store.dataHex}
                size="sm"
                onChange={(e) => {
                  store.onAmountChage(e, 4);
                }}
              />
            </Flex>
            <Flex alignItems="center" mb="3">
              <Text size="sm" w="220px">
                {lang.t("deposit.gasCostList")}：
              </Text>
              <Input
                placeholder="0"
                value={store.gasCosLimit}
                size="sm"
                onChange={(e) => {
                  store.onAmountChage(e, 5);
                }}
              />
            </Flex> */}
            </Stack>
          )}
          {store.step === 1 && (
            <Stack>
              <Text mb={2}>You have successfully deposited {store.amount.format} IOTX to your portal account.</Text>
              <HStack>
                <Text color={'textWhite50'} minW={"50%"}>{lang.t('deposit.account')}:</Text>
                <Text>{helper.string.truncate(god.currentNetwork.account, 12, '...')}</Text>
              </HStack>
              <HStack>
                <Text color={'textWhite50'} minW={"50%"}>{lang.t('deposit.amount')}:</Text>
                <Text>{store.amount.format} IOTX</Text>
              </HStack>
              <HStack align={'start'}>
                <Text color={'textWhite50'} minW={"50%"}>{lang.t('tx.hash')}:</Text>
                <HStack w={"50%"} textColor={'brandColor'}>
                  <Tooltip label={store.tx_hash}>
                    <Link href={god.currentChain.explorerURL + '/tx/' + store.tx_hash}>
                      {helper.string.truncate(store.tx_hash, 20, '...')}
                    </Link>
                  </Tooltip>
                  <Box
                    onClick={() => {
                      navigator.clipboard.writeText(store.tx_hash)
                      toast({
                        title: 'Copied to clipboard',
                        status: 'success',
                        duration: 1000,
                        isClosable: true,
                        position: 'bottom'
                      });
                    }}
                    _hover={{cursor: "pointer"}}
                  >
                    <AiOutlineCopy />
                  </Box>
                </HStack>
              </HStack>
              <HStack>
                <Text color={'textWhite50'} minW={"50%"}>{lang.t('remaining.credit')}:</Text>
                <Text>{deviceStore.bank.balance.format} IOTX</Text>
              </HStack>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter borderTop="1px" borderColor="white10">
          <PrimaryLineButton
            hidden={store.step !== 0}
            w="140px"
            onClick={() => {
              store.closeDeposit();
            }}
            mr="0.5rem"
          >
            {lang.t('cancel')}
          </PrimaryLineButton>
          <PrimaryButton
            hidden={store.step !== 0}
            w="140px"
            isLoading={store.isLoading}
            onClick={() => {
              store.sendIopay()
            }}
            disabled={!store.coinStatus.valid}
          >
            {store.coinStatus.valid ? lang.t('deposit') : lang.t("insufficient.balance")}
          </PrimaryButton>
          <PrimaryButton
            hidden={store.step !== 1}
            w="140px"
            onClick={() => {
              onClose()
              store.resetForm()
            }}
          >
            {lang.t('ok').toUpperCase()}
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
