import React, { useEffect, useState } from 'react';
import { Alert, AlertIcon, CloseButton, Text } from '@chakra-ui/react';
import { useStore } from '@/store/index';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { ExclamationCircle } from '@/components/Icons';

export const BalanceAlert = observer(() => {
  const [show, setShow] = useState(true);
  const { deviceStore,lang } = useStore();
  const store = useLocalObservable(() => ({
    get showAlert() {
      return !deviceStore.bank.balanceStatus
    }
  }));
  return (
    <>
      {show && store.showAlert && <Alert color='#FAAD14' bg='rgba(18, 18, 34, 0.5)' status='warning' mb={5}>
        <ExclamationCircle />
        {lang.t('credit.alert')}
        {/*<CloseButton position='absolute' right='8px' top='8px' onClick={() => setShow(false)} />*/}
      </Alert>
      }
    </>
  );
});
