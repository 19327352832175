import React from "react";
import { Box } from '@chakra-ui/react';

const DeviceSignal = ({ snr }) => {
  const getSnr = (snr: number) => {
    let path = 'M12.8691 7.65317C11.4719 6.5811 9.76007 6 7.999 6C6.23792 6 4.52606 6.5811 3.12891 7.65317L7.999 14L12.8691 7.65317Z';
    if (snr >= 21) {
      path = 'M10.0295 11.354C9.38247 11.1213 8.69595 11 7.99915 11C7.30233 11 6.61578 11.1213 5.96875 11.354L7.99911 14L10.0295 11.354Z';
    } else if (snr < 21 && snr >= 14) {
      path = 'M12.8691 7.65317C11.4719 6.5811 9.76007 6 7.999 6C6.23792 6 4.52606 6.5811 3.12891 7.65317L7.999 14L12.8691 7.65317Z'
    } else if (snr < 14 && snr >= 7) {
      path = 'M11.6528 9.23988C10.6049 8.43582 9.32103 8 8.00023 8C6.67942 8 5.39552 8.43582 4.34766 9.23988L8.00023 14L11.6528 9.23988Z';
    } else if (snr < 7 && snr >= 0) {
      path = 'M10.0295 11.354C9.38246 11.1213 8.69592 11 7.99912 11C7.30231 11 6.61578 11.1213 5.96875 11.354L7.99912 14L10.0295 11.354Z';
    }
    return path;
  };
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" d="M15.3056 4.47976C13.2099 2.87165 10.6421 2 8.00045 2C5.35884 2 2.79104 2.87165 0.695312 4.47976L8.00045 14L15.3056 4.47976Z" fill="#43C9BA"/>
      <path d={getSnr(snr)} fill="#43C9BA"/>
    </svg>
  );
};
export default DeviceSignal;
