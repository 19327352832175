import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { Box, Checkbox, Flex, Heading, Image, Link, Text } from '@chakra-ui/react';
import { Button, PrimaryLineButton,PrimaryButton } from '@/components/Button';
import { useStore } from '@/store/index';
import { Link as ReactLink } from 'react-router-dom';

const BeforeStart = observer(({ nextStep }: { nextStep: () => void }) => {
  const { lang } = useStore();
  const store = useLocalObservable(() => ({
    isChecked: false,
    toggleChecked(checked) {
      this.isChecked = checked;
    }
  }));
  return (
    <>
      <Flex alignItems={{base:'center',xl:'flex-start'}} flexDirection={{base:'column',xl:'row'}} textAlign='left'>
        <Image src='/images/img_imei.png' />
        <Box pl={{base:0,xl:24}} pr={{base:0,xl:10}} mt={{base:10,md:0}}>
          <Text  fontSize='1rem' whiteSpace='pre-line'>{lang.t('add.new.device.tips')}</Text>
          <Box pl={{base: 0, xl: 4}} mb={8}>
            <Text  fontSize='1rem' whiteSpace='pre-line'>1. {lang.t('add.new.device.tips1')}  <Link _hover={{color:'brandColor'}} _focus={{}} target="_blank" href='https://docs.iotex.io/verifiable-data/pebble-tracker/quick-start' color='brandColor' isExternal>{lang.t('add.new.device.tips1.1')}</Link></Text>
            <Text  fontSize='1rem' whiteSpace='pre-line'>2. {lang.t('add.new.device.tips2')} </Text>
            <Flex>
              <Text fontSize='1rem'>3. </Text>&nbsp;<Text  fontSize='1rem' whiteSpace='pre-line'> {lang.t('add.new.device.tips3')} </Text>
            </Flex>
          </Box>
          <Link  _hover={{color:'brandColor'}} _focus={{}}  fontSize="1rem" target="_blank" href='https://docs.iotex.io/verifiable-data/pebble-tracker/quick-start' color='brandColor' isExternal>{lang.t('Quick Start Guide')}</Link>
          <Heading color='white' as='h3' mt={8}>{lang.t('Terms of use')}</Heading>
          <Text mt={5} fontSize='14px'
                color='rgba(255, 255, 255, 0.45)'>{'Please take some time to review our Terms of service and Privacy policy'}</Text>
          <Link display='block' _focus={{}} _hover={{color:'brandColor'}} href='/term-of-use' isExternal color='brandColor'>{'Terms of service >>'}</Link>
          <Link _hover={{color:'brandColor'}} _focus={{}} href='/privacy-policy' color='brandColor' isExternal>{'Privacy policy >>'}</Link>
          <Checkbox mt={5} isChecked={store.isChecked}
                    onChange={(e) => store.toggleChecked(e.target.checked)}>{'I have read and accept the Terms of service and Privacy policy'}</Checkbox>
        </Box>
      </Flex>
      <Flex justifyContent='flex-end' mt={{base:10,md:40}} flexDirection={{base:'column-reverse',md:'row'}}>
        <Link as={ReactLink} to='/device' _hover={{}}>
          <PrimaryLineButton fontWeight='400'
                             mr={10}
                             mt={{base:5,md:0}}
                             color='white'
                             h='50px'
                             w='270px'
                             size='md'>
            {lang.t('cancel')}
          </PrimaryLineButton>
        </Link>
        <PrimaryButton  h='50px' isDisabled={!store.isChecked} w='270px' fontWeight={400} onClick={nextStep}>
          {lang.t('next')}
        </PrimaryButton>
      </Flex>
    </>
  );
});
BeforeStart.displayName = 'BeforeStart';
export default BeforeStart;
