import { observer } from 'mobx-react-lite';
import { helper } from '@/lib/helper';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { DeviceState } from '@/store/lib/DeviceState';
import { useStore } from '@/store/index';

export const DeviceAddress = observer(({ item }: { item: DeviceState }) => {
  const { god } = useStore();

  return (
    <>
      <Text>{helper.string.truncate(god.displayAddressType==='0x'?item.address:item.ioAddress, 12, '...')}</Text>
    </>
  );
});
