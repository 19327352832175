import React from "react"
import { Button, ButtonProps } from '@chakra-ui/react';
interface IComponentProps extends ButtonProps{
  text?:string
  children?:React.ReactNode
}
const PrimaryButton=({text,children,...props}:IComponentProps)=>{
  return(
    <>
      <Button  bg='btnPrimaryColor' color='black' _hover={{color:'white'}} _focus={{}} _active={{}} fontWeight={500}  {...props}>
        {text}
        {children}
      </Button>
    </>
  )
}

export default PrimaryButton
