import React from "react"
import { Td,Tr } from "@chakra-ui/table"
import { getObjectValueByPath } from "./helpers"
import { observer } from 'mobx-react-lite';
import { Header } from '@/components/Table/type';
interface ItemProps{
  header: Header,
  item?: any,
}
const Item=observer(({item,header}:ItemProps)=>{
  let value = getObjectValueByPath(item,header.value)

  if (header.formatter){
    value = header.formatter(value)
  }

  if (header.children){
    value = header.children(item)
  }


  return(
    <Td width={header.width?header.width:'auto'}>
      {value}
    </Td>
  )
})

interface RowProps{
  headers: Header[],
  item?: any[],
}

export const Row = observer(({headers,item}:RowProps)=>{
  return (
    <Tr _hover={{boxShadow:'0px 0px 20px rgba(142, 159, 179, 0.5)', borderRadius:'20px'}}>
      {headers.map((header, index) => <Item key={`item_${index}`} header={header} item={item} />)}
    </Tr>
  )
})
