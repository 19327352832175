import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  Box,
  Button,
  Flex, Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay, SimpleGrid,
  Text
} from '@chakra-ui/react';
import { useStore } from '@/store/index';
import {  useHistory } from 'react-router-dom';

const Item = ({img,title,onClick})=>{

  const bg=()=>{
    switch (title) {
      case 'Pebble':
        return'linear-gradient(118.23deg, #43C9BA -0.53%, #9643C9 100%), linear-gradient(147.16deg, rgba(255, 255, 255, 0.1) 14.71%, rgba(255, 255, 255, 0) 114.16%)';
      case 'Ucam':
        return  ' linear-gradient(90deg, rgba(88, 189, 233, 0.1) 20%, rgba(120, 143, 244, 0.1) 100%)'
      case 'Dev Board':
        return 'linear-gradient(118.18deg, rgba(57, 226, 104, 0.1) 0%, rgba(41, 195, 228, 0.1) 104.54%)'
    }
  }

  return (
    <Flex cursor={onClick?'pointer':'not-allowed'}  onClick={onClick} alignItems='center' justifyContent='center' flexDirection='column' w='152px' h='152px' borderRadius='20px' opacity={title==='Pebble'?1:'0.4'} bg={bg()}>
      <Flex w={title==='Dev Board'?'50%':'30%'}  minH='50%'>
        <Image src={img} objectFit={'scale-down'}/>
      </Flex>
      <Text mt={4} textAlign='center' color='black'>{title}</Text>
    </Flex>
  )
}

const ChooseDeviceModal = ({ isOpen, onClose }) => {
  const { lang } = useStore();
  const router = useHistory()
  const devices = [
    {title:'Pebble',img:'/images/device/pebble.png',onClick:()=>router.push('/device/add')},
    {title:'Ucam',img:'/images/device/ucam.png'},
    {title:'Dev Board', img:'images/device/raspberry.png'}
  ]
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size='2xl'>
        <ModalOverlay />
        <ModalContent w={{base:'80%',md:'600px'}}  bg='linear-gradient(0deg, rgba(23, 22, 42, 0.5), rgba(23, 22, 42, 0.5)), #2E2D40'>
          <ModalHeader>
            <Text>{lang.t('select.device')}</Text>
          </ModalHeader>
          <ModalCloseButton _focus={{}} onClick={onClose} _hover={{color:'brandColor'}}/>
          <ModalBody pb={10}>
            <SimpleGrid columns={[1,3]} spacingY='20px' justifyItems='center'>
              {
                devices.map(device=><Item key={device.title} title={device.title} img={device.img} onClick={device.onClick}/>)
              }
            </SimpleGrid>

          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default observer(ChooseDeviceModal);
