import React, { useEffect } from 'react';
import { Box, Heading, useColorMode } from '@chakra-ui/react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useStore } from '@/store/index';

interface IComponentProps {
  title?: any;
  showSubTitle: boolean;
  unit: string;
  data: Array<any>;
  time: Array<any>;
  type: 'tep' | 'air' | 'hum' | 'gas' | 'snr' | 'bat' | 'gry' | 'acc' | 'light';
  lineColor?:string;
}

export const LineChart = observer((props: IComponentProps) => {
  const { colorMode } = useColorMode()
  const color = colorMode === 'light' ? '#000000': '#FCFCFC'

  const getAverage = (abc) => {
    return abc.reduce((num, item, index) => {
        if(index != abc.length-1) {
            return num+item;
        } else {
            return ((num+item)/abc.length).toFixed(2);
        }
    })
  }

  let options: any
  if (props.data && (props.type === 'gry' || props.type == 'acc')) {
    const dataX: any[] = []
    const dataY: any[] = []
    const dataZ: any[] = []

    for (let index = 0; index < props.data.length; index++) {
      const element = JSON.parse(props.data[index]);
      dataX.push(element[0]? element[0] : 0)
      dataY.push(element[1]? element[1] : 0)
      dataZ.push(element[2]? element[2] : 0)
    }

    options = {
      chart: {
        height: 300,
      },
      title: {
        text: '',
        align: 'left',
        style: {
          color: props.lineColor||color,
          fontSize: '1rem',
          fontWeight: 'bold'
        }
      },
      legend: {
        enabled: false
      },
      xAxis: {
        visible: false,
        categories: [props.time[0], props.time[props.time.length - 1]]
      },
      yAxis: {
        visible: false
      },
      tooltip: {
        shared: true,
        valueSuffix:  props.unit
      },
      plotOptions: {
        areaspline: {
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 1,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: [
        {
          name: '',
          data: [dataX[0], dataX[dataX.length -1]]
        },
        {
          name: '',
          data: [dataY[0], dataY[dataX.length -1]]
        },
        {
          name: '',
          data: [dataZ[0], dataZ[dataX.length -1]]
        }
      ]
    };
  } else {
    const text = `${props.data[props.data.length-1]} ${props.unit}`;
    let formatData = []
    props.data.forEach((item, index) => {
      formatData.push([props.time[index], item])
    })
    options = {
      chart: {
        type: 'line',
        zoomType: 'x',
        height: 300,
        backgroundColor: 'transparent',
        color: props.lineColor||color,
      },
      title: {
        text: text,
        align: 'left',
        style: {
          color: color,
          fontSize: '1rem',
          fontWeight: 'bold'
        }
      },
      legend: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%m-%d',
          week: '%m-%d',
          month: '%Y-%m',
          year: '%Y'
        },
        visible: true,
      },
      yAxis: {
        visible: true,
        gridLineWidth: 0,
      },
      tooltip: {
        shared: true,
        valueSuffix:  props.unit,
      },
      plotOptions: {
        areaspline: {
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      series: [
        {
          name: '',
          data: formatData,
          color: props.lineColor
        }
      ]
    };
  }



  return (
    <Box w="100%" h="100%" bg='#17162A' borderRadius='20px'>
      <Heading color={colorMode === 'light' ? 'blackAlpha.600' : 'lightColor'} size="sm" pl="10px" pt="15px" pb="10px">
        {props.title}
      </Heading>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
});
