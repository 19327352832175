import React, { useEffect } from 'react';
import { Header } from '@/components/Header-pebble';
import { Flex, Box, useBreakpointValue } from '@chakra-ui/react';
import { Sider } from './Sider';
import { useStore } from '../store';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { BalanceAlert } from './BalanceAlert';
import { SIDEBAR_WIDTH_FULL, SIDEBAR_WIDTH_REDUCED } from './Sider/config';

export const Layout = observer(({ children, breadName, breadPath }:{children:any,breadName:string,breadPath:string}) => {
  const router = useHistory();
  const isCollapsed = useBreakpointValue({base:true,xl:false})
  const store = useLocalObservable(() => ({
    clickBread: (path: string) => {
      router.push(path);
    },
    currentPath:'',
    get showBg(){
      return this.currentPath==='/home'
    },
    collapsed: false,
    toggleCollapsed(){
      this.collapsed=!this.collapsed
    }
  }));

  useEffect(()=>{
    store.collapsed = isCollapsed
    store.currentPath = breadPath
  },[isCollapsed,breadPath])

  return (
    <>
      <Header />
      <Flex css={{ height: 'calc(100vh - 64px)' }} overflow='hidden'>
        <Sider collapsed={store.collapsed} toggleCollapsed={store.toggleCollapsed}/>
        <Box ml={{base:0,lg:`${store.collapsed?SIDEBAR_WIDTH_REDUCED:SIDEBAR_WIDTH_FULL}px`}} flex='1' py='1rem' px={{base:'1rem',md:'1.25rem'}} h='100%' overflowY='auto' bg='mainColor' backgroundImage={store.showBg?'url(images/home/bg.png)':null} bgRepeat='no-repeat' bgPos='right bottom'>
          <BalanceAlert />
          <Box maxW='1100px' mx='auto' h='100%'>
            {children}
          </Box>
        </Box>
      </Flex>
    </>
  );
})
